export const customAds = [
  {
    id: 1,
    title: "IM",
    name: "Miodrag Perunovic",
    image: "miodrag.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/channel/UChxLXqMph7cRG_MHIIhlZ7A",
      },
      {
        type: "Chessable",
        url: "https://www.chessable.com/author/MiodragPerunovic",
      },
      { type: "Website", url: "https://bigmio.com" },
    ],
  },
  {
    id: 2,
    title: "IM",
    name: "Alex Banzea",
    image: "banzea.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/c/AlexBanzea",
      },
      {
        type: "Chessable",
        url: "https://www.chessable.com/author/alexbanzea/",
      },
    ],
  },
  {
    id: 3,
    title: "NM",
    name: "Nelson Lopez",
    image: "chessvibes.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/@ChessVibesOfficial",
      },
      { type: "Website", url: "https://chessvibes.net" },
    ],
  },
  {
    id: 4,
    title: "GM",
    name: "Daniel Naroditsky",
    image: "naroditsky.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/channel/UCHP9CdeguNUI-_nBv_UXBhw",
      },
      { type: "Twitch", url: "https://www.twitch.tv/gmnaroditsky" },
      { type: "Patreon", url: "https://www.patreon.com/danielnaroditsky" },
    ],
  },
  {
    id: 5,
    title: "GM",
    name: "Arturs Neiksans",
    image: "neiksans.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/channel/UChrqQ5BlmmGkaVmraYQw-IQ",
      },
      {
        type: "Chessable",
        url: "https://www.chessable.com/author/GMNeiksans",
      },
      { type: "Twitch", url: "https://www.twitch.tv/gmneiksans" },
      { type: "Patreon", url: "https://www.patreon.com/GMNeiksans" },
    ],
  },
  {
    id: 6,
    name: "Hanging Pawns",
    image: "hangingpawns.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/@HangingPawns",
      },
      { type: "Patreon", url: "https://www.patreon.com/c/hangingpawns" },
    ],
  },
  {
    id: 7,
    name: "St Louis Chess Club",
    image: "stlouis.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/channel/UCM-ONC2bCHytG2mYtKDmIeA",
      },
      { type: "Website", url: "https://saintlouischessclub.org/" },
    ],
  },
  {
    id: 8,
    title: "IM",
    name: "Eric Rosen",
    image: "rosen.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/@eric-rosen",
      },
      { type: "Twitch", url: "https://www.twitch.tv/imrosen" },
      { type: "Website", url: "https://imrosen.com" },
    ],
  },
  {
    id: 9,
    name: "Chess Dojo",
    image: "chessdojo.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/chessdojo",
      },
      { type: "Twitch", url: "https://www.twitch.tv/chessdojo" },
      { type: "Website", url: "https://www.chessdojo.club/" },
      { type: "Patreon", url: "https://www.patreon.com/ChessDojo" },
    ],
  },
  {
    id: 10,
    name: "ChessBrah",
    image: "chessbrah.png",
    socials: [
      {
        type: "YouTube",
        url: "https://www.youtube.com/chessbrah",
      },
      { type: "Twitch", url: "https://www.twitch.tv/chessbrah" },
      { type: "Website", url: "https://chessbrahstore.com" },
    ],
  },
  {
    id: 11,
    name: "ChessMood",
    image: "chessmood.png",
    socials: [
      { type: "Website", url: "https://chessmood.com/" },
      {
        type: "YouTube",
        url: "https://www.youtube.com/@ChessMood",
      },
      { type: "Twitch", url: "https://www.twitch.tv/chessmood" },
    ],
  },
];
