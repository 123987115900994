import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import useLevelColours from "../Components/useLevelColours";

const MovesCard = ({ moves, selectedMoveId, setSelectedMoveId }) => {
  const [collapsedLines, setCollapsedLines] = useState(new Set()); // ✅ Track only collapsed lines

  const selectedMoveRef = useRef(null);

  const toggleLineExpansion = (moveId) => {
    setCollapsedLines((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(moveId)) {
        newExpanded.delete(moveId); // Collapse line
      } else {
        newExpanded.add(moveId); // Expand line
      }
      return newExpanded;
    });
  };

  const handleMoveClick = (node) => {
    if (!node || !node.id) return;

    // Update the selected move ID
    setSelectedMoveId(node.id);
  };

  const levelColors = useLevelColours();

  const buildMoveLines = (
    moveId = "root",
    level = 0,
    lines = [],
    currentLine = null,
    moveNumber = 1, // ✅ Ensure move numbers start from 1
    inheritedMoveNumber = null
  ) => {
    if (!moveId || !moves[moveId]) return lines;

    // ✅ Skip "root" and start from root.next
    if (moveId === "root" && moves[moveId].next) {
      return buildMoveLines(moves[moveId].next, level, lines, [], 1);
    }

    const node = moves[moveId];

    // ✅ Initialize `currentLine` if it's null (first move)
    if (!currentLine) {
      currentLine = [];
    }

    // ✅ Assign correct move number (inherit from parent for variations)
    const assignedMoveNumber = inheritedMoveNumber ?? moveNumber;

    // ✅ Store the move number in the move object
    const moveData = {
      ...node,
      id: moveId, // ✅ Ensure move ID is stored
      level,
      moveNumber: assignedMoveNumber, // ✅ Correct move numbering
    };

    currentLine.push(moveData);

    // ✅ If variations exist, push the current line and process variations separately
    if (node.variations && node.variations.length > 0) {
      lines.push([...currentLine]); // Save the current line before processing variations

      node.variations.forEach((variationId) => {
        buildMoveLines(
          variationId,
          level + 1,
          lines,
          [], // Start a new indented line
          moveNumber, // ✅ Maintain move number for variations
          assignedMoveNumber // ✅ Inherit move number for variations
        );
      });

      currentLine = []; // Reset current line after variations
    }

    // ✅ If the move has a `next`, continue in the same line and increment move number after a White move
    if (node.next) {
      return buildMoveLines(
        node.next,
        level,
        lines,
        currentLine,
        moveNumber + 1, // ✅ Increment after White moves only
        null // ✅ Reset inherited move number for mainline moves
      );
    }

    // ✅ If no more moves, push the last collected line (if not empty)
    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    return lines;
  };

  const displayMoveLines = () => {
    const lines = buildMoveLines(); // Get structured move lines
    let visible = true; // Track whether lines should be shown based on parents
    let lastLevel = 0;

    return (
      <div
        className="scrollable-container"
        style={{
          height: "400px",
          overflowY: "auto",
        }}
      >
        {lines.map((line, lineIndex) => {
          const firstMove = line[0]; // First move in this line
          const nextLine = lines[lineIndex + 1]; // Look ahead to the next line
          const hasVariations = nextLine && nextLine[0].level > firstMove.level;

          const isCollapsed = !collapsedLines.has(firstMove.id);

          // ** Visibility Logic **
          if (firstMove.level === 0) {
            visible = true; // Reset visibility when reaching a top-level move
          }

          if (firstMove.level <= lastLevel) {
            visible = true;
          }

          if (!visible) {
            return null; // Skip rendering if hidden by a collapsed parent
          }

          // If this line has variations and is collapsed, hide all deeper levels
          if (hasVariations && !isCollapsed) {
            visible = false;
          }

          lastLevel = firstMove.level;

          return (
            <div
              key={lineIndex}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: `${firstMove.level * 20}px`,
                color: levelColors[firstMove.level % levelColors.length],
              }}
            >
              {/* ✅ Move Toggle OUTSIDE the Move Container */}
              <span
                style={{
                  width: "30px important!", // ✅ Fixed width to maintain alignment
                  textAlign: "center",
                  display: "inline-block",
                  cursor: hasVariations ? "pointer" : "default",
                  fontWeight: "normal",
                  marginRight: "6px",
                  fontFamily: "monospace",
                }}
                onClick={
                  hasVariations ? () => toggleLineExpansion(firstMove.id) : null
                }
              >
                {hasVariations
                  ? !isCollapsed
                    ? "[+]"
                    : "[-]"
                  : "\u00A0\u00A0\u00A0"}
              </span>

              {/* ✅ Wrap Moves Inside a `div` to Keep them Together */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {/* Render the move sequence */}
                {line.map((move, index) => {
                  const fullMoveNumber = Math.ceil(move.moveNumber / 2);
                  const isWhiteMove = move.moveNumber % 2 !== 0;
                  const isStartOfLine = index === 0;

                  let movePrefix = "";
                  if (isWhiteMove) {
                    movePrefix = `${fullMoveNumber}. `;
                  } else if (isStartOfLine) {
                    movePrefix = `${fullMoveNumber}.. `;
                  }

                  return (
                    <React.Fragment key={move.id}>
                      <span
                        ref={
                          selectedMoveId === move.id ? selectedMoveRef : null
                        }
                        style={{
                          paddingRight: "5px",
                          cursor: "pointer",
                          display: "inline",
                          fontWeight:
                            selectedMoveId === move.id ? "bold" : "normal",
                          textDecoration:
                            selectedMoveId === move.id ? "underline" : "none",
                        }}
                        onClick={() => handleMoveClick(move)}
                      >
                        {movePrefix}
                        {move.san}
                      </span>
                      {move.transpositionParent &&
                        moves[move.transpositionParent] &&
                        moves[moves[move.transpositionParent].next] && (
                          <span
                            style={{
                              paddingRight: "5px",
                              cursor: "pointer",
                              display: "inline",
                              color: "grey",
                            }}
                            onClick={() => {
                              const transpositionMove = {
                                ...moves[move.transpositionParent],
                                id: move.transpositionParent, // ✅ Inject the ID manually
                              };

                              handleMoveClick(transpositionMove);
                            }}
                          >
                            ↪T
                          </span>
                        )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (selectedMoveRef.current) {
      selectedMoveRef.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [selectedMoveId]);

  return <Box>{displayMoveLines()}</Box>;
};

export default MovesCard;
