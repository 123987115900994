import { createContext, useEffect, useState, useMemo } from "react";
import { createTheme } from "@mui/material";

// Color Design Tokens
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        background: { 100: "#000000", 200: "#111111" },
        black: {
          100: "#121212",
          200: "#2a2a2a",
          300: "#414141",
          400: "#595959",
          500: "#717171",
          600: "#898989",
          700: "#a0a0a0",
          800: "#b8b8b8",
          900: "#d0d0d0",
          1000: "#e7e7e7",
          1100: "#ffffff",
        },
        grey: {
          100: "#b8b8b8",
          200: "#bfbfbf",
          300: "#c6c6c6",
          400: "#cdcdcd",
          500: "#d4d4d4",
          600: "#dcdcdc",
          700: "#e3e3e3",
          800: "#eaeaea",
          900: "#f1f1f1",
        },
        blue: {
          100: "#102b37",
          200: "#123240",
          300: "#153949",
          400: "#174052",
          500: "#1a475b",
          600: "#31596b",
          700: "#486c7c",
          800: "#5f7e8c",
          900: "#76919d",
        },
        green: {
          100: "#188a7d",
          200: "#2f968a",
          300: "#46a197",
          400: "#5dada4",
          500: "#74b9b1",
          600: "#0e534b",
          700: "#116158",
          800: "#136e64",
          900: "#167c71",
        },
        red: {
          100: "#941f1f",
          200: "#ac2424",
          300: "#c52929",
          400: "#dd2e2e",
          500: "#f63333",
          600: "#f74747",
          700: "#f85c5c",
          800: "#f97070",
          900: "#fa8585",
        },
        yellow: {
          100: "99861f",
          200: "b39c24",
          300: "ccb229",
          400: "e6c92e",
          500: "ffdf33",
          600: "ffe247",
          700: "ffe55c",
          800: "ffe970",
          900: "ffec85",
        },
        purple: {
          100: "#636ea0",
          200: "#58628e",
          300: "#4d557d",
          400: "#42496b",
          500: "#6e7ab2",
          600: "#7d87ba",
          700: "#8b95c1",
          800: "#9aa2c9",
          900: "#a8afd1",
        },
        orange: {
          100: "#daa136",
          200: "#c28f30",
          300: "#a97d2a",
          400: "#916b24",
          500: "#f2b33c",
          600: "#f3bb50",
          700: "#f5c263",
          800: "#f6ca77",
          900: "#f7d18a",
        },
        material: {
          1: "#C62828", // red
          2: "#D81B60", // pink
          3: "#4A148C", // purple
          4: "#311B92", // deepPurple
          5: "#1A237E", // indigo
          6: "#0D47A1", // blue
          7: "#01579B", // lightBlue
          8: "#006064", // cyan
          9: "#004D40", // teal
          10: "#1B5E20", // green
          11: "#AFB42B", // lime
          12: "#FDD835", // yellow
          13: "#FFA000", // amber
          14: "#F57C00", // orange
          15: "#BF360C", // deepOrange
          16: "#3E2723", // brown
          17: "#212121", // grey
          18: "#37474F", // blueGrey
        },
      }
    : {
        background: { 100: "#FFFFFF", 200: "#F2F2F2" },
        black: {
          100: "#ffffff",
          200: "#e7e7e7",
          300: "#d0d0d0",
          400: "#b8b8b8",
          500: "#a0a0a0",
          600: "#898989",
          700: "#717171",
          800: "#595959",
          900: "#414141",
          1000: "#2a2a2a",
          1100: "#121212",
        },
        grey: {
          100: "#f1f1f1",
          200: "#eaeaea",
          300: "#e3e3e3",
          400: "#dcdcdc",
          500: "#d4d4d4",
          600: "#cdcdcd",
          700: "#c6c6c6",
          800: "#bfbfbf",
          900: "#b8b8b8",
        },
        blue: {
          100: "#76919d",
          200: "#5f7e8c",
          300: "#486c7c",
          400: "#31596b",
          500: "#1a475b",
          600: "#174052",
          700: "#153949",
          800: "#123240",
          900: "#102b37",
        },
        green: {
          100: "#167c71",
          200: "#136e64",
          300: "#116158",
          400: "#0e534b",
          500: "#74b9b1",
          600: "#5dada4",
          700: "#46a197",
          800: "#2f968a",
          900: "#188a7d",
        },
        red: {
          100: "#fa8585",
          200: "#f97070",
          300: "#f85c5c",
          400: "#f74747",
          500: "#f63333",
          600: "#dd2e2e",
          700: "#c52929",
          800: "#ac2424",
          900: "#941f1f",
        },
        yellow: {
          100: "ffec85",
          200: "ffe970",
          300: "ffe55c",
          400: "ffe247",
          500: "ffdf33",
          600: "e6c92e",
          700: "ccb229",
          800: "b39c24",
          900: "99861f",
        },
        purple: {
          100: "#a8afd1",
          200: "#9aa2c9",
          300: "#8b95c1",
          400: "#7d87ba",
          500: "#6e7ab2",
          600: "#42496b",
          700: "#4d557d",
          800: "#58628e",
          900: "#636ea0",
        },
        orange: {
          100: "#f7d18a",
          200: "#f6ca77",
          300: "#f5c263",
          400: "#f3bb50",
          500: "#f2b33c",
          600: "#916b24",
          700: "#a97d2a",
          800: "#c28f30",
          900: "#daa136",
        },
        material: {
          1: "#C62828", // red
          2: "#D81B60", // pink
          3: "#4A148C", // purple
          4: "#311B92", // deepPurple
          5: "#1A237E", // indigo
          6: "#0D47A1", // blue
          7: "#01579B", // lightBlue
          8: "#006064", // cyan
          9: "#004D40", // teal
          10: "#1B5E20", // green
          11: "#AFB42B", // lime
          12: "#FDD835", // yellow
          13: "#FFA000", // amber
          14: "#F57C00", // orange
          15: "#BF360C", // deepOrange
          16: "#3E2723", // brown
          17: "#212121", // grey
          18: "#37474F", // blueGrey
        },
      }),
});

// MUI Theme Settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode: mode,
      primary: {
        main: colors.black[100],
      },
      secondary: {
        main: colors.blue[500],
      },
      neutral: {
        dark: colors.grey[500],
        main: colors.grey[700],
        light: colors.grey[900],
      },
      background: {
        default: colors.background[200],
      },
    },
    typography: {
      fontsize: "0.875rem",
      body: {},
      h1: {
        fontsize: 40,
      },
      h2: {
        fontsize: 32,
      },
      h3: {
        fontsize: 24,
      },
      h4: {
        fontsize: 20,
      },
      h5: {
        fontsize: 16,
      },
      h6: {
        fontsize: 14,
      },
      a: {
        textDecoration: "none !important",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colors.black[1100] + " !important", // Set your desired color here
            "&.Mui-focused": {
              color: colors.black[1100] + " !important", // Ensure it stays the same color when focused
            },
            "&.Mui-error": {
              color: colors.red[500] + " !important", // Optional: Set a different color for error state
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "inherit",
            textDecoration: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {},
        },
        variants: [
          {
            props: { variant: "menu" }, // Define the custom variant
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.8)", // Example: Set a dark background
              color: "white",
              borderRadius: "0px",
              padding: "8px 16px",
              boxShadow: "none", // No shadow for menu variant
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                boxShadow: "none", // Ensure no shadow on hover
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.3)", // Example: Disabled state
                color: "rgba(255, 255, 255, 0.5)",
                boxShadow: "none", // Ensure no shadow for disabled state
              },
            },
          },
        ],
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ...(mode === "dark" && {
              boxShadow: "0px 0px 1px rgba(255, 255, 255, 0.4)", // Light shadow for dark mode
            }),
            "&.Mui-focused fieldset": {
              ...(mode === "dark" && {
                boxShadow: "0px 0px 1px rgba(255, 255, 255, 0.6)", // Stronger shadow when focused
              }),
              borderColor: "rgba(255, 255, 255, 0.4)", // Light border color for dark mode
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "37px",
          },
        },
      },
      /*MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: colors.grey[100],
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colors.black[1100] + "!important",
          },
        },
      },*/
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 400, // Example: Set the font weight to 600 (bold)
            fontSize: 14,
            "&:hover": {
              color: colors.blue[500],
              fontWeight: 600,
              opacity: 1,
            },
            "&.Mui-selected": {
              color: colors.blue[500],
              fontWeight: 600,
            },
          },
        },
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState(() => {
    // Get mode from localStorage, fallback to "dark"
    return localStorage.getItem("colorMode") || "dark";
  });

  // Persist mode on change
  useEffect(() => {
    localStorage.setItem("colorMode", mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    [mode]
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
