export const pieceSets = {
  Celtic: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Chessnut: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Daksina: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/wP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/wN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/wB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/wR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/wQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/wK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/bP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/bN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/bB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/bR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/bQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/daksina/bK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black King"
      />
    ),
  },
  Fantasy: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Minerva: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/wP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/wN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/wB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/wR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/wQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/wK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/bP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/bN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/bB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/bR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/bQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/minerva/bK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black King"
      />
    ),
  },
  Reshot: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/wP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/wN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/wB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/wR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/wQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/wK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/bP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/bN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/bB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/bR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/bQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/reshot/bK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black King"
      />
    ),
  },
  Rizal: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/wP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/wN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/wB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/wR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/wQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/wK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/bP.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/bN.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/bB.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/bR.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/bQ.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/rizal/bK.png`}
        style={{ width: squareWidth, height: squareWidth, padding: "3px" }}
        alt="Black King"
      />
    ),
  },
  Spatial: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Wikipedia: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
};
