import React, { useEffect, useRef, useState } from "react";
import { Chessboard } from "react-chessboard";
import { Helmet } from "react-helmet";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { pieceSets } from "../../../styles/pieceset";
import { themeColors } from "../../../styles/boardtheme";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";

import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import PieceTrainerHelp from "../../../help/PieceTrainerHelp";
import HelpModal from "../../../components/HelpModal";

import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";

const PIECES = ["Q", "R", "B", "N", "K"];
const BOARD_SIZE = 8;

function PieceTrainer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const chessboardRef = useRef(null);

  const getRandomSquare = () => {
    const files = "abcdefgh";
    const ranks = "12345678";
    let square =
      files[Math.floor(Math.random() * BOARD_SIZE)] +
      ranks[Math.floor(Math.random() * BOARD_SIZE)];

    return square;
  };

  const { userData, userRef } = useUser();

  const generateFruits = (num, piecePos) => {
    const fruits = new Set();

    // Helper function to get the color of a square
    const getSquareColor = (square) => {
      const fileIndex = square.charCodeAt(0) - "a".charCodeAt(0);
      const rankIndex = parseInt(square[1]) - 1;
      return (fileIndex + rankIndex) % 2 === 0 ? "dark" : "light";
    };

    // If the piece is a Bishop, determine its color complex
    const targetColor = piece === "B" ? getSquareColor(piecePos) : null;

    while (fruits.size < num) {
      const square = getRandomSquare();

      // Ensure square is NOT the same as piecePos
      // If the piece is a Bishop, also check for color complex match
      if (
        square !== piecePos &&
        (targetColor === null || getSquareColor(square) === targetColor)
      ) {
        fruits.add(square);
      }
    }

    return Array.from(fruits);
  };

  const isValidMove = (piece, from, to) => {
    const [fromFile, fromRank] = [from.charCodeAt(0), parseInt(from[1])];
    const [toFile, toRank] = [to.charCodeAt(0), parseInt(to[1])];
    const fileDiff = Math.abs(toFile - fromFile);
    const rankDiff = Math.abs(toRank - fromRank);

    switch (piece) {
      case "Q":
        return (
          fileDiff === rankDiff || fromFile === toFile || fromRank === toRank
        );
      case "R":
        return fromFile === toFile || fromRank === toRank;
      case "B":
        return fileDiff === rankDiff;
      case "N":
        return (
          (fileDiff === 2 && rankDiff === 1) ||
          (fileDiff === 1 && rankDiff === 2)
        );
      case "K":
        return fileDiff <= 1 && rankDiff <= 1;
      default:
        return false;
    }
  };

  const [piece, setPiece] = useState(PIECES[0]);
  const [piecePosition, setPiecePosition] = useState(getRandomSquare());
  const [fruits, setFruits] = useState(null);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); // Fixed 60 seconds timer
  const [arePiecesDraggable, setArePiecesDraggable] = useState(false);
  const { t } = useTranslation("Learn");
  const { setShareData } = useShare();

  const [gridSize, setGridSize] = useState(600); // Default grid size

  const lastUpdateRef = useRef(null);
  const animationFrameRef = useRef(null);

  const MODES = {
    TIMER: "timer",
    FEWEST_MOVES: "fewest_moves",
  };
  const [mode, setMode] = useState(MODES.TIMER);
  const [arrows, setArrows] = useState([]);
  const [touchStartCoords, setTouchStartCoords] = useState(null);
  const getSquareFromCoords = (x, y) => {
    if (!chessboardRef.current) return null; // Safety check

    const rect = chessboardRef.current.getBoundingClientRect(); // Get dimensions
    const squareSize = rect.width / 8; // Calculate square size

    const fileIndex = Math.floor((x - rect.left) / squareSize);
    const rankIndex = 7 - Math.floor((y - rect.top) / squareSize); // Reverse Y-axis

    if (fileIndex < 0 || fileIndex > 7 || rankIndex < 0 || rankIndex > 7) {
      return null; // Out of bounds
    }

    return String.fromCharCode(97 + fileIndex) + (rankIndex + 1);
  };

  const handleTouchStart = (event) => {
    if (mode !== MODES.FEWEST_MOVES) return; // Only enable in Fewest Moves mode

    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    setTouchStartCoords({ x: clientX, y: clientY });
  };

  const handleTouchEnd = (event) => {
    if (!touchStartCoords || mode !== MODES.FEWEST_MOVES) return;

    const { clientX, clientY } = event.changedTouches
      ? event.changedTouches[0]
      : event;
    const startSquare = getSquareFromCoords(
      touchStartCoords.x,
      touchStartCoords.y
    );
    const endSquare = getSquareFromCoords(clientX, clientY);

    if (!startSquare || !endSquare || startSquare === endSquare) {
      setTouchStartCoords(null);
      return;
    }

    if (!isValidMove(piece, startSquare, endSquare)) {
      setTouchStartCoords(null);
      return;
    }

    setArrows((prevArrows) => {
      const newArrows = [...prevArrows];
      const existingIndex = newArrows.findIndex(
        (arrow) => arrow[0] === startSquare && arrow[1] === endSquare
      );

      if (existingIndex !== -1) {
        newArrows.splice(existingIndex, 1); // Remove if exists
      } else {
        newArrows.push([startSquare, endSquare]); // Add new arrow
      }

      return newArrows;
    });

    setTouchStartCoords(null);
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getFEN = () => {
    if (!gameStarted && !gameOver) return "8/8/8/8/8/8/8/8"; // Empty board before game starts
    let board = Array(8)
      .fill()
      .map(() => Array(8).fill("1"));
    const fileIndex = piecePosition.charCodeAt(0) - "a".charCodeAt(0);
    const rankIndex = 8 - parseInt(piecePosition[1]);
    board[rankIndex][fileIndex] = piece;
    fruits.forEach((fruit) => {
      const fFile = fruit.charCodeAt(0) - "a".charCodeAt(0);
      const fRank = 8 - parseInt(fruit[1]);
      board[fRank][fFile] = "p";
    });
    return board
      .map((row) => row.join(""))
      .join("/")
      .replace(/1+/g, (match) => match.length);
  };

  const onDrop = (sourceSquare, targetSquare) => {
    if (sourceSquare !== piecePosition) {
      return false; // Invalid move if it's not the selected piece
    }

    if (isValidMove(piece, sourceSquare, targetSquare)) {
      setPiecePosition(targetSquare);
      if (fruits.includes(targetSquare)) {
        const remainingFruits = fruits.filter(
          (fruit) => fruit !== targetSquare
        );
        setFruits(remainingFruits);
        setScore(score + 1);

        if (remainingFruits.length === 0) {
          setLevel((prevLevel) => prevLevel + 1);
          setFruits(generateFruits(5), piecePosition); // Instantly add 5 new fruits
        }
      }
      return true;
    }
    return false;
  };

  const handleNewGame = () => {
    let newPiecePosition = getRandomSquare();
    const newFruits = generateFruits(5, newPiecePosition);

    setFruits(newFruits);
    setPiecePosition(newPiecePosition);
    setScore(0);
    setLevel(1);
    setArrows([]);
    setTimeLeft(600); // Reset to 60 seconds
    setGameStarted(true);
    setGameOver(false);
    setArePiecesDraggable(mode === MODES.TIMER); // Enable dragging only for Timer Mode

    // GA Tracking
    trackEvent("Learn", "PieceTrainer-Play", "Piece Trainer");

    // Internal Tracking
    if (userData) {
      if (!userData.Learn) {
        userData.Learn = {};
      }
      if (userData.Learn.PieceTrainer) {
        userData.Learn.PieceTrainer.Played =
          (userData.Learn.PieceTrainer.Played || 0) + 1;
      } else {
        userData.Learn.PieceTrainer = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  const handleStop = () => {
    setGameStarted(false); // Stop the game
    setGameOver(true);
    setArePiecesDraggable(false); // Disable piece movement
  };

  const getCustomPieces = () => {
    let customPieces = pieceSets[userData?.pieceset || "Wikipedia"];
    /*customPieces["bP"] = ({ squareWidth }) => (
      <div
        style={{
          marginTop: `${squareWidth * 0.1}px`,
          backgroundColor: "yellow",
          borderRadius: "50%",
          width: `${squareWidth * 0.8}px`,
          height: `${squareWidth * 0.8}px`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
        }}
      />
    );*/
    return customPieces;
  };

  useEffect(() => {
    const handleResize = () => {
      // Dynamically calculate the grid size, considering the viewport width and ensuring it doesn't exceed the screen.
      const availableWidth = Math.min(
        window.innerWidth,
        document.documentElement.clientWidth
      );
      const newSize = Math.min(availableWidth - 100, 500); // Reduce by 20px for potential padding/margin
      setGridSize(newSize); // Set the grid size dynamically
    };

    handleResize(); // Initial size on mount
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/piecetrainer",
      title: "Chessboard Magic - Piece Trainer",
      description:
        "Sharpen your chess skills by mastering piece movement with interactive challenges in the Piece Trainer.",
    };
    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    const updateTimer = () => {
      const now = performance.now();
      const elapsed = now - (lastUpdateRef.current || now); // Time elapsed since last frame
      lastUpdateRef.current = now; // Update the last recorded time

      if (gameStarted) {
        setTimeLeft((prevTime) => Math.max(0, prevTime - elapsed / 100)); // Convert to tenths of a second
      }

      if (timeLeft > 0) {
        animationFrameRef.current = requestAnimationFrame(updateTimer); // Keep updating
      } else {
        setGameOver(true);
        setGameStarted(false);
        setArePiecesDraggable(false);

        trackEvent("Learn", "PieceTrainer-Completed", "Piece Trainer");

        // Internal Tracking and Highest Score Logic
        if (userData) {
          // Increment completed games count
          userData.Learn.PieceTrainer.Completed =
            (userData.Learn.PieceTrainer.Completed || 0) + 1;

          // Initialize the HighestScore field if it doesn't exist
          if (!userData.Learn.PieceTrainer.HighestScore) {
            userData.Learn.PieceTrainer.HighestScore = 0;
          }

          // Compare and update the highest score
          if (score > userData.Learn.PieceTrainer.HighestScore) {
            userData.Learn.PieceTrainer.HighestScore = score; // Update with the new highest score
          }

          // Save updated data
          updateUserData(userRef, userData);
        }
      }
    };

    if (gameStarted) {
      lastUpdateRef.current = performance.now(); // Initialize
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    } else {
      cancelAnimationFrame(animationFrameRef.current); // Stop if game is not running
    }

    return () => cancelAnimationFrame(animationFrameRef.current); // Cleanup on unmount
  }, [gameStarted, timeLeft, score, userData, userRef]);

  useEffect(() => {
    setArePiecesDraggable(mode === MODES.TIMER);
  }, [mode, MODES.TIMER]);

  return (
    <Box>
      <ContentHeader
        title={t("PieceTrainer.header.title")}
        subtitle={t("PieceTrainer.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />
      {/* Helmet Meta Tags */}
      <Helmet>
        <title>Piece Trainer</title>
        <meta
          name="description"
          content="Sharpen your chess skills by mastering piece movement with interactive challenges in the Piece Trainer."
        />
        <meta property="og:title" content="Piece Trainer" />
        <meta
          property="og:description"
          content="Sharpen your chess skills by mastering piece movement with interactive challenges in the Piece Trainer."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/piecetrainer.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/piecetrainer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Piece Trainer" />
        <meta
          name="twitter:description"
          content="Sharpen your chess skills by mastering piece movement with interactive challenges in the Piece Trainer."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/piecetrainer.png`}
        />
      </Helmet>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start" // Use "flex-start" to align items at the top
        marginBottom="16px"
        gap="10px"
        flexWrap="wrap" // Allow buttons to wrap to the next line on overflow
      >
        <Select value={mode} onChange={(e) => setMode(e.target.value)}>
          <MenuItem value={MODES.TIMER}>
            {t("PieceTrainer.mode.timer")}
          </MenuItem>
          <MenuItem value={MODES.FEWEST_MOVES}>
            {t("PieceTrainer.mode.fewest")}
          </MenuItem>
        </Select>
        <Select value={piece} onChange={(e) => setPiece(e.target.value)}>
          {PIECES.map((p) => (
            <MenuItem key={p} value={p}>
              {t(`PieceTrainer.pieces.${p}`)}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          onClick={handleNewGame}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[10] }}
            />
          }
        >
          {t("PieceTrainer.buttons.newGame")}
        </Button>
        <Button
          variant="contained"
          disabled={!gameStarted}
          onClick={handleStop}
          startIcon={<StopCircleIcon style={{ color: colors.material[10] }} />}
        >
          {t("PieceTrainer.buttons.stop")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[10] }} />}
        >
          {t("PieceTrainer.buttons.help")}
        </Button>
        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("PieceTrainer.helpDetails.title")}
          content={<PieceTrainerHelp />}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between" // Spread items evenly across the box
        alignItems="center" // Center items vertically
        sx={{ mb: 2, width: `${gridSize}px`, maxWidth: `${gridSize}px` }} // Match the width of the grid
      >
        {mode === MODES.TIMER ? (
          <>
            <Typography>
              {t("PieceTrainer.labels.timeLeft")}:{" "}
              <b>{(timeLeft / 10).toFixed(1)}s</b>
            </Typography>
            <Typography>
              {t("PieceTrainer.labels.level")}: <b>{level}</b>
            </Typography>
            <Typography>
              {t("PieceTrainer.labels.score")}: <b>{score}</b>
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              {t("PieceTrainer.labels.yourMoves")}: <b>{arrows.length}</b>
            </Typography>
            <Typography
              component="span"
              onClick={() => setArrows([])}
              sx={{
                cursor: "pointer",
              }}
            >
              {t("PieceTrainer.buttons.clearArrows")}
            </Typography>
          </>
        )}
      </Box>

      <Box
        ref={chessboardRef}
        sx={{
          position: "relative", // Parent container for the overlay
          display: "flex",
          flexDirection: "column", // Chessboard rows
          width: `${gridSize}px`, // Dynamic grid size
          height: `${gridSize}px`, // Dynamic grid size
          margin: "0", // Center the chessboard horizontally
          mb: 2,
          userSelect: "none", // Prevent text selection
          WebkitUserSelect: "none", // Safari support
          MozUserSelect: "none", // Firefox support
          msUserSelect: "none", // IE support
        }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleTouchStart} // Allow desktop users to click-drag
        onMouseUp={handleTouchEnd}
        onDragStart={(e) => mode === MODES.FEWEST_MOVES && e.preventDefault()} // Prevent drag ONLY in Fewest Moves Mode
        onSelectStart={(e) => mode === MODES.FEWEST_MOVES && e.preventDefault()}
      >
        <Chessboard
          position={getFEN()}
          onPieceDrop={onDrop}
          arePiecesDraggable={arePiecesDraggable}
          areArrowsAllowed={false}
          customArrows={arrows}
          customPieces={getCustomPieces()}
          customLightSquareStyle={{
            backgroundColor:
              themeColors[userData?.theme || "White Stripe Theme"].lightSquare,
            backgroundImage:
              themeColors[userData?.theme || "White Stripe Theme"]
                .lightSquarePattern,
          }}
          customDarkSquareStyle={{
            backgroundColor:
              themeColors[userData?.theme || "White Stripe Theme"].darkSquare,
            backgroundImage:
              themeColors[userData?.theme || "White Stripe Theme"]
                .darkSquarePattern,
          }}
        />
      </Box>
    </Box>
  );
}

export default PieceTrainer;
