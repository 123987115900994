import { useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  TextField,
  Dialog,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { Tree } from "antd";

import { ref, set } from "firebase/database";
import { rt } from "../../../../config/firebase";
import { addRepertoire } from "../../../../config/firebaseDB";

import { useUser } from "../../../../context/UserContext";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SubjectIcon from "@mui/icons-material/Subject";
import UploadSharpIcon from "@mui/icons-material/UploadSharp";
import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";
import LibraryAddSharpIcon from "@mui/icons-material/LibraryAddSharp";
import FolderSharpIcon from "@mui/icons-material/FolderSharp";
import KeyboardOptionKeySharpIcon from "@mui/icons-material/KeyboardOptionKeySharp";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";

import ImportPGNDialog from "../Components/importPGNDialog";

import { v4 as uuidv4 } from "uuid";
import RepertoireWizardDialog from "./builderRepertoireWizardDialog";
import ImportTemplateDialog from "../Components/importTemplateDialog";
import { useAlert } from "../../../../context/AlertProvider";

function RepertoireCard({
  repertoires,
  setRepertoires,
  selectedRepertoireId,
  setSelectedRepertoireId,
  setRepertoireDetails,
  setMoves,
  setSelectedMoveId,
  setIsModalOpen,
  settings,
  totalRepertoires,
  setTotalRepertoires,
  totalMoves,
  setTotalMoves,
  folders,
  setFolders,
}) {
  const [isImportDialogOpen, setImportDialogOpen] = useState(false);
  const [isTemplateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [isWizardDialogOpen, setWizardDialogOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const { userData } = useUser();
  const userId = userData?.uid || "";

  const showAlert = useAlert();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [renameFolderId, setRenameFolderId] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");

  const handleRenameFolder = (folderId) => {
    const currentName = folders[folderId]?.name || "";
    setRenameFolderId(folderId);
    setNewFolderName(currentName);
    setRenameDialogOpen(true);
  };

  const [colorDialogOpen, setColorDialogOpen] = useState(false);
  const [colorFolderId, setColorFolderId] = useState(null);
  const handleChangeFolderColor = (folderId) => {
    setColorFolderId(folderId);
    setColorDialogOpen(true);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const confirmDeleteFolder = (folderId) => {
    setFolderToDelete(folderId);
    setDeleteDialogOpen(true);
  };

  const handleRightClick = (event, node) => {
    event.preventDefault();
    if (!node) return;

    const isRepertoire = node.type === "repertoire";

    if (isRepertoire) {
      return;
    }

    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      node,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  /** Handles selecting a repertoire */
  const handleRepertoireClick = (id) => {
    setSelectedRepertoireId(id);
    setSelectedMoveId("root");

    const selectedRepertoire = repertoires[id];

    if (selectedRepertoire) {
      setRepertoireDetails({
        title: selectedRepertoire.title || "",
        description: selectedRepertoire.description || "",
        author: selectedRepertoire.author || "",
        boardOrientation: selectedRepertoire.boardOrientation || "white",
        visibility: selectedRepertoire.visibility || "Private",
        allowCopy: selectedRepertoire.allowCopy || "No",
      });

      // ✅ Update moves from selected repertoire
      setMoves(
        selectedRepertoire.moves || {
          root: { san: "", next: null, variations: [], parent: null },
        }
      );
    }
  };

  const handleAddFolder = async (parentId) => {
    const newFolderId = uuidv4();
    const newFolder = {
      id: newFolderId,
      name: "New Folder",
      parentId,
      color: "#888888",
      createdAt: Date.now(),
    };

    await set(ref(rt, `users/${userId}/folders/${newFolderId}`), newFolder);

    setFolders((prev) => ({
      ...prev,
      [newFolderId]: newFolder,
    }));
  };

  const handleMakeTopLevel = async (itemId) => {
    const isRepertoire = repertoires[itemId] !== undefined;
    const isFolder = folders[itemId] !== undefined;

    if (isRepertoire) {
      const updated = { ...repertoires[itemId], parentId: "none" };
      await set(ref(rt, `users/${userId}/repertoires/${itemId}`), updated);
      setRepertoires((prev) => ({ ...prev, [itemId]: updated }));
    }

    if (isFolder) {
      const updated = { ...folders[itemId] };
      delete updated.parentId; // ⬅️ Remove the parent
      await set(ref(rt, `users/${userId}/folders/${itemId}`), updated);
      setFolders((prev) => ({ ...prev, [itemId]: updated }));
    }
  };

  const presetColors = [
    "#f44336", // red
    "#e91e63", // pink
    "#9c27b0", // purple
    "#673ab7", // deep purple
    "#3f51b5", // indigo
    "#2196f3", // blue
    "#4caf50", // green
    "#ff9800", // orange
    "#795548", // brown
    "#607d8b", // blue grey
  ];

  const buildTree = () => {
    const nodes = [
      ...Object.values(folders).map((f) => ({
        key: f.id,
        title: f.id === "none" ? "Uncategorised" : f.name,
        parentId: f.parentId ?? null,
        type: "folder",
        color: f.color,
        children: [],
      })),
      ...Object.entries(repertoires).map(([id, r]) => ({
        key: id,
        title: r.title,
        parentId: r.parentId ?? "none",
        type: "repertoire",
        children: [],
      })),
    ];

    const map = new Map();
    nodes.forEach((node) => map.set(node.key, node));

    const tree = [];

    nodes.forEach((node) => {
      const parent = map.get(node.parentId);
      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(node);
      } else {
        tree.push(node);
      }
    });

    // 🔁 Recursively sort children alphabetically by title
    const sortNodesRecursively = (nodeList) => {
      nodeList.forEach((node) => {
        if (node.children) {
          sortNodesRecursively(node.children);
          node.children.sort((a, b) =>
            a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
          );
        }
      });
    };

    sortNodesRecursively(tree);

    // 📌 Sort top-level by fixed order first, then alphabetical
    const sortOrder = ["white", "black"]; // ❌ intentionally exclude "none"

    const sortedTree = tree.sort((a, b) => {
      const aIndex = sortOrder.indexOf(a.key);
      const bIndex = sortOrder.indexOf(b.key);

      // 📦 Handle "white" and "black" sorting
      if (aIndex !== -1 || bIndex !== -1) {
        return (aIndex === -1 ? 999 : aIndex) - (bIndex === -1 ? 999 : bIndex);
      }

      // 🧼 Always push "none" to the bottom
      if (a.key === "none") return 1;
      if (b.key === "none") return -1;

      // 🔤 Fallback to alphabetical
      return a.title.localeCompare(b.title, undefined, { sensitivity: "base" });
    });

    const convertToAntTree = (nodeList) =>
      nodeList.map((node) => {
        const isRepertoire = node.type === "repertoire";
        const isSelected = node.key === selectedRepertoireId;

        return {
          key: node.key,
          title: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", direction: "row" }}>
                <Box>
                  {isRepertoire ? (
                    <KeyboardOptionKeySharpIcon
                      sx={{ color: node.color, alignItems: "center", mr: 1 }}
                    />
                  ) : (
                    <FolderSharpIcon
                      sx={{ color: node.color, alignItems: "center", mr: 1 }}
                    />
                  )}
                </Box>
                <Box>
                  <span>{node.title}</span>
                </Box>
              </Box>
              {isRepertoire && isSelected && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation(); // prevent node selection when clicking cog
                    setIsModalOpen(true);
                  }}
                  sx={{ ml: 1 }}
                >
                  <SettingsSharpIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          ),
          icon: isRepertoire ? (
            <KeyboardOptionKeySharpIcon sx={{ color: node.color }} />
          ) : (
            <FolderSharpIcon sx={{ color: node.color }} />
          ),
          parentId: node.parentId,
          type: node.type,
          children: node.children ? convertToAntTree(node.children) : [],
        };
      });

    return convertToAntTree(sortedTree);
  };

  /** Handles adding a new repertoire */
  const handleAddRepertoire = async () => {
    if (totalRepertoires >= settings.limitRepertoires) {
      showAlert(
        `Maximum Number of Repertoires Exceeded (${settings.limitRepertoires}). Please upgrade to have more repertoires.`,
        "error"
      );

      return;
    }

    const newRepertoireId = uuidv4();
    const newRepertoire = {
      id: newRepertoireId,
      title: `Repertoire ${Object.keys(repertoires).length + 1}`,
      description: "",
      author: userData?.username,
      boardOrientation: "white",
      visibility: "Private",
      allowCopy: "No",
      createdAt: Date.now(),
      order: -9999,
      moves: {
        root: { san: "", next: null, variations: [], parent: null },
      },
    };

    try {
      await addRepertoire(userId, newRepertoire);

      setTotalRepertoires((prevCount) => prevCount + 1);

      // Update state and ensure the correct repertoire is selected
      setRepertoires((prev) => {
        return {
          ...prev,
          [newRepertoireId]: newRepertoire,
        };
      });
    } catch (error) {}
  };

  const handleDrop = async (info) => {
    const dragNode = info.dragNode;
    const dropNode = info.node;

    if (dragNode.key === "none") {
      return;
    }

    const dragKey = dragNode?.dataRef?.key || dragNode.key;
    const dropKey = dropNode?.dataRef?.key || dropNode.key;

    const isRepertoire = dragNode.icon?.type === KeyboardOptionKeySharpIcon;
    const isFolder = dragNode.icon?.type === FolderSharpIcon;

    if (isRepertoire) {
      const updatedRepertoire = {
        ...repertoires[dragKey],
        parentId: dropKey,
      };

      await set(
        ref(rt, `users/${userId}/repertoires/${dragKey}`),
        updatedRepertoire
      );

      setRepertoires((prev) => ({
        ...prev,
        [dragKey]: updatedRepertoire,
      }));
    }

    if (isFolder) {
      const updatedFolder = {
        ...folders[dragKey],
        parentId: dropKey,
      };

      await set(ref(rt, `users/${userId}/folders/${dragKey}`), updatedFolder);

      setFolders((prev) => ({
        ...prev,
        [dragKey]: updatedFolder,
      }));
    }
  };

  return (
    <Card
      id="builder-repertoirecard"
      sx={{
        position: "relative", // Required for pseudo-element positioning
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none !important",
        width: "100%",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SubjectIcon />
          </Box>
        }
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Repertoires
          </Typography>
        }
        action={
          <>
            <Tooltip title="Add New Repertoire">
              <IconButton
                id="builder-repertoirecard-add"
                color="primary"
                onClick={handleAddRepertoire}
              >
                <ControlPointIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Import Repertoire">
              <IconButton
                id="builder-repertoirecard-import"
                color="primary"
                onClick={() => setImportDialogOpen(true)}
              >
                <UploadSharpIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Import Template">
              <IconButton
                id="builder-repertoirecard-template"
                color="primary"
                onClick={() => setTemplateDialogOpen(true)}
              >
                <LibraryAddSharpIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Open Repertoire Wizard">
              <IconButton
                id="builder-repertoirecard-wizard"
                color="primary"
                onClick={() => setWizardDialogOpen(true)}
              >
                <AutoAwesomeSharpIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>
            <ImportPGNDialog
              open={isImportDialogOpen}
              onClose={() => setImportDialogOpen(false)}
              setRepertoires={setRepertoires}
              setSelectedRepertoireId={setSelectedRepertoireId}
              setMoves={setMoves}
              setSelectedMoveId={setSelectedMoveId}
              repertoires={repertoires}
              userData={userData}
              settings={settings}
              totalRepertoires={totalRepertoires}
              setTotalRepertoires={setTotalRepertoires}
              totalMoves={totalMoves}
              setTotalMoves={setTotalMoves}
            />

            <ImportTemplateDialog
              open={isTemplateDialogOpen}
              onClose={() => setTemplateDialogOpen(false)}
              setRepertoires={setRepertoires}
              setSelectedRepertoireId={setSelectedRepertoireId}
              setMoves={setMoves}
              setSelectedMoveId={setSelectedMoveId}
              repertoires={repertoires}
              userData={userData}
              settings={settings}
              totalRepertoires={totalRepertoires}
              setTotalRepertoires={setTotalRepertoires}
              totalMoves={totalMoves}
              setTotalMoves={setTotalMoves}
            />

            <RepertoireWizardDialog
              open={isWizardDialogOpen}
              onClose={() => setWizardDialogOpen(false)}
              userData={userData}
              setRepertoires={setRepertoires}
              settings={settings}
              totalRepertoires={totalRepertoires}
              setTotalRepertoires={setTotalRepertoires}
              totalMoves={totalMoves}
              setTotalMoves={setTotalMoves}
            />
          </>
        }
        sx={{ pt: 1, pb: 1 }}
      />
      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />
      <CardContent
        sx={{
          p: 0,
          mt: 2,
          ml: 1,
          mb: 0,
          mr: 1,
          overflowY: "auto",
          maxHeight: "300px",
          color: colors.black[900],

          "& .ant-tree .ant-tree-treenode": {
            display: "flex",
            alignItems: "stretch",
            margin: 0,
            padding: 0,
          },

          "& .ant-tree .ant-tree-node-content-wrapper": {
            padding: "8px",
            marginRight: "10px",
          },

          "& .ant-tree .ant-tree-node-content-wrapper:hover": {
            backgroundColor: `${colors.background[200]} !important`,
          },

          "& .ant-tree .ant-tree-node-selected": {
            backgroundColor: `${colors.background[200]} !important`,
            color: `${colors.black[900]} !important`,
            fontWeight: "bold",
          },

          "& .ant-tree-iconEle": {
            marginRight: "5px",
          },

          "& .ant-tree-switcher": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "stretch",
            backgroundColor: "transparent !important",
            transition: "none !important",
            width: "20px",
          },

          "& .ant-tree-switcher::before": {
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
          },
          "& .ant-tree-switcher.ant-tree-switcher_close:hover": {
            background: `transparent !important`,
          },

          "& .ant-tree-switcher.ant-tree-switcher_open:hover": {
            background: `transparent !important`,
          },
        }}
      >
        <Tree
          blockNode
          onDrop={handleDrop}
          draggable={{
            icon: false,
            //nodeDraggable: (node) => node.key !== "none",
          }}
          allowDrop={() => true}
          onRightClick={({ event, node }) => handleRightClick(event, node)}
          treeData={buildTree()}
          onSelect={(selectedKeys, info) => {
            const selectedId = selectedKeys[0];
            const isRepertoire =
              info.node?.icon?.type === KeyboardOptionKeySharpIcon;

            if (isRepertoire && repertoires[selectedId]) {
              handleRepertoireClick(selectedId);
            }
          }}
          defaultExpandAll
          style={{
            backgroundColor: colors.background[100],
            color: colors.black[900],
          }}
        />
      </CardContent>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenu?.node?.type === "folder" && [
          ...(contextMenu.node.key !== "none" &&
          contextMenu.node.parentId !== null
            ? [
                <MenuItem
                  key="make-top"
                  onClick={() => {
                    handleMakeTopLevel(contextMenu.node.key);
                    handleCloseContextMenu();
                  }}
                >
                  Make Top-Level
                </MenuItem>,
                <Divider key="divider-1" />,
              ]
            : []),

          <MenuItem
            key="add-folder"
            onClick={() => {
              handleAddFolder(contextMenu.node.key);
              handleCloseContextMenu();
            }}
          >
            Add Folder
          </MenuItem>,

          ...(contextMenu.node.key !== "none"
            ? [
                <MenuItem
                  key="delete-folder"
                  onClick={() => {
                    confirmDeleteFolder(contextMenu.node.key);
                    handleCloseContextMenu();
                  }}
                >
                  Delete Folder
                </MenuItem>,
                <MenuItem
                  key="rename-folder"
                  onClick={() => {
                    handleRenameFolder(contextMenu.node.key);
                    handleCloseContextMenu();
                  }}
                >
                  Rename Folder
                </MenuItem>,
              ]
            : []),

          <MenuItem
            key="change-color"
            onClick={() => {
              handleChangeFolderColor(contextMenu.node.key);
              handleCloseContextMenu();
            }}
          >
            Change Color
          </MenuItem>,
        ]}
      </Menu>

      <Dialog
        open={renameDialogOpen}
        onClose={() => setRenameDialogOpen(false)}
      >
        <DialogContent>
          <Box>
            <TextField
              autoFocus
              variant="filled"
              label="New Folder Name"
              margin="dense"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              mt: 1, // optional: adds a bit of space above the buttons
            }}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
              variant="contained"
              onClick={async () => {
                const trimmed = newFolderName.trim();
                if (
                  !trimmed ||
                  !renameFolderId ||
                  trimmed === folders[renameFolderId]?.name
                ) {
                  setRenameDialogOpen(false);
                  return;
                }

                const updated = {
                  ...folders[renameFolderId],
                  name: trimmed,
                };

                await set(
                  ref(rt, `users/${userId}/folders/${renameFolderId}`),
                  updated
                );

                setFolders((prev) => ({
                  ...prev,
                  [renameFolderId]: updated,
                }));

                setRenameDialogOpen(false);
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => setRenameDialogOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={colorDialogOpen} onClose={() => setColorDialogOpen(false)}>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Choose a Folder Color
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: 2,
              justifyItems: "center",
            }}
          >
            {presetColors.map((color) => (
              <Box
                key={color}
                sx={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  backgroundColor: color,
                  cursor: "pointer",
                  border: "2px solid transparent",
                  transition: "border-color 0.2s ease-in-out",
                  "&:hover": {
                    borderColor: "#000",
                  },
                }}
                onClick={async () => {
                  const folder = folders[colorFolderId];
                  if (!folder) return;

                  const updated = { ...folder, color };
                  await set(
                    ref(rt, `users/${userId}/folders/${colorFolderId}`),
                    updated
                  );

                  setFolders((prev) => ({
                    ...prev,
                    [colorFolderId]: updated,
                  }));

                  setColorDialogOpen(false); // Auto-close on selection
                }}
              />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to delete this folder and all its subfolders?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              mt: 1,
            }}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: colors.red[500],
                "&:hover": {
                  backgroundColor: colors.red[600],
                },
              }}
              variant="contained"
              onClick={async () => {
                const foldersToDelete = [folderToDelete];

                const collectDescendants = (id) => {
                  Object.values(folders).forEach((f) => {
                    if (f.parentId === id) {
                      foldersToDelete.push(f.id);
                      collectDescendants(f.id);
                    }
                  });
                };

                collectDescendants(folderToDelete);

                for (const id of foldersToDelete) {
                  await set(ref(rt, `users/${userId}/folders/${id}`), null);
                }

                const updatedRepertoires = { ...repertoires };
                for (const [id, rep] of Object.entries(updatedRepertoires)) {
                  if (foldersToDelete.includes(rep.parentId)) {
                    updatedRepertoires[id] = { ...rep, parentId: "none" };
                    await set(
                      ref(rt, `users/${userId}/repertoires/${id}`),
                      updatedRepertoires[id]
                    );
                  }
                }

                setFolders((prev) => {
                  const updated = { ...prev };
                  foldersToDelete.forEach((id) => delete updated[id]);
                  return updated;
                });

                setRepertoires(updatedRepertoires);
                setDeleteDialogOpen(false);
                setFolderToDelete(null);
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

export default RepertoireCard;
