import { useMemo } from "react";

const useCategories = () => {
  return useMemo(
    () => ({
      move: [
        { symbol: "!", description: "Good Move", color: "#1b5e20" },
        { symbol: "?", description: "Mistake", color: "#ffa726" },
        { symbol: "!!", description: "Brilliant Move", color: "#009688" },
        { symbol: "??", description: "Blunder", color: "#d50000" },
        { symbol: "!?", description: "Interesting Move", color: "#ec407a" },
        { symbol: "?!", description: "Dubious Move", color: "#2196f3" },
        { symbol: "□", description: "Only Move", color: "#7986cb" },
        { symbol: "⨀", description: "Zugzwang", color: "#6200ea" },
      ],
      position: [
        { symbol: "=", description: "Equal Position", color: "#0d47a1" },
        { symbol: "∞", description: "Unclear Position", color: "#0d47a1" },
        {
          symbol: "⩲",
          description: "White is Slightly Better",
          color: "#0d47a1",
        },
        {
          symbol: "⩱",
          description: "Black is Slightly Better",
          color: "#0d47a1",
        },
        { symbol: "±", description: "White is Better", color: "#0d47a1" },
        { symbol: "∓", description: "Black is Better", color: "#0d47a1" },
        { symbol: "+−", description: "White is Winning", color: "#0d47a1" },
        { symbol: "-+", description: "Black is Winning", color: "#0d47a1" },
      ],
      observation: [
        { symbol: "N", description: "Novelty", color: "#0d47a1" },
        { symbol: "↑↑", description: "Development", color: "#0d47a1" },
        { symbol: "↑", description: "Initiative", color: "#0d47a1" },
        { symbol: "→", description: "Attack", color: "#0d47a1" },
        { symbol: "⇆", description: "Counterplay", color: "#0d47a1" },
        { symbol: "⊕", description: "Time Trouble", color: "#0d47a1" },
        { symbol: "=∞", description: "With Compensation", color: "#0d47a1" },
        { symbol: "∆", description: "With the Idea", color: "#0d47a1" },
      ],
    }),
    []
  );
};

export default useCategories;
