import { Box, Tooltip } from "@mui/material";

const MovesLineChart = ({ opening, colorFilter }) => {
  if (!opening) return null;

  // Extract top moves and played move evaluation
  let prevMoves = opening.previousEvaluation?.topMoves || [];
  const playedMove = opening.moves.split(" ").slice(-1)[0]; // Last move played
  const playedMoveEval = Number(opening.evaluation.stockEval); // Convert to number

  // Replace the evaluation of the played move if it exists in top moves
  prevMoves = prevMoves.map((move) => {
    if (move.move === playedMove) {
      return { ...move, eval: playedMoveEval };
    }
    return move;
  });

  // Collect all evaluations
  const allEvals = prevMoves.map((move) => Number(move.eval));
  const minEval = Math.min(...allEvals, playedMoveEval);
  const maxEval = Math.max(...allEvals, playedMoveEval);

  // Create points for visualization
  const points = prevMoves.map((move) => ({
    value: Number(move.eval),
    moveName: move.move,
    isPlayedMove: false,
  }));

  points.push({
    value: playedMoveEval,
    moveName: playedMove,
    isPlayedMove: true, // Mark the played move
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: 50,
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "2px",
          backgroundColor: "white",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />

      {points.map((point, index) => {
        const positionPercentage =
          ((point.value - minEval) / (maxEval - minEval)) * 100;
        let pointColor = "white";

        if (point.isPlayedMove) {
          if (colorFilter === "Black") {
            pointColor = positionPercentage === 0 ? "green" : "red";
          } else if (colorFilter === "White") {
            pointColor = positionPercentage === 100 ? "green" : "red";
          } else {
            pointColor = "blue"; // Default color for played move
          }
        }

        return (
          <Tooltip
            key={index}
            title={`${point.moveName}: ${point.value}`}
            placement="top"
          >
            <Box
              sx={{
                position: "absolute",
                left: `${positionPercentage}%`,
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: 14,
                height: 14,
                borderRadius: "50%",
                backgroundColor: pointColor, // Dynamic color assignment
                cursor: "pointer",
                border: "1px solid black", // ✅ Adds a black outline
              }}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default MovesLineChart;
