import { useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";

const OpeningAnalysisChessboard = ({
  boardOrientation,
  setSelectedLine,
  formatMovesToPGN,
}) => {
  const [game, setGame] = useState(new Chess());

  useEffect(() => {
    setGame(new Chess());
  }, [boardOrientation]);

  const handleMove = (sourceSquare, targetSquare) => {
    try {
      const gameCopy = new Chess();
      gameCopy.loadPgn(game.pgn());

      const move = gameCopy.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q",
      });

      if (move) {
        setGame(gameCopy);
        setSelectedLine(formatMovesToPGN(gameCopy.history().join(" ")));
        return true;
      }

      throw new Error("Illegal move");
    } catch (error) {
      console.error("Invalid move:", error.message);
      return false;
    }
  };

  const handleBack = () => {
    const gameCopy = new Chess();
    gameCopy.loadPgn(game.pgn());
    gameCopy.undo(); // Undo the last move
    setGame(gameCopy);
    setSelectedLine(formatMovesToPGN(gameCopy.history().join(" ")));
  };

  const handleReset = () => {
    setGame(new Chess());
    setSelectedLine("");
  };

  return (
    <Box sx={{ width: "500px", maxWidth: "500px", p: 3 }}>
      <Chessboard
        boardOrientation={boardOrientation.toLowerCase()}
        position={game.fen()}
        onPieceDrop={handleMove}
      />
      <Stack direction="row" spacing={1} mt={2}>
        <Button
          variant="contained"
          onClick={handleBack}
          disabled={game.history().length === 0}
        >
          Back
        </Button>
        <Button variant="contained" onClick={handleReset}>
          Reset
        </Button>
      </Stack>
    </Box>
  );
};

export default OpeningAnalysisChessboard;
