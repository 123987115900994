import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Box,
  IconButton,
  useTheme,
  Stack,
  Typography,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import BugReportSharpIcon from "@mui/icons-material/BugReportSharp";
import DiscordIcon from "../../../../icons/DiscordIcon";

import useDialogFocused from "./useDialogFocused";

const ReportBugDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900], // Text color
          border: `0.5px solid #222222`, // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      open={open}
      onClose={() => onClose(false)}
      maxWidth="md"
      fullWidth
    >
      {useDialogFocused(open)}
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {/* Left-aligned: Help Icon + Title */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <BugReportSharpIcon />
            <Typography variant="h6">Report Bug</Typography>
          </Stack>

          {/* Right-aligned: Close Button */}
          <IconButton onClick={() => onClose(false)} color="inherit">
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ backgroundColor: colors.green[900], height: "1.5px" }} />
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} height="100%" p={2}>
          <Typography variant="body1">
            If you come across a bug or something that doesn’t seem quite right,
            please don’t hesitate to let me know. Your feedback is incredibly
            valuable and plays a key role in improving the platform. The more
            details you can provide, the easier it is for me to investigate and
            fix the issue.
          </Typography>

          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            Thank you for being part of this journey — your support truly means
            a lot.
          </Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography sx={{ mb: 1 }}>
            You can contact me directly at:
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              href="https://discord.gg/c82jQuy9AM"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<DiscordIcon sx={{ width: 24, height: 24 }} />}
            >
              Discord
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              href="https://lichess.org/@/HollowLeaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/${
                    useTheme().palette.mode === "dark"
                      ? "lichessdotorgwhite.png"
                      : "lichessdotorg.png"
                  }`}
                  alt="lichess.org"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              href="https://www.chess.com/member/hollowleaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/chessdotcom.png`}
                  alt="chess.com"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Typography component="span">
              or email me at{" "}
              <a
                href="mailto:toan.hoang@simplifyinsights.com?subject=Chessboard Magic"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                toan.hoang@simplifyinsights.com
              </a>
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReportBugDialog;
