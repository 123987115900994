import React from "react";
import { Box, Typography } from "@mui/material";

const AccessDenied = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Administration Access Denied
      </Typography>
      <Typography variant="body1" color="text.secondary">
        You do not have permission to access this section.
      </Typography>
    </Box>
  );
};

export default AccessDenied;
