import { useRef, useEffect } from "react";

const useDialogFocused = (open) => {
  const lastFocusedElement = useRef(null);

  useEffect(() => {
    if (open) {
      lastFocusedElement.current = document.activeElement; // Store last focused element
    } else {
      setTimeout(() => {
        lastFocusedElement.current?.focus(); // Restore focus when dialog closes
      }, 100); // Delay ensures the DOM updates
    }
  }, [open]); // Runs when dialog opens/closes
};

export default useDialogFocused;
