export const peopleCredits = ["Alexander Riddick", "Sayed Hajaj"];

export const techCredits = [
  { name: "Stockfish.js", url: "https://github.com/nmrugg/stockfish.js" },
  { name: "Chess.js", url: "https://github.com/jhlywa/chess.js/" },
  {
    name: "react-chessboard",
    url: "https://github.com/Clariity/react-chessboard",
  },
  { name: "React", url: "https://reactjs.org/" },
  { name: "Material UI", url: "https://mui.com/" },
  { name: "Nivo", url: "https://nivo.rocks/" },
  { name: "Ant Design", url: "https://ant.design/" },
  { name: "react-i18next", url: "https://react.i18next.com/" },

  { name: "Chessnut", url: "https://github.com/LexLuengas/chessnut-pieces" },
  { name: "Fantasy", url: "https://github.com/maurimo/chess-art" },
  { name: "Spatial", url: "https://github.com/maurimo/chess-art" },
  { name: "Celtic", url: "https://github.com/maurimo/chess-art" },
  {
    name: "Wikipedia",
    url: "https://commons.wikimedia.org/wiki/Category:SVG_chess_pieces",
  },
  { name: "Reshot", url: "https://www.reshot.com/free-svg-icons/chess" },
  { name: "Daksina", url: "https://www.flaticon.com/authors/deemakdaksina" },
  { name: "Rizal", url: "https://www.flaticon.com/authors/rizal2109" },
];
