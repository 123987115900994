import React, { useState, useEffect } from "react";
import { Box, Button, Tooltip, TextField, useTheme } from "@mui/material";
import { Chessboard } from "react-chessboard";
import { themeColors } from "../styles/boardtheme";
import { pieceSets } from "../styles/pieceset";
import { useUser } from "../context/UserContext";
import { tokens } from "../styles/theme";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const PositionViewer = ({
  FEN,
  SquareHighlights,
  ArrowsHighlights,
  ComponentPadding,
  ShowFen = false,
  ShowOrientation = false,
  BoardOrientation = "white",
}) => {
  const [boardArrowColours, setBoardArrowColours] = useState([]);
  const [boardSquareColours, setBoardSquareColours] = useState({});
  const [orientation, setOrientation] = useState(BoardOrientation);
  const { userData } = useUser();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const toggleOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };

  useEffect(() => {
    const processArrowColours = (arrows) => {
      const colorMap = {
        B: "blue",
        G: "green",
        R: "red",
      };
      if (arrows) {
        const processedArrows = arrows.split(",").map((arrow) => {
          const color = colorMap[arrow[0]];
          const from = arrow.slice(1, 3);
          const to = arrow.slice(3, 5);
          return [from, to, color];
        });
        setBoardArrowColours(processedArrows);
      } else {
        setBoardArrowColours([]);
      }
    };

    const processSquareColours = (squares) => {
      const colorMap = {
        B: "blue",
        G: "green",
        R: "red",
      };
      if (squares) {
        const processedSquares = squares.split(",").reduce((acc, square) => {
          const color = colorMap[square[0]];
          const squarePos = square.slice(1);
          acc[squarePos] = {
            boxShadow: `inset 0 0 0 3px ${color}`,
            borderRadius: "50%",
            boxSizing: "border-box",
          };
          return acc;
        }, {});
        setBoardSquareColours(processedSquares);
      } else {
        setBoardSquareColours({});
      }
    };

    processArrowColours(ArrowsHighlights);
    processSquareColours(SquareHighlights);
  }, [ArrowsHighlights, SquareHighlights]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(FEN);
  };

  return (
    <Box pt={1} sx={{ padding: ComponentPadding }}>
      <Box display="flex" justifyContent="flex-start">
        <Box
          id="chessboard"
          style={{
            width: "100%",
            maxWidth: "500px",
            boxSizing: "border-box",
            padding: "0px 10px 10px 0px", // Uniform padding
          }}
        >
          <Chessboard
            position={FEN}
            customSquareStyles={boardSquareColours}
            customArrows={boardArrowColours}
            boardOrientation={orientation}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
            areArrowsAllowed={false}
            arePiecesDraggable={false}
          />
          <Box display="flex" alignItems="center" mt={2}>
            {ShowFen && (
              <>
                <TextField
                  label="Fen"
                  value={FEN}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true, // Disable the underline
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 4,
                      borderBottom: "none", // Remove the bottom border
                    },
                    "& .MuiInputBase-input": {
                      cursor: "text", // Makes the text selectable
                      fontSize: "0.8rem", // Set the font size here
                    },
                  }}
                  fullWidth
                  variant="filled"
                  InputLabelProps={{
                    style: { color: colors.black[900] },
                  }}
                />
                <Tooltip title="Copy to clipboard">
                  <Button
                    sx={{
                      width: "60px",
                      height: "50px",
                      minWidth: "30px",
                      marginRight: "8px",
                      padding: "8px",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    variant="contained"
                    onClick={copyToClipboard}
                  >
                    <ContentCopyRoundedIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            {ShowOrientation && (
              <Tooltip title="Flip Orientation">
                <Button
                  sx={{
                    width: "60px",
                    height: "50px",
                    minWidth: "30px",
                    padding: "8px",
                  }}
                  variant="contained"
                  onClick={toggleOrientation}
                >
                  <SwapVertRoundedIcon />
                </Button>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PositionViewer;
