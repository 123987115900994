import React, { useState, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import MovesLineChart from "./movesLineChart";

const AnalysisTable = ({
  openingData,
  colorFilter,
  moveRange,
  selectedLine,
}) => {
  const [sortColumn, setSortColumn] = useState("occurrences");
  const [sortDirection, setSortDirection] = useState("desc");

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 records

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const filteredOpenings = useMemo(() => {
    return openingData.filter((line) => {
      return (
        // Case 1: Show all entries of the selected color when no selectedLine is provided
        (line.color === colorFilter && selectedLine === "") ||
        // Case 2: Filter entries that start with selectedLine and have moves within the specified range
        (line.color === colorFilter &&
          line.moves.startsWith(selectedLine) &&
          line.numberOfMoves >= moveRange[0] &&
          line.numberOfMoves <= moveRange[1])
      );
    });
  }, [openingData, colorFilter, selectedLine, moveRange]);

  const sortedOpenings = useMemo(() => {
    const data = [...filteredOpenings];
    if (!sortColumn) return data;

    const sortFn = (a, b) => {
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];

      if (sortColumn.includes(".")) {
        const parts = sortColumn.split(".");
        aValue = parts.reduce((obj, key) => obj?.[key], a);
        bValue = parts.reduce((obj, key) => obj?.[key], b);
      }

      if (typeof aValue === "string") {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      } else if (typeof aValue === "number") {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (aValue === bValue) return 0;

      return sortDirection === "asc"
        ? aValue > bValue
          ? 1
          : -1
        : aValue > bValue
        ? -1
        : 1;
    };

    return data.sort(sortFn);
  }, [filteredOpenings, sortColumn, sortDirection]);

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Handle records per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        backgroundColor: "rgba(0, 0, 0, 1)",
        overflow: "hidden",
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ backgroundColor: "rgba(0, 0, 0, 1)" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 1 }}>
                <strong>Line</strong>
              </TableCell>
              <TableCell
                onClick={() => handleSort("occurrences")}
                style={{ cursor: "pointer" }}
                sx={{ p: 1 }}
              >
                <strong>Occurrences</strong>
                {sortColumn === "occurrences" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("evaluation.stockEval")}
                style={{ cursor: "pointer" }}
                sx={{ p: 1 }}
              >
                <strong>Current Eval</strong>
                {sortColumn === "evaluation.stockEval" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("previousEvaluation.stockEval")}
                style={{ cursor: "pointer" }}
                sx={{ p: 1 }}
              >
                <strong>Previous Eval</strong>
                {sortColumn === "previousEvaluation.stockEval" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("evaluationDiff")}
                style={{ cursor: "pointer" }}
                sx={{ p: 1 }}
              >
                <strong>Evaluation Diff</strong>
                {sortColumn === "evaluationDiff" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell sx={{ p: 1 }}>
                <strong>Stockfish Moves</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedOpenings
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((line, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ p: 1 }}>{line.moves}</TableCell>
                  <TableCell sx={{ p: 1 }}>{line.occurrences}</TableCell>
                  <TableCell sx={{ p: 1 }}>
                    {line.evaluation?.stockEval}
                  </TableCell>
                  <TableCell sx={{ p: 1 }}>
                    {line.previousEvaluation?.stockEval}
                  </TableCell>

                  <TableCell
                    style={{
                      color:
                        line.evaluationDiff > 0
                          ? line.color === "White"
                            ? "green"
                            : "red"
                          : line.evaluationDiff < 0
                          ? line.color === "Black"
                            ? "green"
                            : "red"
                          : "white",
                    }}
                    sx={{ p: 1 }}
                  >
                    {line.evaluationDiff?.toFixed(2) || "-"}
                  </TableCell>
                  <TableCell sx={{ p: 1 }}>
                    <MovesLineChart opening={line} colorFilter={colorFilter} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={sortedOpenings.length}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};

export default AnalysisTable;
