import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PieceTrainerHelp = () => {
  const { t } = useTranslation("Learn"); // Use the correct namespace

  return (
    <>
      <Typography paragraph>
        {t("PieceTrainer.helpDetails.description")}
      </Typography>

      <List>
        {/* Choosing a Piece and Starting the Game */}
        <ListItem>
          <ListItemText
            primary={t(
              "PieceTrainer.helpDetails.instructions.choose_piece.primary"
            )}
            secondary={t(
              "PieceTrainer.helpDetails.instructions.choose_piece.secondary"
            )}
          />
        </ListItem>

        {/* Timer Mode */}
        <ListItem>
          <ListItemText
            primary={t(
              "PieceTrainer.helpDetails.instructions.timer_mode.primary"
            )}
            secondary={t(
              "PieceTrainer.helpDetails.instructions.timer_mode.secondary"
            )}
          />
        </ListItem>

        {/* Fewest Moves Mode */}
        <ListItem>
          <ListItemText
            primary={t(
              "PieceTrainer.helpDetails.instructions.fewest_moves_mode.primary"
            )}
            secondary={t(
              "PieceTrainer.helpDetails.instructions.fewest_moves_mode.secondary"
            )}
          />
        </ListItem>

        {/* Clear Arrows (Only for Fewest Moves Mode) */}
        <ListItem>
          <ListItemText
            primary={t(
              "PieceTrainer.helpDetails.instructions.clear_arrows.primary"
            )}
            secondary={t(
              "PieceTrainer.helpDetails.instructions.clear_arrows.secondary"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default PieceTrainerHelp;
