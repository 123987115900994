import { Chess } from "chess.js";
import { ref, update } from "firebase/database";
import { rt } from "../../../../config/firebase";

export const runRepertoireAnalysis = async (
  repertoireId,
  tasksRef,
  setTasks,
  repertoires,
  setRepertoires,
  userData
) => {
  const STOCKFISH_COUNT = 5; // Adjust based on performance needs
  const stockfishWorkers = [];
  const firebaseUpdates = {};
  const userId = userData?.uid || "";

  const initializeStockfishWorkers = async () => {
    return new Promise((resolve) => {
      let readyCount = 0;

      const handleReady = (index) => (event) => {
        if (event.data === "readyok") {
          stockfishWorkers[index].status = "idle";
          readyCount++;
          if (readyCount === STOCKFISH_COUNT) resolve();
        }
      };

      for (let i = 0; i < STOCKFISH_COUNT; i++) {
        const worker = new Worker(
          `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
        );
        stockfishWorkers.push({ worker, status: "idle" });

        worker.onmessage = handleReady(i); // Pass index explicitly ✅

        worker.postMessage("uci");
        worker.postMessage("isready");
      }
    });
  };

  const getAvailableWorker = async () => {
    while (true) {
      const workerObj = stockfishWorkers.find((w) => w.status === "idle");
      if (workerObj) {
        workerObj.status = "busy";
        return workerObj;
      }
      await new Promise((resolve) => setTimeout(resolve, 50)); // Wait for an available worker
    }
  };

  const cleanupStockfishWorkers = () => {
    stockfishWorkers.forEach(({ worker }) => worker.terminate());
  };

  const repertoire = repertoires[repertoireId]; // ✅ Access directly
  if (!repertoire) {
    return;
  }

  const moves = repertoire.moves || {};
  const moveIds = Object.keys(moves);
  const description = repertoire.title;

  setTasks((prevTasks) => {
    const exists = prevTasks.some((t) => t.id === repertoireId);
    return exists
      ? prevTasks.map((t) =>
          t.id === repertoireId
            ? {
                ...t,
                status: "in-progress",
                progress: 0,
                progressTarget: moveIds.length,
                description,
              }
            : t
        )
      : [
          ...prevTasks,
          {
            id: repertoireId,
            status: "in-progress",
            progress: 0,
            progressTarget: moveIds.length,
            description,
          },
        ];
  });

  if (!moveIds.length) {
    setTasks((prevTasks) =>
      prevTasks.map((t) =>
        t.id === repertoireId
          ? { ...t, status: "completed", progressTarget: 0 }
          : t
      )
    );
    return;
  }

  await initializeStockfishWorkers();

  const analyzePosition = (fen, depth, workerObj) => {
    return new Promise((resolve) => {
      let bestEval = null;
      const { worker } = workerObj;
      const isBlackTurn = fen.includes(" b ");

      const handleMessage = (event) => {
        const message = event.data;

        if (message.startsWith("info depth") && message.includes("score")) {
          const match = message.match(/score (\w+) (-?\d+)/);
          if (match) {
            const evalType = match[1];
            const evalValue = parseInt(match[2], 10);

            if (evalType === "cp") {
              bestEval = (isBlackTurn ? -evalValue : evalValue) / 100;
              bestEval = bestEval.toFixed(2);
            } else if (evalType === "mate") {
              const mateValue = parseInt(match[2], 10);
              bestEval =
                (isBlackTurn ? -mateValue : mateValue) > 0
                  ? `M${Math.abs(mateValue)}`
                  : `-M${Math.abs(mateValue)}`;
            }
          }
        }

        if (message.includes("bestmove")) {
          worker.removeEventListener("message", handleMessage);
          workerObj.status = "idle"; // Mark worker as available
          resolve(bestEval);
        }
      };

      worker.addEventListener("message", handleMessage);
      worker.postMessage(`position fen ${fen}`);
      worker.postMessage(`go depth ${depth}`);
    });
  };

  const getMoveSequence = (moves, selectedMoveId) => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san.trim() !== "") movePath.unshift(san);
      currentMoveId = moves[currentMoveId].parent;
    }

    return movePath;
  };

  const moveQueue = [...moveIds]; // Queue of moves to process
  const runningTasks = new Set(); // Track running tasks

  while (moveQueue.length > 0 || runningTasks.size > 0) {
    while (moveQueue.length > 0 && runningTasks.size < STOCKFISH_COUNT) {
      const currentMoveId = moveQueue.shift();
      const sequence = getMoveSequence(moves, currentMoveId);
      const chess = new Chess();
      sequence.forEach((moveSan) => chess.move(moveSan));
      const fen = chess.fen();

      const workerObj = await getAvailableWorker();
      runningTasks.add(workerObj); // Track running worker

      analyzePosition(fen, 15, workerObj)
        .then((evaluation) => {
          setRepertoires((prevRepertoires) => {
            if (
              !prevRepertoires[repertoireId] ||
              !prevRepertoires[repertoireId].moves[currentMoveId]
            ) {
              return prevRepertoires; // Skip if repertoire or move was deleted
            }

            firebaseUpdates[
              `users/${userId}/repertoires/${repertoireId}/moves/${currentMoveId}/eval`
            ] = evaluation;

            return {
              ...prevRepertoires,
              [repertoireId]: {
                ...prevRepertoires[repertoireId],
                moves: {
                  ...prevRepertoires[repertoireId].moves,
                  [currentMoveId]: {
                    ...prevRepertoires[repertoireId].moves[currentMoveId],
                    eval: evaluation,
                  },
                },
              },
            };
          });

          setTasks((prevTasks) =>
            prevTasks.map((t) =>
              t.id === repertoireId
                ? { ...t, progress: moveIds.length - moveQueue.length + 1 }
                : t
            )
          );
        })
        .finally(() => {
          runningTasks.delete(workerObj); // Remove from running tasks
        });
    }

    await new Promise((resolve) => setTimeout(resolve, 50)); // Wait for tasks to finish
  }

  await Promise.all([...runningTasks]); // Wait for remaining tasks

  firebaseUpdates[
    `users/${userId}/repertoires/${repertoireId}/repertoire_analysed`
  ] = true;
  firebaseUpdates[
    `users/${userId}/repertoires/${repertoireId}/repertoire_analysed_datetime`
  ] = Date.now();

  await update(ref(rt), firebaseUpdates);

  setRepertoires((prevRepertoires) => ({
    ...prevRepertoires,
    [repertoireId]: {
      ...prevRepertoires[repertoireId],
      repertoire_analysed: true,
      repertoire_analysed_datetime: Date.now(),
    },
  }));

  cleanupStockfishWorkers();

  setTasks((prevTasks) =>
    prevTasks.map((t) =>
      t.id === repertoireId ? { ...t, status: "completed" } : t
    )
  );
};

export const runUserGameAnalysis = async (
  gameId,
  tasksRef,
  setTasks,
  userGames,
  setUserGames,
  userData,
  depth
) => {
  const STOCKFISH_COUNT = 5; // Adjust for parallelism
  const stockfishWorkers = [];
  const firebaseUpdates = {};
  const userId = userData?.uid || "";

  const initializeStockfishWorkers = async () => {
    return new Promise((resolve) => {
      let readyCount = 0;

      const handleReady = (index) => (event) => {
        if (event.data === "readyok") {
          stockfishWorkers[index].status = "idle";
          readyCount++;
          if (readyCount === STOCKFISH_COUNT) resolve();
        }
      };

      for (let i = 0; i < STOCKFISH_COUNT; i++) {
        const worker = new Worker(
          `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
        );
        stockfishWorkers.push({ worker, status: "idle", currentTask: null });

        worker.onmessage = handleReady(i);

        worker.postMessage("uci");
        worker.postMessage("isready");
      }
    });
  };

  const getAvailableWorker = async () => {
    while (true) {
      const workerObj = stockfishWorkers.find((w) => w.status === "idle");
      if (workerObj) {
        workerObj.status = "busy";
        return workerObj;
      }
      await new Promise((resolve) => setTimeout(resolve, 50)); // Wait for a free worker
    }
  };

  const cleanupStockfishWorkers = () => {
    stockfishWorkers.forEach(({ worker }) => worker.terminate());
  };

  const game = userGames.find((g) => g.id === gameId);
  const moves = game.moves;
  const moveIds = Object.keys(moves || {});
  const description = `${game?.white || "White"} vs ${game?.black || "Black"}`;

  setTasks((prevTasks) => {
    const exists = prevTasks.some((t) => t.id === gameId);
    return exists
      ? prevTasks.map((t) =>
          t.id === gameId
            ? {
                ...t,
                status: "in-progress",
                progress: 0,
                progressTarget: moveIds.length,
                description: description,
              }
            : t
        )
      : [
          ...prevTasks,
          {
            id: gameId,
            status: "in-progress",
            progress: 0,
            progressTarget: moveIds.length,
            description: description,
          },
        ];
  });

  if (!moveIds.length) {
    setTasks((prevTasks) =>
      prevTasks.map((t) =>
        t.id === gameId ? { ...t, status: "completed", progressTarget: 0 } : t
      )
    );
    return;
  }

  await initializeStockfishWorkers();

  const analyzePosition = (fen, depth = 15, workerObj) => {
    return new Promise((resolve) => {
      let bestEval = null;
      const { worker } = workerObj;
      const isBlackTurn = fen.includes(" b ");

      const handleMessage = (event) => {
        const message = event.data;

        if (message.startsWith("info depth") && message.includes("score")) {
          const match = message.match(/score (\w+) (-?\d+)/);
          if (match) {
            const evalType = match[1];
            const evalValue = parseInt(match[2], 10);

            if (evalType === "cp") {
              bestEval = (isBlackTurn ? -evalValue : evalValue) / 100;
              bestEval = bestEval.toFixed(2);
            } else if (evalType === "mate") {
              const mateValue = parseInt(match[2], 10);
              bestEval =
                (isBlackTurn ? -mateValue : mateValue) > 0
                  ? `M${Math.abs(mateValue)}`
                  : `-M${Math.abs(mateValue)}`;
            }
          }
        }

        if (message.includes("bestmove")) {
          worker.removeEventListener("message", handleMessage);
          workerObj.status = "idle"; // Mark worker as available
          resolve(bestEval);
        }
      };

      worker.addEventListener("message", handleMessage);
      worker.postMessage(`position fen ${fen}`);
      worker.postMessage(`go depth ${depth}`);
    });
  };

  const getMoveSequence = (moves, selectedMoveId) => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san.trim() !== "") movePath.unshift(san);
      currentMoveId = moves[currentMoveId].parent;
    }

    return movePath;
  };

  const moveQueue = [...moveIds]; // Queue of moves to process
  const runningTasks = new Set(); // Track running tasks

  while (moveQueue.length > 0 || runningTasks.size > 0) {
    while (moveQueue.length > 0 && runningTasks.size < STOCKFISH_COUNT) {
      const currentMoveId = moveQueue.shift();
      const sequence = getMoveSequence(moves, currentMoveId);
      const chess = new Chess();
      sequence.forEach((moveSan) => chess.move(moveSan));
      const fen = chess.fen();

      const workerObj = await getAvailableWorker();
      runningTasks.add(workerObj); // Track running worker

      analyzePosition(fen, depth, workerObj)
        .then((evaluation) => {
          setUserGames((prevGames) => {
            const gameIndex = prevGames.findIndex((game) => game.id === gameId);
            if (gameIndex === -1) return prevGames;

            const currentGame = prevGames[gameIndex];
            if (!currentGame.moves[currentMoveId]) return prevGames;

            firebaseUpdates[
              `users/${userId}/games/${gameId}/moves/${currentMoveId}/eval`
            ] = evaluation;

            return prevGames.map((game, index) =>
              index === gameIndex
                ? {
                    ...game,
                    moves: {
                      ...game.moves,
                      [currentMoveId]: {
                        ...game.moves[currentMoveId],
                        eval: evaluation,
                      },
                    },
                  }
                : game
            );
          });

          setTasks((prevTasks) =>
            prevTasks.map((t) =>
              t.id === gameId
                ? { ...t, progress: moveIds.length - moveQueue.length }
                : t
            )
          );
        })
        .finally(() => {
          runningTasks.delete(workerObj); // Remove from running tasks
        });
    }

    await new Promise((resolve) => setTimeout(resolve, 50)); // Wait for tasks to finish
  }

  await Promise.all([...runningTasks]); // Wait for remaining tasks

  firebaseUpdates[`users/${userId}/games/${gameId}/game_analysed`] = true;
  firebaseUpdates[`users/${userId}/games/${gameId}/game_analysed_datetime`] =
    Date.now();

  setUserGames((prevGames) =>
    prevGames.map((game) =>
      game.id === gameId
        ? {
            ...game,
            game_analysed: true,
            game_analysed_datetime: Date.now(),
          }
        : game
    )
  );

  await update(ref(rt), firebaseUpdates);

  cleanupStockfishWorkers();

  setTasks((prevTasks) =>
    prevTasks.map((t) => (t.id === gameId ? { ...t, status: "completed" } : t))
  );
};
