import { Box, Button, useTheme, TextField } from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { useState, useEffect } from "react";

import { convertMoveTreeToPGN } from "./helper";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

function PGNCard({ moves }) {
  const [pgn, setPgn] = useState("");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    let newPgn = convertMoveTreeToPGN(moves);
    if (newPgn !== pgn) {
      setPgn(newPgn);
    }
  }, [moves, pgn]); // Add `pgn` as a dependency

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(pgn);
  };

  return (
    <Box sx={{ width: "100%", pl: 1, pr: 1, pb: 0, position: "relative" }}>
      <TextField
        fullWidth
        multiline
        minRows={5}
        maxRows={5}
        value={pgn}
        disabled
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
        sx={{ width: "100%" }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <Button
          sx={{
            color: "white",
            backgroundColor: colors.green[100],
            "&:hover": {
              backgroundColor: colors.green[200],
            },
          }}
          variant="contained"
          startIcon={<ContentCopyRoundedIcon />}
          onClick={handleCopyToClipboard}
        >
          Copy
        </Button>
      </Box>
    </Box>
  );
}

export default PGNCard;
