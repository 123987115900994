import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchRepertoires } from "../../../config/firebaseDB";
import {
  ref,
  update,
  get,
  set,
  query,
  orderByKey,
  push,
  child,
} from "firebase/database";
import { rt } from "../../../config/firebase";

import { Helmet } from "react-helmet";
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Imported Components
import { useShare } from "../../../context/ShareContext";
import { useUser } from "../../../context/UserContext";
import RepertoireBuilderHeader from "./Components/header";
import MastersCard from "./Components/mastersCard";
import DebugCard from "./Components/debugCard";
import EngineCard from "./Components/engineCard";
import RepertoireCard from "./Components/repertoireCard";

// Builder
import RepertoireCardList from "./Builder/builderRepertoireCard";
import RepertoireDialog from "./Builder/builderRepertoireDialog";
import ChessboardCardBuilder from "./Builder/builderChessboardCard";
import MovesCard from "./Builder/builderMovesCard";

// Trainer
import UserGamesList from "./MyGames/myGamesUserGameList";
import ChessboardCardMyGames from "./MyGames/myGamesChessboardCard";
import MovesCardMyGames from "./MyGames/myGamesMoveCard";
import GameDataEditor from "./MyGames/myGamesGameDataEditor";
import RepertoireCardAnalysis from "./MyGames/myGamesRepertoireCard";
import PositionTrainerCard from "./Trainer/trainerPositionTrainerCard";
import RepertoireTrainerCard from "./Trainer/trainerRepertoireTrainerCard";

// Tour
import ApplicationTour from "./Components/applicationTour";

// TaskTracker
import TaskTracker from "./Components/taskTracker";
import BlockedScreen from "./Components/blockedScreen";
import AdminComponent from "./Admin";
import AccessDenied from "./Admin/accessDenied";
import ComingSoonDialog from "./Components/comingSoonDialog";
import ReportBugDialog from "./Components/reportBugDialog";
import ProfileDialog from "./Components/profileDialog";
import TrainingHome from "./Trainer/trainingHome";
import WeaknessTrainerCard from "./Trainer/trainerWeaknessTrainerCard";

// My Games

function RepertoireBuilder() {
  const { userData } = useUser();
  const userId = userData?.uid || "";
  const [settings, setSettings] = useState({});
  const [searchParams] = useSearchParams();
  const isInitialLoad = useRef(true);
  const debugMode = searchParams.get("debug") === "true";
  const [activePage, setActivePage] = useState("Builder");
  const [isBlocked, setIsBlocked] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const chessboardCardBuilderRef = useRef(null);
  const chessboardCardUserGamesRef = useRef(null);

  // State: Store repertoires and selected repertoire
  const [repertoires, setRepertoires] = useState({});
  const [selectedRepertoireId, setSelectedRepertoireId] = useState(null);
  const [selectedRepertoireName, setSelectedRepertoireName] = useState("Lines");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [builderInComment, setBuilderInComment] = useState(false);
  const [userGameInComment, setUserGameInComment] = useState(false);

  const [folders, setFolders] = useState({});

  // Trainer UseStates
  const [selectedTrainingRepertoireId, setSelectedTrainingRepertoireId] =
    useState(null);
  const [selectedTrainingMoveId, setSelectedTrainingMoveId] = useState("root");
  const [trainingMoves, setTrainingMoves] = useState({
    root: { san: "", next: null, variations: [], parent: null },
  });
  const [trainingMode, setTrainingMode] = useState(null);

  // My Game UseStates
  const [userGames, setUserGames] = useState([]);
  const [selectedUserGameId, setSelectedUserGameId] = useState(null);
  const [userGameMoves, setUserGameMoves] = useState({
    root: { san: "", next: null, variations: [], parent: null },
  });
  const [selectedUserGameMoveId, setSelectedUserGameMoveId] = useState("root");
  const [selectedUserGameRepertoireId, setSelectedUserGameRepertoireId] =
    useState(null);
  const [
    selectedUserGamesRepertoireGameIds,
    setSelectedUserGamesRepertoireGameIds,
  ] = useState(null);

  // Dialog Menus
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);
  const [isReportBugModalOpen, setIsReportBugModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  //const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  // Tour
  const [runTour, setRunTour] = useState(false); // Track if the tour should run
  const [tourKey, setTourKey] = useState(0); // Ensures tour restarts from step 1

  // Evals
  const [builderChessboardEval, setBuilderChessboardEval] = useState(0.2);
  const [builderChessboardEvalOn, setBuilderChessboardEvalOn] = useState(false);

  const [gameChessboardEval, setGameChessboardEval] = useState(null);
  const [gameChessboardEvalOn, setGameChessboardEvalOn] = useState(true);

  // ✅ Consolidated repertoire details into a single state object
  const [repertoireDetails, setRepertoireDetails] = useState({
    title: "Repertoire",
    description: "",
    author: "",
    visibility: "Private",
    allowCopy: "No",
    boardOrientation: "white",
    parentId: null,
  });

  // Tasks
  const [tasks, setTasks] = useState([]);
  const tasksRef = useRef(tasks);
  useEffect(() => {
    tasksRef.current = tasks;
  }, [tasks]);

  // ✅ Moves stored separately
  const [moves, setMoves] = useState({
    root: { san: "", next: null, variations: [], parent: null },
  });

  const [selectedMoveId, setSelectedMoveId] = useState("root");

  const [totalMoves, setTotalMoves] = useState(0);
  const [totalGames, setTotalGames] = useState(0);
  const [totalRepertoires, setTotalRepertoires] = useState(0);

  // ✅ Updates Firebase only when "Save" is clicked, not on every state change
  const handleSaveRepertoire = async () => {
    if (!selectedRepertoireId || !userId) return;

    // ✅ Ensure the selected repertoire contains individual attributes
    const updatedRepertoire = {
      ...repertoires[selectedRepertoireId], // Keep existing repertoire data
      title: repertoireDetails.title,
      description: repertoireDetails.description,
      author: repertoireDetails.author,
      visibility: repertoireDetails.visibility,
      allowCopy: repertoireDetails.allowCopy,
      boardOrientation: repertoireDetails.boardOrientation,
      parentId: repertoireDetails.parentId,
      moves: moves, // Include the updated moves object
    };

    try {
      // ✅ Save the updated repertoire to Firebase
      await update(
        ref(rt, `users/${userId}/repertoires/${selectedRepertoireId}`),
        updatedRepertoire
      );

      // ✅ Update local state
      setRepertoires((prev) => ({
        ...prev,
        [selectedRepertoireId]: updatedRepertoire,
      }));

      setIsModalOpen(false); // Close the modal
    } catch (error) {}
  };

  const handleUserLogin = async (userId, userData = {}) => {
    if (!userId) return;

    const userRef = ref(rt, `users/${userId}`);

    try {
      const snapshot = await get(userRef);

      if (!snapshot.exists()) {
        // ✅ Check global limits
        const globalSnapshot = await get(ref(rt, "settings"));
        const globalSettings = globalSnapshot.exists()
          ? globalSnapshot.val()
          : {};

        if (globalSettings.totalUsers >= globalSettings.limitUsers) {
          setIsBlocked(true);
          return;
        }

        const now = Date.now();

        // ✅ Create base user entry
        await update(userRef, {
          createdAt: new Date(now).toISOString(),
          lastLogin: new Date(now).toISOString(),
        });

        // ✅ Determine how many repertoires exist (should be 0 here, but we check)
        const repSnapshot = await get(ref(rt, `users/${userId}/repertoires`));
        const repertoires = repSnapshot.exists() ? repSnapshot.val() : {};
        const newRepertoireId = push(
          child(ref(rt), `users/${userId}/repertoires`)
        ).key;

        // ✅ Your custom repertoire object
        const newRepertoire = {
          id: newRepertoireId,
          title: `Repertoire ${Object.keys(repertoires).length + 1}`,
          description: "",
          author: userData?.username || "",
          boardOrientation: "white",
          visibility: "Private",
          allowCopy: "No",
          createdAt: now,
          order: -9999,
          moves: {
            root: { san: "", next: null, variations: [], parent: null },
          },
        };

        // ✅ Set the new repertoire
        await update(
          ref(rt, `users/${userId}/repertoires/${newRepertoireId}`),
          newRepertoire
        );
      } else {
        // ✅ Update last login
        await set(
          ref(rt, `users/${userId}/lastLogin`),
          new Date().toISOString()
        );
      }
    } catch (error) {
      console.error("Login error:", error.message);
    }
  };

  useEffect(() => {
    if (userId) {
      handleUserLogin(userId);
    }
  }, [userId]);

  // ✅ Fetch repertoires from Firebase
  useEffect(() => {
    const unsubscribe = fetchRepertoires(userId, (data) => {
      setRepertoires(data);

      const totalRepertoires = Object.keys(data).length;
      setTotalRepertoires(totalRepertoires);

      // ✅ Count total moves across all repertoires
      const totalMoves = Object.values(data).reduce(
        (sum, repertoire) =>
          sum +
          (repertoire.moves ? Object.keys(repertoire.moves).length - 1 : 0),
        0
      );
      setTotalMoves(totalMoves);

      // Sort repertoires by order
      const sortedRepertoires = Object.entries(data).sort(
        ([, a], [, b]) => a.order - b.order
      );

      if (sortedRepertoires.length > 0 && isInitialLoad.current) {
        const [firstRepertoireId, firstRepertoire] = sortedRepertoires[0];

        // ✅ Load first repertoire
        setSelectedRepertoireId(firstRepertoireId);
        setRepertoireDetails({
          title: firstRepertoire.title || "",
          description: firstRepertoire.description || "",
          author: firstRepertoire.author || "",
          visibility: firstRepertoire.visibility || "Private",
          allowCopy: firstRepertoire.allowCopy || "No",
          boardOrientation: firstRepertoire.boardOrientation || "white",
          parentId: firstRepertoire.parentId || null,
        });
        setMoves(
          firstRepertoire.moves || {
            root: { san: "", next: null, variations: [], parent: null },
          }
        );

        setSelectedTrainingRepertoireId(firstRepertoireId);
        setTrainingMoves(
          firstRepertoire.moves || {
            root: { san: "", next: null, variations: [], parent: null },
          }
        );

        isInitialLoad.current = false;
      }
    });

    const defaultFolders = {
      none: {
        id: "none",
        name: "Uncategorised",
        color: "#888888",
        createdAt: Date.now(),
      },
      white: {
        id: "white",
        name: "White",
        color: "#888888",
        createdAt: Date.now(),
      },
      black: {
        id: "black",
        name: "Black",
        color: "#888888",
        createdAt: Date.now(),
      },
    };

    if (!userId) return;

    get(ref(rt, `users/${userId}/folders`))
      .then(async (snapshot) => {
        const folderData = snapshot.val();

        if (!snapshot.exists()) {
          // No folders exist — create default ones
          await set(ref(rt, `users/${userId}/folders`), defaultFolders);
          setFolders(defaultFolders);
        } else {
          setFolders(folderData);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch folders:", error);
      });

    return () => unsubscribe();
  }, [userId]);

  useEffect(() => {
    if (!userId) return;

    const gamesRef = query(ref(rt, `users/${userId}/games`), orderByKey());

    get(gamesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setTotalGames(Object.keys(snapshot.val()).length); // ✅ Efficiently count game IDs
        } else {
          setTotalGames(0);
        }
      })
      .catch((error) => {});
  }, [userId]); // ✅ Runs only once on app load

  useEffect(() => {
    if (!selectedRepertoireId || !userId) return; // Ensure valid repertoire and user

    const updateMovesInFirebase = async () => {
      try {
        await update(
          ref(rt, `users/${userId}/repertoires/${selectedRepertoireId}/moves`),
          moves
        );
      } catch (error) {}
    };

    updateMovesInFirebase();
  }, [moves, userId, selectedRepertoireId]);

  useEffect(() => {
    if (!selectedUserGameId || !userId) return; // Ensure valid game and user

    const updateUserGameMovesInFirebase = async () => {
      try {
        await update(
          ref(rt, `users/${userId}/games/${selectedUserGameId}/moves`),
          userGameMoves
        );
      } catch (error) {}
    };

    updateUserGameMovesInFirebase();
  }, [userGameMoves, userId, selectedUserGameId]); // ✅ Runs when userGameMoves change

  // ✅ Share page data via context
  const { setShareData } = useShare();
  useEffect(() => {
    setShareData({
      url: "https://chessboardmagic.com/#Page URL#",
      title: "Chessboard Magic - #Page Name#",
      description: "#Page Description#",
    });
  }, [setShareData]);

  useEffect(() => {
    if (selectedRepertoireId && repertoires[selectedRepertoireId]) {
      setSelectedRepertoireName(
        repertoires[selectedRepertoireId].title || "Lines"
      );
    } else {
      setSelectedRepertoireName("Lines"); // Default fallback if no repertoire is selected
    }
  }, [selectedRepertoireId, repertoires]); // Runs when either changes

  useEffect(() => {
    if (
      selectedTrainingRepertoireId &&
      repertoires[selectedTrainingRepertoireId]
    ) {
      setTrainingMoves(repertoires[selectedTrainingRepertoireId].moves);
    }
  }, [selectedTrainingRepertoireId, repertoires]); // Runs when either changes

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        // ✅ Fetch global settings (includes all plans)
        const globalSnapshot = await get(ref(rt, "settings"));
        const globalSettings = globalSnapshot.exists()
          ? globalSnapshot.val()
          : {};

        const allPlans = globalSettings.plans || {};
        const freePlan = allPlans["free"] || {};

        if (userId) {
          // ✅ Fetch user's selected plan and isAdmin flag
          const userSnapshot = await get(ref(rt, `users/${userId}/settings`));
          const userSettings = userSnapshot.exists() ? userSnapshot.val() : {};

          const plan = userSettings.plan || "free";
          const isAdmin = userSettings.isAdmin || false;

          // ✅ Fetch plan limits (fallback to free)
          const planLimits = allPlans[plan] || freePlan;

          // ✅ Final merged settings
          setSettings({
            plan,
            isAdmin,
            ...planLimits,
          });
        }
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    };

    fetchSettings();
  }, [userId]);

  useEffect(() => {
    // ✅ Determine the current page for tracking
    const page =
      activePage === "Games" && selectedUserGameId ? "GameOpen" : activePage;

    // ✅ Retrieve visited pages from localStorage (or default to an empty object)
    const visitedPages = JSON.parse(localStorage.getItem("visitedPages")) || {};

    // ✅ If this page hasn’t been visited, start the tour and mark it as visited
    if (!visitedPages[page]) {
      setTourKey(0);
      setRunTour(true);

      // ✅ Update localStorage to mark this page as visited
      localStorage.setItem(
        "visitedPages",
        JSON.stringify({ ...visitedPages, [page]: true })
      );
    }
  }, [activePage, selectedUserGameId]);

  useEffect(() => {
    if (!userId) return; // Make sure userId is available before proceeding

    // Update Firebase metadata whenever totalMoves, totalGames, or totalRepertoires change
    const updateMetadataInFirebase = async () => {
      try {
        await update(ref(rt, `users/${userId}/metadata`), {
          moveCount: totalMoves,
          repertoireCount: totalRepertoires,
          gameCount: totalGames,
        });
      } catch (err) {
        console.error("🔴 Failed to update metadata:", err);
      }
    };

    updateMetadataInFirebase();
  }, [userId, totalMoves, totalGames, totalRepertoires]); // Dependencies: update on changes to these values

  return (
    <Box>
      <Helmet>
        <title>Repertoire Builder - {activePage}</title>
        <meta
          name="description"
          content="Master your chess openings with the Repertoire Builder. Import games, analyze with Stockfish, use beginner-friendly templates, and train your lines with powerful learning tools"
        />
        <meta property="og:title" content="Repertoire Builder" />
        <meta
          property="og:description"
          content="Master your chess openings with the Repertoire Builder. Import games, analyze with Stockfish, use beginner-friendly templates, and train your lines with powerful learning tools"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/analytics/repertoirebuilder.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/repertoirebuilder`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Repertoire Builder" />
        <meta
          name="twitter:description"
          content="Master your chess openings with the Repertoire Builder. Import games, analyze with Stockfish, use beginner-friendly templates, and train your lines with powerful learning tools"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/analytics/repertoirebuilder.png`}
        />
      </Helmet>

      {isBlocked ? (
        <BlockedScreen />
      ) : (
        <>
          <RepertoireBuilderHeader
            activePage={activePage}
            setActivePage={setActivePage}
            setSelectedMoveId={setSelectedMoveId}
            settings={settings}
            userData={userData}
            repertoires={repertoires}
            userGames={userGames}
            setSelectedUserGameId={setSelectedUserGameId}
            setRunTour={setRunTour}
            setTourKey={setTourKey}
            totalMoves={totalMoves}
            totalGames={totalGames}
            totalRepertoires={totalRepertoires}
            setIsComingSoonModalOpen={setIsComingSoonModalOpen}
            setIsReportBugModalOpen={setIsReportBugModalOpen}
            setIsProfileModalOpen={setIsProfileModalOpen}
            setTrainingMode={setTrainingMode}
          />

          <ApplicationTour
            runTour={runTour}
            setRunTour={setRunTour}
            activePage={activePage}
            selectedUserGameId={selectedUserGameId}
            tourKey={tourKey}
            trainingMode={trainingMode}
          />

          {activePage === "Builder" ? (
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              sx={{ p: 2, gap: 2 }}
            >
              {/* Left Column: Chessboard & Repertoire */}
              <Box
                sx={{
                  p: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: isMobile ? "100%" : "40%",
                  minWidth: isMobile ? "unset" : "400px",
                  gap: 2,
                }}
              >
                <ChessboardCardBuilder
                  ref={chessboardCardBuilderRef}
                  moves={moves}
                  setMoves={setMoves}
                  selectedMoveId={selectedMoveId}
                  setSelectedMoveId={setSelectedMoveId}
                  userData={userData}
                  repertoireBoardOrientation={
                    repertoireDetails.boardOrientation
                  }
                  activePage={activePage}
                  settings={settings}
                  totalMoves={totalMoves}
                  setTotalMoves={setTotalMoves}
                  evaluation={builderChessboardEval}
                  evaluationOn={builderChessboardEvalOn}
                  inComment={builderInComment}
                />

                <RepertoireCardList
                  repertoires={repertoires}
                  setRepertoires={setRepertoires}
                  selectedRepertoireId={selectedRepertoireId}
                  setSelectedRepertoireId={setSelectedRepertoireId}
                  repertoireDetails={repertoireDetails}
                  setRepertoireDetails={setRepertoireDetails}
                  setMoves={setMoves}
                  setSelectedMoveId={setSelectedMoveId}
                  setIsModalOpen={setIsModalOpen}
                  settings={settings}
                  totalRepertoires={totalRepertoires}
                  setTotalRepertoires={setTotalRepertoires}
                  totalMoves={totalMoves}
                  setTotalMoves={setTotalMoves}
                  folders={folders}
                  setFolders={setFolders}
                />

                <RepertoireDialog
                  repertoires={repertoires}
                  setRepertoires={setRepertoires}
                  moves={moves}
                  setMoves={setMoves}
                  setSelectedMoveId={setSelectedMoveId}
                  selectedRepertoireId={selectedRepertoireId}
                  setSelectedRepertoireId={setSelectedRepertoireId}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  repertoireDetails={repertoireDetails}
                  setRepertoireDetails={setRepertoireDetails}
                  handleSaveRepertoire={handleSaveRepertoire}
                  setTotalRepertoires={setTotalRepertoires}
                  totalMoves={totalMoves}
                  setTotalMoves={setTotalMoves}
                />
              </Box>

              {/* Center Column: Moves Panel */}
              <Box
                sx={{
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  width: isMobile ? "100%" : "40%",
                  minWidth: isMobile ? "unset" : "400px",
                }}
              >
                <MovesCard
                  repertoireName={selectedRepertoireName}
                  selectedRepertoireId={selectedRepertoireId}
                  moves={moves}
                  setMoves={setMoves}
                  selectedMoveId={selectedMoveId}
                  setSelectedMoveId={setSelectedMoveId}
                  setTasks={setTasks}
                  tasksRef={tasksRef}
                  repertoires={repertoires}
                  setRepertoires={setRepertoires}
                  userData={userData}
                  setTotalMoves={setTotalMoves}
                  setInComment={setBuilderInComment}
                />
              </Box>

              {/* Right Column: Analysis Panels */}
              <Box
                sx={{
                  p: 0,
                  overflowY: "auto",
                  width: isMobile ? "100%" : "20%",
                  minWidth: isMobile ? "unset" : "400px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <EngineCard
                  chessboardRef={chessboardCardBuilderRef}
                  moves={moves}
                  selectedMoveId={selectedMoveId}
                  setEval={setBuilderChessboardEval}
                  setEvalOn={setBuilderChessboardEvalOn}
                />
                <MastersCard
                  chessboardRef={chessboardCardBuilderRef}
                  moves={moves}
                  selectedMoveId={selectedMoveId}
                  boardOrientation={repertoireDetails.boardOrientation}
                />
                {debugMode && (
                  <DebugCard moves={moves} selectedMoveId={selectedMoveId} />
                )}
              </Box>
            </Box>
          ) : activePage === "Trainer" ? (
            trainingMode === null ? (
              <TrainingHome
                setTrainingMode={setTrainingMode}
                repertoires={repertoires}
              />
            ) : trainingMode === "positionTrainer" ? (
              <>
                <PositionTrainerCard
                  moves={trainingMoves}
                  setMoves={setTrainingMoves}
                  selectedMoveId={selectedTrainingMoveId}
                  setSelectedMoveId={setSelectedTrainingMoveId}
                  userData={userData}
                  repertoires={repertoires}
                  setRepertoires={setRepertoires}
                  selectedRepertoireId={selectedTrainingRepertoireId}
                  setSelectedRepertoireId={setSelectedTrainingRepertoireId}
                  activePage={activePage}
                  setTrainingMode={setTrainingMode}
                />
              </>
            ) : trainingMode === "repertoireTrainer" ? (
              <>
                <RepertoireTrainerCard
                  moves={trainingMoves}
                  setMoves={setTrainingMoves}
                  selectedMoveId={selectedTrainingMoveId}
                  setSelectedMoveId={setSelectedTrainingMoveId}
                  userData={userData}
                  repertoires={repertoires}
                  setRepertoires={setRepertoires}
                  selectedRepertoireId={selectedTrainingRepertoireId}
                  setSelectedRepertoireId={setSelectedTrainingRepertoireId}
                  activePage={activePage}
                  setTrainingMode={setTrainingMode}
                />
              </>
            ) : trainingMode === "weaknessTrainer" ? (
              <>
                <WeaknessTrainerCard
                  userData={userData}
                  repertoires={repertoires}
                  setRepertoires={setRepertoires}
                  activePage={activePage}
                  setTrainingMode={setTrainingMode}
                />
              </>
            ) : (
              <div>Unknown training mode</div>
            )
          ) : activePage === "Games" ? (
            <Box>
              {selectedUserGameId === null ? (
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  sx={{ gap: 0, p: 1 }}
                >
                  <UserGamesList
                    userData={userData}
                    userGames={userGames}
                    setUserGames={setUserGames}
                    selectedUserGameId={selectedUserGameId}
                    setSelectedUserGameId={setSelectedUserGameId}
                    setSelectedUserGameMoveId={setSelectedUserGameMoveId}
                    selectedUserGamesRepertoireGameIds={
                      selectedUserGamesRepertoireGameIds
                    }
                    settings={settings}
                    totalGames={totalGames}
                    setTotalGames={setTotalGames}
                  />
                  <RepertoireCardAnalysis
                    userData={userData}
                    userGames={userGames}
                    repertoires={repertoires}
                    selectedRepertoireId={selectedUserGameRepertoireId}
                    setSelectedRepertoireId={setSelectedUserGameRepertoireId}
                    setSelectedUserGamesRepertoireGameIds={
                      setSelectedUserGamesRepertoireGameIds
                    }
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  sx={{ p: 2, gap: 2 }}
                >
                  <Box
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: isMobile ? "100%" : "40%",
                      minWidth: isMobile ? "unset" : "400px",
                    }}
                  >
                    <ChessboardCardMyGames
                      ref={chessboardCardUserGamesRef}
                      userGames={userGames}
                      setUserGames={setUserGames}
                      moves={userGameMoves}
                      setMoves={setUserGameMoves}
                      selectedUserGameId={selectedUserGameId}
                      setSelectedUserGameId={setSelectedUserGameId}
                      selectedUserGameMoveId={selectedUserGameMoveId}
                      setSelectedUserGameMoveId={setSelectedUserGameMoveId}
                      userData={userData}
                      activePage={activePage}
                      evaluation={gameChessboardEval}
                      evaluationOn={gameChessboardEvalOn}
                      inComment={userGameInComment}
                    />
                  </Box>
                  <Box
                    sx={{
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      width: isMobile ? "100%" : "40%",
                      minWidth: isMobile ? "unset" : "400px",
                    }}
                  >
                    <MovesCardMyGames
                      moves={userGameMoves}
                      setMoves={setUserGameMoves}
                      selectedMoveId={selectedUserGameMoveId}
                      setSelectedMoveId={setSelectedUserGameMoveId}
                      selectedUserGameId={selectedUserGameId}
                      setTasks={setTasks}
                      tasksRef={tasksRef}
                      userGames={userGames}
                      setUserGames={setUserGames}
                      userData={userData}
                      setInComment={setUserGameInComment}
                    />
                  </Box>
                  {/* Right Column: Analysis Panels */}
                  <Box
                    sx={{
                      p: 0,
                      overflowY: "auto",
                      width: isMobile ? "100%" : "20%",
                      minWidth: isMobile ? "unset" : "400px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <RepertoireCard
                      repertoires={repertoires}
                      userGameMoves={userGameMoves}
                      selectedUserGameMoveId={selectedUserGameMoveId}
                    />

                    <EngineCard
                      chessboardRef={chessboardCardUserGamesRef}
                      moves={userGameMoves}
                      selectedMoveId={selectedUserGameMoveId}
                      setEval={setGameChessboardEval}
                      setEvalOn={setGameChessboardEvalOn}
                    />
                    <MastersCard
                      chessboardRef={chessboardCardUserGamesRef}
                      moves={userGameMoves}
                      selectedMoveId={selectedUserGameMoveId}
                      boardOrientation={
                        userGames.find((game) => game.id === selectedUserGameId)
                          .boardOrientation
                      }
                    />
                    <GameDataEditor
                      selectedUserGameId={selectedUserGameId}
                      userGames={userGames}
                      userGameMoves={userGameMoves}
                      setUserGames={setUserGames}
                      userData={userData}
                    />
                    {debugMode && (
                      <DebugCard
                        moves={userGameMoves}
                        selectedMoveId={selectedUserGameMoveId}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          ) : activePage === "Admin" ? (
            <>{settings.isAdmin ? <AdminComponent /> : <AccessDenied />}</>
          ) : (
            <></>
          )}
          <TaskTracker tasks={tasks} setTasks={setTasks} />
          <ComingSoonDialog
            open={isComingSoonModalOpen}
            onClose={setIsComingSoonModalOpen}
          />
          <ReportBugDialog
            open={isReportBugModalOpen}
            onClose={setIsReportBugModalOpen}
          />
          <ProfileDialog
            open={isProfileModalOpen}
            onClose={setIsProfileModalOpen}
          />
        </>
      )}
    </Box>
  );
}

export default RepertoireBuilder;
