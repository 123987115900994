import {
  Box,
  TextField,
  Button,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { useCallback, useEffect, useState } from "react";

import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

import { Chess } from "chess.js";
import { openings } from "../../../../data/eco/eco";

/**
 * DebugCard Component
 * Displays debug information for selected nodes and repertoire data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.selectedNode - The selected node object.
 * @param {Object[]} props.lines - The repertoire data.
 */
function OpeningCard({ moves, selectedMoveId }) {
  /**
   * Store details of the current opening, such as its name and ECO code.
   */
  const [openingDetails, setOpeningDetails] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  /**
   * Finds the chess opening name based on the game's move history.
   *
   * @param {Array} gameHistory - An array of SAN (Standard Algebraic Notation) strings representing the game's move history.
   * @returns {Object|null} An object representing the matched opening, including its name, PGN, FEN, and transposition status, or null if no match is found.
   */
  function findOpening(gameHistory) {
    const maxMoves = 15; // Limit the search to the first 15 moves to reduce computation
    const chess = new Chess(); // Create a new instance of the Chess.js library

    // Variable to store the last matched opening
    let lastMatchedOpening = null;

    /**
     * Builds a properly formatted PGN (Portable Game Notation) string from a history of moves.
     *
     * @param {Array} history - An array of SAN strings representing a sequence of moves.
     * @returns {string} A formatted PGN string.
     */
    const buildPGN = (history) => {
      let pgn = ""; // Initialize an empty PGN string

      for (let i = 0; i < history.length; i++) {
        if (i % 2 === 0) {
          // Add the move number for White's turn
          pgn += `${Math.floor(i / 2) + 1}. `;
        }
        pgn += `${history[i]} `; // Append the move to the PGN string
      }
      return pgn.trim(); // Remove any trailing whitespace
    };

    /**
     * Extracts the position part of a FEN (Forsyth-Edwards Notation) string.
     * The position part includes only the piece placement on the board.
     *
     * @param {string} fen - A full FEN string.
     * @returns {string} The position part of the FEN string.
     */
    const extractPositionFromFEN = (fen) => fen.split(" ")[0]; // Split by space and return the first part

    // Iterate through the moves in the game's history, up to the maximum allowed moves
    for (let i = 0; i < Math.min(gameHistory.length, maxMoves); i++) {
      const currentMoves = buildPGN(gameHistory.slice(0, i + 1)); // Build PGN for the current move sequence
      chess.reset(); // Reset the board to the starting position
      chess.loadPgn(currentMoves); // Load the PGN string into the chess instance

      // Check for a direct PGN match in the openings dataset
      const pgnMatch = openings.find((opening) => opening.pgn === currentMoves);

      if (pgnMatch) {
        lastMatchedOpening = {
          ...pgnMatch, // Include all details of the matched opening
          transposition: false, // Indicates a direct match with no transposition
        };
      } else {
        // If no PGN match, check for a FEN match (possible transposition)
        const currentFEN = extractPositionFromFEN(chess.fen()); // Get the FEN of the current position
        const fenMatch = openings.find(
          (opening) => extractPositionFromFEN(opening.fen) === currentFEN
        );
        if (fenMatch) {
          lastMatchedOpening = {
            ...fenMatch, // Include all details of the matched opening
            transposition: true, // Indicates a match via transposition
          };
        }
      }
    }

    // Return the last matched opening or null if no match was found
    return lastMatchedOpening;
  }

  const handleCopyToClipboard = (fen) => {
    navigator.clipboard.writeText(fen);
  };

  const getMoveSequence = useCallback(() => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san.trim() !== "") {
        // Exclude empty or whitespace-only SAN moves
        movePath.unshift(san); // Add move at the beginning to maintain order
      }
      currentMoveId = moves[currentMoveId].parent; // Move backwards to the parent
    }

    return movePath; // Return the sequence from root to selectedMoveId
  }, [selectedMoveId, moves]);

  // Identify and update the opening details based on the game's history
  useEffect(() => {
    const opening = findOpening(getMoveSequence());

    if (opening) {
      setOpeningDetails({
        name: opening.name,
        eco: opening.eco,
        pgn: opening.pgn,
        fen: opening.fen,
        transposition: opening.transposition,
      });
    } else {
      setOpeningDetails(null);
    }
  }, [getMoveSequence]);

  return (
    <Box sx={{ width: "100%" }}>
      {openingDetails ? (
        <>
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            {openingDetails.eco}: {openingDetails.name}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, fontSize: "0.9em" }}>
            {openingDetails.pgn}
          </Typography>
          {openingDetails.transposition && (
            <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
              (via Transposition)
            </Typography>
          )}
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Fen"
              value={openingDetails.fen}
              InputProps={{
                readOnly: true,
                disableUnderline: true, // Disable the underline
              }}
              sx={{
                "& .MuiFilledInput-root": {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 4,
                  borderBottom: "none", // Remove the bottom border
                },
                "& .MuiInputBase-input": {
                  cursor: "text", // Makes the text selectable
                  fontSize: "0.8rem", // Set the font size here
                },
              }}
              fullWidth
              variant="filled"
              InputLabelProps={{
                style: { color: colors.black[900] },
              }}
            />
            <Tooltip title="Copy to clipboard">
              <Button
                sx={{
                  width: "50px",
                  height: "50px",
                  minWidth: "30px",
                  marginRight: "8px",
                  padding: "10px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                variant="contained"
                onClick={() => handleCopyToClipboard(openingDetails.fen)}
              >
                <ContentCopyRoundedIcon />
              </Button>
            </Tooltip>
          </Box>
        </>
      ) : (
        <Typography>No opening found</Typography>
      )}
    </Box>
  );
}

export default OpeningCard;
