import { ref, set, remove, onValue, off } from "firebase/database";
import { rt } from "./firebase"; // Use the correct export for the Realtime Database instance

// Add a new repertoire
export const addRepertoire = (userId, repertoire) => {
  const repertoireId = repertoire.id;
  return set(
    ref(rt, `users/${userId}/repertoires/${repertoireId}`),
    repertoire
  );
};

// Delete a repertoire
export const deleteRepertoire = (userId, repertoireId) => {
  return remove(ref(rt, `users/${userId}/repertoires/${repertoireId}`));
};

// Fetch repertoires in real-time
export const fetchRepertoires = (userId, callback) => {
  const repertoiresRef = ref(rt, `users/${userId}/repertoires`);
  const listener = onValue(repertoiresRef, (snapshot) => {
    if (snapshot.exists()) {
      callback(snapshot.val());
    } else {
      callback({});
    }
  });

  // Return a cleanup function
  return () => {
    off(repertoiresRef, "value", listener);
  };
};
