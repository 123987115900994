import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  MenuItem,
  Box,
  Typography,
  Stack,
  Switch,
  useTheme,
} from "@mui/material";
import { ref, update } from "firebase/database";
import { rt } from "../../../../config/firebase"; // Adjust import path for Firebase

import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";

import { tokens } from "../../../../styles/theme";

const GameDataEditor = ({
  selectedUserGameId,
  userGames,
  userGameMoves,
  setUserGames,
  userData,
}) => {
  const [gameData, setGameData] = useState({});
  const [gamedataOn, setGamedataOn] = useState(false);
  const userId = userData?.uid || "";

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Initialize gameData when userGame changes
  useEffect(() => {
    if (userGames && selectedUserGameId) {
      const updatedGame = userGames.find((g) => g.id === selectedUserGameId);
      if (updatedGame) {
        setGameData(updatedGame); // ✅ Ensure gameData stays up-to-date
      }
    }
  }, [userGames, selectedUserGameId]);

  // Handle field changes
  const handleChange = async (field, value) => {
    if (!selectedUserGameId) return;

    try {
      // ✅ Update only the affected field in Firebase
      await update(ref(rt, `users/${userId}/games/${selectedUserGameId}`), {
        [field]: value,
      });

      // ✅ Ensure moves stay in sync by pulling from `userGameMoves`
      setUserGames((prevGames) =>
        prevGames.map((game) =>
          game.id === selectedUserGameId
            ? {
                ...game, // ✅ Preserve all existing game data
                [field]: value, // ✅ Update only the metadata field
                moves: userGameMoves || {}, // ✅ Always take the latest moves from state
              }
            : game
        )
      );
    } catch (error) {}
  };

  if (!selectedUserGameId || !userGames) {
    return <Typography>No game selected.</Typography>;
  }

  return (
    <Card
      id="games-gamedatacard"
      sx={{
        position: "relative", // Required for pseudo-element positioning
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SettingsEthernetIcon />
          </Box>
        }
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Game Data
          </Typography>
        }
        sx={{ pt: 1, pb: 1 }}
        action={
          <Switch
            checked={gamedataOn} // State variable
            onChange={(event) => {
              setGamedataOn(event.target.checked);
            }}
            sx={{
              "& .MuiSwitch-switchBase": { color: "white" },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: colors.green[100],
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "grey",
              },
              "& .MuiSwitch-track": { backgroundColor: "grey" },
            }}
          />
        }
      />

      {gamedataOn && ( // Render only if engineOn is true
        <>
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent
            sx={{
              paddingTop: "20px !important",
              paddingLeft: "10px !important",
              paddingRight: "10px !important",
              paddingBottom: "10px !important",
            }}
          >
            <Stack spacing={2}>
              {/* Editable Fields */}
              <Box display="flex" gap={2} sx={{ width: "100%" }}>
                <TextField
                  label="Event"
                  value={gameData.event || ""}
                  onChange={(e) => handleChange("event", e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Date"
                  type="date"
                  value={gameData.date || ""}
                  onChange={(e) => handleChange("date", e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& input[type=date]::-webkit-calendar-picker-indicator": {
                      filter: "invert(1)",
                    },
                  }}
                />
              </Box>

              <Box display="flex" gap={2} sx={{ width: "100%" }}>
                <TextField
                  select
                  label="Result"
                  value={gameData.result || ""}
                  onChange={(e) => handleChange("result", e.target.value)}
                  fullWidth
                >
                  {[
                    { label: "White Won", value: "1-0" },
                    { label: "Black Won", value: "0-1" },
                    { label: "Draw", value: "1/2-1/2" },
                    { label: "Game Incomplete", value: "*" },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Round"
                  value={gameData.round || ""}
                  onChange={(e) => handleChange("round", e.target.value)}
                  fullWidth
                />
              </Box>
              <Box display="flex" gap={2} sx={{ width: "100%" }}>
                <TextField
                  label="Time Control"
                  value={gameData.timeControl || ""}
                  onChange={(e) => handleChange("timeControl", e.target.value)}
                  sx={{ flex: 1 }} // ✅ Allow the field to share space
                />

                <TextField
                  select
                  label="Time Control (Label)"
                  value={gameData.timeControlLabel || ""}
                  onChange={(e) =>
                    handleChange("timeControlLabel", e.target.value)
                  }
                  sx={{ flex: 1 }} // ✅ Allow the field to share space
                >
                  {[
                    "UltraBullet",
                    "Bullet",
                    "Blitz",
                    "Rapid",
                    "Classical",
                    "Correspondence",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Divider />
              <Box display="flex" gap={2} sx={{ width: "100%" }}>
                <TextField
                  label="White Player"
                  value={gameData.white || ""}
                  onChange={(e) => handleChange("white", e.target.value)}
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="White Elo"
                  type="number"
                  value={gameData.whiteElo || ""}
                  onChange={(e) => handleChange("whiteElo", e.target.value)}
                  sx={{ width: "30%" }}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ width: "100%" }}>
                <TextField
                  label="Black Player"
                  value={gameData.black || ""}
                  onChange={(e) => handleChange("black", e.target.value)}
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Black Elo"
                  type="number"
                  value={gameData.blackElo || ""}
                  onChange={(e) => handleChange("blackElo", e.target.value)}
                  sx={{ width: "30%" }}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ width: "100%" }}>
                <TextField
                  label="Opening Name"
                  value={gameData.openingName || ""}
                  onChange={(e) => handleChange("openingName", e.target.value)}
                  sx={{ width: "70%" }}
                />
                <TextField
                  label="ECO Code"
                  value={gameData.eco || ""}
                  onChange={(e) => handleChange("eco", e.target.value)}
                  sx={{ width: "30%" }}
                />
              </Box>
              <TextField
                select
                label="Default Board Orientation"
                value={gameData.boardOrientation || ""}
                onChange={(e) =>
                  handleChange("boardOrientation", e.target.value)
                }
                sx={{ flex: 1 }} // ✅ Allow the field to share space
              >
                {["white", "black"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Site"
                value={gameData.site || ""}
                onChange={(e) => handleChange("site", e.target.value)}
                fullWidth
              />
            </Stack>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default GameDataEditor;
