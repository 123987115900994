import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";

import { useNavigate } from "react-router-dom";

const BlockedScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Card
        sx={{
          textAlign: "center",
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          color: colors.black[900], // Text color
          backgroundImage: "none !important",
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 2, // Padding for the card content
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Sorry, we're at full capacity!
          </Typography>
          <Typography variant="body1" paragraph>
            Please check back later when more slots open up.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
            sx={{ textTransform: "none" }}
          >
            Return to Chessboard Magic
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BlockedScreen;
