import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Box,
  IconButton,
  useTheme,
  Stack,
  Typography,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import DiscordIcon from "../../../../icons/DiscordIcon";

import useDialogFocused from "./useDialogFocused";

const ComingSoonDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900], // Text color
          border: `0.5px solid #222222`, // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      open={open}
      onClose={() => onClose(false)}
      maxWidth="md"
      fullWidth
    >
      {useDialogFocused(open)}
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {/* Left-aligned: Help Icon + Title */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6">Coming Soon</Typography>
          </Stack>

          {/* Right-aligned: Close Button */}
          <IconButton onClick={() => onClose(false)} color="inherit">
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ backgroundColor: colors.green[900], height: "1.5px" }} />
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} height="100%" p={2}>
          <Typography variant="body1">
            This is a <strong>public alpha release</strong> of my Repertoire
            Builder, and it includes the baseline features that will form the
            core of the full application. I built this as a passion project and
            I am incredibly grateful for everyone who has tried it out and
            shared feedback. Your support helps guide the direction of future
            development.
          </Typography>

          <Typography variant="body1">
            Below is a preview of the key features I am planning to include in
            the first full release:
          </Typography>

          <Box component="ul" sx={{ pl: 3, mt: 0 }}>
            <Box component="li" mb={1.5}>
              <strong>Spaced Trainer for your Repertoire</strong> – A smarter
              training system using spaced repetition to help you internalize
              your lines more effectively over time. Rather than brute-force
              memorization, this method ensures you practice the right lines at
              the right time, boosting long-term retention and performance over
              the board.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Generate Repertoire from Your Games</strong> –
              Automatically analyze your imported games to generate tailored
              repertoires, providing a solid foundation to build upon based on
              your actual playing style and experience.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Search Functionality</strong> – I believe the ability to
              search within your repertoire will be a powerful addition. Whether
              you're looking for a specific line, move, or variation, being able
              to instantly jump to that point would greatly improve navigation
              and workflow — especially as repertoires grow in size. I am still
              exploring the best approach to implement this effectively and am
              definitely open to suggestions.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Build From Here</strong> – I plan to integrate the
              Repertoire Wizard directly into the Moves Card. This means you'll
              be able to click on any move and ask the app to generate a move
              tree from that position using Stockfish. It will make building
              deep and logical repertoires much faster without needing to
              manually consult the engine each time.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Offline Mode</strong> – I also want to support offline
              editing. This will allow you to work on your repertoire without
              needing an internet connection, and the app will automatically
              sync your changes once you’re back online — keeping everything
              seamless and uninterrupted.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Mobile Support</strong> – Currently, the Repertoire
              Builder is designed for desktop use. Once I feel the core
              functionality is stable, I will begin optimizing the interface and
              interactions for mobile devices so you can build, view, and train
              on the go.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Increased Limits</strong> – Since this is a non-profit
              project, I want to strike the right balance between fair access
              and sustainable costs. I will continue monitoring usage and server
              load to ensure that limits are reasonable and that paid tiers (if
              introduced) remain affordable and inclusive.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Increased Templates</strong> – At the moment, the template
              selection is limited. I want to offer a much wider range of
              repertoire templates for different styles, openings, and levels of
              experience. This should make it easier for new users to get
              started and explore openings they may not be familiar with.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Annotated Templates</strong> – I aim to provide detailed
              commentary and insights for each line in selected templates. These
              annotations will explain why certain moves are played and help
              users understand the ideas behind the lines. While I am not a
              titled player, I will do my best to provide value, and I would
              love to feature community-contributed annotations as well.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Repertoire Sharing</strong> – The current flag icons are
              just placeholders. My goal is to allow users to publish their
              repertoires, making them publicly accessible and sharable. Others
              will be able to view, copy, and import these repertoires into
              their own workspace — even without an account — enabling
              community-driven learning.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Integrate Chessboard Magic Games</strong> – I am working
              to bring over additional tools like User Scout and Opponent Prep
              into the builder experience. The goal is to create a complete
              ecosystem where you can prepare, train, and explore all in one
              place — making it more than just a repertoire app.
            </Box>
            <Box component="li" mb={1.5}>
              <strong>Localisation</strong> – The rest of Chessboard Magic
              already supports 12 different languages, and I want the Repertoire
              Builder to reflect that same global accessibility. Multilingual
              support will allow players from all backgrounds to learn and train
              in their preferred language.
            </Box>
          </Box>

          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            Thank you for being part of this journey — your support truly means
            a lot.
          </Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography sx={{ mb: 1 }}>
            You can contact me directly at:
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {" "}
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              href="https://discord.gg/c82jQuy9AM"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<DiscordIcon sx={{ width: 24, height: 24 }} />}
            >
              Discord
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              href="https://lichess.org/@/HollowLeaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/${
                    useTheme().palette.mode === "dark"
                      ? "lichessdotorgwhite.png"
                      : "lichessdotorg.png"
                  }`}
                  alt="lichess.org"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              href="https://www.chess.com/member/hollowleaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/chessdotcom.png`}
                  alt="chess.com"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Typography component="span">
              or email me at{" "}
              <a
                href="mailto:toan.hoang@simplifyinsights.com?subject=Chessboard Magic"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                toan.hoang@simplifyinsights.com
              </a>
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ComingSoonDialog;
