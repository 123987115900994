import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import GameViewer from "../../../components/GameViewer";
import { Helmet } from "react-helmet";

function ChessPrinciples() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // If you have cleanup code, you can return a cleanup function (optional)
    return () => {};
  }, []);

  return (
    <Box>
      <ContentHeader
        title="Chess Principles"
        subtitle="Chess principles include controlling the center, developing pieces early, ensuring king safety, and planning strategically for both attack and defense."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Helmet>
        <title>Chess Principles</title>
        <meta
          name="description"
          content="Chess principles include controlling the center, developing pieces early, ensuring king safety, and planning strategically for both attack and defense."
        />
        <meta property="og:title" content="Chess Principles" />
        <meta
          property="og:description"
          content="Chess principles include controlling the center, developing pieces early, ensuring king safety, and planning strategically for both attack and defense."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/chessprinciples.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/chessprinciples`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Principles" />
        <meta
          name="twitter:description"
          content="Chess principles include controlling the center, developing pieces early, ensuring king safety, and planning strategically for both attack and defense."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/chessprinciples.png`}
        />
      </Helmet>
      <Box>
        <GameViewer Moves="1. e4 e5" />
      </Box>
    </Box>
  );
}

export default ChessPrinciples;
