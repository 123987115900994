import React, { useEffect, useCallback, useRef, useState } from "react";
import { Chessboard } from "react-chessboard";
import { useSearchParams } from "react-router-dom";
import { Chess } from "chess.js";
import {
  Box,
  Button,
  Card,
  CardHeader,
  MenuItem,
  Select,
  useTheme,
  Fade,
  Alert,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import KeyboardOptionKeyIcon from "@mui/icons-material/KeyboardOptionKey";
import { themeColors } from "../../../../styles/boardtheme";
import { pieceSets } from "../../../../styles/pieceset";
import { tokens } from "../../../../styles/theme";

import { ref, update } from "firebase/database";
import { rt } from "../../../../config/firebase";

import TrainingMovesCard from "./trainerMovesCard";
import DebugCard from "../Components/debugCard";

const RepertoireTrainerCard = ({
  moves,
  setMoves,
  selectedMoveId,
  setSelectedMoveId,
  userData,
  repertoires,
  setRepertoires,
  selectedRepertoireId,
  setSelectedRepertoireId,
  activePage,
  setTrainingMode,
}) => {
  const chessboardRef = useRef(null);
  const [game, setGame] = useState(new Chess());
  const [showAlert, setShowAlert] = useState(false);
  const [boardOrientation, setBoardOrientation] = useState(
    repertoires[selectedRepertoireId]?.boardOrientation
  );
  const [requiredMove, setRequiredMove] = useState(null);
  const [trainingStarted, setTrainingStarted] = useState(false);
  const [result, setResult] = useState(null);

  const userId = userData?.uid || "";

  const [searchParams] = useSearchParams();
  const debugMode = searchParams.get("debug") === "true";

  const [moveMistakes, setMoveMistakes] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showCard, setShowCard] = useState(false);
  const [computerMove, setComputerMove] = useState(true);

  const trainingStartTimeRef = useRef(null);

  const handleTrainClick = () => {
    if (!selectedRepertoireId || !moves["root"]) return;

    const firstMoveId = moves["root"].next;
    if (firstMoveId) {
      setRequiredMove(firstMoveId);
      setTrainingStarted(true);
      trainingStartTimeRef.current = performance.now();
      setShowAlert(false);
      setShowCard(false);
      setLastMove({ from: null, to: null });
      setGame(new Chess());
    }

    if (boardOrientation === "black") {
      makeApplicationMove(firstMoveId);
    }
  };

  const updateAnalysisData = async (
    selectedMoveId,
    mistakeCount,
    isIntentionalAnswer = true
  ) => {
    const trainingEndTime = performance.now();
    const responseTime = trainingStartTimeRef.current
      ? trainingEndTime - trainingStartTimeRef.current
      : null;

    trainingStartTimeRef.current = null; // Clear ref for safety

    const today = new Date().toISOString().split("T")[0];
    const move = moves[selectedMoveId];
    if (!move) return;

    let newAccuracy = move.trainingAccuracy || 0;

    // ⏱ Time-based modifier
    let timeBonus = 0;
    if (responseTime !== null && isIntentionalAnswer) {
      if (responseTime < 5000) {
        timeBonus = mistakeCount === 0 ? 0.25 : -0.25;
      } else if (responseTime > 20000) {
        timeBonus = mistakeCount === 0 ? -0.125 : 0;
      }
    }

    // 🎯 Accuracy logic
    if (mistakeCount === 0) {
      newAccuracy += 1 + timeBonus;
    } else {
      const penalties = [-1, -0.5, -0.25, -0.1, -0.05];
      let totalPenalty = 0;
      const cappedMistakes = Math.min(mistakeCount, 5);
      for (let i = 0; i < cappedMistakes; i++) {
        totalPenalty += penalties[i];
      }
      newAccuracy += totalPenalty + timeBonus;
    }

    // Clamp value
    move.trainingAccuracy = Number(
      Math.max(-5, Math.min(5, newAccuracy)).toFixed(2)
    );
    move.trainingDate = today;
    move.trainingCount = (move.trainingCount || 0) + 1;

    if (responseTime !== null) {
      const averageResponseTime = responseTime / (mistakeCount + 1);
      move.trainingAvgResponseTime = Number(averageResponseTime.toFixed(2));
    }

    // Update local state
    setRepertoires((prev) => ({
      ...prev,
      [selectedRepertoireId]: {
        ...prev[selectedRepertoireId],
        moves: {
          ...prev[selectedRepertoireId].moves,
          [selectedMoveId]: move,
        },
      },
    }));

    setMoves((prevMoves) => ({
      ...prevMoves,
      [selectedMoveId]: move,
    }));

    // Update Firebase
    try {
      await update(
        ref(
          rt,
          `users/${userId}/repertoires/${selectedRepertoireId}/moves/${selectedMoveId}`
        ),
        {
          trainingAccuracy: move.trainingAccuracy,
          trainingDate: move.trainingDate,
          trainingCount: move.trainingCount,
          trainingAvgResponseTime: move.trainingAvgResponseTime,
        }
      );
    } catch (error) {
      console.error("Failed to update move in Firebase:", error);
    }
  };

  const handleMove = (sourceSquare, targetSquare) => {
    try {
      const newGame = new Chess();
      newGame.loadPgn(game.pgn());

      const move = newGame.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q",
      });

      if (!trainingStarted) return true;

      if (!move || !requiredMove) {
        setResult("incorrect");
        setMoveMistakes((prev) => prev + 1);
        setShowAlert(true);
        return false;
      }

      // Get the required move and variations clearly
      const possibleMoves = [
        moves[requiredMove],
        ...(moves[requiredMove]?.variations || []).map((v) => moves[v]),
      ].filter(Boolean);

      // Find the move ID matching the played SAN
      const matchedMove = possibleMoves.find((m) => m.san === move.san);

      if (!matchedMove) {
        setResult("incorrect");
        setMoveMistakes((prev) => prev + 1);
        setShowAlert(true);
        return false;
      }

      setGame(newGame);
      setResult("correct");
      updateAnalysisData(requiredMove, moveMistakes);
      setShowAlert(true);
      setLastMove({ from: sourceSquare, to: targetSquare });
      setSelectedMoveId(matchedMove.id);

      if (matchedMove.next) {
        setRequiredMove(matchedMove.next);
        makeApplicationMove(matchedMove.next);
      } else {
        setResult("completed");
        setTrainingStarted(false);
        setShowCard(true);
        setShowAlert(true);
      }

      return true;
    } catch (error) {
      setResult("incorrect");
      setShowAlert(true);
      return false;
    }
  };

  const handleSkipMove = () => {
    if (!requiredMove || !moves[requiredMove]) return;

    const skippedMove = moves[requiredMove];

    updateAnalysisData(requiredMove, moveMistakes + 1);

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn());
      const moveResult = newGame.move(skippedMove.san);

      if (!moveResult) return prevGame;

      const history = newGame.history({ verbose: true });
      if (history.length > 0) {
        const lastMove = history[history.length - 1];
        setLastMove({ from: lastMove.from, to: lastMove.to });
      }

      setSelectedMoveId(requiredMove);
      return newGame;
    });

    if (skippedMove.next) {
      setRequiredMove(skippedMove.next);

      setTimeout(() => {
        makeApplicationMove(skippedMove.next);
      }, 500); // short 0.5-second delay before AI plays
    } else {
      setResult("completed");
      setTrainingStarted(false);
      setShowCard(true);
    }
  };

  const makeApplicationMove = (currentMoveId) => {
    setComputerMove(true);
    setMoveMistakes(0);

    setTimeout(() => {
      const moveOptions = [
        currentMoveId,
        ...(moves[currentMoveId]?.variations || []),
      ].filter(Boolean);

      if (moveOptions.length === 0) {
        return;
      }

      const randomMoveId =
        moveOptions[Math.floor(Math.random() * moveOptions.length)];

      if (!moves[randomMoveId]) {
        return;
      }

      setGame((prevGame) => {
        const newGame = new Chess();
        newGame.loadPgn(prevGame.pgn());

        const moveResult = newGame.move(moves[randomMoveId].san);

        if (!moveResult) {
          return prevGame;
        }

        const history = newGame.history({ verbose: true });
        if (history.length > 0) {
          const lastMove = history[history.length - 1];
          setLastMove({ from: lastMove.from, to: lastMove.to });
        }

        setSelectedMoveId(randomMoveId);

        return newGame;
      });

      if (moves[randomMoveId].next == null) {
        setResult("completed");
        setTrainingStarted(false);
        setShowCard(true);
        setShowAlert(true);
        return;
      }

      setComputerMove(false);
      setRequiredMove(moves[randomMoveId].next);
      trainingStartTimeRef.current = performance.now();
    }, 1000);
  };

  const getMoveSequence = useCallback(() => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san && san.trim() !== "") {
        movePath.unshift(san); // Insert at the beginning to maintain move order
      }
      currentMoveId = moves[currentMoveId].parent; // Move backwards to the parent
    }

    return movePath; // Return the sequence from root to selectedMoveId
  }, [selectedMoveId, moves]);

  const handleRepertoireChange = (event) => {
    const selectedId = event.target.value;

    setSelectedRepertoireId(selectedId);
    setResult(null);

    const repertoire = repertoires[selectedId];
    if (repertoire) {
      setBoardOrientation(repertoire.boardOrientation || "white");
    }
    setSelectedMoveId("root");
  };

  const [lastMove, setLastMove] = useState({ from: null, to: null });

  const getSquareStyles = () => {
    const styles = {}; // Initialize an empty object for square styles

    if (lastMove.from) {
      styles[lastMove.from] = { backgroundColor: "rgba(173, 216, 230, 0.5)" }; // Light blue for from-square
    }
    if (lastMove.to) {
      styles[lastMove.to] = { backgroundColor: "rgba(173, 216, 230, 0.5)" }; // Light green for to-square
    }

    return styles; // Return the styles object
  };

  const handleNext = useCallback(() => {
    if (result === "incorrect" || result === null) return;

    if (!selectedMoveId) return; // No move selected

    const currentMove = moves[selectedMoveId];
    if (!currentMove || !currentMove.next) return; // No next move available

    const nextMoveId = currentMove.next;
    const nextMove = moves[nextMoveId];

    if (!nextMove) return; // Defensive check

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.move(nextMove.san); // Play the next move
      return newGame;
    });

    setSelectedMoveId(nextMoveId); // Update selected move
  }, [selectedMoveId, result, moves, setGame, setSelectedMoveId]);

  const handleQuit = () => {
    setResult("quit");
    setShowCard(true);
    setShowAlert(false);
    setTrainingStarted(false);
  };

  const handleBack = useCallback(() => {
    if (result === "incorrect" || result === null) return;
    if (!selectedMoveId || selectedMoveId === "root") return; // Do nothing if at the root

    const currentMove = moves[selectedMoveId];
    if (!currentMove || !currentMove.parent) return; // No parent = already at the start

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.undo(); // Undo the last move
      return newGame;
    });

    setSelectedMoveId(currentMove.parent); // Move back to the parent move
  }, [selectedMoveId, result, moves, setGame, setSelectedMoveId]);

  useEffect(() => {
    if (showAlert && result !== "completed") {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Alert fades out after 3 seconds for other cases

      return () => clearTimeout(timer); // Cleanup in case component unmounts
    }
  }, [showAlert, result]); // Dependency includes result
  // useEffect to update the game when selectedMoveId changes
  useEffect(() => {
    if (!selectedMoveId || !moves[selectedMoveId]) return;

    setGame(() => {
      const newGame = new Chess();
      const moveSequence = getMoveSequence();

      moveSequence.forEach((san) => {
        newGame.move(san); // Apply each move to reconstruct the game state
      });

      // Capture the last move (from & to squares)
      const history = newGame.history({ verbose: true });
      if (history.length > 0) {
        const lastMove = history.slice(-1)[0]; // Get the last move
        setLastMove({ from: lastMove.from, to: lastMove.to });
      } else {
        setLastMove({ from: null, to: null }); // Reset when no moves exist
      }

      return newGame;
    });
  }, [selectedMoveId, moves, getMoveSequence]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (activePage !== "Trainer" || trainingStarted) return; // Only handle events when activePage is "Trainer"

      switch (event.key) {
        case "ArrowLeft":
          handleBack();
          break;
        case "ArrowRight":
          handleNext();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleBack, handleNext, activePage, trainingStarted]);

  useEffect(() => {
    setGame(new Chess());
    setLastMove({ from: null, to: null });
  }, []);

  return (
    <Box display="flex" flexDirection="row" sx={{ p: 2, gap: 2 }}>
      {showAlert && (
        <Fade in={showAlert} timeout={500} mountOnEnter unmountOnExit>
          <Alert
            variant="outlined"
            sx={{
              position: "fixed",
              bottom: 16,
              left: 16,
              zIndex: 1300,
              minWidth: "300px",
              maxWidth: "90%",
              boxShadow: 3,
              backgroundColor: colors.background[100],
            }}
            severity={
              result === "correct"
                ? "success"
                : result === "completed"
                ? "info"
                : "error"
            }
            onClose={() => setShowAlert(false)}
          >
            {result === "correct"
              ? "Correct Move!"
              : result === "incorrect"
              ? "Incorrect Move!"
              : "You have reached the end of the line!"}
          </Alert>
        </Fade>
      )}
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: isMobile ? "100%" : "35%",
          minWidth: isMobile ? "unset" : "400px",
        }}
      >
        <Card
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 0,
            mb: 2,
          }}
        >
          <CardHeader
            avatar={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <KeyboardOptionKeyIcon />
              </Box>
            }
            title={
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Repertoire Trainer
              </Typography>
            }
            action={
              <Button
                id="repertoireTrainer-home"
                onClick={() => setTrainingMode(null)}
                variant="none"
                size="small"
                sx={{ textTransform: "none" }}
              >
                Exit
              </Button>
            }
          />
        </Card>
        <Card
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
            mb: 2,
          }}
        >
          <Box ref={chessboardRef}>
            <Chessboard
              position={game.fen()}
              onPieceDrop={handleMove}
              areArrowsAllowed={true}
              boardOrientation={boardOrientation}
              customSquareStyles={getSquareStyles()}
              customLightSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .lightSquare,
                backgroundImage:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .lightSquarePattern,
              }}
              customDarkSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .darkSquare,
                backgroundImage:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .darkSquarePattern,
              }}
              customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
            />
          </Box>
          {!trainingStarted && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              textAlign="left"
              sx={{ pt: 2, width: "100%" }}
            >
              <Tooltip title="Reset the game">
                <span>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedMoveId("root");
                      setGame(new Chess());
                    }}
                    style={{ marginRight: 10 }}
                    disabled={selectedMoveId === "root"}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <ReplayIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to previous move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    disabled={selectedMoveId === "root"}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to next move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={moves[selectedMoveId]?.next === null}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Toggle board orientation">
                <span>
                  <Button
                    variant="contained"
                    onClick={() =>
                      setBoardOrientation((prev) =>
                        prev === "white" ? "black" : "white"
                      )
                    }
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <SwapVertRoundedIcon />
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}
        </Card>

        <Card
          id="trainer"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 1 }}>
            <Select
              id="repertoireTrainer-repertoire"
              sx={{ width: "100%" }}
              value={selectedRepertoireId}
              onChange={handleRepertoireChange}
            >
              {Object.entries(repertoires)
                .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
                .map(([repId, repData]) => (
                  <MenuItem key={repId} value={repId}>
                    {repData.title}
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
            <Select
              id="repertoireTrainer-color"
              value={boardOrientation}
              onChange={(e) => setBoardOrientation(e.target.value)}
            >
              <MenuItem value="white">White</MenuItem>
              <MenuItem value="black">Black</MenuItem>
            </Select>
            {!trainingStarted && (
              <Button
                id="repertoireTrainer-start"
                variant="contained"
                color="secondary"
                onClick={handleTrainClick}
                disabled={!selectedRepertoireId}
                startIcon={<PlayArrowIcon />}
                sx={{
                  backgroundColor: colors.green[100],
                  "&:hover": {
                    backgroundColor: colors.green[200],
                  },
                }}
              >
                Train
              </Button>
            )}

            {trainingStarted && !showCard && (
              <>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleQuit}
                  startIcon={<CancelOutlinedIcon />}
                  sx={{
                    backgroundColor: colors.red[500],
                    "&:hover": {
                      backgroundColor: colors.red[600],
                    },
                  }}
                >
                  Quit
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleSkipMove}
                  sx={{
                    backgroundColor: colors.orange[500],
                    "&:hover": {
                      backgroundColor: colors.orange[600],
                    },
                  }}
                  disabled={computerMove}
                >
                  Skip
                </Button>
              </>
            )}
          </Box>
        </Card>

        {isMobile && (
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <TrainingMovesCard
              moves={moves}
              showCard={showCard}
              repertoires={repertoires}
              selectedRepertoireId={selectedRepertoireId}
              selectedMoveId={selectedMoveId}
              setSelectedMoveId={setSelectedMoveId}
            />
          </Box>
        )}
      </Box>
      {!isMobile && (
        <Box
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "65%",
            gap: 2,
          }}
        >
          <TrainingMovesCard
            moves={moves}
            showCard={showCard}
            repertoires={repertoires}
            selectedRepertoireId={selectedRepertoireId}
            selectedMoveId={selectedMoveId}
            setSelectedMoveId={setSelectedMoveId}
          />
          {debugMode && (
            <DebugCard moves={moves} selectedMoveId={selectedMoveId} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default RepertoireTrainerCard;
