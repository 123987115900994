import { ColorModeContext, useMode } from "./styles/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Other/Homepage";
import Layout from "./components/Layout";
import NotFound from "./pages/Other/NotFound";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

import GuessTheOpening from "./pages/Games/GuessTheOpening";
import GuessTheEval from "./pages/Games/GuessTheEval";
import PlayTheOpening from "./pages/Games/PlayTheOpening";
import Profile from "./pages/Other/Profile";
import Wordsearch from "./pages/Games/Wordsearch";
import GuessTheElo from "./pages/Games/GuessTheElo";
import ChessSlide from "./pages/Games/ChessSlide";
import FischerRandomGenerator from "./pages/Tools/FischerRandomGenerator";
import OpeningExplorer from "./pages/Tools/OpeningExplorer";
import Hangman from "./pages/Games/Hangman";
import About from "./pages/Other/About";
import { usePageTracking } from "./config/ga";
import Glossary from "./pages/Library/Glossary";
import Quotes from "./pages/Library/Quotes";
import Anagrams from "./pages/Games/Anagrams";
import ImagePuzzle from "./pages/Games/ImagePuzzle";
import NotationTrainer from "./pages/Tools/NotationTrainer";

import { UserProvider, useUser } from "./context/UserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./config/firebase";
import { updateUserFromAuth } from "./features/Firestore";
import Login from "./pages/Other/Login";
import OpponentPrep from "./pages/Analytics/OpponentPrep";
//import MyProfile from "./pages/Analytics/MyProfile";
import UserScout from "./pages/Analytics/UserScout";
import Privacy from "./pages/Other/Privacy";
import WorldChampionships from "./pages/Library/WorldChampionships";
import ClassicGames from "./pages/Library/ClassicGames";
import BasicRulesOfPlay from "./pages/Library/BasicRulesOfPlay";
import ChessPrinciples from "./pages/Learn/ChessPrinciples";
import ChessOpenings from "./pages/Learn/ChessOpenings/course";
import CompetitiveRulesOfPlay from "./pages/Library/CompetitiveRulesOfPlay";
import Crossword from "./pages/Games/Crossword";
import GifGenerator from "./pages/Tools/GifGenerator";
import ChessResources from "./pages/Learn/ChessResources";
import MiniatureGames from "./pages/Library/Miniature";
import { ShareProvider } from "./context/ShareContext";
import OpeningCoursesHomepage from "./pages/Learn/ChessOpenings/openingCourseHomepage";
import HandAndBrain from "./pages/Learn/HandAndBrain";
import GuessTheMove from "./pages/Games/GuessTheMove";
import GuessWho from "./pages/Games/GuessWho";
import PGNViewer from "./pages/Tools/PGNViewer";
import { LanguageProvider } from "./context/LocaleContext";
//import ECFRatingsExplorer from "./pages/Tools/ECFRatingsExplorer";
import "./locale/i18n";
import WhereAreMyPieces from "./pages/Games/WhereAreMyPieces";
import Tablebase from "./pages/Tools/Tablebase";
import RotatingImagePuzzle from "./pages/Games/RotatingImagePuzzle";
import Cryptograms from "./pages/Games/Cryptograms";
import HiddenChess from "./pages/Learn/HiddenChess";
import DecryptChess from "./pages/Games/DecryptChess";
import WhackAPawn from "./pages/Arcade/WhackAPawn/WhackaPawn";
import JumpingKnight from "./pages/Arcade/JumpingKnight/JumpingKnight";
import CoordinateTrainer from "./pages/Learn/CoordinateTrainer";
import CheckmateTrainer from "./pages/Learn/CheckmateTrainer";
import ChessClock from "./pages/Tools/ChessClock";
import RookRacer from "./pages/Arcade/RookRacer";
import BlankLayout from "./components/BlankLayout";

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import RepertoireBuilder from "./pages/Analytics/RepertoireBuilder";
import OpeningAnalysis from "./pages/Analytics/OpeningAnalysis";
import PieceTrainer from "./pages/Learn/PieceTrainer";
import { AlertProvider } from "./context/AlertProvider";

function PrivateRoute({ children }) {
  const [user, loading] = useAuthState(auth); // Use `loading` to detect session restoration
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Ensure loading time is at least 2 seconds
    const timer = setTimeout(() => {
      if (!loading) {
        setIsLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer); // Clean up the timer
  }, [loading]);

  if (isLoading) {
    // Show a spinner while loading
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full screen spinner
        }}
      >
        <CircularProgress
          size={80} // Larger size
          sx={{
            color: "#fff", // White color for the spinner
          }}
        />
      </Box>
    );
  }

  if (!user) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If authenticated, render the children
  return children;
}

function AuthenticatedApp() {
  usePageTracking();
  const [user] = useAuthState(auth);
  const { setUserData, setUserRef } = useUser();

  useEffect(() => {
    updateUserFromAuth(user, setUserData, setUserRef);
  }, [user, setUserData, setUserRef]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/** Other */}
        <Route path="/" element={<Homepage />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        {/** Puzzles */}
        <Route path="/chessslide" element={<ChessSlide />} />
        <Route path="/anagrams" element={<Anagrams />} />
        <Route path="/guesstheelo/:gameId" element={<GuessTheElo />} />
        <Route path="/guesstheelo" element={<GuessTheElo />} />
        <Route path="/guesstheeval/:gameId" element={<GuessTheEval />} />
        <Route path="/guesstheeval" element={<GuessTheEval />} />
        <Route path="/guessthemove" element={<GuessTheMove />} />
        <Route
          path="/guesstheopening/:lengthId/:gameId"
          element={<GuessTheOpening />}
        />
        <Route path="/guesstheopening" element={<GuessTheOpening />} />
        <Route
          path="/playtheopening/:lengthId/:gameId"
          element={<PlayTheOpening />}
        />
        <Route path="/playtheopening" element={<PlayTheOpening />} />
        <Route path="/wordsearch" element={<Wordsearch />} />
        <Route path="/hangman" element={<Hangman />} />
        <Route path="/imagepuzzle" element={<ImagePuzzle />} />
        <Route path="/guesswho" element={<GuessWho />} />
        <Route path="/crossword" element={<Crossword />} />
        <Route path="/wherearemypieces" element={<WhereAreMyPieces />} />
        <Route path="/rotatingimagepuzzle" element={<RotatingImagePuzzle />} />
        <Route path="/cryptograms" element={<Cryptograms />} />
        <Route path="/decryptchess" element={<DecryptChess />} />

        {/** Learn 

        <Route path="/chessprinciples" element={<ChessPrinciples />} />
        <Route path="/chessopenings" element={<ChessOpenings />} />*/}
        {/** Library */}
        <Route path="/glossary" element={<Glossary />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/worldchampionships" element={<WorldChampionships />} />
        <Route path="/classicgames" element={<ClassicGames />} />

        <Route path="/miniaturegames" element={<MiniatureGames />} />
        <Route path="/basicrulesofplay" element={<BasicRulesOfPlay />} />
        <Route
          path="/competitiverulesofplay"
          element={<CompetitiveRulesOfPlay />}
        />
        {/** Arcade */}
        <Route path="/whackapawn" element={<WhackAPawn />} />
        <Route path="/jumpingknight" element={<JumpingKnight />} />

        <Route path="/rookracer" element={<RookRacer />} />

        {/** Analytics */}
        <Route path="/userscout" element={<UserScout />} />
        <Route path="/opponentprep" element={<OpponentPrep />} />

        {/** Tools */}
        <Route path="/fischerrandom" element={<FischerRandomGenerator />} />
        <Route path="/openingexplorer" element={<OpeningExplorer />} />
        <Route path="/notationtrainer" element={<NotationTrainer />} />
        <Route path="/gifgenerator" element={<GifGenerator />} />
        <Route path="/pgnviewer" element={<PGNViewer />} />
        <Route path="/tablebase" element={<Tablebase />} />
        <Route path="/chessclock" element={<ChessClock />} />
        {/*<Route path="/ecfratingsexplorer" element={<ECFRatingsExplorer />} />*/}

        {/** Learn */}
        <Route path="/chessprinciples" element={<ChessPrinciples />} />
        <Route path="/chessopenings" element={<OpeningCoursesHomepage />} />
        <Route path="/chessopenings/:id" element={<ChessOpenings />} />
        <Route path="/chessresources" element={<ChessResources />} />
        <Route path="/handandbrain" element={<HandAndBrain />} />
        <Route path="/hiddenchess" element={<HiddenChess />} />
        <Route path="/coordinatetrainer" element={<CoordinateTrainer />} />
        <Route path="/checkmatetrainer" element={<CheckmateTrainer />} />
        <Route path="/piecetrainer" element={<PieceTrainer />} />

        {/** Other*/}
        <Route path="*" element={<NotFound />} />
        {/** Login*/}
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<BlankLayout />}>
        <Route
          path="/repertoirebuilder"
          element={
            <PrivateRoute>
              <RepertoireBuilder />
            </PrivateRoute>
          }
        />
        <Route
          path="/openinganalysis"
          element={
            <PrivateRoute>
              <OpeningAnalysis />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
}

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    document.cookie = "name=value; SameSite=None; Secure";
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <AlertProvider>
        <LanguageProvider>
          <UserProvider>
            <ShareProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthenticatedApp />
              </ThemeProvider>
            </ShareProvider>
          </UserProvider>
        </LanguageProvider>
      </AlertProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
