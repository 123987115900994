import React, { useEffect } from "react";
import { useTheme } from "@mui/material";
import Joyride from "react-joyride";
import tourSteps from "./tour";
import { tokens } from "../../../../styles/theme";

const ApplicationTour = ({
  runTour,
  setRunTour,
  activePage,
  selectedUserGameId,
  trainingMode,
  tourKey,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const resolveTourSteps = () => {
    if (activePage === "Games" && selectedUserGameId) {
      return tourSteps["GameOpen"];
    }

    if (activePage === "Trainer") {
      if (trainingMode === "positionTrainer") {
        return tourSteps["PositionTrainer"];
      }
      if (trainingMode === "repertoireTrainer") {
        return tourSteps["RepertoireTrainer"];
      }
      // If the user hasn't selected a mode yet
      return tourSteps["Trainer"];
    }

    return tourSteps[activePage] || [];
  };

  // Reset tour when the page changes
  useEffect(() => {
    setRunTour((prev) => {
      if (prev) {
        return false;
      }
      return prev;
    });
  }, [activePage, setRunTour]);

  return (
    <Joyride
      key={tourKey}
      steps={resolveTourSteps().map((step) => ({
        ...step,
        disableBeacon: true,
        disableOverlay: false,
        spotlightClicks: false,
      }))}
      run={runTour}
      showSkipButton
      showProgress
      continuous={true} // ✅ Users can explore freely
      disableCloseOnEsc={false} // ✅ Allow ESC key to close
      disableOverlayClose={true} // ✅ Allow clicking outside tooltip to close
      locale={{
        last: "Close",
        next: "Next",
        skip: "Skip",
        back: "Back",
      }}
      callback={(data) => {
        // ✅ Detect if the user pressed "X", ESC, or clicked outside
        if (
          ["finished", "skipped", "close", "error:target_not_found"].includes(
            data.status
          ) ||
          data.action === "close"
        ) {
          setRunTour(false); // ✅ Forcefully stop the tour
        }
      }}
      styles={{
        options: {
          primaryColor: colors.red[500], // ✅ Button color
          textColor: "#414141",
          backgroundColor: "#FFFFFF",
          arrowColor: "#FFFFFF",
        },
        buttonNext: {
          backgroundColor: colors.green[100],
          color: "#FFFFFF",
        },
        buttonBack: {
          color: colors.black[500],
        },
        buttonSkip: {
          color: colors.black[500],
        },
      }}
    />
  );
};

export default ApplicationTour;
