import {
  Box,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  InputLabel,
  FormControl,
  ListItemText,
  MenuItem,
  Typography,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { ResponsiveBar } from "@nivo/bar";
import GameViewer from "../../../../components/GameViewer";
import { Chess } from "chess.js";

import { tokens } from "../../../../styles/theme";

import { rt } from "../../../../config/firebase";
import { ref, get, update } from "firebase/database";
import { useUser } from "../../../../context/UserContext";

function MastersCard({
  chessboardRef,
  moves,
  selectedMoveId,
  boardOrientation,
}) {
  const [mastersOn, setMastersOn] = useState(false);
  const [mastersData, setMastersData] = useState(null);
  const [lichessData, setLichessData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [expandedGameId, setExpandedGameId] = useState(null);
  const [gamePGN, setGamePGN] = useState(null);
  const [loadingPGN, setLoadingPGN] = useState(false);
  const previousMastersData = useRef(null);
  const previousLichessData = useRef(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { userData } = useUser();
  const userId = userData?.uid || "";

  const [selectedSpeeds, setSelectedSpeeds] = useState([
    "blitz",
    "rapid",
    "classical",
  ]);
  const [tempSpeeds, setTempSpeeds] = useState([]);
  const [speedMenuOpen, setSpeedMenuOpen] = useState(false);
  const SPEED_OPTIONS = [
    { label: "UltraBullet", value: "ultraBullet" },
    { label: "Bullet", value: "bullet" },
    { label: "Blitz", value: "blitz" },
    { label: "Rapid", value: "rapid" },
    { label: "Classical", value: "classical" },
    { label: "Correspondence", value: "correspondence" },
  ];

  const updateUserRatings = (userId, ratings) => {
    update(ref(rt, `users/${userId}/database`), {
      ratings,
    });
  };

  const updateUserTimeControl = (userId, timecontrol) => {
    update(ref(rt, `users/${userId}/database`), {
      timecontrol,
    });
  };

  const getMoveDepth = (moves, selectedMoveId) => {
    let depth = 0;
    let currentId = selectedMoveId;

    while (currentId && moves[currentId] && moves[currentId].parent) {
      depth += 1;
      currentId = moves[currentId].parent;
    }

    return depth; // subtracting 1 as per your requirement
  };

  // Handle applying/cancelling
  const applySpeedSelection = () => {
    setSelectedSpeeds(tempSpeeds);
    updateUserTimeControl(userId, tempSpeeds);
    setSpeedMenuOpen(false);
  };

  const cancelSpeedSelection = () => {
    setTempSpeeds(selectedSpeeds); // revert
    setSpeedMenuOpen(false);
  };

  // Toggle individual option in temp
  const handleTempSpeedChange = (value) => {
    setTempSpeeds((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  // Toggle "All" option
  const handleSelectAllSpeeds = () => {
    if (tempSpeeds.length === SPEED_OPTIONS.length) {
      setTempSpeeds([]);
    } else {
      setTempSpeeds(SPEED_OPTIONS.map((o) => o.value));
    }
  };

  const [selectedRatings, setSelectedRatings] = useState(["2200", "2500"]);
  const RATING_OPTIONS = [
    { label: "0–999", value: "0" },
    { label: "1000–1199", value: "1000" },
    { label: "1200–1399", value: "1200" },
    { label: "1400–1599", value: "1400" },
    { label: "1600–1799", value: "1600" },
    { label: "1800–1999", value: "1800" },
    { label: "2000–2199", value: "2000" },
    { label: "2200–2499", value: "2200" },
    { label: "2500+", value: "2500" },
  ];

  const [tempRatings, setTempRatings] = useState([]);
  const [ratingMenuOpen, setRatingMenuOpen] = useState(false);

  const applyRatingSelection = () => {
    setSelectedRatings(tempRatings);
    updateUserRatings(userId, tempRatings);
    setRatingMenuOpen(false);
  };

  const cancelRatingSelection = () => {
    setTempRatings(selectedRatings);
    setRatingMenuOpen(false);
  };

  const handleTempRatingChange = (value) => {
    setTempRatings((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const handleSelectAllRatings = () => {
    if (tempRatings.length === RATING_OPTIONS.length) {
      setTempRatings([]);
    } else {
      setTempRatings(RATING_OPTIONS.map((o) => o.value));
    }
  };

  const getFenFromMoves = useMemo(() => {
    // Step 1: Generate move sequence using getMoveSequence
    const getMoveSequence = (moves, selectedMoveId) => {
      let currentMoveId = selectedMoveId;
      let movePath = [];

      while (currentMoveId && moves[currentMoveId]) {
        const san = moves[currentMoveId].san;
        if (san.trim() !== "") {
          movePath.unshift(san); // Add move at the beginning to maintain order
        }
        currentMoveId = moves[currentMoveId].parent; // Move backwards to the parent
      }

      return movePath;
    };

    // Step 2: Get the list of moves
    const moveSequence = getMoveSequence(moves, selectedMoveId);

    // Step 3: Create a new Chess.js instance
    const game = new Chess();

    // Step 4: Play all moves in Chess.js
    moveSequence.forEach((moveSan) => {
      try {
        game.move(moveSan); // Apply each move
      } catch (error) {}
    });

    // Step 5: Return the resulting FEN position
    return game.fen();
  }, [moves, selectedMoveId]); // ✅ Now `getFenFromMoves` only recalculates when moves or selectedMoveId change

  // Function to fetch PGN from Lichess API
  const fetchPGN = async (gameId) => {
    setLoadingPGN(true);
    try {
      const response = await fetch(
        `https://explorer.lichess.ovh/masters/pgn/${gameId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch PGN");
      }
      const pgn = await response.text();
      setGamePGN(extractMovesFromPGN(pgn));
    } catch (error) {
      setGamePGN(null);
    } finally {
      setLoadingPGN(false);
    }
  };

  const extractMovesFromPGN = (pgn) => {
    // Step 1: Remove metadata lines (everything inside square brackets [])
    const withoutMetadata = pgn.replace(/\[.*?\]\s*/g, "").trim();

    // Step 2: Remove any extra new lines or leading/trailing spaces
    const cleanedPGN = withoutMetadata.replace(/\n+/g, " ").trim();

    return cleanedPGN;
  };

  const formatLargeNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(2).replace(/\.00$/, "") + "b";
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(2).replace(/\.00$/, "") + "m";
    } else {
      return num.toLocaleString(); // Adds commas for thousands
    }
  };

  // Handle row click to toggle game expansion
  const handleRowClick = (gameId) => {
    if (expandedGameId === gameId) {
      setExpandedGameId(null); // Collapse if clicking the same row
      setGamePGN(null);
    } else {
      setExpandedGameId(gameId);
      fetchPGN(gameId); // Fetch PGN when expanding a game
    }
  };

  const [tabValue, setTabValue] = useState(0);
  /**
   * Handles changes to the active tab in the UI.
   *
   * @param {Event} event - The event object triggered by the tab change action.
   * @param {number} newValue - The index of the newly selected tab.
   */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Update the state to reflect the new active tab
  };

  useEffect(() => {
    if (!userId) return;

    const loadPreferences = async () => {
      try {
        const snapshot = await get(ref(rt, `users/${userId}/database`));
        const data = snapshot.val();

        if (data) {
          if (data.ratings) {
            setSelectedRatings(data.ratings);
            setTempRatings(data.ratings);
          }

          if (data.timecontrol) {
            setSelectedSpeeds(data.timecontrol);
            setTempSpeeds(data.timecontrol);
          }
        }
      } catch (error) {
        console.error("Failed to load user database preferences:", error);
      }
    };

    loadPreferences();
  }, [userId]);

  useEffect(() => {
    const fen = getFenFromMoves;
    if (mastersOn && fen) {
      const fetchMastersData = async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await fetch(
            `https://explorer.lichess.ovh/masters?fen=${encodeURIComponent(
              fen
            )}&topGames=10`
          );
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
          const data = await response.json();

          // Store previous data before updating
          previousMastersData.current = data;
          setMastersData(data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchMastersData();
    }
  }, [selectedMoveId, getFenFromMoves, mastersOn]);

  useEffect(() => {
    const fen = getFenFromMoves;
    if (mastersOn && fen) {
      const fetchLichessData = async () => {
        setLoading(true);
        setError(null);

        try {
          const url = new URL("https://explorer.lichess.ovh/lichess");

          url.searchParams.set("fen", fen);
          url.searchParams.set("speeds", selectedSpeeds.join(","));
          url.searchParams.set("ratings", selectedRatings.join(","));
          url.searchParams.set("moves", "12");

          const response = await fetch(url.toString());
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          previousLichessData.current = data;
          setLichessData(data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchLichessData();
    }
  }, [
    selectedMoveId,
    getFenFromMoves,
    mastersOn,
    selectedRatings,
    selectedSpeeds,
  ]);

  // Calculate move percentages
  const masterTotalGames = useMemo(() => {
    if (!mastersData) return 0;
    return mastersData.white + mastersData.draws + mastersData.black;
  }, [mastersData]);

  const masterMovesData = useMemo(() => {
    if (!mastersData) return [];
    return mastersData.moves.map((move) => ({
      move: move.san,
      games: move.white + move.draws + move.black,
      percentage:
        ((move.white + move.draws + move.black) / masterTotalGames) * 100,
      white: (move.white / (move.white + move.draws + move.black)) * 100,
      draws: (move.draws / (move.white + move.draws + move.black)) * 100,
      black: (move.black / (move.white + move.draws + move.black)) * 100,
    }));
  }, [mastersData, masterTotalGames]);

  const totalMasterGames = useMemo(() => {
    return masterMovesData.reduce((sum, move) => sum + move.games, 0);
  }, [masterMovesData]);

  const totalLichessGames = useMemo(() => {
    return lichessData?.white + lichessData?.draws + lichessData?.black;
  }, [lichessData]);

  const totalMasterWhite = useMemo(() => {
    return masterMovesData.reduce(
      (sum, move) => sum + (move.white * move.games) / 100,
      0
    );
  }, [masterMovesData]);

  const totalMasterDraws = useMemo(() => {
    return masterMovesData.reduce(
      (sum, move) => sum + (move.draws * move.games) / 100,
      0
    );
  }, [masterMovesData]);

  const totalMasterBlack = useMemo(() => {
    return masterMovesData.reduce(
      (sum, move) => sum + (move.black * move.games) / 100,
      0
    );
  }, [masterMovesData]);

  return (
    <Card
      id="builder-masterscard"
      sx={{
        position: "relative", // Required for pseudo-element positioning
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none !important",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SearchSharpIcon />
          </Box>
        }
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Database
          </Typography>
        }
        sx={{ pt: 1, pb: 1 }}
        action={
          <Switch
            checked={mastersOn}
            onChange={(event) => setMastersOn(event.target.checked)}
            sx={{
              "& .MuiSwitch-switchBase": { color: "white" },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: colors.green[100],
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "grey",
              },
              "& .MuiSwitch-track": { backgroundColor: "grey" },
            }}
          />
        }
      />
      {mastersOn && (
        <>
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent sx={{ p: "0px 10px 0px 10px", width: "100%" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="custom styled tabs"
              sx={{
                height: "20px",
                "& .MuiTab-root": {
                  color: colors.black[900], // Text color for unselected tabs
                  transition: "color 0.3s, border-color 0.3s", // Smooth transition for hover/focus effects
                  minWidth: "50px", // Set tab width
                  textAlign: "center", // Center align text or icon
                  "&:hover": {
                    color: colors.black[700], // Slightly lighter on hover
                  },
                },
                "& .Mui-selected": {
                  color: colors.black[900], // ✅ Fix: Sets active tab text color
                  fontWeight: "bold", // ✅ Optional: Make selected text bold
                },
                "& .MuiTab-root.Mui-selected": {
                  color: colors.black[900], // ✅ Ensure selected tab text color overrides MUI default
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: colors.green[900], // ✅ Active tab underline color
                  height: "1.5px",
                },
                mb: 1,
                mt: 1,
              }}
            >
              <Tab
                icon={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50px",
                    }}
                  >
                    Master Moves
                  </Box>
                }
                aria-label="Comment"
                sx={{
                  fontSize: "10px", // Adjust font size for the icon label
                }}
              />

              <Tab
                icon={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50px",
                    }}
                  >
                    Lichess Moves
                  </Box>
                }
                aria-label="Comment"
                sx={{
                  fontSize: "10px", // Adjust font size for the icon label
                }}
              />
              <Tab
                icon={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50px",
                    }}
                  >
                    Master Games
                  </Box>
                }
                aria-label="Comment"
                sx={{
                  fontSize: "10px", // Adjust font size for the icon label
                }}
              />
            </Tabs>

            {/* Moves Tab Panels */}
            <Box role="tabpanel" hidden={tabValue !== 0}>
              {loading ? (
                <CircularProgress sx={{ margin: 2 }} />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : mastersData ? (
                <>
                  <TableContainer>
                    <Table
                      size="small"
                      sx={{ tableLayout: "fixed", width: "100%" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                              textAlign: "center",
                            }}
                          >
                            Move
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                              textAlign: "center",
                            }}
                          >
                            Games
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                              textAlign: "center",
                            }}
                          >
                            %
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            White / Draw / Black
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Normal Move Rows */}
                        {masterMovesData.map((move, index) => (
                          <TableRow
                            key={index}
                            onClick={() => {
                              chessboardRef.current?.addMoveToLines(move.move);
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: colors.background[200],
                              }, // Light grey on hover
                              transition: "background-color 0.2s ease-in-out", // Smooth transition
                            }}
                          >
                            <TableCell
                              sx={{
                                textAlign: "center",
                                fontSize: "0.9em",
                                borderBottom: `1px dashed ${colors.black[300]}`,
                              }}
                            >
                              {move.move}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                fontSize: "0.9em",
                                borderBottom: `1px dashed ${colors.black[300]}`,
                              }}
                            >
                              {formatLargeNumber(move.games)}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                fontSize: "0.9em",
                                borderBottom: `1px dashed ${colors.black[300]}`,
                              }}
                            >
                              {move.percentage.toFixed(1)}%
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "8px 16px",
                                width: "50%",
                                borderBottom: `1px dashed ${colors.black[300]}`,
                              }}
                            >
                              <Box sx={{ height: 30 }}>
                                <ResponsiveBar
                                  data={[
                                    {
                                      move: move.move,
                                      white: move.white,
                                      draws: move.draws,
                                      black: move.black,
                                      whiteLabel:
                                        move.white < 10
                                          ? ""
                                          : `${move.white.toFixed(0)}%`,
                                      drawsLabel:
                                        move.draws < 10
                                          ? ""
                                          : `${move.draws.toFixed(0)}%`,
                                      blackLabel:
                                        move.black < 10
                                          ? ""
                                          : `${move.black.toFixed(0)}%`,
                                    },
                                  ]}
                                  keys={["white", "draws", "black"]}
                                  indexBy="move"
                                  layout="horizontal"
                                  margin={{
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                  }}
                                  padding={0.2}
                                  colors={["#F2F2F2", "#9e9e9e", "#000000"]}
                                  borderColor={{
                                    from: "color",
                                    modifiers: [["darker", 1.6]],
                                  }}
                                  axisLeft={null}
                                  axisBottom={null}
                                  enableGridX={false}
                                  enableLabel={true}
                                  labelSkipWidth={1}
                                  labelSkipHeight={1}
                                  label={({ id, value, data }) =>
                                    id === "white"
                                      ? data.whiteLabel
                                      : id === "draws"
                                      ? data.drawsLabel
                                      : id === "black"
                                      ? data.blackLabel
                                      : ""
                                  }
                                  labelTextColor={(bar) =>
                                    bar.color === "#000000"
                                      ? "#ffffff"
                                      : "#000000"
                                  }
                                  tooltip={() => null}
                                  theme={{
                                    labels: { text: { fontSize: 9 } },
                                  }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}

                        {/* Total Row */}
                        <TableRow>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            All
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            {masterTotalGames}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            100.0%
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "8px 16px",
                              width: "50%",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            <Box sx={{ height: 30 }}>
                              <ResponsiveBar
                                data={[
                                  {
                                    move: "Total",
                                    white: totalMasterWhite,
                                    draws: totalMasterDraws,
                                    black: totalMasterBlack,
                                    whiteLabel:
                                      (totalMasterWhite / totalMasterGames) *
                                        100 <
                                      10
                                        ? ""
                                        : `${(
                                            (totalMasterWhite /
                                              totalMasterGames) *
                                            100
                                          ).toFixed(0)}%`,

                                    drawsLabel:
                                      (totalMasterDraws / totalMasterGames) *
                                        100 <
                                      10
                                        ? ""
                                        : `${(
                                            (totalMasterDraws /
                                              totalMasterGames) *
                                            100
                                          ).toFixed(0)}%`,

                                    blackLabel:
                                      (totalMasterBlack / totalMasterGames) *
                                        100 <
                                      10
                                        ? ""
                                        : `${(
                                            (totalMasterBlack /
                                              totalMasterGames) *
                                            100
                                          ).toFixed(0)}%`,
                                  },
                                ]}
                                keys={["white", "draws", "black"]}
                                indexBy="move"
                                layout="horizontal"
                                margin={{
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                }}
                                padding={0.2}
                                colors={["#F2F2F2", "#9e9e9e", "#000000"]}
                                borderColor={{
                                  from: "color",
                                  modifiers: [["darker", 1.6]],
                                }}
                                axisLeft={null}
                                axisBottom={null}
                                enableGridX={false}
                                enableLabel={true}
                                labelSkipWidth={1}
                                labelSkipHeight={1}
                                label={({ id, value, data }) =>
                                  id === "white"
                                    ? data.whiteLabel
                                    : id === "draws"
                                    ? data.drawsLabel
                                    : id === "black"
                                    ? data.blackLabel
                                    : ""
                                }
                                labelTextColor={(bar) =>
                                  bar.color === "#000000"
                                    ? "#ffffff"
                                    : "#000000"
                                }
                                tooltip={() => null}
                                theme={{
                                  labels: { text: { fontSize: 9 } },
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Typography>No data available.</Typography>
              )}
            </Box>
            {/* Lichess Moves Tab Panels */}
            <Box role="tabpanel" hidden={tabValue !== 1}>
              {loading ? (
                <CircularProgress sx={{ margin: 2 }} />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : lichessData ? (
                <>
                  <Box sx={{ display: "flex", gap: 1, mb: 2, mt: 2 }}>
                    {/* Speeds Multi-Select */}
                    <FormControl size="small" fullWidth>
                      <InputLabel id="speed-select-label">
                        Time Control
                      </InputLabel>
                      <Select
                        labelId="speed-select-label"
                        label={
                          selectedSpeeds.length === SPEED_OPTIONS.length
                            ? "Rating Range"
                            : selectedSpeeds.length === 0
                            ? ""
                            : "Rating Range"
                        }
                        multiple
                        open={speedMenuOpen}
                        onOpen={() => {
                          setTempSpeeds(selectedSpeeds);
                          setSpeedMenuOpen(true);
                        }}
                        onClose={() => setSpeedMenuOpen(false)}
                        value={selectedSpeeds}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === SPEED_OPTIONS.length) {
                            return "All Selected";
                          } else if (selected.length === 0) {
                            return "";
                          } else {
                            return `${selected.length} selected`;
                          }
                        }}
                        size="small"
                        sx={{ minWidth: 160 }}
                      >
                        {/* All Checkbox */}
                        <MenuItem value="all" onClick={handleSelectAllSpeeds}>
                          <Checkbox
                            checked={tempSpeeds.length === SPEED_OPTIONS.length}
                            indeterminate={
                              tempSpeeds.length > 0 &&
                              tempSpeeds.length < SPEED_OPTIONS.length
                            }
                            sx={{
                              color: colors.background[200],
                              "&.Mui-checked": { color: colors.green[100] },
                            }}
                          />
                          <ListItemText primary="All" />
                        </MenuItem>

                        {/* Individual Speeds */}
                        {SPEED_OPTIONS.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={() => handleTempSpeedChange(option.value)}
                          >
                            <Checkbox
                              checked={tempSpeeds.includes(option.value)}
                              sx={{
                                color: colors.background[200],
                                "&.Mui-checked": { color: colors.green[100] },
                              }}
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}

                        <Divider />

                        {/* Apply/Cancel Buttons */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                        >
                          <Typography
                            variant="body2"
                            sx={{ cursor: "pointer", color: "gray" }}
                            onClick={cancelSpeedSelection}
                          >
                            Cancel
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ cursor: "pointer", color: colors.green[100] }}
                            onClick={applySpeedSelection}
                          >
                            Apply
                          </Typography>
                        </Box>
                      </Select>
                    </FormControl>

                    {/* Ratings Multi-Select */}
                    <FormControl size="small" fullWidth>
                      <InputLabel id="rating-select-label">
                        Rating Range
                      </InputLabel>

                      <Select
                        labelId="rating-select-label"
                        label={
                          selectedRatings.length === RATING_OPTIONS.length
                            ? "Rating Range"
                            : selectedRatings.length === 0
                            ? ""
                            : "Rating Range"
                        }
                        multiple
                        open={ratingMenuOpen}
                        onOpen={() => {
                          setTempRatings(selectedRatings);
                          setRatingMenuOpen(true);
                        }}
                        onClose={() => setRatingMenuOpen(false)}
                        value={selectedRatings}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === RATING_OPTIONS.length) {
                            return "All Selected";
                          } else if (selected.length === 0) {
                            return "";
                          } else {
                            return `${selected.length} selected`;
                          }
                        }}
                        size="small"
                        sx={{ minWidth: 160 }}
                      >
                        {/* All Option */}
                        <MenuItem value="all" onClick={handleSelectAllRatings}>
                          <Checkbox
                            checked={
                              tempRatings.length === RATING_OPTIONS.length
                            }
                            indeterminate={
                              tempRatings.length > 0 &&
                              tempRatings.length < RATING_OPTIONS.length
                            }
                            sx={{
                              color: colors.background[200],
                              "&.Mui-checked": { color: colors.green[100] },
                            }}
                          />
                          <ListItemText primary="All" />
                        </MenuItem>

                        {/* Individual Ratings */}
                        {RATING_OPTIONS.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={() => handleTempRatingChange(option.value)}
                          >
                            <Checkbox
                              checked={tempRatings.includes(option.value)}
                              sx={{
                                color: colors.background[200],
                                "&.Mui-checked": { color: colors.green[100] },
                              }}
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}

                        <Divider />

                        {/* Apply/Cancel */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                        >
                          <Typography
                            variant="body2"
                            sx={{ cursor: "pointer", color: "gray" }}
                            onClick={cancelRatingSelection}
                          >
                            Cancel
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              cursor: "pointer",
                              color: colors.green[100],
                            }}
                            onClick={applyRatingSelection}
                          >
                            Apply
                          </Typography>
                        </Box>
                      </Select>
                    </FormControl>
                  </Box>

                  <TableContainer>
                    <Table
                      size="small"
                      sx={{ tableLayout: "fixed", width: "100%" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                              textAlign: "center",
                            }}
                          >
                            Move
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                              textAlign: "center",
                            }}
                          >
                            Games
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                              textAlign: "center",
                            }}
                          >
                            %
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            White / Draw / Black
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lichessData?.moves.map((move, index) => {
                          const games = move.white + move.draws + move.black;
                          const white = (move.white / games) * 100;
                          const draws = (move.draws / games) * 100;
                          const black = (move.black / games) * 100;

                          return (
                            <TableRow
                              key={index}
                              onClick={() => {
                                chessboardRef.current?.addMoveToLines(move.san);
                              }}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: colors.background[200],
                                },
                                transition: "background-color 0.2s ease-in-out",
                              }}
                            >
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  fontSize: "0.9em",
                                  borderBottom: `1px dashed ${colors.black[300]}`,
                                }}
                              >
                                {move.san}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  fontSize: "0.9em",
                                  borderBottom: `1px dashed ${colors.black[300]}`,
                                }}
                              >
                                {formatLargeNumber(games)}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  fontSize: "0.9em",
                                  borderBottom: `1px dashed ${colors.black[300]}`,
                                }}
                              >
                                {((games / totalLichessGames) * 100).toFixed(1)}
                                %
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "8px 16px",
                                  width: "50%",
                                  borderBottom: `1px dashed ${colors.black[300]}`,
                                }}
                              >
                                <Box sx={{ height: 30 }}>
                                  <ResponsiveBar
                                    data={[
                                      {
                                        move: move.san,
                                        white,
                                        draws,
                                        black,
                                        whiteLabel:
                                          white < 10
                                            ? ""
                                            : `${white.toFixed(0)}%`,
                                        drawsLabel:
                                          draws < 10
                                            ? ""
                                            : `${draws.toFixed(0)}%`,
                                        blackLabel:
                                          black < 10
                                            ? ""
                                            : `${black.toFixed(0)}%`,
                                      },
                                    ]}
                                    keys={["white", "draws", "black"]}
                                    indexBy="move"
                                    layout="horizontal"
                                    margin={{
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                    }}
                                    padding={0.2}
                                    colors={["#F2F2F2", "#9e9e9e", "#000000"]}
                                    borderColor={{
                                      from: "color",
                                      modifiers: [["darker", 1.6]],
                                    }}
                                    axisLeft={null}
                                    axisBottom={null}
                                    enableGridX={false}
                                    enableLabel={true}
                                    labelSkipWidth={1}
                                    labelSkipHeight={1}
                                    label={({ id, value, data }) =>
                                      id === "white"
                                        ? data.whiteLabel
                                        : id === "draws"
                                        ? data.drawsLabel
                                        : id === "black"
                                        ? data.blackLabel
                                        : ""
                                    }
                                    labelTextColor={(bar) =>
                                      bar.color === "#000000"
                                        ? "#ffffff"
                                        : "#000000"
                                    }
                                    tooltip={() => null}
                                    theme={{
                                      labels: { text: { fontSize: 9 } },
                                    }}
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {/* Total Row */}
                        <TableRow>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            All
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            {formatLargeNumber(totalLichessGames)}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            100.0%
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "8px 16px",
                              width: "50%",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            <Box sx={{ height: 30 }}>
                              <ResponsiveBar
                                data={[
                                  {
                                    move: "Total",
                                    white: lichessData?.white,
                                    draws: lichessData?.draws,
                                    black: lichessData?.black,
                                    whiteLabel:
                                      (lichessData?.white / totalLichessGames) *
                                        100 <
                                      10
                                        ? ""
                                        : `${(
                                            (lichessData?.white /
                                              totalLichessGames) *
                                            100
                                          ).toFixed(0)}%`,

                                    drawsLabel:
                                      (lichessData?.draws / totalLichessGames) *
                                        100 <
                                      10
                                        ? ""
                                        : `${(
                                            (lichessData?.draws /
                                              totalLichessGames) *
                                            100
                                          ).toFixed(0)}%`,

                                    blackLabel:
                                      (lichessData?.black / totalLichessGames) *
                                        100 <
                                      10
                                        ? ""
                                        : `${(
                                            (lichessData?.black /
                                              totalLichessGames) *
                                            100
                                          ).toFixed(0)}%`,
                                  },
                                ]}
                                keys={["white", "draws", "black"]}
                                indexBy="move"
                                layout="horizontal"
                                margin={{
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                }}
                                padding={0.2}
                                colors={["#F2F2F2", "#9e9e9e", "#000000"]}
                                borderColor={{
                                  from: "color",
                                  modifiers: [["darker", 1.6]],
                                }}
                                axisLeft={null}
                                axisBottom={null}
                                enableGridX={false}
                                enableLabel={true}
                                labelSkipWidth={1}
                                labelSkipHeight={1}
                                label={({ id, value, data }) =>
                                  id === "white"
                                    ? data.whiteLabel
                                    : id === "draws"
                                    ? data.drawsLabel
                                    : id === "black"
                                    ? data.blackLabel
                                    : ""
                                }
                                labelTextColor={(bar) =>
                                  bar.color === "#000000"
                                    ? "#ffffff"
                                    : "#000000"
                                }
                                tooltip={() => null}
                                theme={{
                                  labels: { text: { fontSize: 9 } },
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Typography>No data available.</Typography>
              )}
            </Box>
            {/* Games Tab Panel */}
            <Box role="tabpanel" hidden={tabValue !== 2}>
              {loading ? (
                <CircularProgress sx={{ margin: 2 }} />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : mastersData ? (
                <>
                  <TableContainer>
                    <Table size="small" sx={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              width: "100%",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            Players
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            Result
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "70px",
                              fontSize: "0.75em",
                              borderBottom: `1px dashed ${colors.black[300]}`,
                            }}
                          >
                            Year
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mastersData.topGames.map((game, index) => (
                          <React.Fragment key={index}>
                            <TableRow
                              onClick={() => handleRowClick(game.id)}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: colors.background[200],
                                  //backgroundColor: "",
                                },
                                transition: "background-color 0.2s ease-in-out", // Smooth transition
                              }}
                            >
                              <TableCell
                                sx={{
                                  whiteSpace: "pre-line",
                                  fontSize: "0.75em",
                                  borderBottom: `1px dashed ${colors.black[300]}`,
                                }}
                              >
                                {game.white.name} ({game.white.rating}){"\n"}
                                {game.black.name} ({game.black.rating})
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: `1px dashed ${colors.black[300]}`,
                                }}
                              >
                                {game.winner === "white"
                                  ? "1-0"
                                  : game.winner === "black"
                                  ? "0-1"
                                  : "½-½"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: `1px dashed ${colors.black[300]}`,
                                }}
                              >
                                {game.year}
                              </TableCell>
                            </TableRow>

                            {/* Collapsible Game Viewer */}
                            {gamePGN && expandedGameId === game.id && (
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  sx={{ pl: 1, pr: 0, pt: 1, pb: 1 }}
                                >
                                  <Collapse
                                    in={expandedGameId === game.id}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {loadingPGN ? (
                                      <CircularProgress sx={{ margin: 2 }} />
                                    ) : (
                                      gamePGN && (
                                        <GameViewer
                                          WhitePlayer={game.white.name}
                                          WhiteElo={game.white.rating}
                                          BlackPlayer={game.black.name}
                                          BlackElo={game.black.rating}
                                          Moves={gamePGN}
                                          BoardOnly={true}
                                          BoardOrientation={boardOrientation}
                                          StartingPosition={getMoveDepth(
                                            moves,
                                            selectedMoveId
                                          )}
                                        />
                                      )
                                    )}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                ""
              )}
            </Box>
          </CardContent>
        </>
      )}
    </Card>
  );
}

export default MastersCard;
