import React, { createContext, useContext, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

// ✅ Create Context
const AlertContext = createContext();

// ✅ Global Alert Provider
export const AlertProvider = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info"); // Default severity
  const [alertOpen, setAlertOpen] = useState(false);

  // Function to show an alert
  const showAlert = (message, severity = "info") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);

    // Auto-hide after 3 seconds
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}

      {/* ✅ Global Snackbar Alert */}
      <Snackbar
        open={alertOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setAlertOpen(false)}
        autoHideDuration={6000}
      >
        <Alert severity={alertSeverity} onClose={() => setAlertOpen(false)}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

// ✅ Custom hook for using the alert
export const useAlert = () => {
  return useContext(AlertContext);
};
