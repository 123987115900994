import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import {
  Box,
  Typography,
  Button,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PlayTheOpeningHelp from "../../../help/PlayTheOpeningHelp";
import { Helmet } from "react-helmet";
import { openings } from "../../../data/eco/eco";
import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";

import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

function PlayTheOpening() {
  const [game, setGame] = useState(new Chess());
  const [moves, setMoves] = useState([]); // The Moves that the user needs to play
  const [playerMoves, setPlayerMoves] = useState([]);
  const [moveColors, setMoveColors] = useState([]);
  const [moveCounter, setMoveCounter] = useState(0);
  const [attempts, setAttempts] = useState([]); // Saves the previous attempts
  const maxAttempts = 6;
  const [totalAttempts, setTotalAttempts] = useState(0); // A variable containing the total events. Probably can be replaced by counting the attempts array
  const [selectedNumberOfMoves, setSelectedNumberOfMoves] = useState(8);
  const [status, setStatus] = useState("");
  const [gameOver, setGameOver] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedLine, setSelectedLine] = useState(null); // The Object that holds the selected line and details.
  const [correctGuesses, setCorrectGuesses] = useState([]);
  const { lengthId, gameId } = useParams();
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Games");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/playtheopening",
      title: "Chessboard Magic - Play The Opening",
      description:
        "Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const handleResetBoard = () => {
    const newGame = new Chess();
    setGame(newGame);
    setPlayerMoves([]);
    setMoveColors([]);
    setMoveCounter(0);
    setStatus("");
  };

  const handleFillCorrect = () => {
    const newGame = new Chess();
    let correctMoves = 0;
    for (let i = 0; i < correctGuesses.length; i++) {
      if (correctGuesses[i] === "") {
        break;
      }
      newGame.move(correctGuesses[i]);
      correctMoves = correctMoves + 1;
    }

    setGame(newGame);
    setPlayerMoves(correctGuesses);
    setMoveCounter(correctMoves);
  };

  const handleNumberOfMovesChange = (event) => {
    setSelectedNumberOfMoves(event.target.value);
  };

  const handleNewGame = useCallback(
    (lengthId, gameId) => {
      handleResetBoard();

      let eco;
      let randomIndex = 8;

      const numericGameId = Number(gameId);
      const numericLengthId = Number(lengthId);
      if (
        !isNaN(numericGameId) &&
        numericGameId >= 0 &&
        !isNaN(numericLengthId) &&
        numericLengthId >= 6 &&
        numericLengthId <= 12
      ) {
        randomIndex = Math.floor(Math.random() * openings.length);
        eco = openings[randomIndex];
      } else {
        const filteredOpenings = openings.filter(
          (opening) => opening.length === selectedNumberOfMoves
        );
        randomIndex = Math.floor(Math.random() * filteredOpenings.length);
        eco = filteredOpenings[randomIndex];
      }
      setSelectedLine(eco);
      setMoves(extractMovesFromString(eco.pgn));

      setCorrectGuesses([]);
      setPlayerMoves([]);
      setMoveColors([]);
      setMoveCounter(0);
      setAttempts([]);
      setGameOver(false);
      setStatus("");
      setTotalAttempts(0);

      //GA Tracking
      trackEvent("Games", "PlayTheOpening-Play", "Play The Opening");
      // Internal Tracking
      if (userData) {
        if (!userData.Puzzles) {
          userData.Puzzles = {};
        }
        if (userData.Puzzles.PlayTheOpening) {
          userData.Puzzles.PlayTheOpening.Played =
            (userData.Puzzles.PlayTheOpening.Played || 0) + 1;
        } else {
          userData.Puzzles.PlayTheOpening = {
            Played: 1,
            Completed: 0,
          };
        }
        updateUserData(userRef, userData);
      }
    },
    [selectedNumberOfMoves, userData, userRef]
  );

  useEffect(() => {
    if (lengthId) {
      setSelectedNumberOfMoves(lengthId);
    }
    if (gameId) {
      handleNewGame(lengthId, gameId);
    }
  }, [lengthId, gameId, handleNewGame]);

  function extractMovesFromString(inputString) {
    // Use a regular expression to match moves (words)
    const splitArray = inputString.split(" ");

    // Filter out items that contain a period (".")
    const filteredArray = splitArray.filter((item) => !item.includes("."));

    return filteredArray;
  }

  function onDrop(sourceSquare, targetSquare) {
    // Check if the player has already played the same number of moves as in the `moves` array
    if (moveCounter === moves.length) {
      return false; // Prevent the move from being accepted
    }

    try {
      // Attempt to move
      const move = game.move({
        from: sourceSquare,
        to: targetSquare,
      });

      // Check if the move is valid
      if (move === null) {
        return false; // Prevent the move from being accepted
      }

      // Update player moves based on the moveCounter
      const newPlayerMoves = [...playerMoves];
      newPlayerMoves[moveCounter] = move.san;
      setPlayerMoves(newPlayerMoves);

      // Increment the move counter
      setMoveCounter(moveCounter + 1);

      return true; // Accept the move
    } catch (error) {
      return false; // Prevent the move from being accepted
    }
  }

  const handleCompareMoves = () => {
    // Update the totalAttempts state

    setTotalAttempts(totalAttempts + 1);

    const newMoveColors = playerMoves.map((move, index) => {
      if (move.toLowerCase() === moves[index].toLowerCase()) {
        return colors.green[500]; // Correct move in the correct position
      } else if (
        moves.map((m) => m.toLowerCase()).includes(move.toLowerCase())
      ) {
        return "#E0C879"; // Correct move in the wrong position
      } else {
        return ""; // Incorrect move
      }
    });

    // Set the audo fill move array.
    const newCorrectGuesses = playerMoves.map((move, index) => {
      if (move.toLowerCase() === moves[index].toLowerCase()) {
        return move;
      } else {
        return "";
      }
    });

    if (newCorrectGuesses[0] === "") {
      // Fill the whole array with blanks
      newCorrectGuesses.fill("");
    } else {
      // Find the index of the first blank element
      let firstBlankIndex = newCorrectGuesses.indexOf("");

      // If there's a blank element in the array
      if (firstBlankIndex !== -1) {
        // Fill all elements after the first blank with ""
        for (let i = firstBlankIndex; i < newCorrectGuesses.length; i++) {
          newCorrectGuesses[i] = "";
        }
      }
    }

    setCorrectGuesses(newCorrectGuesses);
    setMoveColors(newMoveColors);

    // Calculate the number of correct moves
    const correctCount = newMoveColors.filter(
      (color) => color === colors.green[500]
    ).length;

    // Update the status based on the number of correct moves and attempts
    if (correctCount === moves.length) {
      setStatus("COMPLETED");

      // GA Tracking
      trackEvent("Games", "PlayTheOpening-Success", "Play The Opening");
      // Internal Tracking
      if (userData) {
        userData.Puzzles.PlayTheOpening.Completed =
          (userData.Puzzles.PlayTheOpening.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }
      setGameOver(true); // Set Game Over to true
    } else if (totalAttempts === maxAttempts - 1) {
      setStatus("FAILED");

      // GA Tracking
      trackEvent("Games", "PlayTheOpening-Failed", "Play The Opening");
      // Internal Tracking
      if (userData) {
        userData.Puzzles.PlayTheOpening.Failed =
          (userData.Puzzles.PlayTheOpening.Failed || 0) + 1;
        updateUserData(userRef, userData);
      }
      setGameOver(true); // Set Game Over to true
    } else {
      setStatus("");
      const attempt = {
        moves: [...playerMoves],
        colors: [...newMoveColors],
      };
      setAttempts([...attempts, attempt]);
      handleResetBoard();
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  return (
    <Box>
      <ContentHeader
        title={t("PlayTheOpening.header.title")}
        subtitle={t("PlayTheOpening.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Play The Opening</title>
        <meta
          name="description"
          content="Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings."
        />
        <meta property="og:title" content="Play The Opening" />
        <meta
          property="og:description"
          content="Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/playtheopening.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/playtheopening`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Play The Opening" />
        <meta
          name="twitter:description"
          content="Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/playtheopening.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("PlayTheOpening.buttons.play")}
        </Button>
        <FormControl sx={{ width: "100px" }}>
          <InputLabel
            id="number-of-moves-label"
            style={{
              backgroundColor: colors.black[100],
              padding: "0 4px",
              color: colors.black[900],
            }}
          >
            {t("PlayTheOpening.game.number_of_moves")}
          </InputLabel>
          <Select
            id="number-of-moves"
            labelId="number-of-moves-label"
            value={selectedNumberOfMoves}
            onChange={handleNumberOfMovesChange}
            style={{
              marginRight: 10,
              border: "1px solid #DDDDDD",
            }}
          >
            {Array.from({ length: 5 }, (_, i) => i + 6).map((num) => (
              <MenuItem key={num} value={num}>
                {num}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("PlayTheOpening.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("PlayTheOpening.helpDetails.title")}
          content={<PlayTheOpeningHelp />}
        ></HelpModal>
      </Box>
      <Box
        sx={{
          p: "20px 0px 0px 0px",
          display: "flex",
          flexDirection: {
            xs: "column", // All screens smaller than 'sm'
            sm: "column", // All screens smaller than 'md'
            md: "row", // Medium screens and larger
          },
          alignItems: "flex-start", // Align items at the start of the flex container
          width: "100%", // Use the full width of the container
        }}
      >
        <div
          id="chessboard"
          style={{
            width: "100%", // Full width in column layout
            maxWidth: "500px", // Maximum width to constrain the chessboard
            padding: "0px 10px 10px 0px", // Uniform padding
            boxSizing: "border-box", // Include padding and border in the element's total width and height
          }}
        >
          <Chessboard
            position={game.fen()}
            onPieceDrop={onDrop}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
            areArrowsAllowed={false}
          />
        </div>

        <div
          id="results"
          style={{
            flex: 1,
            overflow: "auto",
            padding: "0px 0px 10px 0px", // Uniform padding
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            {selectedLine && (
              <>
                <Typography variant="13px">
                  <b>{selectedLine.name}</b>
                </Typography>
                <Typography>
                  <b>{totalAttempts}</b> {t("PlayTheOpening.game.guesses")}
                </Typography>
              </>
            )}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {attempts.map((attempt, attemptIndex) => (
                <div
                  key={`attempt-${attemptIndex}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start", // Aligns children (moves) to the start of the flex container
                    marginBottom: "0px", // Adds bottom margin to separate each attempt row
                    width: "100%", // Ensures the div takes the full width
                  }}
                >
                  {attempt.moves.map((move, moveIndex) => (
                    <React.Fragment
                      key={`attempt-${attemptIndex}-move-${moveIndex}`}
                    >
                      <div
                        style={{
                          backgroundColor: attempt.colors[moveIndex],
                          width: "40px",
                          height: "40px",
                          margin: "2px 4px 2px 0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #999999",
                          borderRadius: "4px",
                        }}
                      >
                        {move}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {moves.map((move, index) => (
              <React.Fragment key={`move-${index}`}>
                {/*index % 2 === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#999999",
                      paddingLeft: "10px",
                    }}
                  >
                    {Math.floor(index / 2) + 1}.
                  </div>
                ) : (
                  " "
                )*/}
                <div
                  style={{
                    backgroundColor: moveColors[index],
                    width: "40px",
                    height: "40px",
                    margin: "2px 4px 2px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #999999",
                    borderRadius: "4px",
                  }}
                >
                  {playerMoves[index]}
                </div>
              </React.Fragment>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleCompareMoves}
              disabled={
                moveCounter !== moves.length ||
                attempts.length >= maxAttempts ||
                gameOver
              }
              style={{ marginRight: 10 }}
            >
              {t("PlayTheOpening.buttons.guess")}
            </Button>
            <Button
              variant="contained"
              onClick={handleResetBoard}
              disabled={moveCounter === 0 || gameOver}
              style={{ marginRight: 10 }}
            >
              {t("PlayTheOpening.buttons.clear")}
            </Button>
            <Button
              variant="contained"
              onClick={handleFillCorrect}
              disabled={correctGuesses.length === 0 || gameOver}
              style={{ marginRight: 10 }}
            >
              {t("PlayTheOpening.buttons.auto_complete")}
            </Button>
          </div>
          {status && (
            <div style={{ padding: "20px 0 0 0" }}>
              <Typography component="span" style={{ display: "inline" }}>
                {status === "COMPLETED" ? (
                  <CheckCircleOutlineIcon
                    style={{
                      color: "green",
                      marginRight: "10px",
                      marginBottom: "-10px",
                      verticalAlign: "middleftle",
                      fontSize: "32px",
                    }}
                  />
                ) : status === "FAILED" ? (
                  <ErrorOutlineIcon
                    style={{
                      color: "red",
                      marginRight: "10px",
                      marginBottom: "-10px",
                      verticalAlign: "left",
                      fontSize: "32px",
                    }}
                  />
                ) : null}
                {selectedLine.pgn}
              </Typography>
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
}

export default PlayTheOpening;
