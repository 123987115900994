import React from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";

const DefaultLogin = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
        <img
          src={`${process.env.PUBLIC_URL}/img/logo-transparent${
            theme.palette.mode === "dark" ? "" : "-black"
          }.png`}
          alt="Chessboard Magic"
          style={{ maxWidth: "50px" }}
        />
        <Typography variant="h5" component="div">
          <strong>Chessboard Magic</strong>!
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="body1" sx={{ mb: 1 }} gutterBottom>
        Step into the world of Chessboard Magic — a growing collection of tools,
        games, and learning resources designed to support your chess journey.
        Whether you're here to play, improve, or explore, everything you need is
        just a few clicks away.
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }} gutterBottom>
        By logging in, you unlock:
      </Typography>
      <ul style={{ paddingLeft: "1.2rem", marginTop: 0 }}>
        <li style={{ marginBottom: "1rem" }}>
          <Typography variant="body1">
            <strong>Choose from a wide range of board themes</strong> to
            personalize your experience
          </Typography>
        </li>
        <li style={{ marginBottom: "1rem" }}>
          <Typography variant="body1">
            <strong>Select your preferred chess pieces</strong> and see them
            reflected across the entire platform
          </Typography>
        </li>
        <li style={{ marginBottom: "1rem" }}>
          <Typography variant="body1">
            <strong>Access powerful analytics tools</strong> to review your
            games and understand your strengths and weaknesses
          </Typography>
        </li>
        <li style={{ marginBottom: "1rem" }}>
          <Typography variant="body1">
            <strong>Train smarter with guided tools</strong> — access analytics
            and insights designed to help you improve your game and support you
            throughout your chess journey.
          </Typography>
        </li>
      </ul>

      <Typography variant="body1">
        Your personalized chess journey starts here. Let's make every move
        count.
      </Typography>
    </Box>
  );
};

export default DefaultLogin;
