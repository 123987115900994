import React, { useState, useCallback, useMemo } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  Typography,
  Switch,
  useTheme,
} from "@mui/material";

import { tokens } from "../../../../styles/theme";

import SubjectIcon from "@mui/icons-material/Subject";

const RepertoireCard = ({
  repertoires,
  userGameMoves,
  selectedUserGameMoveId,
}) => {
  const [repertoireOn, setRepertoireOn] = useState(true);
  const [depth, setDepth] = useState(5);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // ✅ 1️⃣ Find the move sequence leading to `selectedUserGameMoveId`
  const getMoveSequence = useCallback(
    (moveId) => {
      let sequence = [];
      let currentMoveId = moveId;

      while (currentMoveId && userGameMoves[currentMoveId]?.san) {
        sequence.unshift(userGameMoves[currentMoveId].san); // Add move at the beginning (so it's in order)
        currentMoveId = userGameMoves[currentMoveId].parent; // Move up the tree
      }

      return sequence;
    },
    [userGameMoves] // ✅ Only recalculates if `userGameMoves` changes
  );

  // ✅ 2️⃣ Find matching repertoires and get next moves
  const matchingRepertoires = useMemo(() => {
    const sequence = getMoveSequence(selectedUserGameMoveId);
    if (sequence.length === 0) {
      return [];
    }

    let matches = [];

    Object.entries(repertoires).forEach(([repertoireId, repertoire]) => {
      let repMoveId = repertoire.moves["root"]?.next; // ✅ Start from root.next
      let matchedMoveId = null;
      let moveIndex = 0;

      // ✅ Traverse the repertoire move tree and check if it matches the sequence
      while (repMoveId && repertoire.moves[repMoveId]) {
        const repMove = repertoire.moves[repMoveId];

        if (repMove.san === sequence[moveIndex]) {
          moveIndex++;

          if (moveIndex === sequence.length) {
            matchedMoveId = repMoveId;
            break; // ✅ Stop once full sequence is matched
          }
        } else {
          break; // ❌ Stop if any move in the sequence does not match
        }

        repMoveId = repMove.next;
      }

      if (matchedMoveId) {
        // ✅ Get next 5 moves after the match
        let nextMoves = [];
        let nextMoveId = repertoire.moves[matchedMoveId]?.next;
        let count = 0;

        while (nextMoveId && repertoire.moves[nextMoveId] && count < depth) {
          nextMoves.push(repertoire.moves[nextMoveId].san);
          nextMoveId = repertoire.moves[nextMoveId].next;
          count++;
        }

        // ✅ Get variations for the **next move** after match
        const variations = {};
        const matchedVariations = repertoire.moves[matchedMoveId]?.next
          ? repertoire.moves[repertoire.moves[matchedMoveId]?.next]
              ?.variations || []
          : [];

        matchedVariations.forEach((variationId) => {
          let variationMoves = [];
          let varMoveId = variationId;
          let varCount = 0;

          while (varMoveId && repertoire.moves[varMoveId] && varCount < depth) {
            variationMoves.push(repertoire.moves[varMoveId].san);
            varMoveId = repertoire.moves[varMoveId].next;
            varCount++;
          }

          variations[variationId] = variationMoves;
        });

        matches.push({
          repertoireName: repertoire.title,
          nextMoves,
          variations,
        });
      }
    });

    if (matches.length === 0) {
    }

    return matches.length > 0 ? matches : [{ noMatch: true }];
  }, [repertoires, selectedUserGameMoveId, depth, getMoveSequence]);

  return (
    <Card
      id="games-repertoirecard"
      sx={{
        position: "relative", // Required for pseudo-element positioning
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SubjectIcon />
          </Box>
        }
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Repertoire
          </Typography>
        }
        sx={{ pt: 1, pb: 1 }}
        action={
          <Switch
            checked={repertoireOn} // State variable
            onChange={(event) => {
              setRepertoireOn(event.target.checked);
            }}
            sx={{
              "& .MuiSwitch-switchBase": { color: "white" },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: colors.green[100],
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "grey",
              },
              "& .MuiSwitch-track": { backgroundColor: "grey" },
            }}
          />
        }
      />

      {repertoireOn && ( // Render only if engineOn is true
        <>
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px", // Add some spacing below the text
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginRight: "5px",
                }}
              >
                Repertoire search depth{" "}
              </Typography>
              <Box
                sx={{
                  display: "inline-block",
                  cursor: "pointer",
                  borderBottom: `1px dashed ${colors.black[300]}`, // Indicate editable text
                }}
              >
                <input
                  type="number"
                  value={depth}
                  min={1}
                  onChange={(e) => {
                    const newValue = Math.max(1, Number(e.target.value)); // ✅ Prevents values below 1
                    setDepth(newValue);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    textAlign: "center",
                    width: "40px",
                    outline: "none",
                    color: colors.black[900],
                  }}
                />
              </Box>
            </Box>

            {matchingRepertoires.length === 0 ||
            matchingRepertoires[0].noMatch ? (
              <Typography>No match in repertoire</Typography>
            ) : (
              matchingRepertoires.map((match, index) => {
                // ✅ Define sequence INSIDE useMemo and pass it here
                const sequence = getMoveSequence(selectedUserGameMoveId);

                let moveCounter = Math.floor(sequence.length / 2) + 1; // Start from correct move number

                const formatMoves = (moves) => {
                  let formatted = "";
                  let localMoveCounter = Math.floor(moveCounter / 2) + 1; // ✅ Ensure correct starting move number
                  let localWhiteMove = moveCounter % 2 === 0; // ✅ Ensure correct turn for first move

                  moves.forEach((move, idx) => {
                    if (idx === 0 && !localWhiteMove) {
                      // ✅ First move is Black → Add "X.. move"
                      formatted += `${localMoveCounter}.. ${move} `;
                      localMoveCounter++;
                    } else if (localWhiteMove) {
                      formatted += `${localMoveCounter}. ${move} `;
                    } else {
                      formatted += `${move} `;
                      localMoveCounter++; // ✅ Increment only after Black's move
                    }

                    localWhiteMove = !localWhiteMove; // ✅ Alternate between White & Black
                  });

                  return formatted.trim();
                };

                return (
                  <Box key={index} sx={{ p: 1 }}>
                    <Typography variant="body" sx={{ fontWeight: "bold" }}>
                      {match.repertoireName}
                    </Typography>
                    <Typography sx={{ pl: 2 }}>
                      {formatMoves(match.nextMoves)}
                    </Typography>
                    {Object.entries(match.variations).length > 0 && (
                      <Box>
                        {Object.entries(match.variations).map(
                          ([variationId, moves]) => (
                            <Typography key={variationId} sx={{ pl: 4 }}>
                              {formatMoves(moves)}
                            </Typography>
                          )
                        )}
                      </Box>
                    )}
                  </Box>
                );
              })
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default RepertoireCard;
