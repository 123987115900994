import React from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { ResponsiveLine } from "@nivo/line";

const MyGamesAnalysisLineChart = ({ moves }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const extractMovesWithEval = (moves) => {
    if (!moves || !moves.root) return [];

    let result = [];
    let currentMove = moves.root;
    let moveNumber = 1;

    while (currentMove) {
      let evalValue = currentMove.eval;

      // Handle mate values
      if (typeof evalValue === "string") {
        if (evalValue.startsWith("M")) {
          evalValue = 5; // White has a forced mate
        } else if (evalValue.startsWith("-M")) {
          evalValue = -5; // Black has a forced mate
        } else {
          evalValue = parseFloat(evalValue); // Convert string numbers
        }
      }

      // Ensure evalValue is a valid number, then cap between -5 and 5
      evalValue = !isNaN(evalValue) ? Math.max(-5, Math.min(5, evalValue)) : 0;

      result.push({
        x: moveNumber,
        y: evalValue,
        label: currentMove.san || "N/A",
      });

      currentMove = currentMove.next ? moves[currentMove.next] : null;
      moveNumber++;
    }

    return result;
  };

  const chartData = [
    {
      id: "Evaluation",
      data: extractMovesWithEval(moves),
    },
  ];

  return (
    <Box sx={{ height: "135px", width: "100%" }}>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 10, right: 0, bottom: 0, left: 0 }}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: -5, max: 5, reverse: false }}
        axisBottom={null}
        axisLeft={null}
        pointSize={0}
        pointBorderWidth={1.5}
        pointBorderColor={{ from: "serieColor" }}
        enablePointLabel={false}
        useMesh={false}
        tooltip={({ point }) => (
          <strong>
            {point.data.label}: {point.data.yFormatted}
          </strong>
        )}
        colors={[colors.red[500]]}
        enableGridX={false}
        enableGridY={false}
        layers={[
          // ✅ Custom Background Layer with Off-White & Off-Black
          ({ yScale, innerWidth, innerHeight }) => {
            return (
              <>
                {/* Upper half (Slightly Off-White) */}
                <rect
                  x="0"
                  y={yScale(0)}
                  width={innerWidth}
                  height={yScale(-5) - yScale(0)} // Height from 0 to -5
                  fill="#111111" // Light Grayish White
                />
                {/* Lower half (Slightly Off-Black) */}
                <rect
                  x="0"
                  y={yScale(5)} // Start from top
                  width={innerWidth}
                  height={yScale(0) - yScale(5)} // Height from 5 to 0
                  fill="#dddddd" // Dark Grayish Black
                />
              </>
            );
          },
          "lines", // The actual line
          "points", // The data points
        ]}
      />
    </Box>
  );
};

export default MyGamesAnalysisLineChart;
