/**
 * Arrow Component
 *
 * This component renders a straight arrow on a chessboard from one square to another.
 * The arrow is represented as an SVG `<line>` element with customizable start and end positions,
 * color, and an optional marker (e.g., arrowhead) at the end of the line.
 *
 * Props:
 * - `start` (string): The starting square of the arrow in algebraic notation (e.g., "e2").
 * - `end` (string): The ending square of the arrow in algebraic notation (e.g., "e4").
 * - `color` (string): The color of the arrow's stroke (e.g., "#ff0000" for red).
 * - `markerId` (string): The ID of the SVG marker to use for the arrowhead. This allows you
 *   to dynamically assign an arrowhead style by linking to an `<marker>` defined in your SVG.
 *
 * Internal Functions:
 * - `getSquarePosition(square: string): { x: number, y: number }`
 *   Converts a chessboard square (e.g., "e2") into normalized SVG coordinates.
 *   - `square` (string): A chessboard square in algebraic notation.
 *   - Returns an object with `x` and `y` coordinates normalized to percentages of the board size.
 *
 * Example Usage:
 * ```jsx
 * <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
 *   <defs>
 *     <marker
 *       id="arrowhead"
 *       markerWidth="10"
 *       markerHeight="10"
 *       refX="10"
 *       refY="5"
 *       orient="auto"
 *     >
 *       <path d="M0,0 L10,5 L0,10 Z" fill="#ff0000" />
 *     </marker>
 *   </defs>
 *   <Arrow start="e2" end="e4" color="#ff0000" markerId="arrowhead" />
 * </svg>
 * ```
 */
const Arrow = ({ start, end, color, markerId }) => {
  /**
   * Converts a chessboard square (e.g., "e2") into normalized SVG coordinates.
   *
   * @param {string} square - A chessboard square in algebraic notation.
   * @returns {{x: number, y: number}} An object containing normalized `x` and `y` coordinates.
   */
  const getSquarePosition = (square) => {
    const file = square.charCodeAt(0) - 97; // Convert 'a'-'h' to 0-7
    const rank = 8 - parseInt(square[1], 10); // Convert '1'-'8' to 7-0 (invert ranks)
    return { x: (file + 0.5) / 8, y: (rank + 0.5) / 8 }; // Normalize to percentages
  };

  // Calculate normalized start and end positions
  const startPos = getSquarePosition(start);
  const endPos = getSquarePosition(end);

  return (
    <line
      x1={`${startPos.x * 100}%`} // Start x-coordinate in percentage
      y1={`${startPos.y * 100}%`} // Start y-coordinate in percentage
      x2={`${endPos.x * 100}%`} // End x-coordinate in percentage
      y2={`${endPos.y * 100}%`} // End y-coordinate in percentage
      stroke={color} // Set the stroke color
      strokeWidth="2" // Set the stroke width
      markerEnd={`url(#${markerId})`} // Dynamically assign the marker ID for the arrowhead
    />
  );
};

export default Arrow;
