import React, { useState } from "react";
import {
  Button,
  Box,
  TextField,
  Alert,
  Divider,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  handleGoogleSignIn,
  handleEmailLogin,
  handleEmailSignUp,
  handlePasswordReset,
} from "../../../features/Authentication";
import { tokens } from "../../../styles/theme";
import GoogleIcon from "../../../icons/GoogleIcon";
import Turnstile from "react-turnstile";
import RepertoireBuilderLogin from "./repertoireBuilderLogin";
import DefaultLogin from "./defaultLogin";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { control, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [captchaResponse, setCaptchaResponse] = useState("");

  // Get the "from" location or default to "/"
  const from = location.state?.from?.pathname || "/";

  const handleGoogleLogin = async () => {
    const result = await handleGoogleSignIn();
    if (result) {
      navigate(from); // Redirect to the previous page
    } else {
      setError(result.error);
    }
  };

  const onSubmit = async () => {
    if (!captchaResponse) {
      setError("Please complete the CAPTCHA.");
      return;
    }
    try {
      if (isCreatingAccount) {
        if (password !== confirmPassword) {
          setError("Passwords do not match.");
          return;
        }
        const result = await handleEmailSignUp(email, password);
        if (result) {
          setMessage("Verification email sent. Please check your inbox.");
          setIsCreatingAccount(false); // Switch to login view
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setError("");
        } else {
          setError(result.error);
        }
      } else {
        const result = await handleEmailLogin(email, password);
        if (result.success) {
          navigate(from); // Redirect to the previous page
        } else {
          setError(result.error);
        }
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  const handlePasswordResetRequest = async () => {
    try {
      const result = await handlePasswordReset(email);
      if (result) {
        setMessage("Password reset email sent!");
        setIsResettingPassword(false);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column", // Stack on small screens
          md: "row", // Side by side on medium+ screens
        },
        justifyContent: "center", // Centers items horizontally
        alignItems: "flex-start", // Aligns items to the top
        gap: 2,
        mt: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%", // Full width on small screens
            md: "60%", // 60% on medium and up
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 3,
          }}
        >
          {from === "/repertoirebuilder" ? (
            <RepertoireBuilderLogin />
          ) : (
            <DefaultLogin />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%", // Full width on small screens
            md: "40%", // 60% on medium and up
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 3,
          }}
        >
          {/* Alerts */}
          {error && (
            <Alert
              severity="error"
              sx={{ marginBottom: 2 }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setError("")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          )}
          {message && (
            <Alert
              severity="success"
              sx={{ marginBottom: 2 }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setMessage("")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {message}
            </Alert>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="filled"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ marginBottom: 2 }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  fullWidth
                  autoComplete="email"
                />
              )}
            />
            {!isResettingPassword && (
              <>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      variant="filled"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{ marginBottom: 2 }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      fullWidth
                      autoComplete="current-password"
                    />
                  )}
                />
                {isCreatingAccount && (
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Confirm Password"
                        variant="filled"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ marginBottom: 2 }}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        fullWidth
                        autoComplete="new-password"
                      />
                    )}
                  />
                )}
              </>
            )}
            <Turnstile
              sitekey="0x4AAAAAAAgKQaDETBl-vYMI"
              theme={theme.palette.mode}
              onVerify={(response) => setCaptchaResponse(response)}
              onError={() => setCaptchaResponse("")}
              onExpire={() => setCaptchaResponse("")}
              size="flexible"
            />
            {isResettingPassword ? (
              <Button
                variant="contained"
                onClick={handlePasswordResetRequest}
                sx={{ marginTop: 2 }}
                fullWidth
              >
                Reset Password
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: 2 }}
                fullWidth
              >
                {isCreatingAccount ? "Sign Up" : "Log In"}
              </Button>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
                width: "100%",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setIsCreatingAccount(!isCreatingAccount);
                  setMessage("");
                  setError("");
                }}
                fullWidth
              >
                {isCreatingAccount
                  ? "Already have an account? Log In"
                  : "Create a new account"}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setIsResettingPassword(!isResettingPassword);
                  setMessage("");
                  setError("");
                }}
                fullWidth
              >
                {isResettingPassword ? "Back to Log In" : "Forgot Password?"}
              </Button>
            </Box>
            <Divider sx={{ marginY: 3, width: "100%" }}>OR</Divider>
            <Button
              variant="contained"
              onClick={handleGoogleLogin}
              sx={{ width: "100%" }}
            >
              <GoogleIcon /> Log In with Google
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
