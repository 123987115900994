import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  Slider,
  MenuItem,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../styles/theme";

import { Helmet } from "react-helmet";
import { Chess } from "chess.js";

import EvaluationLineChart from "./evaluationChart";
import AnalysisTable from "./analysisTable";
import OpeningAnalysisChessboard from "./analysisChessboard";

function OpeningAnalysis() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [username, setUsername] = useState("HollowLeaf");
  const [numGames, setNumGames] = useState(3);
  const [depth, setDepth] = useState(10);

  const [stockfishDepth, setStockfishDepth] = useState(12);
  const [stockfishPV, setStockfishPV] = useState(5);
  const [colorFilter, setColorFilter] = useState("White");
  const [apiSource, setApiSource] = useState("lichess");
  const [moveRange, setMoveRange] = useState([1, depth]);

  const [openings, setOpenings] = useState([]);
  const [firstEvaluationOpenings, setFirstEvaluationOpenings] = useState([]);
  const [secondEvaluationOpenings, setSecondEvaluationOpenings] = useState([]);

  const [startAnalysis, setStartAnalysis] = useState(false);
  const [analysisStep, setAnalysisStep] = useState("");
  const [gamesProcessedFirstEval, setGamesProcessedFirstEval] = useState(0);
  const [gamesProcessedSecondEval, setGamesProcessedSecondEval] = useState(0);
  const [gamesLoaded, setGamesLoaded] = useState(0);

  const [selectedLine, setSelectedLine] = useState("");

  const [startTime, setStartTime] = useState(null); // Stores when analysis starts
  const [elapsedTime, setElapsedTime] = useState(0); // Stores elapsed time in seconds
  const elapsedInterval = useRef(null); // Reference for interval timer

  const tabStyles = (isSelected) => ({
    color: isSelected ? colors.black[900] : colors.black[500],
    "&.Mui-selected": {
      color: colors.black[900],
      backgroundColor: "rgba(0, 0, 0, 1)",
      border: "none",
    },
    "&:hover": {
      color: colors.black[900],
    },
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const STOCKFISH_COUNT = 10;
  const stockfishWorkers = useRef([]);

  const getAvailableStockfish = useCallback(async () => {
    let retries = 0;

    while (retries < 100) {
      const availableWorker = stockfishWorkers.current.find(
        (w) => w.status === "idle"
      );

      if (availableWorker) {
        availableWorker.status = "busy"; // Mark as busy before returning
        return availableWorker;
      }

      await new Promise((resolve) => setTimeout(resolve, 500)); // Wait before retrying
      retries++;
    }

    return null;
  }, []);

  const cleanupStockfishWorkers = () => {
    stockfishWorkers.current.forEach(({ worker }) => worker.terminate());
    stockfishWorkers.current = []; // Remove references for garbage collection

    // (Optional) If debugging in Chrome DevTools, manually trigger GC:
    if (window.gc) {
      window.gc();
    }
  };

  const initializeStockfishWorkers = useCallback(() => {
    return new Promise((resolve) => {
      stockfishWorkers.current = Array.from(
        { length: STOCKFISH_COUNT },
        (_, index) => ({
          worker: new Worker(
            `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
          ),
          status: "idle",
          index,
          ready: false, // Track if worker is fully ready
        })
      );

      let readyWorkers = 0;

      stockfishWorkers.current.forEach(({ worker, index }) => {
        worker.onmessage = (event) => {
          if (event.data === "readyok") {
            stockfishWorkers.current[index].ready = true;
            readyWorkers++;

            // Only resolve once ALL workers are ready
            if (readyWorkers === STOCKFISH_COUNT) {
              resolve();
            }
          }
        };

        worker.postMessage("uci");
        worker.postMessage("isready");
      });
    });
  }, []);

  const analyze = async () => {
    if (!username) return;

    setStartAnalysis(false); // Reset analysis trigger to prevent auto-run
    setOpenings([]);
    setFirstEvaluationOpenings([]);
    setSecondEvaluationOpenings([]);
    setGamesLoaded(0);
    setGamesProcessedFirstEval(0);
    setGamesProcessedSecondEval(0);
    setAnalysisStep("Fetching Games");

    const startTimestamp = Date.now();
    setStartTime(startTimestamp);
    setElapsedTime(0); // Reset elapsed time

    // Start the interval timer
    if (elapsedInterval.current) clearInterval(elapsedInterval.current);
    elapsedInterval.current = setInterval(() => {
      setElapsedTime((prev) => (Date.now() - startTimestamp) / 1000); // Use float seconds
    }, 100);

    if (stockfishWorkers.current.length > 0) {
      stockfishWorkers.current.forEach(({ worker }) => worker.terminate());
      stockfishWorkers.current = [];
    }

    setStartAnalysis(true); // Manually trigger evaluations

    let allOpenings =
      apiSource === "lichess"
        ? await fetchLichessGames()
        : await fetchChessComGames();

    setAnalysisStep("Initializing Stockfish");

    // Initialize Stockfish before running evaluations
    await initializeStockfishWorkers();

    // Set openings after Stockfish is ready
    setOpenings(allOpenings);
  };

  const formatMovesToPGN = (movesString) => {
    if (!movesString || movesString.trim() === "") {
      return "";
    }
    const moves = movesString.split(" "); // Split moves into an array
    let formattedPGN = "";

    for (let i = 0; i < moves.length; i++) {
      if (i % 2 === 0) {
        formattedPGN += `${Math.floor(i / 2) + 1}. `; // Add move number (1., 2., etc.)
      }
      formattedPGN += moves[i] + " "; // Append move
    }

    return formattedPGN.trim(); // Remove trailing space
  };

  const formatElapsedTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const tenths = Math.floor((seconds - Math.floor(seconds)) * 10); // Extract decimal part

    if (hours > 0) {
      return `${hours}h ${minutes}m ${secs}.${tenths}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${secs}.${tenths}s`;
    } else {
      return `${secs}.${tenths}s`;
    }
  };

  const stopAnalysis = useCallback(() => {
    cleanupStockfishWorkers();

    // 🔄 Reset all state variables
    setStartAnalysis(false);
    setOpenings([]);
    setFirstEvaluationOpenings([]);
    setSecondEvaluationOpenings([]);
    setGamesLoaded(0);
    setGamesProcessedFirstEval(0);
    setGamesProcessedSecondEval(0);
    setAnalysisStep("Analysis Stopped");

    // ⏳ Stop the elapsed time counter
    if (elapsedInterval.current) {
      clearInterval(elapsedInterval.current);
      elapsedInterval.current = null;
    }
    setElapsedTime(0);
  }, []);

  const fetchLichessGames = async () => {
    let moveSequences = {};
    let gameCount = 0;

    try {
      const response = await fetch(
        `https://lichess.org/api/games/user/${username}?max=${numGames}&format=ndjson&lastFen=true&division=true`,
        {
          headers: { Accept: "application/x-ndjson" },
        }
      );

      if (!response.ok) return [];

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let ndjsonData = "";

      while (gameCount < numGames) {
        setGamesLoaded(gameCount);
        const { done, value } = await reader.read();
        if (done) break;

        ndjsonData += decoder.decode(value);
        const lines = ndjsonData.split("\n");
        ndjsonData = lines.pop();

        for (const l of lines) {
          if (gameCount >= numGames) break;
          let game;
          try {
            game = JSON.parse(l);
          } catch (e) {
            continue;
          }

          if (!game.moves || !game.players) continue;
          let moves = game.moves.split(" ");
          if (moves.length === 0) continue;

          let sequence = "";
          let playerColor =
            game.players.white.user.name.toLowerCase() ===
            username.toLowerCase()
              ? "White"
              : "Black";

          for (let i = 0; i < Math.min(moves.length, depth); i++) {
            sequence += (i > 0 ? " " : "") + moves[i];
            let key = `${sequence}-${playerColor}`;

            if (!moveSequences[key]) {
              moveSequences[key] = {
                id: game.id,
                moves: formatMovesToPGN(sequence),
                occurrences: 0,
                color: playerColor,
                numberOfMoves: i + 1,
              };
            }
            moveSequences[key].occurrences += 1;
          }

          gameCount++;
          setGamesLoaded(gameCount);
        }
      }

      return Object.values(moveSequences).sort(
        (a, b) => b.occurrences - a.occurrences
      );
    } catch (error) {
      return [];
    }
  };

  const fetchChessComGames = async () => {
    let moveSequences = {};
    let gameCount = 0;
    let date = new Date();
    let monthsSearched = 0;

    while (gameCount < numGames && monthsSearched < 24) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");

      try {
        const response = await fetch(
          `https://api.chess.com/pub/player/${username}/games/${year}/${month}`
        );

        if (!response.ok) {
          break;
        }

        const data = await response.json();
        if (!data.games || data.games.length === 0) {
          date.setMonth(date.getMonth() - 1);
          monthsSearched++;
          continue;
        }

        for (let game of data.games) {
          if (gameCount >= numGames) break;
          if (!game.pgn) continue;

          let moves = extractMoves(game.pgn);
          if (moves.length === 0) continue;

          let sequence = "";
          let playerColor =
            game.white.username.toLowerCase() === username.toLowerCase()
              ? "White"
              : "Black";

          for (let i = 0; i < Math.min(moves.length, depth); i++) {
            sequence += (i > 0 ? " " : "") + moves[i];
            let key = `${sequence}-${playerColor}`;

            if (!moveSequences[key]) {
              moveSequences[key] = {
                id: game.url.split("/").pop(),
                moves: formatMovesToPGN(sequence),
                occurrences: 0,
                color: playerColor,
                numberOfMoves: i + 1, // Add number of moves field
              };
            }
            moveSequences[key].occurrences += 1;
          }

          gameCount++;
          setGamesLoaded(gameCount);
        }
      } catch (error) {
        break;
      }

      date.setMonth(date.getMonth() - 1);
      monthsSearched++;
    }

    return Object.values(moveSequences).sort(
      (a, b) => b.occurrences - a.occurrences
    );
  };

  const extractMoves = (pgn) => {
    const noMetadata = pgn.replace(/^\[.*\]$/gm, "");
    const noComments = noMetadata.replace(/\{.*?\}/g, "");
    const noMoveNumbers = noComments.replace(/\d+\.+/g, "");
    return noMoveNumbers.trim().split(/\s+/);
  };

  const getEvaluation = useCallback(
    async (fen, workerInstance) => {
      const { worker } = workerInstance;

      return new Promise((resolve) => {
        const lines = [];
        let resolved = false;

        worker.onmessage = (event) => {
          const message = event.data;

          if (message.startsWith("bestmove")) {
            worker.postMessage("stop");

            if (!resolved) {
              resolved = true;
              workerInstance.status = "idle"; // Release worker for reuse
              resolve({
                stockEval: lines.length > 0 ? lines[0].eval : null,
                topMoves: lines.slice(0, 1), // ✅ Return in SAN format
              });
            }
          }

          if (message.startsWith(`info depth ${stockfishDepth}`)) {
            const match = message.match(/score (cp|mate) (-?\d+).* pv (.+)/);
            if (match) {
              const scoreType = match[1];
              let scoreValue = parseInt(match[2], 10);
              const uciMoves = match[3].split(" ");

              // Convert first move to SAN format using chess.js
              let sanMove = "";
              try {
                const chess = new Chess(fen); // Initialize chess.js for FEN position
                const move = chess.move({
                  from: uciMoves[0].slice(0, 2),
                  to: uciMoves[0].slice(2, 4),
                  promotion: "q",
                });
                sanMove = move ? move.san : uciMoves[0]; // Fallback if move fails
              } catch (error) {
                sanMove = uciMoves[0]; // If conversion fails, use UCI as fallback
              }

              let evaluation;
              if (scoreType === "cp") {
                evaluation = scoreValue / 100;
                if (fen.split(" ")[1] === "b") evaluation = -evaluation;
              } else if (scoreType === "mate") {
                evaluation = `M${Math.abs(scoreValue)}`;
                if (scoreValue < 0) evaluation = `-M${Math.abs(scoreValue)}`;
              }

              lines.push({ eval: evaluation, move: sanMove });

              if (lines.length === stockfishPV && !resolved) {
                resolved = true;
                worker.postMessage("stop");
                workerInstance.status = "idle"; // Release worker for reuse
                resolve({
                  stockEval: lines[0].eval,
                  topMoves: lines.slice(0, stockfishPV), // ✅ Top 3 moves in SAN format
                });
              }
            }
          }
        };

        worker.postMessage(`setoption name MultiPV value ${stockfishPV}`);
        worker.postMessage(`position fen ${fen}`);
        worker.postMessage(`go depth ${stockfishDepth}`);

        // Safety Timeout: Reset Worker if it hangs
        setTimeout(() => {
          if (!resolved) {
            workerInstance.worker.terminate();
            workerInstance.worker = new Worker(
              `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
            );
            workerInstance.status = "idle"; // Mark worker as available again

            resolve({ stockEval: null, topMoves: [] });
          }
        }, 5000);
      });
    },
    [stockfishDepth, stockfishPV]
  );

  const addFirstEvaluation = useCallback(async () => {
    setAnalysisStep("Performing first evaluation...");

    let updatedOpenings = [...openings]; // Work with a copy
    let promises = []; // Store evaluation promises

    for (let index = 0; index < updatedOpenings.length; index++) {
      const opening = updatedOpenings[index];
      const chess = new Chess();
      chess.loadPgn(opening.moves);
      const fen = chess.fen();

      // Ensure a Stockfish worker is available before proceeding
      let workerInstance = null;
      while (!workerInstance) {
        workerInstance = await getAvailableStockfish();
        if (!workerInstance) {
          await new Promise((resolve) => setTimeout(resolve, 50)); // Wait and retry
        }
      }

      // Push the evaluation promise into an array
      const evaluationPromise = getEvaluation(fen, workerInstance).then(
        (evaluationResult) => {
          updatedOpenings[index] = {
            ...opening,
            fen,
            evaluation: evaluationResult,
          };

          setGamesProcessedFirstEval((prevCount) => prevCount + 1);
          workerInstance.status = "idle"; // Mark worker as idle for reuse
        }
      );

      promises.push(evaluationPromise);
    }

    // Ensure all evaluations complete before updating state
    await Promise.all(promises);

    setFirstEvaluationOpenings(updatedOpenings);
  }, [openings, getEvaluation, getAvailableStockfish]);

  const addSecondEvaluation = useCallback(async () => {
    setAnalysisStep("Performing second evaluation...");

    let updatedOpenings = [...firstEvaluationOpenings]; // Work with a copy
    let promises = []; // Store evaluation promises

    for (let index = 0; index < updatedOpenings.length; index++) {
      const opening = updatedOpenings[index];
      const chess = new Chess();
      chess.loadPgn(opening.moves);
      chess.undo(); // Undo last move
      const fen = chess.fen();

      // Ensure a Stockfish worker is available before proceeding
      let workerInstance = null;
      while (!workerInstance) {
        workerInstance = await getAvailableStockfish();
        if (!workerInstance) {
          await new Promise((resolve) => setTimeout(resolve, 50)); // Wait and retry
        }
      }

      // Push the evaluation promise into an array
      const evaluationPromise = getEvaluation(fen, workerInstance).then(
        (evaluationResult) => {
          const evaluationDiff =
            parseFloat(opening.evaluation.stockEval) -
            parseFloat(evaluationResult.stockEval);

          updatedOpenings[index] = {
            ...opening,
            fen,
            previousEvaluation: evaluationResult,
            evaluationDiff: isNaN(evaluationDiff) ? 0 : evaluationDiff, // Ensure it's a number
          };

          setGamesProcessedSecondEval((prevCount) => prevCount + 1);

          // Mark worker as idle so it can be reused
          workerInstance.status = "idle";
        }
      );

      promises.push(evaluationPromise);
    }

    // Ensure all evaluations complete before updating state
    await Promise.all(promises);

    setSecondEvaluationOpenings(updatedOpenings);
    cleanupStockfishWorkers();
    if (elapsedInterval.current) {
      clearInterval(elapsedInterval.current);
      elapsedInterval.current = null;
    }
  }, [firstEvaluationOpenings, getEvaluation, getAvailableStockfish]);

  useEffect(() => {
    // Create Stockfish workers
    stockfishWorkers.current = Array.from(
      { length: STOCKFISH_COUNT },
      (_, index) => ({
        worker: new Worker(
          `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
        ),
        status: "idle", // Tracks whether worker is busy or not
        index,
      })
    );

    return () => {
      // Clean up workers on unmount
      stockfishWorkers.current.forEach(({ worker }) => worker.terminate());
    };
  }, []);

  useEffect(() => {
    if (startAnalysis && openings.length > 0) {
      addFirstEvaluation();
    }
  }, [startAnalysis, openings, addFirstEvaluation]); // Only run when `startAnalysis` is triggered

  useEffect(() => {
    if (startAnalysis && firstEvaluationOpenings.length > 0) {
      addSecondEvaluation();
      setStartAnalysis(false);
    }
  }, [startAnalysis, firstEvaluationOpenings, addSecondEvaluation]);

  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Opening Analysis</title>
        <meta
          name="description"
          content="Analyze your chess openings with Lichess and Chess.com data."
        />
      </Helmet>
      <Box mb={2}>
        <Typography variant="h6">Status: {analysisStep}</Typography>
        <Typography variant="body1">
          Games Loaded: {gamesLoaded} | Processed (First Eval):{" "}
          {openings.length > 0
            ? ((gamesProcessedFirstEval / openings.length) * 100).toFixed(1) +
              "%"
            : "0%"}{" "}
          | Processed (Second Eval):{" "}
          {openings.length > 0
            ? ((gamesProcessedSecondEval / openings.length) * 100).toFixed(1) +
              "%"
            : "0%"}
        </Typography>
        <Typography variant="body1">
          🕒 Start Time:{" "}
          {startTime ? new Date(startTime).toLocaleTimeString() : "N/A"} | ⏳
          Elapsed Time: {formatElapsedTime(elapsedTime)}
        </Typography>
      </Box>
      <Typography variant="h4" gutterBottom>
        Opening Analysis
      </Typography>
      <Box display="flex" mb={2} sx={{ height: "50px" }}>
        <TextField
          label="Username"
          value={username}
          variant="outlined"
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="off"
          sx={{
            height: "100%", // Ensures it takes full height
            "& .MuiInputBase-root": {
              height: "100%",
              alignItems: "center",
              borderRadius: "4px 0 0 4px", // Rounded right, flat left
              borderLeft: "none", // Remove border overlap with Select
              boxShadow: "none", // Ensures no box shadow
              "& fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Set border color
              },
              "&:hover fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents hover change
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents focus change
              },
            },
            "& .MuiOutlinedInput-root": {
              height: "100%", // Ensure the outlined variant stretches fully
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px", // Ensures content doesn't overflow
            },
          }}
        />
        <Select
          value={apiSource}
          onChange={(e) => setApiSource(e.target.value)}
          displayEmpty
          IconComponent={() => null}
          variant="outlined"
          sx={{
            mr: 1,
            borderRadius: "0 4px 4px 0", // Rounded right, flat left
            width: "50px",
            minWidth: "50px",
            height: "100%",
            textAlign: "center",
            backgroundColor: "transparent", // Ensures background is clean
            "& .MuiOutlinedInput-root": {
              border: "1px solid rgba(255, 255, 255, 0.23) !important", // Forces a visible border
              "&:hover": {
                borderColor: "rgba(255, 255, 255, 0.5) !important",
              },
              "&.Mui-focused": {
                borderColor: "rgba(255, 255, 255, 0.8) !important",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(255, 255, 255, 0.23) !important", // Keeps border visible at all times
              borderWidth: "1px !important", // Forces a single 1px border
            },
            "& .MuiSelect-select": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "visible",
              paddingLeft: "25px",
            },
          }}
        >
          <MenuItem value="lichess">
            <img
              src={`${process.env.PUBLIC_URL}/img/lichessdotorgwhite.png`}
              alt="Lichess"
              width={25}
            />
          </MenuItem>
          <MenuItem value="chesscom">
            <img
              src={`${process.env.PUBLIC_URL}/img/chessdotcom.png`}
              alt="Chess.com"
              width={25}
            />
          </MenuItem>
        </Select>

        <TextField
          label="# Games"
          type="number"
          value={numGames}
          onChange={(e) => setNumGames(parseInt(e.target.value))}
          sx={{
            height: "100%", // Ensures it takes full height
            width: "120px",
            mr: 1,
            "& .MuiInputBase-root": {
              height: "100%",
              alignItems: "center",
              borderLeft: "none", // Remove border overlap with Select
              boxShadow: "none", // Ensures no box shadow
              "& fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Set border color
              },
              "&:hover fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents hover change
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents focus change
              },
            },
            "& .MuiOutlinedInput-root": {
              height: "100%", // Ensure the outlined variant stretches fully
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px", // Ensures content doesn't overflow
            },
          }}
        />
        <TextField
          label="Moves Depth"
          type="number"
          value={depth}
          onChange={(e) => setDepth(parseInt(e.target.value))}
          sx={{
            height: "100%", // Ensures it takes full height
            width: "120px",
            mr: 1,
            "& .MuiInputBase-root": {
              height: "100%",
              alignItems: "center",
              borderLeft: "none", // Remove border overlap with Select
              boxShadow: "none", // Ensures no box shadow
              "& fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Set border color
              },
              "&:hover fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents hover change
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents focus change
              },
            },
            "& .MuiOutlinedInput-root": {
              height: "100%", // Ensure the outlined variant stretches fully
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px", // Ensures content doesn't overflow
            },
          }}
        />
        <TextField
          label="Stockfish Depth"
          type="number"
          value={stockfishDepth}
          onChange={(e) => setStockfishDepth(parseInt(e.target.value))}
          sx={{
            height: "100%", // Ensures it takes full height
            width: "120px",
            mr: 1,
            "& .MuiInputBase-root": {
              height: "100%",
              alignItems: "center",
              borderLeft: "none", // Remove border overlap with Select
              boxShadow: "none", // Ensures no box shadow
              "& fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Set border color
              },
              "&:hover fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents hover change
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents focus change
              },
            },
            "& .MuiOutlinedInput-root": {
              height: "100%", // Ensure the outlined variant stretches fully
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px", // Ensures content doesn't overflow
            },
          }}
        />
        <TextField
          label="Stockfish PV"
          type="number"
          value={stockfishPV}
          onChange={(e) => setStockfishPV(parseInt(e.target.value))}
          sx={{
            height: "100%", // Ensures it takes full height
            width: "120px",
            mr: 1,
            "& .MuiInputBase-root": {
              height: "100%",
              alignItems: "center",
              borderLeft: "none", // Remove border overlap with Select
              boxShadow: "none", // Ensures no box shadow
              "& fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Set border color
              },
              "&:hover fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents hover change
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents focus change
              },
            },
            "& .MuiOutlinedInput-root": {
              height: "100%", // Ensure the outlined variant stretches fully
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px", // Ensures content doesn't overflow
            },
          }}
        />
        <Select
          value={colorFilter}
          onChange={(e) => {
            setColorFilter(e.target.value);
            setSelectedLine("");
          }}
          sx={{
            height: "100%", // Ensures it takes full height
            width: "100px",
            "& .MuiInputBase-root": {
              height: "100%",
              alignItems: "center",
              borderLeft: "none", // Remove border overlap with Select
              boxShadow: "none", // Ensures no box shadow
              "& fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Set border color
              },
              "&:hover fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents hover change
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px !important",
                borderColor: "rgba(255, 255, 255, 0.23)", // Prevents focus change
              },
            },
            "& .MuiOutlinedInput-root": {
              height: "100%", // Ensure the outlined variant stretches fully
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px", // Ensures content doesn't overflow
            },
          }}
        >
          <MenuItem value="White">White</MenuItem>
          <MenuItem value="Black">Black</MenuItem>
        </Select>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="200px"
          sx={{ pl: "20px", pr: "20px" }}
        >
          <Typography sx={{ fontSize: "0.75rem", color: "white" }}>
            Move Range
          </Typography>
          <Slider
            value={moveRange}
            onChange={(event, newValue) => setMoveRange(newValue)}
            valueLabelDisplay="auto"
            min={1}
            max={depth}
            sx={{
              maxWidth: "100%",
              color: "white", // Makes the slider track and thumb white
              "& .MuiSlider-thumb": {
                backgroundColor: "white",
                width: "12px", // Make the thumb smaller (default ~20px)
                height: "12px",
              },
              "& .MuiSlider-track": {
                backgroundColor: "white",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "rgba(255, 255, 255, 0.3)", // Light gray for contrast
              },
              "& .MuiSlider-valueLabel": {
                color: "black",
                backgroundColor: "white",
              },
            }}
          />
        </Box>

        {startAnalysis ? (
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "140px" }}
            onClick={stopAnalysis}
          >
            🛑 Stop
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "140px" }}
            onClick={analyze}
          >
            🎯 Analyze
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab label="Opening Table" sx={tabStyles(selectedTab === 1)} />
          <Tab label="Evaluation Chart" sx={tabStyles(selectedTab === 0)} />
        </Tabs>
      </Box>
      {secondEvaluationOpenings.length > 0 && (
        <Box
          sx={{
            display: selectedTab === 0 ? "flex" : "none",
            justifyContent: "flex-start",
            backgroundColor: "rgba(0, 0, 0, 1)",
          }}
        >
          <OpeningAnalysisChessboard
            boardOrientation={colorFilter}
            setSelectedLine={setSelectedLine}
            formatMovesToPGN={formatMovesToPGN}
          />
          <AnalysisTable
            openingData={secondEvaluationOpenings}
            colorFilter={colorFilter}
            moveRange={moveRange}
            selectedLine={selectedLine}
          />
        </Box>
      )}
      {selectedTab === 1 && secondEvaluationOpenings.length > 0 && (
        <EvaluationLineChart
          openingData={secondEvaluationOpenings}
          colorFilter={colorFilter}
          moveRange={moveRange}
        />
      )}
    </Box>
  );
}

export default OpeningAnalysis;
