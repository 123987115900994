import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { useUser } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";

import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";

import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import HelpModal from "../../../components/HelpModal";
import CheckmateTrainerHelp from "../../../help/CheckmateTrainerHelp";

import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";

function CheckmateTrainer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const { t } = useTranslation("Learn");

  const { setShareData } = useShare();

  const [game, setGame] = useState(new Chess());
  const [gameType, setGameType] = useState("Queen vs King");
  const [fen, setFen] = useState(game.fen());
  const [timer, setTimer] = useState(0);
  const [moveCount, setMoveCount] = useState(0);
  const [drawMessage, setDrawMessage] = useState("");
  const [isTimerActive, setIsTimerActive] = useState(false);

  const stockfishWorker = useRef(null); // Ref to store the Stockfish worker instance.

  // Map game types to corresponding piece placements and difficulty levels.
  const gameTypes = useMemo(
    () => [
      { type: "Queen vs King", pieces: ["k", "K", "Q"] },
      { type: "Rook vs King", pieces: ["k", "K", "R"] },
      { type: "Two Rooks vs King", pieces: ["k", "K", "R", "R"] },
      { type: "Queen vs Rook", pieces: ["k", "K", "Q", "r"] },
      { type: "Two Bishops vs King", pieces: ["k", "K", "B", "B"] },
      { type: "Bishop Knight vs King", pieces: ["k", "K", "B", "N"] },
      { type: "Rook vs Bishop", pieces: ["k", "K", "R", "b"] },
      { type: "Rook vs Knight", pieces: ["k", "K", "R", "n"] },
      //{ type: "Rook and Knight vs Rook", pieces: ["k", "K", "R", "N", "r"] },
      //{ type: "Rook and Bishop vs Rook", pieces: ["k", "K", "R", "B", "r"] },
    ],
    []
  );

  // Initialize share data
  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/checkmatetrainer",
      title: "Chessboard Magic - Checkmate Trainer",
      description:
        "Practice classic checkmating patterns against an engine in a fun and interactive chess game for all skill levels!",
    };
    setShareData(newShareData);
  }, [setShareData]);

  // Timer logic
  useEffect(() => {
    if (isTimerActive) {
      const interval = setInterval(() => {
        setTimer((prev) => prev + 0.1); // Increment timer
      }, 100);

      return () => clearInterval(interval); // Cleanup
    }
  }, [isTimerActive]);

  // Load Stockfish as a Web Worker
  useEffect(() => {
    stockfishWorker.current = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    return () => {
      if (stockfishWorker.current) {
        stockfishWorker.current.terminate();
      }
    };
  }, []);

  // Start a new game
  const newGame = useCallback(() => {
    const selectedType = gameTypes.find((gt) => gt.type === gameType);
    const pieces = selectedType?.pieces || ["k", "K"];
    const newGame = new Chess();

    let validPosition = false;
    while (!validPosition) {
      newGame.clear(); // Clear board
      const bishopSquares = [];

      pieces.forEach((piece) => {
        let square;

        do {
          square = randomSquare(newGame);

          // Perform extra check only for "Two Bishops vs King"
          if (
            gameType === "Two Bishops vs King" &&
            piece.toLowerCase() === "b" &&
            bishopSquares.length === 1 &&
            (bishopSquares[0].charCodeAt(0) +
              parseInt(bishopSquares[0][1], 10)) %
              2 ===
              (square.charCodeAt(0) + parseInt(square[1], 10)) % 2
          ) {
            // Retry if the bishops are not on opposite colors
            continue;
          }

          break;
        } while (true);

        if (piece.toLowerCase() === "b") {
          bishopSquares.push(square); // Track bishop squares to ensure opposite colors
        }

        newGame.put(
          {
            type: piece.toLowerCase(),
            color: piece === piece.toUpperCase() ? "w" : "b",
          },
          square
        );
      });

      // Set the turn to Black before validation
      const currentFen = newGame.fen();
      const fenParts = currentFen.split(" ");
      fenParts[1] = "b"; // Set turn to Black for validation
      const newFen = fenParts.join(" ");
      newGame.load(newFen);

      // Validate that the Black king is not in check, and it is not stalemate or checkmate
      validPosition =
        !newGame.inCheck() && !newGame.isCheckmate() && !newGame.isStalemate();
    }

    // Ensure it's White's turn after a valid position
    const validFen = newGame.fen();
    const fenParts = validFen.split(" ");
    fenParts[1] = "w"; // Set turn to White
    newGame.load(fenParts.join(" "));

    setGame(newGame);
    setFen(newGame.fen());
    setTimer(0);
    setMoveCount(0);
    setDrawMessage("");
    setIsTimerActive(true); // Start timer

    // GA Tracking
    trackEvent("Learn", "CheckmateTrainer-Play", "Checkmate Trainer");
    // Internal Tracking

    if (userData) {
      if (!userData.Learn) {
        userData.Learn = {};
      }
      if (userData.Learn.CheckmateTrainer) {
        userData.Learn.CheckmateTrainer.Played =
          (userData.Learn.CheckmateTrainer.Played || 0) + 1;
      } else {
        userData.Learn.CheckmateTrainer = {
          Played: 1,
        };
      }
      updateUserData(userRef, userData);
    }
  }, [gameType, gameTypes, userData, userRef]);

  const stopGame = () => {
    setIsTimerActive(false); // Stop timer
    setDrawMessage("Stopped"); // Update message
  };

  const randomSquare = (currentGame) => {
    const files = "abcdefgh";
    const ranks = "12345678";
    let square;
    do {
      square = `${files[Math.floor(Math.random() * 8)]}${
        ranks[Math.floor(Math.random() * 8)]
      }`;
    } while (currentGame.get(square));
    return square;
  };

  const onDrop = (sourceSquare, targetSquare) => {
    // Save the current game state in case the move is invalid
    const previousFen = game.fen();

    try {
      // Attempt the move
      const move = game.move({ from: sourceSquare, to: targetSquare });

      // Check if the move is valid
      if (move === null) {
        return false; // Prevent the move from being accepted
      }

      // Update the game state if the move is valid
      setFen(game.fen());
      setMoveCount((prev) => prev + 1);

      // Check for draw messages
      if (moveCount + 1 === 50) {
        setDrawMessage("Draw50");
      } else if (moveCount + 1 === 75) {
        setDrawMessage("Draw75");
      }

      // Check if the game is over
      if (game.isGameOver()) {
        setIsTimerActive(false); // Stop the timer
        if (game.isCheckmate()) {
          setDrawMessage("Checkmate!");
        } else if (game.isStalemate()) {
          setDrawMessage("Stalemate");
        } else if (game.isDraw()) {
          setDrawMessage("Draw");
        }
        return true; // Allow the move but stop further processing
      }

      // Let Stockfish respond
      if (stockfishWorker.current) {
        stockfishWorker.current.postMessage(`position fen ${game.fen()}`);
        stockfishWorker.current.postMessage("go depth 12");

        // Handle Stockfish's response
        stockfishWorker.current.onmessage = (event) => {
          const message = event.data;
          if (message.startsWith("bestmove")) {
            const bestMove = message.split(" ")[1];

            // Make Stockfish's move
            const engineMove = game.move({
              from: bestMove.substring(0, 2),
              to: bestMove.substring(2, 4),
            });

            if (engineMove) {
              setFen(game.fen());

              if (game.isGameOver()) {
                setIsTimerActive(false); // Stop the timer
                if (game.isCheckmate()) {
                  setDrawMessage("Checkmate!");
                } else if (game.isStalemate()) {
                  setDrawMessage("Stalemate");
                } else if (game.isDraw()) {
                  setDrawMessage("Draw");
                }
              }
            } else {
              // If Stockfish's move is invalid, revert to the previous state
              game.load(previousFen);
              setFen(previousFen);
            }
          }
        };
      }
    } catch (error) {
      // Log the error for debu

      // Revert to the previous state
      game.load(previousFen);
      setFen(previousFen);
      return false; // Prevent the move from being accepted
    }

    return true;
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ContentHeader
        title={t("CheckmateTrainer.header.title")}
        subtitle={t("CheckmateTrainer.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />
      <Helmet>
        <title>Checkmate Trainer</title>
        <meta
          name="description"
          content="Practice classic checkmating patterns against an engine in a fun and interactive chess game for all skill levels!"
        />
        <meta property="og:title" content="Checkmate Trainer" />
        <meta
          property="og:description"
          content="Practice classic checkmating patterns against an engine in a fun and interactive chess game for all skill levels!"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/checkmatetrainer.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/checkmatetrainer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Checkmate Trainer" />
        <meta
          name="twitter:description"
          content="Practice classic checkmating patterns against an engine in a fun and interactive chess game for all skill levels!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/checkmatetrainer.png`}
        />
      </Helmet>

      {/* Game Controls */}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start" // Use "flex-start" to align items at the top
        marginBottom="16px"
        gap="10px"
        flexWrap="wrap" // Allow buttons to wrap to the next line on overflow
      >
        <Select value={gameType} onChange={(e) => setGameType(e.target.value)}>
          {gameTypes.map((gt) => (
            <MenuItem key={gt.type} value={gt.type}>
              {t(`CheckmateTrainer.gameTypes.${gt.type.replace(/\s+/g, "")}`)}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          onClick={newGame}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[10] }}
            />
          }
        >
          {t("CheckmateTrainer.buttons.newGame")}
        </Button>
        <Button
          variant="contained"
          onClick={stopGame}
          disabled={!isTimerActive}
          startIcon={<StopCircleIcon style={{ color: colors.material[10] }} />}
        >
          {t("CheckmateTrainer.buttons.stop")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[10] }} />}
        >
          {t("CheckmateTrainer.buttons.help")}
        </Button>
        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("CheckmateTrainer.helpDetails.title")}
          content={<CheckmateTrainerHelp />}
        />
      </Box>

      {/* Timer and Move Counter */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
        style={{
          width: "100%", // Full width in column layout
          maxWidth: "500px", // Maximum width to constrain the chessboard
          boxSizing: "border-box", // Include padding and border in the element's total width and height
        }}
      >
        <Typography>
          {t("CheckmateTrainer.labels.times")}: <b>{timer.toFixed(1)}s</b>
        </Typography>
        <Typography>
          {t("CheckmateTrainer.labels.moves")}: <b>{moveCount}</b>
        </Typography>
      </Box>

      {/* Chessboard */}
      <Box
        id="chessboard"
        style={{
          width: "100%", // Full width in column layout
          maxWidth: "500px", // Maximum width to constrain the chessboard
          boxSizing: "border-box", // Include padding and border in the element's total width and height
        }}
      >
        <Chessboard
          position={fen}
          onPieceDrop={onDrop}
          customLightSquareStyle={{
            backgroundColor:
              themeColors[userData?.theme || "White Stripe Theme"].lightSquare,
            backgroundImage:
              themeColors[userData?.theme || "White Stripe Theme"]
                .lightSquarePattern,
          }}
          customDarkSquareStyle={{
            backgroundColor:
              themeColors[userData?.theme || "White Stripe Theme"].darkSquare,
            backgroundImage:
              themeColors[userData?.theme || "White Stripe Theme"]
                .darkSquarePattern,
          }}
          customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
          areArrowsAllowed={false}
        />
        {drawMessage && (
          <Typography sx={{ pt: 1 }}>
            {drawMessage === "Draw50" && t("CheckmateTrainer.labels.draw50")}
            {drawMessage === "Draw75" && t("CheckmateTrainer.labels.draw75")}
            {drawMessage === "Checkmate!" &&
              t("CheckmateTrainer.labels.checkmate")}
            {drawMessage === "Stalemate" &&
              t("CheckmateTrainer.labels.stalemate")}
            {drawMessage === "Draw" && t("CheckmateTrainer.labels.draw")}
            {drawMessage === "Stopped" && t("CheckmateTrainer.labels.stopped")}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CheckmateTrainer;
