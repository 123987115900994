import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { formatDate } from "../Components/helper";

import SubjectIcon from "@mui/icons-material/Subject";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import KeyboardOptionKeyIcon from "@mui/icons-material/KeyboardOptionKey";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DataUsageIcon from "@mui/icons-material/DataUsage";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

const mapAccuracyToLabel = (accuracy) => {
  if (accuracy >= 4) return "Excellent";
  if (accuracy >= 2) return "Strong";
  if (accuracy >= -0.5) return "Good";
  return "Needs Work";
};

const accuracyBuckets = {
  "Needs Work": {
    color: "#ef9a9a", // brighter pastel red
    min: -5,
    max: -0.5,
  },
  Good: {
    color: "#ffcc80", // brighter pastel orange
    min: -0.5,
    max: 2,
  },
  Strong: {
    color: "#aed581", // brighter pastel light green
    min: 2,
    max: 4,
  },
  Excellent: {
    color: "#388e3c", // dark green (not pastel)
    min: 4,
    max: 5,
  },
};

const AccuracyBar = ({ accuracy }) => {
  const bucketEntries = Object.entries(accuracyBuckets);
  const totalWidth = bucketEntries.reduce(
    (sum, [, b]) => sum + (b.max - b.min),
    0
  );

  let leftPercent = 0;
  for (const [, bucket] of bucketEntries) {
    if (accuracy >= bucket.max) {
      leftPercent += ((bucket.max - bucket.min) / totalWidth) * 100;
    } else if (accuracy >= bucket.min) {
      const partial =
        ((accuracy - bucket.min) / (bucket.max - bucket.min)) *
        ((bucket.max - bucket.min) / totalWidth) *
        100;
      leftPercent += partial;
      break;
    }
  }

  return (
    <Box sx={{ position: "relative", width: "100%", minWidth: 100 }}>
      <Box
        sx={{
          display: "flex",
          height: 14,
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        {bucketEntries.map(([label, bucket]) => (
          <Tooltip title={label}>
            <Box
              key={label}
              sx={{
                flex: bucket.max - bucket.min,
                backgroundColor: bucket.color,
              }}
            />
          </Tooltip>
        ))}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: -3,
          left: `${leftPercent}%`,
          transform: "translateX(-50%)",
          width: 2,
          height: 20,
          backgroundColor: "black",
          borderRadius: 1,
        }}
      />
    </Box>
  );
};

const TrainingHome = ({ setTrainingMode, repertoires }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getPastDates = (days) => {
    const today = new Date();
    return Array.from({ length: days }, (_, i) => {
      const date = new Date(today - (days - 1 - i) * 86400000);
      return date.toISOString().split("T")[0];
    });
  };

  const getTrainingStats = (repertoires) => {
    const today = new Date();
    const formatDate = (date) => date.toISOString().split("T")[0];
    const getPastDays = (days) =>
      Array.from({ length: days }, (_, i) =>
        formatDate(new Date(today - i * 86400000))
      ).reverse();

    const last10Days = getPastDays(10);

    let totalRepertoires = 0;
    let totalMoves = 0;
    let totalPositionsTrained = 0;
    let totalTrainingCount = 0;
    let totalResponseTime = 0;
    let totalResponseSamples = 0;
    let lastTrainingDate = null;
    let totalAccuracy = 0;
    let totalAccuracySamples = 0;
    const globalTrainingDays = Array(15).fill(false);

    const breakdownByRepertoire = {};

    for (const [repId, repData] of Object.entries(repertoires || {})) {
      totalRepertoires++;
      const moveStats = {
        title: repData.title || "Untitled",
        totalMoves: 0,
        trainedMoves: 0,
        trainingCount: 0,
        avgTime: 0,
        avgAccuracy: null,
        accuracyLabel: "",
        totalResponseTime: 0,
        lastDate: null,
        trainingDays: Array(15).fill(false),
      };

      const allMoves = repData.moves || {};
      for (const [moveId, move] of Object.entries(allMoves)) {
        if (moveId === "root") continue;

        moveStats.totalMoves++;
        totalMoves++;

        const hasAccuracy = move.trainingAccuracy !== undefined;
        const hasTime = move.trainingAvgResponseTime !== undefined;
        const hasCount = move.trainingCount !== undefined;

        if (hasAccuracy) {
          moveStats.trainedMoves++;
          totalPositionsTrained++;

          if (hasCount) {
            moveStats.trainingCount += move.trainingCount;
            totalTrainingCount += move.trainingCount;
          }

          if (hasTime && hasCount) {
            const totalTimeForMove =
              move.trainingAvgResponseTime * move.trainingCount;
            totalResponseTime += totalTimeForMove;
            totalResponseSamples += move.trainingCount;
            moveStats.totalResponseTime += totalTimeForMove;
          }

          totalAccuracy += move.trainingAccuracy;
          totalAccuracySamples++;

          if (move.trainingDate) {
            const moveDateStr = move.trainingDate;
            const moveDate = new Date(moveDateStr);

            // Update local last trained date
            const localLatest = moveStats.lastDate
              ? new Date(moveStats.lastDate)
              : null;
            if (!localLatest || moveDate > localLatest) {
              moveStats.lastDate = moveDateStr;
            }

            // Update global last trained date
            const globalLatest = lastTrainingDate
              ? new Date(lastTrainingDate)
              : null;
            if (!globalLatest || moveDate > globalLatest) {
              lastTrainingDate = moveDateStr;
            }

            // Update training days
            last10Days.forEach((day, index) => {
              if (moveDateStr === day) {
                moveStats.trainingDays[index] = true;
                globalTrainingDays[index] = true;
              }
            });
          }
        }
      }

      moveStats.avgTime =
        moveStats.trainingCount > 0
          ? Number(
              (moveStats.totalResponseTime / moveStats.trainingCount).toFixed(2)
            )
          : 0;

      if (moveStats.trainedMoves > 0) {
        moveStats.avgAccuracy = Number(
          (
            Object.values(allMoves)
              .filter((m) => m.trainingAccuracy !== undefined)
              .reduce((sum, m) => sum + m.trainingAccuracy, 0) /
            moveStats.trainedMoves
          ).toFixed(2)
        );
        moveStats.accuracyLabel = mapAccuracyToLabel(moveStats.avgAccuracy);
      }

      breakdownByRepertoire[repId] = moveStats;
    }
    const avgAccuracy =
      totalAccuracySamples > 0
        ? Number((totalAccuracy / totalAccuracySamples).toFixed(2))
        : null;

    return {
      totalRepertoires,
      totalMoves,
      totalPositionsTrained,
      totalTrainingCount,
      avgMoveTime:
        totalResponseSamples > 0
          ? Number((totalResponseTime / totalResponseSamples).toFixed(2))
          : 0,
      lastTrainingDate,
      avgAccuracy,
      accuracyLabel:
        avgAccuracy !== null ? mapAccuracyToLabel(avgAccuracy) : "N/A",
      trainingDays: globalTrainingDays,
      breakdownByRepertoire,
    };
  };

  const stats = getTrainingStats(repertoires);

  const getAccuracyIcon = (label) => {
    const color = accuracyBuckets[label]?.color || "#ffa726";

    switch (label) {
      case "Excellent":
        return (
          <CheckCircleOutlinedIcon sx={{ color, fontSize: 20, mb: -0.5 }} />
        );
      case "Strong":
        return (
          <ArrowCircleUpOutlinedIcon sx={{ color, fontSize: 18, mb: -0.5 }} />
        );
      case "Needs Work":
        return (
          <ReportProblemOutlinedIcon sx={{ color, fontSize: 20, mb: -0.5 }} />
        );
      case "Good":
        return (
          <ArrowCircleUpOutlinedIcon sx={{ color, fontSize: 18, mb: -0.5 }} />
        );
      default:
        return (
          <RemoveCircleOutlineIcon sx={{ color, fontSize: 18, mb: -0.5 }} />
        );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"} // 🔁 Responsive layout
      justifyContent="space-between"
      gap={2}
      width="100%"
      sx={{ p: 2 }}
    >
      {/* Left Column */}
      <Box flex={1} display="flex" flexDirection="column" gap={2}>
        <Card
          sx={{
            position: "relative", // Required for pseudo-element positioning
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none !important",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
            "::before": {
              content: '""', // Required for pseudo-element
              position: "absolute", // Position relative to the parent
              top: 0, // Align with the top of the parent
              left: 0, // Align with the left of the parent
              width: "100%", // Match the width of the parent
              height: "100%", // Match the height of the parent
              backgroundColor: "transparent", // Transparent background to show the card
              border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
              zIndex: -1, // Place behind the card content
              pointerEvents: "none", // Ensure the outline does not interfere with interactions
            },
            p: 1, // Padding for the card content
          }}
        >
          <CardHeader
            avatar={
              <Box sx={{ display: "flex", alignItems: "center", m: 0 }}>
                <ControlCameraIcon />
              </Box>
            }
            title={
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Position Trainer
              </Typography>
            }
            sx={{
              pt: 1,
              pb: 1,
              "& .MuiCardHeader-avatar": {
                mr: 1, // Remove the default margin
              },
            }}
          />
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Focus on individual positions from your repertoire. You'll be
              shown a specific point and asked to find the correct move, helping
              reinforce specific responses and improve precision.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setTrainingMode("positionTrainer")}
              sx={{
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
            >
              Start Training
            </Button>
          </CardContent>
        </Card>

        <Card
          sx={{
            position: "relative", // Required for pseudo-element positioning
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none !important",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
            "::before": {
              content: '""', // Required for pseudo-element
              position: "absolute", // Position relative to the parent
              top: 0, // Align with the top of the parent
              left: 0, // Align with the left of the parent
              width: "100%", // Match the width of the parent
              height: "100%", // Match the height of the parent
              backgroundColor: "transparent", // Transparent background to show the card
              border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
              zIndex: -1, // Place behind the card content
              pointerEvents: "none", // Ensure the outline does not interfere with interactions
            },
            p: 1, // Padding for the card content
          }}
        >
          <CardHeader
            avatar={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <KeyboardOptionKeyIcon />
              </Box>
            }
            title={
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Repertoire Trainer
              </Typography>
            }
            sx={{
              pt: 1,
              pb: 1,
              "& .MuiCardHeader-avatar": {
                mr: 1, // Remove the default margin
              },
            }}
          />
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Practice full lines from your repertoire by playing through moves
              as if in a real game. The trainer plays your opponent's moves and
              challenges you to follow your preparation accurately.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setTrainingMode("repertoireTrainer")}
              sx={{
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
            >
              Start Training
            </Button>
          </CardContent>
        </Card>
        <Card
          sx={{
            position: "relative", // Required for pseudo-element positioning
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none !important",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
            "::before": {
              content: '""', // Required for pseudo-element
              position: "absolute", // Position relative to the parent
              top: 0, // Align with the top of the parent
              left: 0, // Align with the left of the parent
              width: "100%", // Match the width of the parent
              height: "100%", // Match the height of the parent
              backgroundColor: "transparent", // Transparent background to show the card
              border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
              zIndex: -1, // Place behind the card content
              pointerEvents: "none", // Ensure the outline does not interfere with interactions
            },
            p: 1, // Padding for the card content
          }}
        >
          <CardHeader
            avatar={
              <Box sx={{ display: "flex", alignItems: "center", m: 0 }}>
                <WarningAmberIcon />
              </Box>
            }
            title={
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Weakness Trainer
              </Typography>
            }
            sx={{
              pt: 1,
              pb: 1,
              "& .MuiCardHeader-avatar": {
                mr: 1, // Remove the default margin
              },
            }}
          />
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Focus on the positions you've gotten wrong while training your
              repertoire. This mode highlights your weakest positions based on
              past performance, helping you strengthen them through targeted
              repetition.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setTrainingMode("weaknessTrainer")}
              sx={{
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
            >
              Start Training
            </Button>
          </CardContent>
        </Card>
      </Box>

      {/* Center Column */}
      <Box flex={2}>
        <Card
          sx={{
            position: "relative", // Required for pseudo-element positioning
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none !important",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
            "::before": {
              content: '""', // Required for pseudo-element
              position: "absolute", // Position relative to the parent
              top: 0, // Align with the top of the parent
              left: 0, // Align with the left of the parent
              width: "100%", // Match the width of the parent
              height: "100%", // Match the height of the parent
              backgroundColor: "transparent", // Transparent background to show the card
              border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
              zIndex: -1, // Place behind the card content
              pointerEvents: "none", // Ensure the outline does not interfere with interactions
            },
            p: 1, // Padding for the card content
            mb: 2,
          }}
        >
          <CardHeader
            avatar={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DataUsageIcon />
              </Box>
            }
            title={
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                {stats.totalRepertoires} Repertoire Statistics
              </Typography>
            }
            sx={{
              pt: 1,
              pb: 1,
              "& .MuiCardHeader-avatar": {
                mr: 1, // Remove the default margin
              },
            }}
          />
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                p: 0,
              }}
            >
              {/* 25% width on desktop, full width on mobile */}
              {[
                {
                  label: "Total Moves",
                  value: stats.totalMoves.toLocaleString(),
                },
                {
                  label: "Positions Trained",
                  value: stats.totalPositionsTrained.toLocaleString(),
                },
                {
                  label: "Repetitions",
                  value: stats.totalTrainingCount.toLocaleString(),
                },
                {
                  label: "Avg. Move Time",
                  value: `${(stats.avgMoveTime / 1000).toFixed(1)}s`,
                },
                {
                  label: "Accuracy",
                  value: `${stats.accuracyLabel || ""}s`,
                  avgAccuracy: `${stats.avgAccuracy}`,
                },
                {
                  label: "Last Trained",
                  value: stats.lastTrainingDate
                    ? formatDate(stats.lastTrainingDate)
                    : "N/A",
                },
              ].map((item, index) => (
                <Box sx={{ flex: isMobile ? "100%" : "32%", p: 0 }}>
                  <Typography variant="body2">
                    <strong>{item.label}:</strong> {item.value}{" "}
                    {item.label === "Accuracy"
                      ? getAccuracyIcon(item.label)
                      : ""}
                    {item.label === "Accuracy" ? (
                      <Box
                        sx={{
                          flex: isMobile ? "100%" : "32%",
                          display: "flex",
                          maxWidth: "200px",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center", // or "flex-start" if you want left-aligned
                          p: 0,
                          mt: 0.5,
                        }}
                      >
                        <AccuracyBar accuracy={item.avgAccuracy} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {item.label === "Last Trained" ? (
                      <Box
                        sx={{
                          flex: isMobile ? "100%" : "32%",
                          display: "flex",
                          flexDirection: "row",
                          gap: 0.5,
                          overflowX: "auto",
                          flexWrap: "nowrap",
                          mt: 0.5,
                        }}
                      >
                        {getPastDates(10).map((date, index) => (
                          <Tooltip key={`allrepo-${index}`} title={date}>
                            <Box
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: "2px",
                                backgroundColor: stats.trainingDays[index]
                                  ? colors.green[500]
                                  : "#ccc",
                                border: "1px solid rgba(0,0,0,0.2)",
                              }}
                            />
                          </Tooltip>
                        ))}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>

        <Card
          sx={{
            position: "relative", // Required for pseudo-element positioning
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none !important",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
            "::before": {
              content: '""', // Required for pseudo-element
              position: "absolute", // Position relative to the parent
              top: 0, // Align with the top of the parent
              left: 0, // Align with the left of the parent
              width: "100%", // Match the width of the parent
              height: "100%", // Match the height of the parent
              backgroundColor: "transparent", // Transparent background to show the card
              border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
              zIndex: -1, // Place behind the card content
              pointerEvents: "none", // Ensure the outline does not interfere with interactions
            },
            p: 1, // Padding for the card content
          }}
        >
          <CardHeader
            avatar={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SubjectIcon />
              </Box>
            }
            title={
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Repertoire Breakdown
              </Typography>
            }
            sx={{
              pt: 1,
              pb: 1,
              "& .MuiCardHeader-avatar": {
                mr: 1, // Remove the default margin
              },
            }}
          />
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent>
            {Object.entries(stats.breakdownByRepertoire || {})
              .sort(([, a], [, b]) => {
                // Sort untrained ones to the bottom
                if (!a.trainingCount && !b.trainingCount) return 0;
                if (!a.trainingCount) return 1;
                if (!b.trainingCount) return -1;

                // Then sort by trainingAccuracy (lower first = needs more work)
                return (a.trainingAccuracy ?? 0) - (b.trainingAccuracy ?? 0);
              })
              .map(([repId, repStats], index, array) => (
                <Box key={repId} sx={{ mb: 2 }}>
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    {repStats.title}{" "}
                    {repStats.trainingCount > 0 ? (
                      ""
                    ) : (
                      <Typography display="inline" sx={{ color: "#ccc" }}>
                        {" "}
                        Untrained
                      </Typography>
                    )}
                  </Typography>

                  {repStats.trainingCount > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        mb: 1,
                        mt: 1,
                      }}
                    >
                      {[
                        {
                          label: "Total Moves",
                          value: repStats.totalMoves.toLocaleString(),
                        },
                        {
                          label: "Positions Trained",
                          value: repStats.trainedMoves.toLocaleString(),
                        },
                        {
                          label: "Repetitions",
                          value: repStats.trainingCount.toLocaleString(),
                        },
                        {
                          label: "Avg. Move Time",
                          value: `${(repStats.avgTime / 1000).toFixed(1)}s`,
                        },
                        {
                          label: "Accuracy",
                          value: `${repStats.accuracyLabel}`,
                          avgAccuracy: `${repStats.avgAccuracy}`,
                        },
                        {
                          label: "Last Trained",
                          value: repStats.lastDate
                            ? formatDate(repStats.lastDate)
                            : "N/A",
                        },
                      ].map((item, i) => (
                        <Box
                          key={i}
                          sx={{
                            flex: isMobile ? "100%" : "32%",
                          }}
                        >
                          <Typography variant="body2">
                            <strong>{item.label}:</strong> {item.value}{" "}
                            {item.label === "Accuracy"
                              ? getAccuracyIcon(item.label)
                              : ""}
                            {item.label === "Accuracy" ? (
                              <Box
                                sx={{
                                  flex: isMobile ? "100%" : "32%",
                                  display: "flex",
                                  maxWidth: "200px",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center", // or "flex-start" if you want left-aligned
                                  p: 0,
                                  mt: 0.5,
                                }}
                              >
                                <AccuracyBar accuracy={item.avgAccuracy} />
                              </Box>
                            ) : (
                              ""
                            )}
                            {item.label === "Last Trained" ? (
                              <Box
                                sx={{
                                  flex: isMobile ? "100%" : "calc(25% - 8px)",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 0.5,
                                  overflowX: "auto",
                                  flexWrap: "nowrap",
                                  mt: 0.5,
                                }}
                              >
                                {getPastDates(10).map((date, i) => (
                                  <Tooltip
                                    key={`${
                                      repStats.id || repStats.title
                                    }-${i}`}
                                    title={date}
                                  >
                                    <Box
                                      sx={{
                                        width: 16,
                                        height: 16,
                                        borderRadius: "2px",
                                        backgroundColor: repStats
                                          .trainingDays?.[i]
                                          ? colors.green[500]
                                          : "#ccc",
                                        border: "1px solid rgba(0,0,0,0.2)",
                                      }}
                                    />
                                  </Tooltip>
                                ))}
                              </Box>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}

                  {index !== array.length - 1 && (
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  )}
                </Box>
              ))}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default TrainingHome;
