import { useTheme } from "@mui/material";
import { tokens } from "../../../../styles/theme"; // Ensure tokens is imported correctly

const useLevelColours = () => {
  const theme = useTheme(); // ✅ Now inside the hook
  const colors = tokens(theme.palette.mode);

  // Define the refined color levels
  const levelColors = [
    colors.black[900], // Level 0 (White/Neutral)
    "#3FA7FF", // Sharper Light Blue
    "#4CAF50", // Richer Green
    "#FFC107", // Deep Gold
    "#FF69B4", // Stronger Pink (Hot Pink)
    "#FF7043", // Vivid Salmon
    "#C653C6", // Deeper Orchid
    "#00CED1", // More Striking Turquoise
    "#FF4500", // Bold Tomato (Red-Orange)
    "#7B1FA2", // Deepened Blue Violet
  ];

  return levelColors;
};

export default useLevelColours;
