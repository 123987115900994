import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  List,
  ListItem,
  IconButton,
  Divider,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import DoNotDisturbSharpIcon from "@mui/icons-material/DoNotDisturbSharp";

const TaskTracker = ({ tasks, setTasks }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Ensure tasks is always an array
  if (!Array.isArray(tasks) || tasks.length === 0) return null;

  const cancelTask = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, status: "canceled" } : task
      )
    );
  };

  const clearCompleted = () => {
    setTasks((prevTasks) =>
      prevTasks.filter(
        (task) => task.status !== "completed" && task.status !== "canceled"
      )
    );
  };

  return (
    <Card
      sx={{
        position: "fixed",
        bottom: 15,
        right: 15,
        width: 350,
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none !important",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        sx={{ pt: 1, pb: 1, pl: 1 }}
        title={
          <Typography
            variant="h7"
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              pl: 0,
            }}
          >
            {tasks.some((task) => task.status === "in-progress") && (
              <Box
                sx={{
                  width: "30px",
                  display: "flex",
                  justifyContent: "center",
                  mr: 1,
                }}
              >
                <CircularProgress size={25} sx={{ color: colors.green[500] }} />
              </Box>
            )}
            {tasks.filter((task) => task.status === "in-progress").length}{" "}
            running tasks
          </Typography>
        }
        action={
          <IconButton onClick={() => setIsMinimized(!isMinimized)}>
            {isMinimized ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      {!isMinimized && (
        <>
          <Divider />
          <CardContent
            sx={{ p: 1, "&:last-child": { paddingBottom: "0 !important" } }}
          >
            <List sx={{ p: 0 }}>
              {[...tasks]
                .sort((a, b) => {
                  const order = { "in-progress": 0, completed: 1, canceled: 2 };
                  return order[a.status] - order[b.status];
                })
                .map((task, index) => (
                  <ListItem
                    key={`${task.id}-${index}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      pl: 0,
                    }}
                  >
                    {/* Left Side - Status Icon */}
                    {task.status === "canceled" ? (
                      <Box
                        sx={{
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          mr: 1,
                        }}
                      >
                        <DoNotDisturbSharpIcon
                          sx={{ fontSize: 30, color: colors.red[500] }}
                        />
                      </Box>
                    ) : task.status === "completed" ? (
                      <Box
                        sx={{
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          mr: 1,
                        }}
                      >
                        <CheckCircleOutlineSharpIcon
                          sx={{ fontSize: 30, color: colors.green[500] }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          mr: 1,
                        }}
                      >
                        <CircularProgress
                          size={25}
                          sx={{ color: colors.green[500] }}
                        />
                      </Box>
                    )}

                    {/* Center - Title & Description (Stacked) */}
                    <div style={{ flexGrow: 1 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {task.description}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {task.status === "in-progress"
                          ? `Analyzing ${task.progress}/${task.progressTarget} moves`
                          : task.status === "canceled"
                          ? "Task was canceled"
                          : "Task completed"}
                      </Typography>
                    </div>

                    {/* Right Side - Cancel Button */}
                    {task.status === "in-progress" && (
                      <Button
                        onClick={() => cancelTask(task.id)}
                        sx={{ textTransform: "none" }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontSize: "0.8em" }}
                        >
                          Cancel
                        </Typography>
                      </Button>
                    )}
                  </ListItem>
                ))}
            </List>
            {tasks.some(
              (task) =>
                task.status === "completed" || task.status === "canceled"
            ) && (
              <Typography
                onClick={clearCompleted}
                variant="body2"
                align="center"
                sx={{ py: 1, cursor: "pointer" }}
              >
                Clear Completed
              </Typography>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default TaskTracker;
