import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";

const EvaluationLineChart = ({ openingData = [], colorFilter, moveRange }) => {
  const filteredLineData = useMemo(() => {
    if (!openingData || openingData.length === 0) {
      return [];
    }

    const filtered = openingData
      .filter(
        (line) =>
          line.numberOfMoves >= moveRange[0] &&
          line.numberOfMoves <= moveRange[1]
      )
      .filter((d) => d.color === colorFilter);

    return filtered;
  }, [openingData, colorFilter, moveRange]);

  const processedData = useMemo(() => {
    if (filteredLineData.length === 0) {
      return [];
    }

    const evalMap = new Map();

    filteredLineData.forEach((entry) => {
      const moveNumber = entry.numberOfMoves;
      const evalValue = entry.evaluation?.stockEval ?? 0;
      const color = entry.color;

      const key = `${moveNumber}-${color}`;

      if (!evalMap.has(key)) {
        evalMap.set(key, { evals: [], color });
      }

      evalMap.get(key).evals.push(evalValue);
    });

    const mappedData = Array.from(evalMap.entries()).map(
      ([key, { evals, color }]) => {
        const moveNumber = key.split("-")[0];
        const minEval = Math.min(...evals).toFixed(2);
        const maxEval = Math.max(...evals).toFixed(2);
        const avgEval = (
          evals.reduce((sum, val) => sum + val, 0) / evals.length
        ).toFixed(2);

        return {
          move: `${moveNumber}`,
          min: minEval,
          max: maxEval,
          avg: avgEval,
          color,
        };
      }
    );

    return mappedData;
  }, [filteredLineData]);

  const formattedData = useMemo(() => {
    const formatted = [
      {
        id: "Min Eval",
        color: "red",
        data: processedData.map((d) => ({ x: d.move, y: d.min })),
      },
      {
        id: "Max Eval",
        color: "green",
        data: processedData.map((d) => ({ x: d.move, y: d.max })),
      },
      {
        id: "Avg Eval",
        color: "blue",
        data: processedData.map((d) => ({ x: d.move, y: d.avg })),
      },
    ];
    return formatted;
  }, [processedData]);

  if (formattedData.length === 0) return null;

  return (
    <Box sx={{ p: 2, height: "450px", backgroundColor: "rgba(0, 0, 0, 1)" }}>
      <ResponsiveLine
        data={formattedData}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: "auto", max: "auto", stacked: false }}
        margin={{ top: 40, right: 140, bottom: 40, left: 40 }}
        axisTop={null}
        axisRight={null}
        curve="monotoneX"
        colors={{ scheme: "tableau10" }}
        axisBottom={{
          tickValues: "every 1",
          orient: "bottom",
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: "middle",
        }}
        enableGridX={true}
        enableGridY={true}
        pointSize={4}
        pointColor={{ theme: "background" }}
        pointBorderWidth={4}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 130,
            translateY: -10,
            itemsSpacing: 40,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 0,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
          },
        ]}
        tooltip={({ point }) => (
          <div
            style={{
              background: "black",
              color: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
              borderRadius: "2px",
              boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
            }}
          >
            <strong>{point.serieId}</strong>
            <br />
            Move: <strong>{point.data.xFormatted}</strong>
            <br />
            Evaluation: <strong>{point.data.yFormatted}</strong>
            <br />
          </div>
        )}
        theme={{
          grid: {
            line: {
              stroke: "rgba(200, 200, 200, 0.3)",
              strokeWidth: 0.25,
            },
          },
        }}
      />
    </Box>
  );
};

export default EvaluationLineChart;
