import React from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";

const RepertoireBuilderLogin = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
        <img
          src={`${process.env.PUBLIC_URL}/img/logo-transparent${
            theme.palette.mode === "dark" ? "" : "-black"
          }.png`}
          alt="Chessboard Magic"
          style={{ maxWidth: "50px" }}
        />
        <Typography variant="h5" component="div">
          <strong>Repertoire Builder (Alpha Release)</strong>!
        </Typography>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Typography variant="body1" sx={{ mb: 1 }} gutterBottom>
        You're about to access the Repertoire Builder - a focused tool designed
        to help you create, refine, and train your personalized chess openings.
        Whether you’re just starting out or looking to optimize an existing
        repertoire, this tool gives you everything you need in one place.
      </Typography>

      <Typography variant="body1" sx={{ mb: 1 }} gutterBottom>
        <strong>Features include:</strong>
      </Typography>

      <ul style={{ paddingLeft: "1.2rem", marginTop: 0 }}>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Create and manage multiple repertoires</strong> tailored to
            different colors, playing styles, training goals, or tournament
            preparation - all organized in one easy-to-use interface
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Import your existing repertoire or preparation</strong> from
            PGN files or Lichess Studies to start building without starting from
            scratch
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Repertoire Templates</strong> to kickstart your study with
            pre-built opening lines, with hundreds of lines and variations
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Repertoire Wizard</strong> - instantly generate a complete
            opening tree from any custom position and explore fresh ideas in
            seconds
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Import your games</strong> and automatically compare them to
            your repertoire - spot deviations, uncover patterns, and turn
            mistakes into improvement
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            ...plus training tools, Stockfish analysis, master games, export
            options, and more!
          </Typography>
        </li>
      </ul>

      <Typography variant="body1">
        Build with purpose, train with clarity — your openings start here.
      </Typography>
    </Box>
  );
};

export default RepertoireBuilderLogin;
