import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  Stack,
  Tabs,
  Tab,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { Chess } from "chess.js";
import Dropzone from "react-dropzone";
import { addRepertoire } from "../../../../config/firebaseDB";
import { ref, set } from "firebase/database";
import { rt } from "../../../../config/firebase";
import { v4 as uuidv4 } from "uuid"; // Generate unique IDs

import UploadSharpIcon from "@mui/icons-material/UploadSharp";

import useDialogFocused from "./useDialogFocused";
import PreviewMovesCard from "./previewMovesCard";
import { useAlert } from "../../../../context/AlertProvider";

const ImportPGNDialog = ({
  open,
  onClose,
  setRepertoires,
  setSelectedRepertoireId,
  setMoves,
  setSelectedMoveId,
  userData,
  settings,
  totalRepertoires,
  setTotalRepertoires,
  totalMoves,
  setTotalMoves,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [pgnText, setPgnText] = useState("");
  const [filename, setFilename] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const showAlert = useAlert();

  const userId = userData?.uid || "";
  const username = userData?.username || "Unknown";

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const colorMap = {
    R: "shift", // Red
    G: "ctrl", // Green
    Y: "alt", // Yellow
    B: "default", // Blue
  };

  // Import from Lichess
  const [lichessUsername, setLichessUsername] = useState("");
  const [lichessStudies, setLichessStudies] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedStudy, setSelectedStudy] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [loadingChapters, setLoadingChapters] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState(null);

  const fetchLichessStudies = async () => {
    if (!lichessUsername.trim()) {
      showAlert("Please enter a Lichess username.", "info");
      return;
    }

    setLoading(true);
    setLichessStudies([]);
    setChapters([]);
    setSelectedChapter(null);
    setPgnText("");

    try {
      const response = await fetch(
        `https://lichess.org/api/study/by/${lichessUsername}`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const textResponse = await response.text(); // Read raw response as text
      const studies = textResponse
        .trim()
        .split("\n") // Split by newlines
        .map((line) => JSON.parse(line)); // Parse each line as a JSON object

      setLichessStudies(studies);
    } catch (error) {
      showAlert(
        "Failed to fetch studies. Check the username and try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchStudyChapters = async (study) => {
    setLoadingChapters(true);
    setChapters([]);

    try {
      const response = await fetch(
        `https://lichess.org/api/study/${study.id}.pgn?clocks=false`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const pgnText = await response.text();

      // Split PGN data into separate chapters based on empty lines before [Event]
      const chapterList = pgnText
        .split(/\n\n(?=\[Event )/)
        .map((pgn, index) => {
          const chapterNameMatch = pgn.match(/\[ChapterName "(.*?)"\]/);
          return {
            id: index,
            title: chapterNameMatch
              ? chapterNameMatch[1]
              : `Chapter ${index + 1}`,
            pgn: pgn.trim(),
          };
        });

      setSelectedStudy({ id: study.id, name: study.name });
      setChapters(chapterList);
    } catch (error) {
      showAlert("Failed to fetch chapters.", "error");
    } finally {
      setLoadingChapters(false);
    }
  };

  /**
   * 🎯 Parses [%cal ...] into a structured array of arrows
   * @param {string} calString - The raw [%cal ...] annotation
   * @returns {Array} - Array of formatted arrows
   */
  const formatArrows = (calString) => {
    if (!calString.startsWith("[%cal")) return [];

    const arrowAnnotationsRaw = calString
      .replace("[%cal", "")
      .replace("]", "")
      .trim();

    const arrowAnnotations = arrowAnnotationsRaw.includes(",")
      ? arrowAnnotationsRaw.split(",")
      : [arrowAnnotationsRaw];

    return arrowAnnotations
      .map((arrow, index) => {
        if (arrow.length < 5) {
          return null;
        }

        const colorCode = arrow[0]; // First character is the color
        const startSquare = arrow.slice(1, 3); // Next two chars are start square
        const endSquare = arrow.slice(3, 5); // Next two chars are end square

        return [startSquare, endSquare, colorMap[colorCode] || "default"];
      })
      .filter(Boolean); // Remove any null values
  };

  /**
   * 🔳 Parses [%csl ...] into a structured array of highlighted squares
   * @param {string} cslString - The raw [%csl ...] annotation
   * @returns {Array} - Array of formatted highlight squares
   */
  const formatHighlights = (cslString) => {
    if (!cslString.startsWith("[%csl")) return [];

    const highlightAnnotationsRaw = cslString
      .replace("[%csl", "")
      .replace("]", "")
      .trim();

    const highlightAnnotations = highlightAnnotationsRaw.includes(",")
      ? highlightAnnotationsRaw.split(",")
      : [highlightAnnotationsRaw];

    return highlightAnnotations
      .map((highlight, index) => {
        if (highlight.length < 3) {
          return null;
        }

        const colorCode = highlight[0]; // First character is the color
        const square = highlight.slice(1, 3); // Next two chars are the square

        return [square, colorMap[colorCode] || "default"];
      })
      .filter(Boolean); // Remove any null values
  };

  const processPGN = (pgn) => {
    let title = "Imported Repertoire";
    let description = "Generated from PGN import";

    if (tabIndex === 1 && filename.trim() !== "") {
      title = filename.replace(/\.pgn$/, "");
      description = `This repertoire was imported from the file ${filename}.`;
    } else if (tabIndex === 0) {
      title = "Pasted PGN";
      description = "This repertoire was created from a pasted PGN.";
    } else if (tabIndex === 2) {
      title = selectedChapter?.title;
      description = "Imported from Lichess.";
    }

    pgn = pgn.replace(/\[(?!%cal|%csl)[^\]]*\]\s*/g, ""); // Remove metadata, but keep [%cal] and [%csl]

    let commentMap = {};
    let commentIndex = 0;

    // ✅ Extract and group comments by move
    pgn = pgn.replace(/\{([^{}]*)\}/g, (match, comment) => {
      let key = `COMMENT_${commentIndex++}`;
      if (!commentMap[key]) {
        commentMap[key] = [];
      }
      commentMap[key].push(comment.trim()); // Store all comments as an array
      return key; // Replace comment with a reference key
    });

    pgn = pgn.replace(/\$\d+/g, ""); // Remove NAGs
    pgn = pgn.replace(/\d+\.\s*/g, ""); // Remove move numbers
    pgn = pgn.replace(/[^a-zA-Z0-9+#\-()_ \s]/g, ""); // Remove unwanted characters

    const tokens = pgn
      .replace(/([()])/g, " $1 ")
      .trim()
      .split(/\s+/)
      .filter(Boolean);

    // ✅ Build move tree with multiple comments, arrows & highlights
    const buildMoveTree = (moves, index = 0, parent = null) => {
      let moveTree = {};
      let currentMoveId = parent || "root";
      let lastMainlineMove = parent;
      let variationParent = parent;
      let lastMoveId = null; // Track the last processed move
      let accumulatedComments = []; // Collect comments for the last move

      while (index < moves.length) {
        let move = moves[index];

        if (move === "(") {
          let depth = 1;
          let variationMoves = [];
          index++;

          while (index < moves.length && depth > 0) {
            if (moves[index] === "(") depth++;
            else if (moves[index] === ")") depth--;

            if (depth > 0) variationMoves.push(moves[index]);
            index++;
          }

          if (variationMoves.length > 0) {
            let variationTree = buildMoveTree(
              variationMoves,
              0,
              variationParent
            );
            let variationRootId = Object.keys(variationTree)[0];

            if (variationRootId && moveTree[lastMainlineMove]) {
              moveTree[lastMainlineMove].variations.push(variationRootId);
            }

            moveTree = { ...moveTree, ...variationTree };
          }
          continue;
        }

        if (move.startsWith("COMMENT_")) {
          if (commentMap[move]) {
            accumulatedComments.push(...commentMap[move]); // Store comments for the next move
          }
          index++;
          continue;
        }

        if (!move.trim() || move === "..") {
          index++;
          continue;
        }

        // ✅ If there's a last move, assign its comments *before creating the next move*
        if (lastMoveId && accumulatedComments.length > 0) {
          let firstComment = null;
          let arrowsData = null;
          let highlightsData = null;

          accumulatedComments.forEach((comment) => {
            // Check if the comment contains [%cal] (arrows)
            const calMatch = comment.match(/\[%cal[^\]]*\]/);
            if (calMatch) {
              arrowsData = formatArrows(calMatch[0]); // Convert to structured data
              comment = comment.replace(calMatch[0], "").trim(); // Remove [%cal] from comment
            }

            // Check if the comment contains [%csl] (highlight squares)
            const cslMatch = comment.match(/\[%csl[^\]]*\]/);
            if (cslMatch) {
              highlightsData = formatHighlights(cslMatch[0]); // Convert to structured data
              comment = comment.replace(cslMatch[0], "").trim(); // Remove [%csl] from comment
            }

            // If it's pure text (not empty after cleanup), treat it as a regular comment
            if (comment.length > 0) {
              firstComment = firstComment
                ? firstComment + " " + comment
                : comment;
            }
          });

          // Assign processed values to moveTree
          moveTree[lastMoveId].comment = firstComment || null;
          moveTree[lastMoveId].arrows = arrowsData || null;
          moveTree[lastMoveId].highlightSquares = highlightsData || null;

          // Reset for the next move
          accumulatedComments = [];
        }

        const moveId = uuidv4();
        let moveData = {
          id: moveId,
          san: move,
          next: null,
          variations: [],
          parent: currentMoveId,
          comments: null, // Will be set before processing next move
          arrows: null,
          highlightSquares: [],
        };

        if (currentMoveId !== "root" && moveTree[currentMoveId]) {
          moveTree[currentMoveId].next = moveId;
        }

        moveTree[moveId] = moveData;
        lastMoveId = moveId; // Update last processed move
        variationParent = lastMainlineMove;
        currentMoveId = moveId;
        lastMainlineMove = moveId;

        index++;
      }

      // ✅ Assign any remaining comments to the last move
      if (lastMoveId && accumulatedComments.length > 0) {
        let firstComment = null;
        let arrowsData = null;
        let highlightsData = null;

        accumulatedComments.forEach((comment) => {
          // Check if the comment contains [%cal] (arrows)
          const calMatch = comment.match(/\[%cal[^\]]*\]/);
          if (calMatch) {
            arrowsData = formatArrows(calMatch[0]); // Convert to structured data
            comment = comment.replace(calMatch[0], "").trim(); // Remove [%cal] from comment
          }

          // Check if the comment contains [%csl] (highlight squares)
          const cslMatch = comment.match(/\[%csl[^\]]*\]/);
          if (cslMatch) {
            highlightsData = formatHighlights(cslMatch[0]); // Convert to structured data
            comment = comment.replace(cslMatch[0], "").trim(); // Remove [%csl] from comment
          }

          // If it's pure text (not empty after cleanup), treat it as a regular comment
          if (comment.length > 0) {
            firstComment = firstComment
              ? firstComment + " " + comment
              : comment;
          }
        });

        // Assign processed values to moveTree
        moveTree[lastMoveId].comment = firstComment || null;
        moveTree[lastMoveId].arrows = arrowsData || null;
        moveTree[lastMoveId].highlightSquares = highlightsData || null;
      }

      return moveTree;
    };

    const moves = buildMoveTree(tokens);

    return {
      id: uuidv4(),
      title: title,
      description: description,
      author: username,
      boardOrientation: "white",
      visibility: "Private",
      allowCopy: "No",
      createdAt: Date.now(),
      order: -9999,
      moves: {
        root: {
          san: "",
          next: Object.keys(moves)[0] || null,
          variations: [],
          parent: null,
        },
        ...moves,
      },
    };
  };

  /** Handle Tab Change */
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  /** Handle File Drop */
  const handleFileDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      if (file.size > 1048576) {
        showAlert(
          "File size exceeds the 1MB limit. Please upload a smaller file.",
          "error"
        );
        return;
      }

      setSelectedFile(file);

      // Read file content
      const reader = new FileReader();
      reader.onload = (e) => {
        setPgnText(e.target.result);
        setFilename(file.name);
      };
      reader.readAsText(file);
    }
  };

  /** Handle PGN Import */
  const handleImport = async () => {
    if (totalRepertoires >= settings.limitRepertoires) {
      showAlert(
        `Maximum Number of Repertoires Exceeded (${settings.limitRepertoires}). Please upgrade to have more repertoires.`,
        "error"
      );
      return;
    }

    if (tabIndex === 0) {
      if (!pgnText.trim()) {
        showAlert(
          "Invalid PGN format. Please paste a valid PGN text.",
          "error"
        );
        return;
      }

      const eventCount = (pgnText.match(/\[Event\s+"/g) || []).length;
      if (eventCount > 1) {
        showAlert(
          "Multiple PGNs detected. Please paste only one game.",
          "error"
        );
        return;
      }
    }

    if (tabIndex === 1) {
      if (!pgnText.trim()) {
        showAlert("Please upload a valid PGN file.", "error");
        return;
      }

      const eventCount = (pgnText.match(/\[Event\s+"/g) || []).length;
      if (eventCount > 1) {
        showAlert(
          "Multiple games detected in the PGN file. Please upload a file containing only one game.",
          "error"
        );
        return;
      }
    }

    if (tabIndex === 2 && !pgnText.trim()) {
      showAlert("Please select a study and chapter.", "error");
      return;
    }

    try {
      const chess = new Chess();
      let formattedPGN = pgnText.trim();

      // Attempt to load PGN into chess.js
      chess.loadPgn(formattedPGN);

      // Process PGN into a structured repertoire
      const newRepertoire = processPGN(formattedPGN);

      const moves = newRepertoire.moves;
      const moveCount = Object.keys(moves).length;

      if (totalMoves + moveCount >= settings.limitMoves) {
        showAlert(
          `Importing this repertoire would exceed the total allowed moves (${settings.limitMoves}). Please upgrade to proceed.`,
          "error"
        );
        return;
      }

      // Save to Firebase
      const newRepertoireId = newRepertoire.id;
      await addRepertoire(userId, newRepertoire);

      await set(
        ref(rt, `users/${userId}/repertoires/${newRepertoireId}`),
        newRepertoire
      );

      // Update State: Add repertoire, set as selected, set moves
      setRepertoires((prev) => ({
        ...prev,
        [newRepertoireId]: newRepertoire,
      }));

      setSelectedRepertoireId(newRepertoireId);
      setMoves(newRepertoire.moves);
      setSelectedMoveId("root");

      setTotalRepertoires((prevCount) => prevCount + 1);
      setTotalMoves((prevCount) => prevCount + moveCount);

      onClose(); // Close the dialog
    } catch (error) {
      if (error.message.includes("Invalid PGN")) {
        showAlert(
          "PGN Parsing Error: The provided PGN is not valid. Please check the format.",
          "error"
        );
      } else if (error.message.includes("set failed")) {
        showAlert("Database Error: Failed to save the repertoire.", "error");
      } else {
        showAlert(`Unexpected Error: ${error.message}`, "error");
      }
    }
  };

  return (
    <Dialog
      sx={{
        backgroundImage: "none !important",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900], // Text color
          border: `0.5px solid #222222`, // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {useDialogFocused(open)}
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <UploadSharpIcon />
          <Typography variant="h6">Import Repertoire</Typography>
        </Stack>
      </DialogTitle>

      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />
      <DialogContent>
        <Tabs
          sx={{
            height: "20px",
            "& .MuiTab-root": {
              color: colors.black[900], // Text color for unselected tabs
              transition: "color 0.3s, border-color 0.3s", // Smooth transition for hover/focus effects
              minWidth: "50px", // Set tab width
              textAlign: "center", // Center align text or icon
              "&:hover": {
                color: colors.black[700], // Slightly lighter on hover
              },
            },
            "& .Mui-selected": {
              color: colors.black[900], // ✅ Fix: Sets active tab text color
              fontWeight: "bold", // ✅ Optional: Make selected text bold
            },
            "& .MuiTab-root.Mui-selected": {
              color: colors.black[900], // ✅ Ensure selected tab text color overrides MUI default
            },
            "& .MuiTabs-indicator": {
              backgroundColor: colors.green[900], // ✅ Active tab underline color
              height: "1.5px",
            },
            mt: 1,
            mb: 1,
          }}
          value={tabIndex}
          onChange={handleTabChange}
        >
          <Tab label="Paste PGN" />
          <Tab label="PGN File" />
          <Tab label="Lichess Study" />
        </Tabs>

        {tabIndex === 0 && (
          <TextField
            label="Paste PGN"
            multiline
            rows={6}
            fullWidth
            margin="normal"
            value={pgnText}
            onChange={(e) => setPgnText(e.target.value)}
          />
        )}

        {tabIndex === 1 && (
          <Dropzone
            onDrop={handleFileDrop}
            accept={{
              "application/x-chess-pgn": [".pgn"], // Specify both MIME type and file extension
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #ccc",
                  padding: "20px",
                  textAlign: "center",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                {selectedFile ? (
                  <p>{selectedFile.name}</p>
                ) : (
                  <p>Drag & drop a PGN file here, or click to select a file</p>
                )}
              </div>
            )}
          </Dropzone>
        )}

        {tabIndex === 2 && (
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={2}
            sx={{ mt: 2, height: isMobile ? "auto" : "400px" }}
          >
            <Stack
              sx={{
                width: "100%",
                padding: "8px",
                gap: 1,
              }}
              spacing={2}
              direction="column"
            >
              <Stack direction="row" spacing={1}>
                <TextField
                  label="Lichess Username"
                  fullWidth
                  value={lichessUsername}
                  onChange={(e) => setLichessUsername(e.target.value)}
                  autoComplete="off"
                />

                <Button
                  onClick={fetchLichessStudies}
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? "Fetching..." : "Fetch"}
                </Button>
              </Stack>

              {selectedStudy ? (
                <Stack
                  sx={{ width: "100%", height: isMobile ? "200px" : "310px" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ mb: 1 }}
                  >
                    <Typography
                      variant="body"
                      sx={{ fontWeight: "bold", flexGrow: 1 }}
                    >
                      {selectedStudy.name}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSelectedStudy(null);
                        setPgnText("");
                      }}
                    >
                      Back
                    </Button>
                  </Stack>

                  <Stack
                    sx={{
                      width: "100%",
                      overflowY: "auto",
                      height: isMobile ? "200px" : "310px",
                      maxHeight: isMobile ? "200px" : "310px",
                      pr: 1,
                      gap: 1,
                    }}
                  >
                    {loadingChapters && (
                      <Typography variant="body2">
                        Loading chapters...
                      </Typography>
                    )}

                    {chapters.length > 0 &&
                      chapters.map((chapter) => (
                        <Button
                          key={chapter.id}
                          sx={{
                            textAlign: "left",
                            width: "100%",
                            justifyContent: "flex-start",
                            textTransform: "none",
                            color: colors.black[900],
                            border: `1px solid ${colors.background[200]}`,
                            backgroundColor:
                              selectedChapter?.id === chapter.id
                                ? colors.background[200]
                                : colors.background[100],
                            "&:hover": {
                              backgroundColor: colors.background[200],
                            },
                          }}
                          onClick={() => {
                            setSelectedChapter(chapter);
                            setPgnText(chapter.pgn);
                          }}
                        >
                          <Stack>
                            <Typography variant="body">
                              {chapter.title}
                            </Typography>
                          </Stack>
                        </Button>
                      ))}
                  </Stack>
                </Stack>
              ) : (
                <Stack
                  sx={{ width: "100%", height: isMobile ? "200px" : "310px" }}
                >
                  <Typography
                    variant="body"
                    sx={{ paddingBottom: "8px", fontWeight: "bold" }}
                  >
                    Public Studies
                  </Typography>

                  <Stack
                    sx={{
                      width: "100%",
                      overflowY: "auto",
                      height: isMobile ? "200px" : "310px",
                      maxHeight: isMobile ? "200px" : "310px",
                      pr: 1,
                      gap: 1,
                    }}
                  >
                    {lichessStudies.length > 0 &&
                      lichessStudies.map((study, index) => (
                        <Button
                          key={index}
                          sx={{
                            textAlign: "left",
                            width: "100%",
                            justifyContent: "flex-start",
                            textTransform: "none",
                            color: colors.black[900],
                            border: `1px solid ${colors.background[200]}`,
                            backgroundColor:
                              selectedStudy?.id === study.id
                                ? colors.background[200]
                                : colors.background[100],
                            "&:hover": {
                              backgroundColor: colors.background[200],
                            },
                          }}
                          onClick={() => fetchStudyChapters(study)}
                        >
                          <Stack>
                            <Typography variant="body">{study.name}</Typography>
                          </Stack>
                        </Button>
                      ))}
                  </Stack>
                </Stack>
              )}
            </Stack>

            {/* PGN Preview now goes below on mobile */}
            <Box
              sx={{
                width: "100%",
                background: colors.background[200],
                height: isMobile ? "200px" : "auto",
              }}
            >
              <PreviewMovesCard moves={processPGN(pgnText).moves} />
            </Box>
          </Stack>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mt: 1 }}>
        <Button
          onClick={handleImport}
          sx={{
            color: "white",
            backgroundColor: colors.green[100],
            "&:hover": {
              backgroundColor: colors.green[200],
            },
          }}
          variant="contained"
          startIcon={<UploadSharpIcon />}
        >
          Import
        </Button>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportPGNDialog;
