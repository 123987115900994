import { useCallback, useEffect, useState, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { Chess } from "chess.js";

import MemorySharpIcon from "@mui/icons-material/MemorySharp";

import { tokens } from "../../../../styles/theme";

/**
 * EngineCard Component
 */
function EngineCard({
  chessboardRef,
  moves,
  selectedMoveId,
  setEval,
  setEvalOn,
}) {
  // Stockfish and Evaluation Management
  /**
   * Toggle the Stockfish engine on or off.
   */
  const [engineOn, setEngineOn] = useState(false);

  /**
   * Store the Stockfish Web Worker instance for engine analysis.
   */
  const [stockfish, setStockfish] = useState(null);

  /**
   * Abort controller to handle evaluation cancellations.
   */
  const evaluationAbortController = useRef(null);

  /**
   * Number of variations (multiPV) to analyze with Stockfish.
   */
  const [multiPV, setMultiPV] = useState(3);

  /**
   * Depth for Stockfish analysis.
   */
  const [depth, setDepth] = useState(15);

  /**
   * Store the current analysis lines returned by Stockfish.
   */
  const [currentLines, setCurrentLines] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  /**
   * It uses multiPV (Multi Principal Variation) and depth to calculate the best lines and evaluations.
   *
   * @param {string} fen - The FEN string representing the current board position.
   * @returns {Promise} A promise that resolves with evaluation data or rejects if an error occurs.
   */
  const getEvaluation = useCallback(
    (fen) => {
      if (!engineOn) {
        return Promise.reject("Engine is off"); // Reject if the engine is turned off
      }

      if (!stockfish) {
        return Promise.reject("Stockfish not ready"); // Reject if Stockfish instance is unavailable
      }

      // Abort any ongoing evaluation to prevent overlap
      if (evaluationAbortController.current) {
        evaluationAbortController.current.abort(); // Abort the previous request
      }

      // Create a new AbortController for the current evaluation
      const controller = new AbortController();
      evaluationAbortController.current = controller;

      const { signal } = controller; // Extract the signal for abort handling

      return new Promise((resolve, reject) => {
        if (signal.aborted) {
          reject("Evaluation aborted."); // Immediately reject if already aborted
          return;
        }

        // Prepare an array to store multiple lines for multiPV analysis
        const lines = Array.from({ length: multiPV }, () => ({}));

        stockfish.postMessage("stop"); // Stop any ongoing calculations

        // Short delay to ensure "stop" command is processed
        setTimeout(() => {
          if (signal.aborted) {
            reject("Evaluation aborted."); // Reject if aborted during the delay
            return;
          }

          // Configure Stockfish options and start analysis
          stockfish.postMessage(`setoption name MultiPV value ${multiPV}`); // Set multiPV
          stockfish.postMessage(`position fen ${fen}`); // Load the given position
          stockfish.postMessage(`go depth ${depth}`); // Set analysis depth

          // Determine if it's Black's turn
          const isBlackTurn = fen.split(" ")[1] === "b";

          // Listener for messages from Stockfish
          const handleMessage = (event) => {
            if (signal.aborted) {
              stockfish.removeEventListener("message", handleMessage);
              reject("Evaluation aborted."); // Reject if aborted during processing
              return;
            }

            const message = event.data;

            // Parse the Stockfish message for depth, score, and moves
            if (message.startsWith("info depth")) {
              const match = message.match(
                /depth (\d+).*score (mate|cp) (-?\d+).* pv (.+)/
              );

              if (match) {
                const depth = parseInt(match[1], 10); // Analysis depth
                const evalType = match[2]; // Evaluation type (mate or cp)
                const evalValue = parseInt(match[3], 10); // Evaluation value
                const uciMoves = match[4].split(" "); // Best moves (in UCI notation)
                const pvIndex =
                  parseInt(message.match(/multipv (\d+)/)?.[1] || "1", 10) - 1; // Line index

                if (pvIndex >= 0 && pvIndex < multiPV) {
                  const tempChess = new Chess(fen); // Clone the game for SAN conversion
                  const sanMoves = [];
                  uciMoves.forEach((uciMove) => {
                    const move = tempChess.move({
                      from: uciMove.slice(0, 2),
                      to: uciMove.slice(2, 4),
                      promotion: uciMove.length > 4 ? uciMove[4] : undefined,
                    });
                    if (move) sanMoves.push(move.san); // Convert to SAN
                  });

                  let evalScore;
                  if (evalType === "mate") {
                    // Mates are shown as M+/-X
                    evalScore =
                      (isBlackTurn ? -evalValue : evalValue) > 0
                        ? `M${Math.abs(evalValue)}`
                        : `-M${Math.abs(evalValue)}`;
                  } else {
                    // Convert centipawns to numeric evaluation
                    evalScore = evalValue / 100;
                    if (isBlackTurn) evalScore = -evalScore; // Reverse for Black
                    evalScore = evalScore.toFixed(2); // Format to 2 decimal places
                  }

                  /**
                   * Converts evaluation score to win probability.
                   * @param {number|string} evaluation - The evaluation score or mate string.
                   * @param {number} k - Slope of the logistic function.
                   * @param {number} positionalFactor - Additional factors affecting probability.
                   * @returns {number} Win probability as a percentage (0-100).
                   */
                  /**
                   * Converts an evaluation score to win probability using a logistic function.
                   *
                   * @param {number|string} evaluation - The evaluation score (centipawns) or mate string (e.g., "M3").
                   * @param {number} k - The slope of the logistic function.
                   * @param {number} positionalFactor - Additional factors affecting probability.
                   * @returns {number} Win probability as a percentage (0-100).
                   */
                  const convertEvalToWinProbability = (
                    evaluation,
                    k = 0.2,
                    positionalFactor = 0
                  ) => {
                    // Handle mate evaluations
                    if (
                      typeof evaluation === "string" &&
                      evaluation.startsWith("M")
                    ) {
                      const mateValue = parseInt(evaluation.substring(1), 10); // Extract mate value
                      if (mateValue > 0) {
                        // Positive mate (winning)
                        if (mateValue <= 3) return 99; // Mate in 1-3 moves
                        else if (mateValue <= 7) return 96; // Mate in 4-7 moves
                        else if (mateValue <= 10)
                          return 95; // Mate in 8-10 moves
                        else return 90; // Mate in more than 10 moves
                      } else {
                        // Negative mate (losing)
                        const absMateValue = Math.abs(mateValue);
                        if (absMateValue <= 3)
                          return 1; // Opponent mates in 1-3 moves
                        else if (absMateValue <= 7)
                          return 4; // Opponent mates in 4-7 moves
                        else if (absMateValue <= 10)
                          return 5; // Opponent mates in 8-10 moves
                        else return 10; // Opponent mates in more than 10 moves
                      }
                    }

                    // Clip extreme evaluations
                    const maxEval = 1500; // Define the maximum evaluation value for scaling
                    evaluation = Math.max(
                      -maxEval,
                      Math.min(maxEval, evaluation)
                    );

                    // Add positional factor and adjust slope dynamically
                    evaluation += positionalFactor;
                    const adjustedK = Math.abs(evaluation) > 500 ? k * 1.5 : k; // Increase slope for extreme evaluations

                    // Calculate probability using logistic function
                    const probability =
                      1 / (1 + Math.exp(-adjustedK * evaluation));
                    return probability * 100; // Convert to percentage
                  };

                  let winProbability = convertEvalToWinProbability(evalScore);

                  // Reverse probability for Black's turn
                  if (isBlackTurn) {
                    winProbability = 100 - winProbability;
                  }

                  // Update the line's data
                  lines[pvIndex] = {
                    eval: evalScore,
                    moves: sanMoves,
                    depth,
                    win_probability: winProbability.toFixed(2), // Add win probability
                  };

                  setCurrentLines([...lines]); // Update the state with current lines
                }
              }
            }
          };

          stockfish.addEventListener("message", handleMessage);

          // Cleanup listener and resolve/reject on timeout or abort
          const timeoutId = setTimeout(() => {
            stockfish.removeEventListener("message", handleMessage);
            reject("Stockfish evaluation timed out.");
          }, 10000); // Timeout after 10 seconds

          signal.addEventListener("abort", () => {
            clearTimeout(timeoutId);
            stockfish.removeEventListener("message", handleMessage);
            reject("Evaluation aborted.");
          });
        }, 100); // Ensure "stop" command has processed
      });
    },
    [stockfish, setCurrentLines, engineOn, multiPV, depth] // Dependencies
  );

  // Initialize Stockfish engine when the component mounts and clean up on unmount
  useEffect(() => {
    const stockfishInstance = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    setStockfish(stockfishInstance); // Save Stockfish instance to state

    // Terminate the Stockfish instance on cleanup
    return () => {
      stockfishInstance.terminate();
    };
  }, []);

  const getMoveSequence = (moves, selectedMoveId) => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san.trim() !== "") {
        // Exclude empty or whitespace-only SAN moves
        movePath.unshift(san); // Add move at the beginning to maintain order
      }
      currentMoveId = moves[currentMoveId].parent; // Move backwards to the parent
    }

    return movePath; // Return the sequence from root to selectedMoveId
  };

  const getFenFromMoves = useCallback((moves, selectedMoveId) => {
    // Step 1: Generate move sequence using getMoveSequence
    const moveSequence = getMoveSequence(moves, selectedMoveId);

    // Step 2: Create a new Chess.js instance
    const game = new Chess();

    // Step 3: Play all moves in Chess.js
    moveSequence.forEach((moveSan) => {
      try {
        game.move(moveSan); // Apply each move
      } catch (error) {
        // You can log or handle errors here if needed
      }
    });

    // Step 4: Return the resulting FEN position
    return game.fen();
  }, []);

  // Evaluate the current position using Stockfish
  useEffect(() => {
    if (stockfish && moves && selectedMoveId) {
      const currentFen = getFenFromMoves(moves, selectedMoveId);

      getEvaluation(currentFen).catch((err) => {
        if (err !== "Evaluation aborted.") {
        }
      });
    }

    // Cleanup the abort controller on unmount or dependency change
    return () => {
      if (evaluationAbortController.current) {
        evaluationAbortController.current.abort();
      }
    };
  }, [
    moves,
    selectedMoveId,
    getEvaluation,
    getFenFromMoves,
    stockfish,
    depth,
    multiPV,
  ]);

  useEffect(() => {
    if (currentLines.length > 0 && currentLines[0]?.eval) {
      setEval(currentLines[0].eval);
    }
  }, [currentLines, setEval]);

  return (
    <Card
      id="builder-enginecard"
      sx={{
        position: "relative", // Required for pseudo-element positioning
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none !important",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MemorySharpIcon />
            {/* Replace `SomeIcon` with your desired icon */}
          </Box>
        }
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            {engineOn ? "Stockfish On" : "Stockfish Off"}
          </Typography>
        }
        sx={{ pt: 1, pb: 1 }}
        action={
          <Switch
            checked={engineOn}
            onChange={(event) => {
              setEngineOn(event.target.checked);
              setEvalOn(event.target.checked);
            }}
            sx={{
              "& .MuiSwitch-switchBase": { color: "white" },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: colors.green[100],
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "grey",
              },
              "& .MuiSwitch-track": { backgroundColor: "grey" },
            }}
          />
        }
      />

      {engineOn && ( // Render only if engineOn is true
        <>
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent
            sx={{
              padding: "10px !important", // Apply even padding to all sides
            }}
          >
            {/* Engine Status Text */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px", // Add some spacing below the text
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginRight: "5px",
                }}
              >
                Stockfish running at depth{" "}
              </Typography>
              <Box
                sx={{
                  display: "inline-block",
                  cursor: "pointer",
                  borderBottom: `1px dashed ${colors.black[300]}`, // Indicate editable text
                }}
              >
                <input
                  type="number"
                  value={depth}
                  onChange={(e) => setDepth(Number(e.target.value))}
                  style={{
                    background: "transparent",
                    border: "none",
                    textAlign: "center",
                    width: "40px",
                    outline: "none",
                    color: colors.black[900],
                  }}
                />
              </Box>
              <Typography variant="body2" sx={{ marginX: "5px" }}>
                for
              </Typography>
              <Box
                sx={{
                  display: "inline-block",
                  cursor: "pointer",
                  borderBottom: `1px dashed ${colors.black[300]}`, // Indicate editable text
                }}
              >
                <input
                  type="number"
                  value={multiPV}
                  onChange={(e) => setMultiPV(Number(e.target.value))}
                  style={{
                    background: "transparent",
                    border: "none",
                    textAlign: "center",
                    width: "40px",
                    outline: "none",
                    color: colors.black[900],
                  }}
                />
              </Box>
              <Typography variant="body2" sx={{ marginLeft: "5px" }}>
                lines.
              </Typography>
            </Box>
            {/* Engine Output Section */}

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      p: 0,
                    }}
                  >
                    <TableCell
                      sx={{
                        width: "8%",
                        fontSize: "0.75em",
                        textAlign: "center",
                        borderBottom: `1px dashed ${colors.black[300]}`,
                      }}
                    >
                      Eval
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "8%",
                        fontSize: "0.75em",
                        textAlign: "center",
                        borderBottom: `1px dashed ${colors.black[300]}`,
                      }}
                    >
                      Win %
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "84%",
                        fontSize: "0.75em",
                        borderBottom: `1px dashed ${colors.black[300]}`,
                      }} // Remaining space for Moves
                    >
                      Moves
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentLines
                    .filter(
                      (line) =>
                        Array.isArray(line.moves) && line.moves.length > 0
                    )
                    .map((line, index) => (
                      <TableRow
                        key={index}
                        onClick={() => {
                          chessboardRef.current?.addMoveToLines(line.moves[0]);
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.background[200],
                          }, // Light grey on hover
                          transition: "background-color 0.2s ease-in-out", // Smooth transition
                        }}
                      >
                        <TableCell
                          sx={{
                            borderBottom: `1px dashed ${colors.black[300]}`, // ✅ Apply to each cell
                          }}
                        >
                          <Typography variant="body2">{line.eval}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: `1px dashed ${colors.black[300]}`, // ✅ Apply to each cell
                          }}
                        >
                          <Typography variant="body2">
                            {Number(line.win_probability).toFixed(1)}%
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: `1px dashed ${colors.black[300]}`, // ✅ Apply to each cell
                          }}
                        >
                          <Typography variant="body2">
                            {(() => {
                              const movesSequence = getMoveSequence(
                                moves,
                                selectedMoveId
                              );
                              const moveCount = movesSequence.length;
                              const isWhiteTurn = moveCount % 2 === 0; // true if it's White's turn to move
                              const formattedMoves = [];
                              let moveNumber = Math.floor(moveCount / 2) + 1;

                              line.moves
                                .slice(0, 12)
                                .forEach((sanMove, idx) => {
                                  const isWhiteMove =
                                    (idx % 2 === 0) === isWhiteTurn;

                                  if (isWhiteMove) {
                                    formattedMoves.push(`${moveNumber}.`);
                                  } else if (idx === 0 && !isWhiteTurn) {
                                    formattedMoves.push(`${moveNumber}...`);
                                  }

                                  formattedMoves.push(sanMove);

                                  if (!isWhiteMove) {
                                    moveNumber += 1;
                                  }
                                });

                              return formattedMoves.join(" ");
                            })()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </>
      )}
    </Card>
  );
}

export default EngineCard;
