import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Typography, Avatar, useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { themeColors } from "../styles/boardtheme";
import { pieceSets } from "../styles/pieceset";
import { useUser } from "../context/UserContext";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useTranslation } from "react-i18next";

const GameViewer = ({
  BlackPlayer,
  BlackElo,
  WhitePlayer,
  WhiteElo,
  Date,
  Result,
  Moves,
  WhiteImage,
  BlackImage,
  SquareHighlights,
  ArrowsHighlights,
  Comments,
  BoardOrientation = "white",
  BoardOnly = false,
  StartingPosition = 0,
}) => {
  const [game, setGame] = useState(new Chess());
  const [currentMove, setCurrentMove] = useState(0);
  const [boardArrowColours, setBoardArrowColours] = useState([]);
  const [boardSquareColours, setBoardSquareColours] = useState({});
  const { userData } = useUser();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [orientation, setOrientation] = useState(BoardOrientation);
  const { t } = useTranslation("Other");

  const processArrowColours = useCallback((arrows, moveIndex) => {
    const colorMap = {
      B: "blue",
      G: "green",
      R: "red",
    };
    if (arrows && arrows[moveIndex]) {
      const processedArrows = arrows[moveIndex].split(",").map((arrow) => {
        const color = colorMap[arrow[0]];
        const from = arrow.slice(1, 3);
        const to = arrow.slice(3, 5);
        return [from, to, color];
      });
      setBoardArrowColours(processedArrows);
    } else {
      setBoardArrowColours([]);
    }
  }, []);

  const processSquareColours = useCallback((squares, moveIndex) => {
    const colorMap = {
      B: "blue",
      G: "green",
      R: "red",
    };
    if (squares && squares[moveIndex]) {
      const processedSquares = squares[moveIndex]
        .split(",")
        .reduce((acc, square) => {
          const color = colorMap[square[0]];
          const squarePos = square.slice(1);
          acc[squarePos] = {
            boxShadow: `inset 0 0 0 3px ${color}`,
            borderRadius: "10%",
            boxSizing: "border-box",
          };
          return acc;
        }, {});
      setBoardSquareColours(processedSquares);
    } else {
      setBoardSquareColours({});
    }
  }, []);

  // Load moves into the chess game
  const moveList = Moves.split(" ").filter((move) => !move.includes("."));
  const moves = moveList.map((move, index) => ({ move, index }));

  const toggleOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };

  const goToMove = useCallback(
    (moveIndex) => {
      const newGame = new Chess();
      for (let i = 0; i < moveIndex; i++) {
        newGame.move(moves[i].move);
      }
      setGame(newGame);
      setCurrentMove(moveIndex);
    },
    [moves]
  );

  const handleNextMove = useCallback(() => {
    if (currentMove < moves.length) {
      const nextMove = moves[currentMove].move;
      if (!["1-0", "0-1", "1/2-1/2", "*"].includes(nextMove)) {
        goToMove(currentMove + 1);
      }
    }
  }, [currentMove, goToMove, moves]);

  const handlePreviousMove = useCallback(() => {
    if (currentMove > 0) {
      goToMove(currentMove - 1);
    }
  }, [goToMove, currentMove]);

  const handleResetGame = useCallback(() => {
    setCurrentMove(0);
    setGame(new Chess());
  }, []);

  const handleLastMove = useCallback(() => {
    goToMove(moves.length - 1);
  }, [goToMove, moves.length]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNextMove();
      } else if (event.key === "ArrowLeft") {
        handlePreviousMove();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentMove, handleNextMove, handlePreviousMove]);

  useEffect(() => {
    setCurrentMove(0);
    setGame(new Chess());
  }, [Moves]);

  useEffect(() => {
    processArrowColours(ArrowsHighlights, currentMove - 1);
    processSquareColours(SquareHighlights, currentMove - 1);
  }, [
    currentMove,
    ArrowsHighlights,
    SquareHighlights,
    processArrowColours,
    processSquareColours,
  ]);

  useEffect(() => {
    const newGame = new Chess();
    const moveList = Moves.split(" ").filter((move) => !move.includes("."));

    for (let i = 0; i < StartingPosition && i < moveList.length; i++) {
      newGame.move(moveList[i]);
    }

    setGame(newGame);
    setCurrentMove(StartingPosition);
  }, [Moves, StartingPosition]); // ✅ empty dependency array ensures it only runs once on mount

  return (
    <Box pt={1}>
      {/* Chessboard and Players */}
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
        <Box
          flex={1}
          id="chessboard"
          style={{
            width: "100%", // Full width in column layout
            maxWidth: "500px", // Maximum width to constrain the chessboard
            padding: "0px 10px 10px 0px", // Uniform padding
            boxSizing: "border-box", // Include padding and border in the element's total width and height
          }}
        >
          {!BoardOnly && WhitePlayer && orientation === "black" && (
            <Box
              display="flex"
              alignItems="center"
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid #DDDDDD",
                padding: "8px 8px 8px 12px",
              }}
            >
              <Avatar
                src={`${process.env.PUBLIC_URL}/img/players/${WhiteImage}.png`}
                alt={WhitePlayer}
                variant="square"
                style={{ marginRight: 10 }}
              />
              <Box display="flex" flexDirection="column">
                <Typography>{WhitePlayer}</Typography>
                <Typography>{WhiteElo}</Typography>
              </Box>
            </Box>
          )}
          {!BoardOnly && BlackPlayer && orientation === "white" && (
            <Box
              display="flex"
              alignItems="center"
              style={{
                backgroundColor: "black",
                padding: "8px 8px 8px 12px",
                color: "white",
              }}
            >
              <Avatar
                src={`${process.env.PUBLIC_URL}/img/players/${BlackImage}.png`}
                alt={BlackPlayer}
                variant="square"
                style={{ marginRight: 10 }}
              />
              <Box display="flex" flexDirection="column">
                <Typography>{BlackPlayer}</Typography>
                <Typography>{BlackElo}</Typography>
              </Box>
            </Box>
          )}
          <Chessboard
            position={game.fen()}
            customSquareStyles={boardSquareColours}
            customArrows={boardArrowColours}
            boardOrientation={orientation}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
            areArrowsAllowed={false}
            arePiecesDraggable={false}
          />
          {!BoardOnly && WhitePlayer && orientation === "white" && (
            <Box
              display="flex"
              alignItems="center"
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid #DDDDDD",
                padding: "8px 8px 8px 12px",
              }}
            >
              <Avatar
                src={`${process.env.PUBLIC_URL}/img/players/${WhiteImage}.png`}
                alt={WhitePlayer}
                variant="square"
                style={{ marginRight: 10 }}
              />
              <Box display="flex" flexDirection="column">
                <Typography>{WhitePlayer}</Typography>
                <Typography>{WhiteElo}</Typography>
              </Box>
            </Box>
          )}
          {!BoardOnly && BlackPlayer && orientation === "black" && (
            <Box
              display="flex"
              alignItems="center"
              style={{
                backgroundColor: "black",
                padding: "8px 8px 8px 12px",
                color: "white",
              }}
            >
              <Avatar
                src={`${process.env.PUBLIC_URL}/img/players/${BlackImage}.png`}
                alt={BlackPlayer}
                variant="square"
                style={{ marginRight: 10 }}
              />
              <Box display="flex" flexDirection="column">
                <Typography>{BlackPlayer}</Typography>
                <Typography>{BlackElo}</Typography>
              </Box>
            </Box>
          )}
          <Box display="flex" justifyContent="center" mt={2}>
            <Tooltip title="Reset the game">
              <span>
                <Button
                  variant="contained"
                  onClick={handleResetGame}
                  style={{ marginRight: 10 }}
                  disabled={currentMove === 0}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <ReplayIcon />
                </Button>
              </span>
            </Tooltip>

            <Tooltip title="Go to previous move">
              <span>
                <Button
                  variant="contained"
                  onClick={handlePreviousMove}
                  disabled={currentMove === 0}
                  style={{ marginRight: 10 }}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <KeyboardDoubleArrowLeftIcon />
                </Button>
              </span>
            </Tooltip>

            <Tooltip title="Go to next move">
              <span>
                <Button
                  variant="contained"
                  onClick={handleNextMove}
                  disabled={
                    currentMove >= moves.length ||
                    ["1-0", "0-1", "1/2-1/2", "*"].includes(
                      moves[currentMove]?.move
                    )
                  }
                  style={{ marginRight: 10 }}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <KeyboardDoubleArrowRightIcon />
                </Button>
              </span>
            </Tooltip>

            <Tooltip title="Go to last move">
              <span>
                <Button
                  variant="contained"
                  onClick={handleLastMove}
                  disabled={currentMove >= moves.length - 1}
                  style={{ marginRight: 10 }}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <SkipNextIcon />
                </Button>
              </span>
            </Tooltip>

            <Tooltip title="Toggle board orientation">
              <span>
                <Button
                  variant="contained"
                  onClick={toggleOrientation}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <SwapVertRoundedIcon />
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>

        {/* Moves */}
        {!BoardOnly && (
          <Box flex={1} ml={isSmallScreen ? 0 : 2} mt={isSmallScreen ? 2 : 0}>
            <Box mb={2}>
              <Typography>
                {Date && (
                  <>
                    {t("GameViewer.date")}
                    {": "}
                    <Typography component="span" style={{ fontWeight: "bold" }}>
                      {Date}
                    </Typography>
                  </>
                )}
                {Date && Result && ", "}
                {Result && (
                  <>
                    {t("GameViewer.results")}
                    {": "}
                    <Typography component="span" style={{ fontWeight: "bold" }}>
                      {Result}
                    </Typography>
                  </>
                )}
              </Typography>
            </Box>

            <Box flexWrap="wrap">
              {moves.map((move, index) => {
                const isResult = ["1-0", "0-1", "1/2-1/2", "*"].includes(
                  move.move
                );
                const isCurrent = index + 1 === currentMove;
                return (
                  <React.Fragment key={"move-comment-" + index}>
                    <span
                      onClick={() => !isResult && goToMove(index + 1)}
                      style={{
                        cursor: isResult ? "default" : "pointer",
                        marginRight: 8,
                        fontWeight: isCurrent ? "bold" : "normal",
                        display: "inline", // Ensure continuous line
                      }}
                    >
                      {isResult
                        ? move.move
                        : index % 2 === 0
                        ? `${Math.floor(index / 2) + 1}. ${move.move}`
                        : `${move.move}`}
                    </span>
                    {Comments && Comments[index] && (
                      <span
                        style={{
                          cursor: "default",
                          marginRight: 8,
                          fontWeight: "normal",
                          display: "inline", // Ensure continuous line
                        }}
                      >
                        {Comments[index]}
                      </span>
                    )}
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GameViewer;
