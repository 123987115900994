import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { themeColors } from "../../../../styles/boardtheme";
import { pieceSets } from "../../../../styles/pieceset";

import { useUser } from "../../../../context/UserContext";
import { updateUserData } from "../../../../features/Firestore";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import useDialogFocused from "./useDialogFocused";
import { Chessboard } from "react-chessboard";

const ProfileDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { userData, setUserData, userRef } = useUser();

  const [displayName, setDisplayName] = useState(userData?.displayName || "");
  const [bio, setBio] = useState(userData?.bio || "");
  const [socialLinks, setSocialLinks] = useState(userData?.socialLinks || []);

  const [selectedThemeColor, setSelectedThemeColor] = useState(
    userData?.theme || "White Stripe Theme"
  );
  const [selectedPieceSet, setSelectedPieceSet] = useState(
    userData?.pieceset || "Wikipedia"
  );

  const sortedThemeNames = Object.keys(themeColors).sort();

  const handleAddSocialLink = () => {
    if (socialLinks.length < 5) {
      setSocialLinks([
        ...socialLinks,
        { type: "Website", url: "" }, // ✅ Explicit default
      ]);
    }
  };

  const handleDeleteSocialLink = (index) => {
    const updated = [...socialLinks];
    updated.splice(index, 1);
    setSocialLinks(updated);
  };

  const handleSocialLinkChange = (index, field, value) => {
    const updated = [...socialLinks];
    updated[index][field] = value;
    setSocialLinks(updated);
  };

  return (
    <Dialog
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900], // Text color
          border: `0.5px solid #222222`, // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      open={open}
      onClose={() => onClose(false)}
      maxWidth="md"
      fullWidth
    >
      {useDialogFocused(open)}
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {/* Left-aligned: Help Icon + Title */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6">
              <ManageAccountsSharpIcon sx={{ mr: 1 }} />
              Profile
            </Typography>
          </Stack>

          {/* Right-aligned: Close Button */}
          <IconButton onClick={() => onClose(false)} color="inherit">
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ backgroundColor: colors.green[900], height: "1.5px" }} />
      <DialogContent>
        <Box display="flex" flexDirection="row" gap={4} flexWrap="wrap">
          {/* Left Column: Display Name and Bio */}
          <Stack spacing={2} flex={1} minWidth="250px">
            <TextField
              label="Display Name"
              fullWidth
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              autoComplete="off"
            />
            <TextField
              label="Bio"
              fullWidth
              multiline
              rows={5}
              value={bio}
              autoComplete="off"
              onChange={(e) => setBio(e.target.value)}
            />

            <Box>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                mt={1}
                sx={{ height: "40px" }}
              >
                <Typography variant="subtitle2">
                  Social Links (Max 5)
                </Typography>
                {socialLinks.length < 5 && (
                  <IconButton onClick={handleAddSocialLink}>
                    <ControlPointIcon />
                  </IconButton>
                )}
              </Box>
              {socialLinks.map((link, index) => (
                <Box
                  key={index}
                  display="flex"
                  gap={1}
                  alignItems="center"
                  sx={{ mt: 0.5 }}
                >
                  <FormControl sx={{ minWidth: 140 }}>
                    <Select
                      size="small"
                      value={link.type || "Website"}
                      onChange={(e) =>
                        handleSocialLinkChange(index, "type", e.target.value)
                      }
                      sx={{ height: 40 }} // Match height
                    >
                      <MenuItem value="Website">Website</MenuItem>
                      <MenuItem value="Lichess.org">Lichess.org</MenuItem>
                      <MenuItem value="Chess.com">Chess.com</MenuItem>
                      <MenuItem value="YouTube">YouTube</MenuItem>
                      <MenuItem value="Twitch">Twitch</MenuItem>
                      <MenuItem value="Twitter">Twitter</MenuItem>
                      <MenuItem value="Facebook">Facebook</MenuItem>
                      <MenuItem value="Instagram">Instagram</MenuItem>
                      <MenuItem value="Tiktok">Tiktok</MenuItem>
                      <MenuItem value="Chessable">Chessable</MenuItem>
                      <MenuItem value="GitHub">GitHub</MenuItem>
                      <MenuItem value="Patreon">Patreon</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="https://"
                    value={link.url}
                    autoComplete="off"
                    onChange={(e) =>
                      handleSocialLinkChange(index, "url", e.target.value)
                    }
                    sx={{
                      height: 40,
                      "& .MuiInputBase-root": { height: "100%" },
                    }} // Match Select height
                  />

                  <IconButton onClick={() => handleDeleteSocialLink(index)}>
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Stack>

          {/* Right Column: Theme and Piece Set */}
          <Stack spacing={2} flex={1} minWidth="250px">
            <FormControl fullWidth>
              <InputLabel id="theme-color-label">Theme Color</InputLabel>
              <Select
                labelId="theme-color-label"
                id="theme-color-select"
                value={selectedThemeColor}
                label="Theme Color"
                onChange={(e) => setSelectedThemeColor(e.target.value)}
              >
                {sortedThemeNames.map((themeName) => (
                  <MenuItem key={themeName} value={themeName}>
                    {themeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="piece-set-label">Piece Set</InputLabel>
              <Select
                labelId="piece-set-label"
                id="piece-set-select"
                value={selectedPieceSet}
                label="Piece Set" // Required to make the label float
                onChange={(e) => setSelectedPieceSet(e.target.value)}
              >
                {Object.keys(pieceSets).map((pieceSetName) => (
                  <MenuItem key={pieceSetName} value={pieceSetName}>
                    {pieceSetName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Chessboard
              customLightSquareStyle={{
                backgroundColor: themeColors[selectedThemeColor].lightSquare,
                backgroundImage:
                  themeColors[selectedThemeColor].lightSquarePattern,
              }}
              customDarkSquareStyle={{
                backgroundColor: themeColors[selectedThemeColor].darkSquare,
                backgroundImage:
                  themeColors[selectedThemeColor].darkSquarePattern,
              }}
              customPieces={pieceSets[selectedPieceSet]}
              areArrowsAllowed={false}
            />
          </Stack>
        </Box>
      </DialogContent>

      <Divider sx={{ height: "1.5px", mb: 1 }} />
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: colors.green[100],
            "&:hover": {
              backgroundColor: colors.green[200],
            },
            textTransform: "none",
          }}
          onClick={() => {
            const updatedData = {
              ...userData,
              displayName,
              bio,
              theme: selectedThemeColor,
              pieceset: selectedPieceSet,
              socialLinks: socialLinks,
            };
            updateUserData(userRef, updatedData);
            setUserData(updatedData);
            onClose(false);
          }}
        >
          <SaveSharpIcon sx={{ mr: 1 }} />
          Save
        </Button>
        <Button
          sx={{
            color: "white",
            backgroundColor: colors.red[500],
            "&:hover": {
              backgroundColor: colors.red[600],
            },
            textTransform: "none",
          }}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileDialog;
