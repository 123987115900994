import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  List,
  ListItemText,
  Container,
  ListItemIcon,
  ListItemButton,
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ColorModeContext, tokens } from "../styles/theme";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import AbcRoundedIcon from "@mui/icons-material/AbcRounded";
import HandymanIcon from "@mui/icons-material/Handyman";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PatternRoundedIcon from "@mui/icons-material/PatternRounded";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
//import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
//import RocketRoundedIcon from "@mui/icons-material/RocketRounded";
//import AllInclusiveRoundedIcon from "@mui/icons-material/AllInclusiveRounded";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import TroubleshootRoundedIcon from "@mui/icons-material/TroubleshootRounded";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import SettingsInputAntennaRoundedIcon from "@mui/icons-material/SettingsInputAntennaRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import GrassRoundedIcon from "@mui/icons-material/GrassRounded";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import TurnSharpRightOutlinedIcon from "@mui/icons-material/TurnSharpRightOutlined";
import TagIcon from "@mui/icons-material/Tag";
import AccessAlarmRoundedIcon from "@mui/icons-material/AccessAlarmRounded";
import InstallIcon from "@mui/icons-material/GetApp";
//import HubRoundedIcon from "@mui/icons-material/HubRounded";
//import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import IsoIcon from "@mui/icons-material/Iso";
import InfoIcon from "@mui/icons-material/Info";
import ShuffleRoundedIcon from "@mui/icons-material/ShuffleRounded";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import CenterFocusStrongRoundedIcon from "@mui/icons-material/CenterFocusStrongRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import GTranslateRoundedIcon from "@mui/icons-material/GTranslateRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
//import StorageIcon from "@mui/icons-material/Storage";
//import BinocularsIcon from "../icons/BinocularsIcon";
import HistoryIcon from "../icons/HistoryIcon";
import CompetitionIcon from "../icons/CompetitionIcon";
import CrosswordIcon from "../icons/CrosswordIcon";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import InsightsIcon from "@mui/icons-material/Insights";
import { chessquotes } from "../data/quotes/chessquotes";

import { useLanguage } from "../context/LocaleContext";
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext";
import { auth } from "../config/firebase";
import { logout } from "../features/Authentication";
import GifIcon from "../icons/GifIcon";
import KnockoutIcon from "../icons/Knockout";
import HandAndBrainIcon from "../icons/HandAndBrainIcon";
import GuessTheMoveIcon from "../icons/GuessTheMove";
import GuessWhoIcon from "../icons/GuessWhoIcon";
import PGNIcon from "../icons/PGNIcon";
import CheckersIcon from "../icons/CheckersIcon";
import GameControllerIcon from "../icons/GameControllerIcon";
import WhackIcon from "../icons/WhackIcon";
//import CrystalIcon from "../icons/CrystalIcon";
import KnightIcon from "../icons/KnightIcon";

const drawerWidth = 220;
const submenuWidth = 250;

const MenuListItem = ({
  text,
  isSmallScreen,
  activeMenuItem,
  onMouseEnter,
  onMouseLeave,
  onClick,
  icon,
  to,
}) => {
  const listItemStyle = {
    height: "48px",
    paddingLeft: "16px",
    paddingRight: "0px",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    minWidth: "auto",
    marginRight: "8px",
  };

  return (
    <ListItemButton
      component={Link}
      to={to}
      selected={activeMenuItem === text}
      onMouseEnter={() => onMouseEnter(text)}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      sx={listItemStyle}
    >
      <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
      <ListItemText primary={isSmallScreen ? "" : text} />
    </ListItemButton>
  );
};

const SubmenuItem = ({ text, to, onClick, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const listItemStyle = {
    height: "48px",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    minWidth: "auto",
    marginRight: "8px",
  };

  const listItemButtonStyle = {
    ...listItemStyle,
    "&:hover": {
      backgroundColor: colors.black[200],
    },
  };

  return (
    <ListItemButton
      sx={listItemButtonStyle}
      onClick={onClick}
      component={Link}
      to={to}
    >
      <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

const InstallButton = ({
  text,
  isSmallScreen,
  activeMenuItem,
  onMouseEnter,
  onMouseLeave,
  onClick,
  icon,
}) => {
  const listItemStyle = {
    height: "48px",
    paddingLeft: "16px",
    paddingRight: "0px",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    minWidth: "auto",
    marginRight: "8px",
  };

  return (
    <ListItemButton
      selected={activeMenuItem === text}
      onMouseEnter={() => onMouseEnter(text)}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      sx={listItemStyle}
    >
      <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
      <ListItemText primary={isSmallScreen ? "" : text} />
    </ListItemButton>
  );
};

const AppSideBar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [isSubmenuItemClicked, setIsSubmenuItemClicked] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [quote, setQuote] = useState({ Quote: "", Author: "" }); // State for the quote
  const colorMode = useContext(ColorModeContext);
  const { userData } = useUser();

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const { language, changeLanguage } = useLanguage(); // Use your LanguageContext

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value); // Change the language
    setIsLanguageDialogOpen(false); // Close the dialog
  };

  const languageMap = {
    ar: "Arabic",
    cn: "Chinese",
    en: "English",
    de: "Germany",
    he: "Hebrew",
    hi: "Hindi",
    es: "Spanish",
    fr: "French",
    ja: "Japanese",
    ko: "Korean",
    ru: "Russian",
    vn: "Vietnamese",
  };

  const { t } = useTranslation("Menu");

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsSmallScreen(window.innerWidth <= 600);
    });

    // Listen for the `beforeinstallprompt` event
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event for triggering it later
      setShowInstallButton(true); // Show the install button
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    setIsModalOpen(true); // Show modal to confirm installation
  };

  const confirmInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Trigger image caching in the service worker
          if (
            "serviceWorker" in navigator &&
            navigator.serviceWorker.controller
          ) {
            navigator.serviceWorker.controller.postMessage({
              type: "CACHE_IMAGES", // Send the message to cache images
            });
          }
        } else {
        }
        setDeferredPrompt(null); // Clear the deferred prompt
      });
    }

    setIsModalOpen(false); // Close the install confirmation modal
  };

  let submenuCloseTimeout;

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  useEffect(() => {
    const filteredQuotes = chessquotes.filter(
      (quote) => quote.Quote.length < 120
    );
    let randomIndex = Math.floor(Math.random() * filteredQuotes.length);
    let quote = filteredQuotes[randomIndex];
    setQuote(quote);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleListItemMouseEnter = (menuItem) => {
    if (menuItem !== "") {
      setIsSubmenuVisible(true);
    } else {
      setIsSubmenuVisible(false);
    }
    setActiveMenuItem(menuItem);
    clearTimeout(submenuCloseTimeout);
  };

  const handleListItemMouseLeave = () => {
    submenuCloseTimeout = setTimeout(() => {
      setIsSubmenuVisible(false);
    }, 500);
  };

  const handleSubmenuItemClicked = () => {
    setIsSubmenuItemClicked(true);
    setIsSubmenuVisible(false);
  };

  const handleSubmenuMouseEnter = () => {
    clearTimeout(submenuCloseTimeout);
  };

  const submenuLeftPosition = isSmallScreen ? 60 : drawerWidth;

  const commonContainerStyle = {
    display: "flex",
  };

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const submenuStyle = {
    width: submenuWidth,
    height: "100vh",
    position: "absolute",
    left: submenuLeftPosition,
    transition: "left 0.5s",
    top: `${scrollY}px`,
    backgroundColor: colors.black[100],
    borderRight: "0.5px solid " + colors.black[300],
    zIndex: 1000,
  };

  const handleMenuMouseLeave = () => {
    if (!isSubmenuVisible && !isSubmenuItemClicked) {
      setActiveMenuItem(null);
    }
  };

  return (
    <>
      <div onMouseLeave={handleMenuMouseLeave}>
        <Container style={commonContainerStyle}>
          <Drawer
            variant="permanent"
            sx={{
              width: isSmallScreen ? 60 : drawerWidth,
              flexShrink: 0,
              transition: "width 0.5s",
              "& .MuiDrawer-paper": {
                width: isSmallScreen ? 60 : drawerWidth,
                transition: "width 0.5s",
              },
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <List>
                <MenuListItem
                  text={t("home")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <HomeRoundedIcon style={{ color: colors.black[900] }} />
                  }
                  to="/"
                />
                <MenuListItem
                  text={t("games")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Games")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <ExtensionRoundedIcon
                      style={{ color: colors.material[1] }}
                    />
                  }
                />
                <MenuListItem
                  text={t("arcade")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Arcade")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <GameControllerIcon style={{ color: colors.material[1] }} />
                  }
                />
                <MenuListItem
                  text={t("analytics")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Analytics")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={<InsightsIcon style={{ color: colors.material[4] }} />}
                />
                <MenuListItem
                  text={t("learn")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Learn")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <GrassRoundedIcon style={{ color: colors.material[10] }} />
                  }
                />
                <MenuListItem
                  text={t("tools")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Tools")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={<HandymanIcon style={{ color: colors.material[14] }} />}
                />
                <MenuListItem
                  text={t("library")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Library")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <LocalLibraryRoundedIcon
                      style={{ color: colors.material[6] }}
                    />
                  }
                />

                {userData ? (
                  <MenuListItem
                    text={userData?.username}
                    isSmallScreen={isSmallScreen}
                    activeMenuItem={activeMenuItem}
                    onMouseEnter={() => handleListItemMouseEnter("Account")}
                    onMouseLeave={handleListItemMouseLeave}
                    icon={
                      auth && userData?.photoURL ? (
                        <img
                          src={userData?.photoURL}
                          alt="User Profile"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <AccountCircleRoundedIcon
                          style={{ color: colors.black[900] }}
                        />
                      )
                    }
                  />
                ) : (
                  <MenuListItem
                    text={t("login")}
                    isSmallScreen={isSmallScreen}
                    activeMenuItem={activeMenuItem}
                    onMouseEnter={() => handleListItemMouseEnter("")}
                    onMouseLeave={handleListItemMouseLeave}
                    icon={
                      <AccountCircleRoundedIcon
                        style={{ color: colors.black[900] }}
                      />
                    }
                    to="/login"
                  />
                )}
                <MenuListItem
                  text={t("about")}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={<InfoIcon style={{ color: colors.black[900] }} />}
                  to="/about"
                />
                {showInstallButton && (
                  <InstallButton
                    text={t("installApp")}
                    isSmallScreen={isSmallScreen}
                    activeMenuItem={activeMenuItem}
                    onMouseEnter={() => handleListItemMouseEnter("")}
                    onMouseLeave={handleListItemMouseLeave}
                    onClick={handleInstallClick}
                    icon={<InstallIcon style={{ color: colors.black[900] }} />}
                  />
                )}
              </List>

              {!isSmallScreen && (
                <Box
                  sx={{
                    padding: "8px",
                    color: colors.black[600],
                    fontStyle: "italic",
                    fontSize: "0.9rem",
                    borderLeft: `3px solid ${colors.black[300]}`,
                    mt: "5px",
                    ml: "10px",
                    lineHeight: "1.6",
                  }}
                >
                  <div>"{quote.Quote}"</div>
                  <div
                    style={{
                      marginTop: "5px",
                      mr: "8px",
                      fontStyle: "normal",
                    }}
                  >
                    - {quote.Author}
                  </div>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                paddingBottom: "5px",
                flexDirection: "column",
              }}
            >
              <MenuListItem
                onMouseEnter={() => handleListItemMouseEnter("")}
                onMouseLeave={handleListItemMouseLeave}
                isSmallScreen={isSmallScreen}
                activeMenuItem={activeMenuItem}
                onClick={colorMode.toggleColorMode}
                text={
                  theme.palette.mode === "dark" ? t("darkMode") : t("lightMode")
                }
                icon={
                  theme.palette.mode === "dark" ? (
                    <DarkModeOutlinedIcon />
                  ) : (
                    <LightModeOutlinedIcon />
                  )
                }
              />
              <MenuListItem
                onMouseEnter={() => handleListItemMouseEnter("")}
                onMouseLeave={handleListItemMouseLeave}
                isSmallScreen={isSmallScreen}
                activeMenuItem={activeMenuItem}
                onClick={() => setIsLanguageDialogOpen(true)}
                text={languageMap[language] || language}
                icon={
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {language.toUpperCase()}{" "}
                    {/* Display the two-letter language code */}
                  </Typography>
                }
              />
            </Box>
          </Drawer>
          <Dialog
            open={isLanguageDialogOpen}
            onClose={() => setIsLanguageDialogOpen(false)}
          >
            <DialogTitle>{t("languageSelect")}</DialogTitle>
            {/* You can use translation here */}
            <DialogContent>
              <Select
                value={language}
                onChange={handleLanguageChange}
                fullWidth
              >
                {Object.entries(languageMap).map(([code, lang]) => (
                  <MenuItem key={code} value={code}>
                    {lang}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>
          </Dialog>

          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <DialogTitle>{t("installApp")}</DialogTitle>
            <DialogContent>{t("installPrompt")}</DialogContent>
            <DialogActions>
              <Button
                onClick={confirmInstall}
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  "&:hover": { backgroundColor: "darkgreen" },
                }}
              >
                {t("yes")}
              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": { backgroundColor: "darkred" },
                }}
              >
                {t("no")}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </div>
      <Box
        sx={submenuStyle}
        style={{
          display: isSubmenuVisible ? "block" : "none",
        }}
        onMouseEnter={handleSubmenuMouseEnter}
        onMouseLeave={() => setIsSubmenuVisible(false)}
      >
        <List>
          {activeMenuItem === "Account" && (
            <>
              <SubmenuItem
                text={t("editProfile")}
                to="/profile"
                onClick={handleSubmenuItemClicked}
                icon={
                  <SettingsRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
              <SubmenuItem
                text={t("logout")}
                to="/"
                onClick={logout}
                icon={
                  <LogoutRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
            </>
          )}
          {activeMenuItem === "Learn" && (
            <>
              {/*<SubmenuItem
                text={t("chessPrinciples")}
                to="/chessprinciples"
                onClick={handleSubmenuItemClicked}
                icon={<HubRoundedIcon style={{ color: colors.black[900] }} />}
              />
              <SubmenuItem
                text={t("chessOpenings")}
                to="/chessopenings"
                onClick={handleSubmenuItemClicked}
                icon={
                  <AutoStoriesRoundedIcon
                    style={{ color: colors.material[10] }}
                  />
                }
              />*/}

              <SubmenuItem
                text={t("checkmateTrainer")}
                to="/checkmatetrainer"
                onClick={handleSubmenuItemClicked}
                icon={<TagIcon style={{ color: colors.material[10] }} />}
              />
              <SubmenuItem
                text={t("coordinateTrainer")}
                to="/coordinatetrainer"
                onClick={handleSubmenuItemClicked}
                icon={
                  <LocationSearchingIcon
                    style={{ color: colors.material[10] }}
                  />
                }
              />
              <SubmenuItem
                text={t("notationTrainer")}
                to="/notationtrainer"
                onClick={handleSubmenuItemClicked}
                icon={<EditNoteIcon style={{ color: colors.material[10] }} />}
              />
              <SubmenuItem
                text={t("pieceTrainer")}
                to="/piecetrainer"
                onClick={handleSubmenuItemClicked}
                icon={
                  <TurnSharpRightOutlinedIcon
                    style={{ color: colors.material[10] }}
                  />
                }
              />
              <SubmenuItem
                text={t("handAndBrain")}
                to="/handandbrain"
                onClick={handleSubmenuItemClicked}
                icon={
                  <HandAndBrainIcon style={{ color: colors.material[10] }} />
                }
              />
              <SubmenuItem
                text={t("hiddenChess")}
                to="/hiddenchess"
                onClick={handleSubmenuItemClicked}
                icon={
                  <VisibilityOffIcon style={{ color: colors.material[10] }} />
                }
              />
              <SubmenuItem
                text={t("whereAreMyPieces")}
                to="/wherearemypieces"
                onClick={handleSubmenuItemClicked}
                icon={<CheckersIcon style={{ color: colors.material[10] }} />}
              />
              <SubmenuItem
                text={t("chessResources")}
                to="/chessresources"
                onClick={handleSubmenuItemClicked}
                icon={<BookmarksIcon style={{ color: colors.material[10] }} />}
              />
            </>
          )}
          {activeMenuItem === "Analytics" && (
            <>
              <SubmenuItem
                text={t("repertoireBuilder")}
                to="/repertoirebuilder"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PlaylistAddCheckCircleIcon
                    style={{ color: colors.material[4] }}
                  />
                }
              />
              <SubmenuItem
                text={t("userScout")}
                to="/userscout"
                onClick={handleSubmenuItemClicked}
                icon={
                  <TroubleshootRoundedIcon
                    style={{ color: colors.material[4] }}
                  />
                }
              />
              <SubmenuItem
                text={t("opponentPrep")}
                to="/opponentprep"
                onClick={handleSubmenuItemClicked}
                icon={
                  <CenterFocusStrongRoundedIcon
                    style={{ color: colors.material[4] }}
                  />
                }
              />
              {/*<SubmenuItem
                text={t("openinganalysis")}
                to="/openinganalysis"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PlaylistAddCheckCircleIcon
                    style={{ color: colors.material[4] }}
                  />
                }
              />
              
              <SubmenuItem
                text="My Profile"
                to="/myprofile"
                onClick={handleSubmenuItemClicked}
                icon={
                  <TroubleshootRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              */}
            </>
          )}
          {activeMenuItem === "Tools" && (
            <>
              <SubmenuItem
                text={t("chessClock")}
                to="/chessclock"
                onClick={handleSubmenuItemClicked}
                icon={
                  <AccessAlarmRoundedIcon
                    style={{ color: colors.material[14] }}
                  />
                }
              />
              <SubmenuItem
                text={t("fischerRandom")}
                to="/fischerrandom"
                onClick={handleSubmenuItemClicked}
                icon={
                  <ShuffleRoundedIcon style={{ color: colors.material[14] }} />
                }
              />
              <SubmenuItem
                text={t("gifGenerator")}
                to="/GifGenerator"
                onClick={handleSubmenuItemClicked}
                icon={<GifIcon style={{ color: colors.material[14] }} />}
              />
              <SubmenuItem
                text={t("openingExplorer")}
                to="/openingexplorer"
                onClick={handleSubmenuItemClicked}
                icon={
                  <AccountTreeIcon style={{ color: colors.material[14] }} />
                }
              />
              <SubmenuItem
                text={t("pgnViewer")}
                to="/pgnviewer"
                onClick={handleSubmenuItemClicked}
                icon={<PGNIcon style={{ color: colors.material[14] }} />}
              />
              <SubmenuItem
                text={t("tablebase")}
                to="/tablebase"
                onClick={handleSubmenuItemClicked}
                icon={
                  <SwitchAccessShortcutAddIcon
                    style={{ color: colors.material[14] }}
                  />
                }
              />
              {/*<SubmenuItem
                text="ECF Ratings Explorer"
                to="/ecfratingsexplorer"
                onClick={handleSubmenuItemClicked}
                icon={<StorageIcon style={{ color: colors.black[900] }} />}
              />*/}
            </>
          )}
          {activeMenuItem === "Arcade" && (
            <>
              <SubmenuItem
                text={t("whackAPawn")}
                to="/whackapawn"
                onClick={handleSubmenuItemClicked}
                icon={<WhackIcon style={{ color: colors.material[1] }} />}
              />
              {/*<SubmenuItem
                text={t("chessCrusher")}
                to="/chesscrusher"
                onClick={handleSubmenuItemClicked}
                icon={<CrystalIcon style={{ color: colors.material[1] }} />}
              />*/}
              <SubmenuItem
                text={t("jumpingKnight")}
                to="/jumpingknight"
                onClick={handleSubmenuItemClicked}
                icon={<KnightIcon style={{ color: colors.material[1] }} />}
              />
            </>
          )}
          {activeMenuItem === "Games" && (
            <>
              <SubmenuItem
                text={t("playTheOpening")}
                to="/playtheopening"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PlayCircleFilledRoundedIcon
                    style={{ color: colors.material[1] }}
                  />
                }
              />

              <SubmenuItem
                text={t("guessTheOpening")}
                to="/guesstheopening"
                onClick={handleSubmenuItemClicked}
                icon={
                  <GridViewRoundedIcon style={{ color: colors.material[1] }} />
                }
              />

              <SubmenuItem
                text={t("guessTheElo")}
                to="/guesstheelo"
                onClick={handleSubmenuItemClicked}
                icon={
                  <HelpOutlineRoundedIcon
                    style={{ color: colors.material[1] }}
                  />
                }
              />

              <SubmenuItem
                text={t("guessTheEval")}
                to="/guesstheeval"
                onClick={handleSubmenuItemClicked}
                icon={<IsoIcon style={{ color: colors.material[1] }} />}
              />
              <SubmenuItem
                text={t("guessTheMove")}
                to="/guessthemove"
                onClick={handleSubmenuItemClicked}
                icon={
                  <GuessTheMoveIcon style={{ color: colors.material[1] }} />
                }
              />

              <SubmenuItem
                text={t("guessWho")}
                to="/guesswho"
                onClick={handleSubmenuItemClicked}
                icon={<GuessWhoIcon style={{ color: colors.material[1] }} />}
              />
              <SubmenuItem
                text={t("imagePuzzle")}
                to="/imagepuzzle"
                onClick={handleSubmenuItemClicked}
                icon={
                  <CropOriginalIcon style={{ color: colors.material[1] }} />
                }
              />
              <SubmenuItem
                text={t("rotatingImagePuzzle")}
                to="/rotatingimagepuzzle"
                onClick={handleSubmenuItemClicked}
                icon={
                  <ScreenRotationIcon style={{ color: colors.material[1] }} />
                }
              />
              <SubmenuItem
                text={t("cryptograms")}
                to="/cryptograms"
                onClick={handleSubmenuItemClicked}
                icon={
                  <GTranslateRoundedIcon
                    style={{ color: colors.material[1] }}
                  />
                }
              />
              <SubmenuItem
                text={t("decryptChess")}
                to="/decryptchess"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PublishedWithChangesIcon
                    style={{ color: colors.material[1] }}
                  />
                }
              />
              <SubmenuItem
                text={t("anagrams")}
                to="/anagrams"
                onClick={handleSubmenuItemClicked}
                icon={<AbcRoundedIcon style={{ color: colors.material[1] }} />}
              />

              <SubmenuItem
                text={t("hangman")}
                to="/hangman"
                onClick={handleSubmenuItemClicked}
                icon={
                  <SettingsInputAntennaRoundedIcon
                    style={{ color: colors.material[1] }}
                  />
                }
              />

              <SubmenuItem
                text={t("wordsearch")}
                to="/wordsearch"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PatternRoundedIcon style={{ color: colors.material[1] }} />
                }
              />
              <SubmenuItem
                text={t("crossword")}
                to="/crossword"
                onClick={handleSubmenuItemClicked}
                icon={<CrosswordIcon style={{ color: colors.material[1] }} />}
              />

              <SubmenuItem
                text={t("chessSlide")}
                to="/chessslide"
                onClick={handleSubmenuItemClicked}
                icon={
                  <OpenWithRoundedIcon style={{ color: colors.material[1] }} />
                }
              />
            </>
          )}
          {activeMenuItem === "Library" && (
            <>
              <SubmenuItem
                text={t("worldChampionships")}
                to="/worldchampionships"
                onClick={handleSubmenuItemClicked}
                icon={
                  <EmojiEventsRoundedIcon
                    style={{ color: colors.material[6] }}
                  />
                }
              />
              <SubmenuItem
                text={t("classicGames")}
                to="/classicgames"
                onClick={handleSubmenuItemClicked}
                icon={<HistoryIcon style={{ color: colors.material[6] }} />}
              />
              <SubmenuItem
                text={t("miniatureGames")}
                to="/miniaturegames"
                onClick={handleSubmenuItemClicked}
                icon={<KnockoutIcon style={{ color: colors.material[6] }} />}
              />
              <SubmenuItem
                text={t("glossary")}
                to="/glossary"
                onClick={handleSubmenuItemClicked}
                icon={
                  <FormatListBulletedRoundedIcon
                    style={{ color: colors.material[6] }}
                  />
                }
              />
              <SubmenuItem
                text={t("quotes")}
                to="/quotes"
                onClick={handleSubmenuItemClicked}
                icon={
                  <FormatQuoteRoundedIcon
                    style={{ color: colors.material[6] }}
                  />
                }
              />
              <SubmenuItem
                text={t("basicRulesOfPlay")}
                to="/basicrulesofplay"
                onClick={handleSubmenuItemClicked}
                icon={
                  <GavelRoundedIcon style={{ color: colors.material[6] }} />
                }
              />
              <SubmenuItem
                text={t("competitiveRulesOfPlay")}
                to="/competitiverulesofplay"
                onClick={handleSubmenuItemClicked}
                icon={<CompetitionIcon style={{ color: colors.material[6] }} />}
              />
              {/*
              
              <SubmenuItem
                text="Classic Games"
                to="/classicgames"
                onClick={handleSubmenuItemClicked}
                icon={
                  <HistoryRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
              <SubmenuItem
                text="Immortal Games"
                to="/immortalgames"
                onClick={handleSubmenuItemClicked}
                icon={
                  <AllInclusiveRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Grandmaster Games"
                to="/grandmastergames"
                onClick={handleSubmenuItemClicked}
                icon={<RocketRoundedIcon style={{ color: colors.black[900] }} />}
              />*/}
            </>
          )}
        </List>
      </Box>
    </>
  );
};

export default AppSideBar;
