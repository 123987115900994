import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  useTheme,
  TextField,
  Button,
  TextareaAutosize,
  Typography,
  Avatar,
  CircularProgress,
  Alert,
  useMediaQuery,
} from "@mui/material";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import html2canvas from "html2canvas";
import GIF from "gif.js.optimized";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useUser } from "../../../context/UserContext";
import styles from "./GifGenerator.css";
import { Helmet } from "react-helmet";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

function GifGenerator() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chessboardRef = useRef();
  const [gif, setGif] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const isCancelledRef = useRef(false);
  const [position, setPosition] = useState("start");
  const [fileName, setFileName] = useState("chess_game");
  const [pgnInput, setPgnInput] = useState("");
  const [speed, setSpeed] = useState(1);
  const [firstMoveDelay, setFirstMoveDelay] = useState(2);
  const [exitDelay, setExitDelay] = useState(2);
  const [introDelay, setIntroDelay] = useState(2);
  const [outroDelay, setOutroDelay] = useState(5);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const { userData } = useUser();
  const gifCreatorRef = useRef(null);

  const [game, setGame] = useState(new Chess());
  const [currentMove, setCurrentMove] = useState(0);
  const [isPgnValid, setIsPgnValid] = useState(false);

  const [whitePlayer, setWhitePlayer] = useState("");
  const [whiteElo, setWhiteElo] = useState("");
  const [blackPlayer, setBlackPlayer] = useState("");
  const [blackElo, setBlackElo] = useState("");
  const [whiteImage, setWhiteImage] = useState(null);
  const [blackImage, setBlackImage] = useState(null);
  const [introImage, setIntroImage] = useState(null);
  const [outroImage, setOutroImage] = useState(null);
  const [orientation, setOrientation] = useState("white");

  const [moves, setMoves] = useState([]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { setShareData } = useShare();
  const { t } = useTranslation("Tools");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/gifgenerator",
      title: "Chessboard Magic - GIF Generator",
      description:
        "Turn Chess PGNs into visually engaging sequences effortlessly with our GIF Generator.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const textareaStyle = {
    width: "100%",
    padding: "10px",
    resize: "none",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.5",
  };

  const toggleOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };

  const showAlertWithMessage = useCallback(
    (message, severity = "info", duration = 3000) => {
      setAlertMessage(message);
      setAlertSeverity(severity);
      setAlertOpen(true);
      setTimeout(() => {
        setAlertOpen(false);
        setAlertMessage("");
      }, duration);
    },
    []
  );

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleGenerateGif = async () => {
    setIsPlaying(true);
    isCancelledRef.current = false;

    const gifCreator = new GIF({
      workers: 2,
      quality: 10,
      workerScript: `${process.env.PUBLIC_URL}/js/gif.worker.js`,
    });

    gifCreatorRef.current = gifCreator;

    const captureFrame = async (element, delayTime) => {
      const canvas = await html2canvas(element, {
        useCORS: true,
        scale: 2,
      });
      gifCreator.addFrame(canvas, { delay: delayTime * 1000, copy: true });
    };

    const captureImageFrame = async (src, width, height, delayTime) => {
      const img = new Image();
      img.src = src;
      img.style.width = `${width}px`;
      img.style.height = `${height}px`;
      img.style.position = "absolute"; // Position it absolutely
      img.style.left = "-9999px"; // Move it off-screen
      document.body.appendChild(img); // Add to DOM
      await delay(100); // Ensure the image is fully rendered
      await captureFrame(img, delayTime); // Capture the frame
      document.body.removeChild(img); // Remove from DOM
    };

    const createGif = async () => {
      // Reset the board to the starting position
      setPosition("start");
      setCurrentMove(0);

      const chessboardElement = chessboardRef.current;
      const { width, height } = chessboardElement.getBoundingClientRect();

      gifCreator.setOption("width", width * 2);
      gifCreator.setOption("height", height * 2);

      if (introImage) {
        await captureImageFrame(introImage, width, height, introDelay);
      }

      const moves = game.history({ verbose: true });
      const newGame = new Chess(); // Create a new instance to reset the game

      await delay(1000); // Ensure the state update completes

      // Capture the initial position with first move delay
      await captureFrame(chessboardElement, firstMoveDelay);

      for (const move of moves) {
        if (isCancelledRef.current) {
          setIsPlaying(false);
          isCancelledRef.current = false;
          return;
        }

        newGame.move(move.san);
        setPosition(newGame.fen());
        await delay(speed * 1000); // Wait for the specified delay
        await captureFrame(chessboardElement, speed);
      }

      // Capture the final position with exit delay
      await captureFrame(chessboardElement, exitDelay);

      if (outroImage) {
        await captureImageFrame(outroImage, width, height, outroDelay);
      }

      gifCreator.on("finished", (blob) => {
        if (isCancelledRef.current) {
          setIsPlaying(false);
          isCancelledRef.current = false;
          return;
        }

        const url = URL.createObjectURL(blob);
        setGif(url);
        setIsPlaying(false);
      });

      gifCreator.render();
    };

    await createGif();
    setPosition("start");
    setCurrentMove(0);
    await delay(1000);
  };

  const handleCancel = () => {
    if (gifCreatorRef.current) {
      gifCreatorRef.current.abort();
    }
    isCancelledRef.current = true;
    setGif(null);
    setPosition("start");
    setCurrentMove(0);
  };

  const goToMove = useCallback(
    (moveIndex) => {
      try {
        const newGame = new Chess();
        if (moves.length > 0) {
          for (let i = 0; i < moveIndex; i++) {
            newGame.move(moves[i]);
          }
        }
        setGame(newGame);
        setCurrentMove(moveIndex);
        setPosition(newGame.fen());
      } catch (error) {}
    },
    [moves]
  );

  const handleNextMove = useCallback(() => {
    if (currentMove < moves.length) {
      const nextMove = moves[currentMove].move;
      if (!["1-0", "0-1", "1/2-1/2", "*"].includes(nextMove)) {
        goToMove(currentMove + 1);
      }
    }
  }, [currentMove, goToMove, moves]);

  const handlePreviousMove = useCallback(() => {
    if (currentMove > 0) {
      goToMove(currentMove - 1);
    }
  }, [goToMove, currentMove]);

  const handleResetGame = useCallback(() => {
    setCurrentMove(0);
    setPosition("start");
  }, []);

  const handleLastMove = useCallback(() => {
    goToMove(moves.length);
  }, [goToMove, moves.length]);

  const handlePgnInputChange = (e) => {
    setPgnInput(e.target.value);
  };

  const handleLoadPgn = () => {
    const newGame = new Chess();
    try {
      newGame.loadPgn(pgnInput);
      setMoves(newGame.history());
      setGame(newGame);
      setPosition("start");
      setGif(null);
      setCurrentMove(0);
      setIsPgnValid(true);
      showAlertWithMessage(
        t("GifGenerator.messages.pgnLoaded"),
        "success",
        1000
      );
    } catch (error) {
      setGame(new Chess());
      setPosition("start");
      setCurrentMove(0);
      setIsPgnValid(false);
      showAlertWithMessage(t("GifGenerator.messages.invalidPgn"), "error");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (alertOpen) return;
      if (event.key === "ArrowRight") {
        handleNextMove();
      } else if (event.key === "ArrowLeft") {
        handlePreviousMove();
      }
    };

    if (!isPlaying) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentMove, handleNextMove, handlePreviousMove, isPlaying, alertOpen]);

  const handleImageUpload = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <ContentHeader
        title={t("GifGenerator.header.title")}
        subtitle={t("GifGenerator.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[14]}
      />
      <Helmet>
        <title>GIF Generator</title>
        <meta
          name="description"
          content="Turn Chess PGNs into visually engaging sequences effortlessly with our GIF Generator."
        />
        <meta property="og:title" content="GIF Generator" />
        <meta
          property="og:description"
          content="Turn Chess PGNs into visually engaging sequences effortlessly with our GIF Generator."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/gifgenerator.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/gifgenerator`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="GIF Generator" />
        <meta
          name="twitter:description"
          content="Turn Chess PGNs into visually engaging sequences effortlessly with our GIF Generator."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/gifgenerator.png`}
        />
      </Helmet>
      <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
        <Box sx={{ width: "100%", maxWidth: "500px", mr: 2 }}>
          <Box sx={{ width: "100%", maxWidth: "500px", mr: 2 }}>
            <div ref={chessboardRef} style={{ width: "100%" }}>
              <Box sx={{ width: "100%", maxWidth: "500px", mr: 2 }}>
                {/* Black Player Details */}
                {(blackPlayer || blackElo || blackImage) &&
                  orientation === "white" && (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        backgroundColor: "black",
                        padding: "8px 8px 8px 12px",
                        color: "white",
                      }}
                    >
                      {blackImage && (
                        <Avatar
                          src={blackImage}
                          alt={blackPlayer}
                          variant="square"
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <Box display="flex" flexDirection="column">
                        {blackPlayer && <Typography>{blackPlayer}</Typography>}
                        {blackElo && <Typography>{blackElo}</Typography>}
                      </Box>
                    </Box>
                  )}
                {(whitePlayer || whiteElo || whiteImage) &&
                  orientation === "black" && (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        backgroundColor: "white",
                        padding: "8px 8px 8px 12px",
                        border: "1px solid #DDDDDD",
                        color: "black",
                      }}
                    >
                      {whiteImage && (
                        <div>
                          <Avatar
                            src={whiteImage}
                            alt={whitePlayer}
                            variant="square"
                            style={{ marginRight: 10 }}
                          />
                        </div>
                      )}
                      <Box display="flex" flexDirection="column">
                        {whitePlayer && <Typography>{whitePlayer}</Typography>}
                        {whiteElo && <Typography>{whiteElo}</Typography>}
                      </Box>
                    </Box>
                  )}

                {/* Chessboard */}
                <div ref={chessboardRef} style={{ width: "100%" }}>
                  <Chessboard
                    position={position}
                    boardOrientation={orientation}
                    customLightSquareStyle={{
                      backgroundColor:
                        themeColors[userData?.theme || "White Stripe Theme"]
                          .lightSquare,
                      backgroundImage:
                        themeColors[userData?.theme || "White Stripe Theme"]
                          .lightSquarePattern,
                    }}
                    customDarkSquareStyle={{
                      backgroundColor:
                        themeColors[userData?.theme || "White Stripe Theme"]
                          .darkSquare,
                      backgroundImage:
                        themeColors[userData?.theme || "White Stripe Theme"]
                          .darkSquarePattern,
                    }}
                    customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
                    areArrowsAllowed={false}
                    arePiecesDraggable={false}
                  />
                </div>

                {/* White Player Details */}
                {(whitePlayer || whiteElo || whiteImage) &&
                  orientation === "white" && (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        backgroundColor: "white",
                        padding: "8px 8px 8px 12px",
                        border: "1px solid #DDDDDD",
                        color: "black",
                      }}
                    >
                      {whiteImage && (
                        <div>
                          <Avatar
                            src={whiteImage}
                            alt={whitePlayer}
                            variant="square"
                            style={{ marginRight: 10 }}
                          />
                        </div>
                      )}
                      <Box display="flex" flexDirection="column">
                        {whitePlayer && <Typography>{whitePlayer}</Typography>}
                        {whiteElo && <Typography>{whiteElo}</Typography>}
                      </Box>
                    </Box>
                  )}
                {(blackPlayer || blackElo || blackImage) &&
                  orientation === "black" && (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        backgroundColor: "black",
                        padding: "8px 8px 8px 12px",
                        color: "white",
                      }}
                    >
                      {blackImage && (
                        <Avatar
                          src={blackImage}
                          alt={blackPlayer}
                          variant="square"
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <Box display="flex" flexDirection="column">
                        {blackPlayer && <Typography>{blackPlayer}</Typography>}
                        {blackElo && <Typography>{blackElo}</Typography>}
                      </Box>
                    </Box>
                  )}
              </Box>
            </div>
          </Box>

          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              onClick={handleResetGame}
              style={{ marginRight: 10 }}
              disabled={currentMove === 0 || isPlaying}
              sx={{ padding: 0, minWidth: 40 }}
            >
              <ReplayIcon />
            </Button>
            <Button
              variant="contained"
              onClick={handlePreviousMove}
              disabled={currentMove === 0 || isPlaying || !isPgnValid}
              style={{ marginRight: 10 }}
              sx={{ padding: 0, minWidth: 40 }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </Button>
            <Button
              variant="contained"
              onClick={handleNextMove}
              disabled={
                isPlaying ||
                currentMove >= moves.length ||
                ["1-0", "0-1", "1/2-1/2", "*"].includes(
                  moves[currentMove]?.move
                ) ||
                !isPgnValid
              }
              style={{ marginRight: 10 }}
              sx={{ padding: 0, minWidth: 40 }}
            >
              <KeyboardDoubleArrowRightIcon />
            </Button>
            <Button
              variant="contained"
              onClick={handleLastMove}
              disabled={currentMove >= moves.length || isPlaying || !isPgnValid}
              style={{ marginRight: 10 }}
              sx={{ padding: 0, minWidth: 40 }}
            >
              <SkipNextIcon />
            </Button>
            <Button
              variant="contained"
              onClick={toggleOrientation}
              sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
            >
              <SwapVertRoundedIcon />
            </Button>
          </Box>
          <Box>
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              {!isPlaying ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateGif}
                  disabled={!isPgnValid}
                  sx={{ mr: 2, mb: 2, mt: 0.5 }}
                >
                  {t("GifGenerator.buttons.generate")}
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                    sx={{ marginRight: 2 }}
                  >
                    {t("GifGenerator.buttons.cancel")}
                  </Button>
                  <CircularProgress
                    size={25}
                    sx={{ color: colors.black[900], mr: 1, mt: 0.5 }}
                  />
                  <Typography variant="body1" sx={{ mt: 0.5 }}>
                    {t("GifGenerator.labels.generating")}
                  </Typography>
                </>
              )}
              {gif && !isPlaying && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <TextField
                    value={fileName}
                    variant="standard"
                    onChange={(e) => setFileName(e.target.value)}
                    sx={{ marginRight: 2, height: "30px" }}
                    InputProps={{
                      style: {
                        color: "black",
                        background: "#EEEEEE",
                        paddingLeft: "10px",
                        paddingBottom: "2px",
                      },
                    }}
                  />
                  <a href={gif} download={`${fileName}.gif`}>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 0.5 }}
                    >
                      {t("GifGenerator.buttons.download")}
                    </Button>
                  </a>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", maxWidth: "500px", p: 1 }}>
          <Box>
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>
              {t("GifGenerator.labels.pgnInput")}
            </Typography>
            <TextareaAutosize
              minRows={4}
              placeholder={t("GifGenerator.labels.enterPGNhere")}
              value={pgnInput}
              onChange={handlePgnInputChange}
              style={textareaStyle}
              className={styles["textarea-autosize"]}
              disabled={isPlaying}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadPgn}
                disabled={isPlaying}
                sx={{ mr: 1, mb: 1 }}
              >
                {t("GifGenerator.buttons.loadPgn")}
              </Button>
              {alertOpen && (
                <Box>
                  <Alert
                    sx={{ maxHeight: "40px", paddingY: 0, paddingX: 1 }}
                    severity={alertSeverity}
                  >
                    {alertMessage}
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            {t("GifGenerator.labels.speedSettings")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              alignItems: "left",
              mt: 1,
              mb: 1,
            }}
          >
            <TextField
              type="number"
              value={speed}
              variant="filled"
              id="seconds-per-move"
              label={t("GifGenerator.labels.secondsPerMove")}
              onChange={(e) => setSpeed(Number(e.target.value))}
              inputProps={{ min: 0.5, step: 0.5 }}
              sx={{
                width: "100%",
                mr: isSmallScreen ? 0 : 2,
                mb: isSmallScreen ? 2 : 0,
              }}
              disabled={isPlaying}
              InputLabelProps={{
                style: { color: "black" },
              }}
            />
            <TextField
              type="number"
              value={firstMoveDelay}
              variant="filled"
              id="seconds-before-start"
              label={t("GifGenerator.labels.secondsBeforeStart")}
              onChange={(e) => setFirstMoveDelay(Number(e.target.value))}
              inputProps={{ min: 2, step: 0.5 }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                sx: { borderBottomColor: "blue" },
              }}
              sx={{
                width: "100%",
                mr: isSmallScreen ? 0 : 2,
                mb: isSmallScreen ? 2 : 0,
              }}
              disabled={isPlaying}
            />
            <TextField
              type="number"
              value={exitDelay}
              variant="filled"
              id="seconds-before-exit"
              label={t("GifGenerator.labels.secondsBeforeExit")}
              onChange={(e) => setExitDelay(Number(e.target.value))}
              inputProps={{ min: 2, step: 0.5 }}
              sx={{ width: "100%", mb: isSmallScreen ? 2 : 0 }}
              disabled={isPlaying}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                sx: { borderBottomColor: "blue" },
              }}
            />
          </Box>
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            {t("GifGenerator.labels.playersDetails")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexBasis: "48%",
              }}
            >
              <TextField
                label={t("GifGenerator.labels.whitePlayer")}
                value={whitePlayer}
                onChange={(e) => setWhitePlayer(e.target.value)}
                fullWidth
                variant="filled"
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { color: "black" } }}
                disabled={isPlaying}
              />
              <TextField
                label={t("GifGenerator.labels.whiteElo")}
                value={whiteElo}
                onChange={(e) => setWhiteElo(e.target.value)}
                fullWidth
                variant="filled"
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { color: "black" } }}
                disabled={isPlaying}
              />
              <Button
                variant="contained"
                component="label"
                sx={{ mb: 2, width: "100%" }}
                disabled={isPlaying}
              >
                {t("GifGenerator.buttons.uploadImage")}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleImageUpload(e, setWhiteImage)}
                />
              </Button>
              {whiteImage && (
                <div>
                  <img
                    src={whiteImage}
                    alt="White Player"
                    style={{ width: "100%", marginBottom: 10 }}
                  />
                  <Typography
                    variant="body2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setWhiteImage(null)}
                  >
                    {t("GifGenerator.buttons.clearImage")}
                  </Typography>
                </div>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexBasis: "48%",
              }}
              mb={2}
            >
              <TextField
                label={t("GifGenerator.labels.blackPlayer")}
                value={blackPlayer}
                onChange={(e) => setBlackPlayer(e.target.value)}
                fullWidth
                variant="filled"
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { color: "black" } }}
                disabled={isPlaying}
              />
              <TextField
                label={t("GifGenerator.labels.blackElo")}
                value={blackElo}
                onChange={(e) => setBlackElo(e.target.value)}
                fullWidth
                variant="filled"
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { color: "black" } }}
                disabled={isPlaying}
              />
              <Button
                variant="contained"
                component="label"
                sx={{ mb: 2, width: "100%" }}
                disabled={isPlaying}
              >
                {t("GifGenerator.buttons.uploadImage")}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleImageUpload(e, setBlackImage)}
                />
              </Button>
              {blackImage && (
                <div>
                  <img
                    src={blackImage}
                    alt="Black Player"
                    style={{ width: "100%", marginBottom: 10 }}
                  />
                  <Typography
                    variant="body2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setBlackImage(null)}
                    sx={{ ml: 1 }}
                  >
                    {t("GifGenerator.buttons.clearImage")}
                  </Typography>
                </div>
              )}
            </Box>
          </Box>
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            {t("GifGenerator.labels.gifIntro")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              mt: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "50%",
                pr: 2,
              }}
            >
              <TextField
                type="number"
                value={introDelay}
                variant="filled"
                id="seconds-before-intro"
                label={t("GifGenerator.labels.secondsForIntro")}
                onChange={(e) => setIntroDelay(Number(e.target.value))}
                inputProps={{ min: 2, step: 0.5 }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  sx: { borderBottomColor: "blue" },
                }}
                sx={{ width: "100%", marginBottom: "10px" }}
                disabled={isPlaying}
              />
              <Button
                variant="contained"
                component="label"
                sx={{ mb: 2, width: "100%" }}
                disabled={isPlaying}
              >
                {t("GifGenerator.buttons.uploadImage")}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleImageUpload(e, setIntroImage)}
                />
              </Button>
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
            >
              {introImage && (
                <div style={{ width: "100%" }}>
                  <img
                    src={introImage}
                    alt="Intro"
                    style={{ width: "100%", marginBottom: 10 }}
                  />
                  <Typography
                    variant="body2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIntroImage(null)}
                  >
                    {t("GifGenerator.buttons.clearImage")}
                  </Typography>
                </div>
              )}
            </Box>
          </Box>

          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            {t("GifGenerator.labels.gifOutro")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              mt: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "50%",
                pr: 2,
              }}
            >
              <TextField
                type="number"
                value={outroDelay}
                variant="filled"
                id="seconds-for-outro"
                label={t("GifGenerator.labels.secondsForOutro")}
                onChange={(e) => setOutroDelay(Number(e.target.value))}
                inputProps={{ min: 2, step: 0.5 }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  sx: { borderBottomColor: "blue" },
                }}
                sx={{ width: "100%", marginBottom: "10px" }}
                disabled={isPlaying}
              />
              <Button
                variant="contained"
                component="label"
                sx={{ mb: 2, width: "100%" }}
                disabled={isPlaying}
              >
                {t("GifGenerator.buttons.uploadImage")}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleImageUpload(e, setOutroImage)}
                />
              </Button>
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
            >
              {outroImage && (
                <div style={{ width: "100%" }}>
                  <img src={outroImage} alt="Outro" style={{ width: "100%" }} />
                  <Typography
                    variant="body2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOutroImage(null)}
                  >
                    {t("GifGenerator.buttons.clearImage")}
                  </Typography>
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GifGenerator;
