import { useMemo } from "react";

const useBoardHighlight = () => {
  return useMemo(
    () => ({
      default: "#0D47A1", // Blue
      shift: "#C62828", // Red
      ctrl: "#1B5E20", // Green
      alt: "#F57C00", // Orange
    }),
    []
  );
};

export default useBoardHighlight;
