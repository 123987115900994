import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import GuessTheEvalHelp from "../../../help/GuessTheEvalHelp";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
import { Helmet } from "react-helmet";
import { guesstheeval } from "../../../data/guesstheeval/guesstheeval";
import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

const GuessButton = ({ mainText, subText, onClick, disabled }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{
      fontSize: "10px",
      padding: "10px",
      whiteSpace: "normal",
      minHeight: "50px",
      textAlign: "center",
      marginBottom: "10px",
      marginRight: "10px",
      width: "80px",
      lineHeight: "1",
      textTransform: "none",
    }}
    disabled={disabled}
  >
    <div>
      <Typography sx={{ fontSize: "13px" }}>{mainText}</Typography>
    </div>
  </Button>
);

function GuessTheEval() {
  const [game, setGame] = useState(new Chess());
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [gameStarted, setGameStarted] = useState(false);
  const [gameEval, setGameEval] = useState(0);
  const [toPlay, setToPlay] = useState("");
  const [evalDescription, setEvalDescription] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [modernMoves, setModernMoves] = useState([]);
  const { gameId } = useParams();
  const [fen, setFen] = useState("");
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Games");
  const [orientation, setOrientation] = useState("white");

  const countPiecesNotOnHomeSquares = (fen) => {
    // Define home squares for black and white pieces
    const homeSquares = {
      r: ["a8", "h8"],
      n: ["b8", "g8"],
      b: ["c8", "f8"],
      q: ["d8"],
      k: ["e8"],
      p: ["a7", "b7", "c7", "d7", "e7", "f7", "g7", "h7"],
      R: ["a1", "h1"],
      N: ["b1", "g1"],
      B: ["c1", "f1"],
      Q: ["d1"],
      K: ["e1"],
      P: ["a2", "b2", "c2", "d2", "e2", "f2", "g2", "h2"],
    };

    const fenParts = fen.split(" ")[0];
    const board = fenParts.split("/");

    let piecesNotOnHome = 0;

    board.forEach((rank, rowIdx) => {
      let colIdx = 0;
      for (let i = 0; i < rank.length; i++) {
        const square = rank[i];
        if (isNaN(square)) {
          const file = "abcdefgh"[colIdx];
          const piecePosition = `${file}${8 - rowIdx}`;
          const piece = square;

          if (!homeSquares[piece]?.includes(piecePosition)) {
            piecesNotOnHome++;
          }
          colIdx++;
        } else {
          colIdx += parseInt(square);
        }
      }
    });

    return piecesNotOnHome;
  };

  const handleNewGame = useCallback(
    (gameId) => {
      // Filter the games to include only those with at least 10 pieces not on their starting squares
      const filteredGames = guesstheeval.filter(
        (game) => countPiecesNotOnHomeSquares(game.FEN) >= 10
      );

      let game;
      const numericGameId = Number(gameId);
      if (
        !isNaN(numericGameId) &&
        numericGameId >= 0 &&
        numericGameId < filteredGames.length
      ) {
        game = filteredGames[numericGameId];
      } else {
        // Otherwise, pick a random game from the filtered list
        let randomNumber = getRandomNumber(filteredGames.length);
        game = filteredGames[randomNumber];
      }

      setFen(game.FEN);
      const newGame = new Chess(game.FEN);
      const moves = game.MOVES.split(" ");
      const newModernMoves = [];

      moves.forEach((move) => {
        if (move === "e1h1") {
          newModernMoves.push("O-O");
          newGame.move("O-O");
        } else if (move === "e8h8") {
          newModernMoves.push("O-O");
          newGame.move("O-O");
        } else if (move === "e1a1") {
          newModernMoves.push("O-O-O");
          newGame.move("O-O-O");
        } else if (move === "e8a8") {
          newModernMoves.push("O-O-O");
          newGame.move("O-O-O");
        } else {
          const result = newGame.move(move);
          if (result) {
            newModernMoves.push(result.san); // Get the move in modern notation (SAN)
          }
        }
      });

      setModernMoves(newModernMoves);

      // Reset the game back to the original FEN
      const originalGame = new Chess(game.FEN);

      const parts = game.FEN.split(" ");
      const firstLetterAfterSpace = parts[1];
      const toPlay =
        firstLetterAfterSpace === "b"
          ? t("GuessTheEval.game.black")
          : t("GuessTheEval.game.white");
      setToPlay(toPlay);
      setOrientation(firstLetterAfterSpace === "b" ? "black" : "white");

      setGameEval(typeof game.EVAL === "number" ? game.EVAL / 100 : game.EVAL);
      setEvalDescription(getEvalDescription(game.EVAL));

      setGame(originalGame); // Update the game state with the new game
      setGameStarted(true);
      setResultMessage("");

      // GA Tracking
      trackEvent("Games", "GuessTheEval-Play", "Guess The Eval");
      // Internal Tracking
      if (userData) {
        if (!userData.Puzzles) {
          userData.Puzzles = {};
        }
        if (userData.Puzzles.GuessTheEval) {
          userData.Puzzles.GuessTheEval.Played =
            (userData.Puzzles.GuessTheEval.Played || 0) + 1;
        } else {
          userData.Puzzles.GuessTheEval = {
            Played: 1,
            Completed: 0,
          };
        }
        updateUserData(userRef, userData);
      }
    },
    [userData, userRef, t]
  );

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/guesstheeval",
      title: "Chessboard Magic - Guess The Eval",
      description:
        "Test your accuracy in evaluating chess positions in this fun and challenging game, and improve your ability with each guess",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    if (gameId) {
      handleNewGame(gameId);
    }
  }, [gameId, handleNewGame]);

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  function getRandomNumber(n) {
    return Math.floor(Math.random() * n);
  }

  const getEvalDescription = (evaluation) => {
    if (typeof evaluation === "string") {
      return evaluation;
    }
    if (evaluation / 100 > 2.0) {
      return "White is winning";
    } else if (evaluation / 100 > 0.5) {
      return "White is better";
    } else if (evaluation / 100 >= -0.5) {
      return "Position is equal";
    } else if (evaluation / 100 > -2.0) {
      return "Black is better";
    } else {
      return "Black is winning";
    }
  };

  const handleGuess = (guess) => {
    const isMateInPositive =
      evalDescription.includes("M") &&
      parseInt(evalDescription.split("M")[1]) > 0;
    const isMateInNegative =
      evalDescription.includes("M") &&
      parseInt(evalDescription.split("M")[1]) < 0;

    if (
      guess === evalDescription ||
      (guess === "White is winning" && isMateInPositive) ||
      (guess === "Black is winning" && isMateInNegative)
    ) {
      setResultMessage(t("GuessTheEval.game.correct"));
      // GA Tracking
      trackEvent("Games", "GuessTheEval-Success", "Guess The Eval");
      // Internal Tracking
      if (userData) {
        userData.Puzzles.GuessTheEval.Completed =
          (userData.Puzzles.GuessTheEval.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }
    } else {
      setResultMessage(t("GuessTheEval.game.incorrect"));

      // GA Tracking
      trackEvent("Games", "GuessTheEval-Failed", "Guess The Eval");
      // Internal Tracking
      if (userData) {
        userData.Puzzles.GuessTheEval.Failed =
          (userData.Puzzles.GuessTheEval.Failed || 0) + 1;
        updateUserData(userRef, userData);
      }
    }

    setGameStarted(false);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMoveClickReset = () => {
    const newGame = new Chess(fen); // Start with the current game position
    setGame(newGame); // Update the game state with the new game position
  };

  const handleMoveClick = (moveIndex) => {
    const newGame = new Chess(fen); // Start with the current game position
    for (let i = 0; i <= moveIndex; i++) {
      newGame.move(modernMoves[i]);
    }
    setGame(newGame); // Update the game state with the new game position
  };

  return (
    <Box>
      <ContentHeader
        title={t("GuessTheEval.header.title")}
        subtitle={t("GuessTheEval.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Guess The Evaluation</title>
        <meta
          name="description"
          content="Test your accuracy in evaluating chess positions in this fun and challenging game, and improve your ability with each guess."
        />
        <meta property="og:title" content="Guess The Evaluation" />
        <meta
          property="og:description"
          content="Test your accuracy in evaluating chess positions in this fun and challenging game, and improve your ability with each guess."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/guesstheeval.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/guesstheeval`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Guess The Evaluation" />
        <meta
          name="twitter:description"
          content="Test your accuracy in evaluating chess positions in this fun and challenging game, and improve your ability with each guess."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/guesstheeval.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("GuessTheEval.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("GuessTheEval.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("GuessTheEval.helpDetails.title")}
          content={<GuessTheEvalHelp />}
        ></HelpModal>
      </Box>
      <Box
        sx={{
          p: "20px 0px 0px 0px",
          display: "flex",
          flexDirection: {
            xs: "column", // All screens smaller than 'sm'
            sm: "column", // All screens smaller than 'md'
            md: "row", // Medium screens and larger
          },
          alignItems: "flex-start", // Align items at the start of the flex container
          width: "100%", // Use the full width of the container
        }}
      >
        <div
          id="chessboard"
          style={{
            width: "100%", // Full width in column layout
            maxWidth: "500px", // Maximum width to constrain the chessboard
            padding: "0px 10px 10px 0px", // Uniform padding
            boxSizing: "border-box", // Include padding and border in the element's total width and height
          }}
        >
          <Chessboard
            position={game.fen()}
            arePiecesDraggable={false}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "White Stripe Theme"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "White Stripe Theme"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
            boardOrientation={orientation}
          />
        </div>

        <div
          id="results"
          style={{
            flex: 1,
            padding: "0px 0px 10px 0px", // Uniform padding
          }}
        >
          {toPlay && (
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Arrange elements vertically
                alignItems: "flex-start", // Align items at the start of the flex container
                margin: "10px 0px 10px 0px",
              }}
            >
              <Typography component="span" style={{ marginBottom: "0px" }}>
                {toPlay} {t("GuessTheEval.game.to_play")}
              </Typography>
              {resultMessage && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {resultMessage === t("GuessTheEval.game.correct") ? (
                      <CheckCircleOutlineIcon
                        style={{
                          color: "green",
                          marginRight: "5px",
                          fontSize: "32px",
                        }}
                      />
                    ) : resultMessage === t("GuessTheEval.game.incorrect") ? (
                      <ErrorOutlineIcon
                        style={{
                          color: "red",
                          marginRight: "5px",
                          fontSize: "32px",
                        }}
                      />
                    ) : null}
                    <Typography component="span">
                      {resultMessage}! {t("GuessTheEval.game.eval_description")}{" "}
                      <b>{gameEval}</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap", // Added this line to allow wrapping
                    }}
                  >
                    <MemoryRoundedIcon
                      style={{
                        color: colors.grey[700],
                        marginRight: "5px",
                        fontSize: "32px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    />
                    <Typography
                      component="span"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <span
                        onClick={() => handleMoveClickReset()}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          marginRight: "5px",
                        }}
                      >
                        {t("GuessTheEval.buttons.reset")}
                      </span>
                      {modernMoves
                        .filter((_, index) => index < 8)
                        .map((move, index) => (
                          <span
                            key={index}
                            onClick={() => handleMoveClick(index)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              marginRight: "5px",
                              marginBottom: "5px", // Optional: Adds some spacing between wrapped lines
                            }}
                          >
                            {move}
                          </span>
                        ))}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          )}

          <GuessButton
            mainText={t("GuessTheEval.buttons.whiteIsWinning")}
            subText="+2"
            onClick={() => handleGuess("White is winning")}
            disabled={!gameStarted}
          />
          <GuessButton
            mainText={t("GuessTheEval.buttons.whiteIsBetter")}
            subText="+0.5"
            onClick={() => handleGuess("White is better")}
            disabled={!gameStarted}
          />
          <GuessButton
            mainText={t("GuessTheEval.buttons.positionIsEqual")}
            subText="0.5 to -0.5"
            onClick={() => handleGuess("Position is equal")}
            disabled={!gameStarted}
          />
          <GuessButton
            mainText={t("GuessTheEval.buttons.blackIsBetter")}
            subText="-0.5"
            onClick={() => handleGuess("Black is better")}
            disabled={!gameStarted}
          />
          <GuessButton
            mainText={t("GuessTheEval.buttons.blackIsWinning")}
            subText="-2"
            onClick={() => handleGuess("Black is winning")}
            disabled={!gameStarted}
          />
        </div>
      </Box>
    </Box>
  );
}

export default GuessTheEval;
