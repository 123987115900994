import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";

import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import HandymanIcon from "@mui/icons-material/Handyman";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { v4 as uuidv4 } from "uuid";
import MovesCard from "../Components/movesCard";

import { themeColors } from "../../../../styles/boardtheme";
import { pieceSets } from "../../../../styles/pieceset";
import { openings } from "../../../../data/eco/eco";

import { addRepertoire } from "../../../../config/firebaseDB";

import useDialogFocused from "../Components/useDialogFocused";
import { useAlert } from "../../../../context/AlertProvider";

const RepertoireWizardDialog = ({
  open,
  onClose,
  userData,
  setRepertoires,
  settings,
  totalRepertoires,
  setTotalRepertoires,
  totalMoves,
  setTotalMoves,
}) => {
  const [game, setGame] = useState(new Chess()); // Chess.js instance

  const [repertoireColor, setRepertoireColor] = useState("white"); // Default White
  const [depth, setDepth] = useState(10); // Default depth
  const [pv, setPV] = useState(3); // Default depth
  const [stockfishDepth, setStockfishDepth] = useState(12); // Default depth
  const [loading, setLoading] = useState(false); // Loading state for analysis
  const [stockfish, setStockfish] = useState(null);
  const [repertoireMoves, setRepertoireMoves] = useState([]);
  const [selectedMoveId, setSelectedMoveId] = useState("root");
  const [openingName, setOpeningName] = useState("New Repertoire");
  const [processingPosition, setProcessingPosition] = useState(0);

  const userId = userData?.uid || "";

  const canceledRef = useRef(false);
  const showAlert = useAlert();

  /**
   * Retrieve the current theme (light or dark) using Material-UI's `useTheme` hook.
   */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Get color tokens based on the current theme mode (light or dark).
   */
  const colors = tokens(theme.palette.mode);

  const estimateGeneratedMoves = useCallback(() => {
    if (depth <= 0) return 0; // No moves if depth is zero

    let totalMoves = 0;
    let currentTurn = processingPosition % 2 === 0 ? "white" : "black"; // Whose turn it is at processingPosition

    const countMoves = (currentDepth, turn) => {
      if (currentDepth > depth) return 0; // Stop if we've reached max depth

      if (turn !== repertoireColor) {
        // Opponent's turn: Generate 'pv' branches
        let newMoves = pv;
        totalMoves += newMoves;

        for (let i = 0; i < newMoves; i++) {
          countMoves(currentDepth + 1, turn === "white" ? "black" : "white");
        }
      } else {
        // Repertoire's turn: Play only 1 best move
        totalMoves += 1;
        countMoves(currentDepth + 1, turn === "white" ? "black" : "white");
      }
    };

    countMoves(1, currentTurn);
    return totalMoves;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depth, pv, repertoireColor, game]);

  const [estimatedMoves, setEstimatedMoves] = useState(0);
  useEffect(() => {
    setEstimatedMoves(estimateGeneratedMoves());
  }, [depth, pv, repertoireColor, estimateGeneratedMoves]);

  function findOpening(gameHistory) {
    const maxMoves = 15; // Limit the search to the first 15 moves to reduce computation
    const chess = new Chess(); // Create a new instance of the Chess.js library

    // Variable to store the last matched opening
    let lastMatchedOpening = null;

    /**
     * Builds a properly formatted PGN (Portable Game Notation) string from a history of moves.
     *
     * @param {Array} history - An array of SAN strings representing a sequence of moves.
     * @returns {string} A formatted PGN string.
     */
    const buildPGN = (history) => {
      let pgn = ""; // Initialize an empty PGN string

      for (let i = 0; i < history.length; i++) {
        if (i % 2 === 0) {
          // Add the move number for White's turn
          pgn += `${Math.floor(i / 2) + 1}. `;
        }
        pgn += `${history[i]} `; // Append the move to the PGN string
      }
      return pgn.trim(); // Remove any trailing whitespace
    };

    /**
     * Extracts the position part of a FEN (Forsyth-Edwards Notation) string.
     * The position part includes only the piece placement on the board.
     *
     * @param {string} fen - A full FEN string.
     * @returns {string} The position part of the FEN string.
     */
    const extractPositionFromFEN = (fen) => fen.split(" ")[0]; // Split by space and return the first part

    // Iterate through the moves in the game's history, up to the maximum allowed moves
    for (let i = 0; i < Math.min(gameHistory.length, maxMoves); i++) {
      const currentMoves = buildPGN(gameHistory.slice(0, i + 1)); // Build PGN for the current move sequence
      chess.reset(); // Reset the board to the starting position
      chess.loadPgn(currentMoves); // Load the PGN string into the chess instance

      // Check for a direct PGN match in the openings dataset
      const pgnMatch = openings.find((opening) => opening.pgn === currentMoves);

      if (pgnMatch) {
        lastMatchedOpening = {
          ...pgnMatch, // Include all details of the matched opening
          transposition: false, // Indicates a direct match with no transposition
        };
      } else {
        // If no PGN match, check for a FEN match (possible transposition)
        const currentFEN = extractPositionFromFEN(chess.fen()); // Get the FEN of the current position
        const fenMatch = openings.find(
          (opening) => extractPositionFromFEN(opening.fen) === currentFEN
        );
        if (fenMatch) {
          lastMatchedOpening = {
            ...fenMatch, // Include all details of the matched opening
            transposition: true, // Indicates a match via transposition
          };
        }
      }
    }

    // Return the last matched opening or null if no match was found
    return lastMatchedOpening;
  }

  // Handle move on the board
  const handleMove = (sourceSquare, targetSquare) => {
    try {
      const newGame = new Chess();
      newGame.loadPgn(game.pgn());

      const move = newGame.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q",
      });

      if (!move) {
        throw new Error("Illegal move");
      }

      setGame(newGame);

      let openingName = findOpening(newGame.history());
      setOpeningName(openingName);

      setProcessingPosition(newGame.history().length);

      return true;
    } catch (error) {
      return false;
    }
  };

  // Reset board
  const resetBoard = () => {
    game.reset();
  };

  /** Handles adding a new repertoire */
  const handleAddRepertoire = async () => {
    if (totalRepertoires >= settings.limitRepertoires) {
      showAlert(
        `Maximum Number of Repertoires Exceeded (${settings.limitRepertoires}). Please upgrade to have more repertoires.`,
        "error"
      );

      return;
    }

    const moveCount = Object.keys(repertoireMoves).length;
    if (totalMoves + moveCount >= settings.limitMoves) {
      showAlert(
        `Importing this repertoire would exceed the total allowed moves (${settings.limitMoves}). Please upgrade to proceed.`,
        "error"
      );
      return;
    }

    const newRepertoireId = uuidv4();
    const newRepertoire = {
      id: newRepertoireId,
      title: openingName
        ? `${openingName.name} (${openingName.eco})`
        : "New Repertoire",
      description: "",
      author: userData?.username,
      boardOrientation: "white",
      visibility: "Private",
      allowCopy: "No",
      createdAt: Date.now(),
      order: 9999,
      moves: repertoireMoves,
    };

    try {
      await addRepertoire(userId, newRepertoire);

      setTotalMoves((prevCount) => prevCount + moveCount);
      setTotalRepertoires((prevCount) => prevCount + 1);
      // Update state and ensure the correct repertoire is selected
      setRepertoires((prev) => {
        return {
          ...prev,
          [newRepertoireId]: newRepertoire,
        };
      });
      onClose();
    } catch (error) {}
  };

  const findSelectedId = (analysedMoves, x) => {
    if (
      !analysedMoves ||
      !analysedMoves["root"] ||
      !analysedMoves["root"].next
    ) {
      return null;
    }

    let selectedId = analysedMoves["root"].next; // Start at first move
    for (let i = 1; i < x; i++) {
      // Iterate X times
      if (
        !selectedId ||
        !analysedMoves[selectedId] ||
        !analysedMoves[selectedId].next
      ) {
        return selectedId; // Return last valid move found
      }
      selectedId = analysedMoves[selectedId].next; // Move to next move
    }

    return selectedId; // Return the move ID after X moves
  };

  // Run Stockfish analysis and generate repertoire
  const generateRepertoire = async () => {
    setLoading(true);
    setRepertoireMoves([]);
    canceledRef.current = false; // Stop ongoing processing

    let startingNumberOfMoves = game.history().length;

    // ✅ Extract user moves and get last move ID
    const { moves, lastMoveId, startFen } = extractUserMoves(game);

    // ✅ Run analysis with adjusted depth
    const analyzedMoves = await analyzePosition(
      startFen, // Start from last user move FEN
      repertoireColor,
      depth, // ✅ Use adjusted depth
      1, // Start at depth 1 for Stockfish
      lastMoveId, // Last user move is the parent for new analysis
      moves // Include user moves
    );

    // ✅ Find the first best move generated by Stockfish
    const firstBestMoveId = Object.keys(analyzedMoves).find(
      (moveId) => analyzedMoves[moveId].parent === lastMoveId
    );

    // ✅ Link the last user move to the first best move
    if (firstBestMoveId) {
      analyzedMoves[lastMoveId].next = firstBestMoveId;
    }

    let processedMoves = processAnalyzedMoves(analyzedMoves);

    setRepertoireMoves(processedMoves); // Save combined user + Stockfish moves

    setSelectedMoveId(findSelectedId(processedMoves, startingNumberOfMoves));

    setLoading(false);
  };

  const processAnalyzedMoves = (analyzedMoves) => {
    const updatedMoves = {};
    const chess = new Chess();

    // ✅ Step 1: Add FEN to every move
    Object.entries(analyzedMoves).forEach(([moveId, move]) => {
      const moveSequence = getMoveSequenceInputs(analyzedMoves, moveId); // ✅ Get move sequence

      // Reset chess engine and apply moves sequentially
      chess.reset();
      moveSequence.forEach((san) => chess.move(san));

      // ✅ Compute the FEN after applying the sequence
      const fen = chess.fen();

      // ✅ Ensure move exists before modifying
      updatedMoves[moveId] = {
        ...move,
        fen, // ✅ Add the FEN field
      };
    });

    return updatedMoves;
  };

  const extractUserMoves = (chess) => {
    try {
      const history = chess.history({ verbose: true });
      const moves = {};
      let lastMoveId = "root";

      if (!moves.root) {
        moves.root = { id: "root", san: "", next: null };
      }

      let prevMoveId = "root";
      const tempChess = new Chess(); // To replay moves and get FEN

      history.forEach((move, index) => {
        try {
          tempChess.move(move.san); // Apply move to temp board
          const moveId = uuidv4();

          moves[moveId] = {
            id: moveId,
            parent: prevMoveId,
            san: move.san,
            next: null,
            variations: [],
          };

          moves[prevMoveId].next = moveId; // Link previous move
          prevMoveId = moveId;
        } catch (moveError) {}
      });

      lastMoveId = prevMoveId; // Last move played by user

      return { moves, lastMoveId, startFen: tempChess.fen() };
    } catch (error) {
      return { moves: {}, lastMoveId: "root", startFen: chess.fen() }; // Return a safe default structure
    }
  };

  const analyzePosition = (
    fen,
    color,
    maxDepth,
    currentDepth = 1,
    parentId = "root",
    moves = {}
  ) => {
    if (canceledRef.current) {
      return Promise.resolve(moves); // Exit immediately if canceled
    }

    if (currentDepth > maxDepth) {
      return Promise.resolve(moves);
    }

    setProcessingPosition((prev) => prev + 1);

    if (!stockfish) {
      return Promise.resolve(moves);
    }

    stockfish.postMessage("stop");
    return new Promise((resolve) => {
      setTimeout(() => {
        stockfish.postMessage("uci");
        stockfish.postMessage(`position fen ${fen}`);
        stockfish.postMessage(`setoption name MultiPV value ${pv}`);
        stockfish.postMessage(`go depth ${stockfishDepth}`);

        if (!moves.root) {
          moves.root = { id: "root", san: "", next: null };
        }

        let bestMove = null;
        const possibleMoves = new Set();
        const game = new Chess(fen);
        const isOpponentTurn = game.turn() !== color.charAt(0);

        const handleMessage = async (event) => {
          try {
            const message = event.data;
            if (typeof message !== "string") return;

            // ✅ Collect opponent's possible moves (Only if it's opponent's turn)
            if (
              isOpponentTurn &&
              message.startsWith(`info depth ${pv}`) &&
              message.includes(" pv ")
            ) {
              const match = message.match(/pv\s([a-h][1-8][a-h][1-8][qrbn]?)/);
              if (match) {
                possibleMoves.add(match[1]);
              }
            }

            if (message.startsWith("bestmove")) {
              bestMove = message.split("bestmove ")[1].split(" ")[0];
            }

            if (bestMove !== null) {
              stockfish.removeEventListener("message", handleMessage);
              possibleMoves.delete(bestMove);

              const newGame = new Chess(fen);
              const bestMoveObj = newGame.move({
                from: bestMove.slice(0, 2),
                to: bestMove.slice(2, 4),
                promotion: bestMove.length > 4 ? bestMove[4] : undefined,
              });

              if (!bestMoveObj) {
                resolve(moves);
                return;
              }

              const bestMoveSan = bestMoveObj.san;
              const bestMoveFen = newGame.fen();
              const bestMoveId = uuidv4();

              moves[bestMoveId] = {
                id: bestMoveId,
                parent: parentId,
                san: bestMoveSan,
                next: null,
                variations: [],
              };

              if (parentId === "root") {
                moves.root.next = bestMoveId;
              }

              // **First, process best move**
              analyzePosition(
                bestMoveFen,
                color,
                maxDepth,
                currentDepth + 1,
                bestMoveId,
                moves
              )
                .then((result) => {
                  moves[bestMoveId].next =
                    result[
                      Object.keys(result).find(
                        (m) => result[m].parent === bestMoveId
                      )
                    ]?.id || null;

                  // ✅ Only process variations if it's the opponent's turn
                  if (isOpponentTurn) {
                    return processVariationsSequentially(
                      Array.from(possibleMoves).slice(0, 4),
                      fen,
                      color,
                      maxDepth,
                      currentDepth,
                      bestMoveId,
                      moves
                    );
                  } else {
                    resolve(moves);
                  }
                })
                .then(() => resolve(moves));
            }
          } catch (error) {
            resolve(moves);
          }
        };

        stockfish.addEventListener("message", handleMessage);
      }, 100);
    });
  };

  /**
   * **Sequentially process variation moves (Only on opponent's turn)**
   */
  const processVariationsSequentially = (
    opponentMoves,
    fen,
    color,
    maxDepth,
    currentDepth,
    parentId,
    moves
  ) => {
    return new Promise((resolve) => {
      let index = 0;

      const processNextVariation = () => {
        if (index >= opponentMoves.length) {
          resolve(moves);
          return;
        }

        const uciMove = opponentMoves[index];
        index++;

        try {
          const tempGame = new Chess(fen);
          const moveObj = tempGame.move({
            from: uciMove.slice(0, 2),
            to: uciMove.slice(2, 4),
            promotion: uciMove.length > 4 ? uciMove[4] : undefined,
          });

          if (!moveObj) {
            processNextVariation();
            return;
          }

          const moveId = uuidv4();
          moves[moveId] = {
            id: moveId,
            parent: moves[parentId].parent,
            san: moveObj.san,
            next: null,
            variations: [],
          };

          moves[parentId].variations.push(moveId);

          setProcessingPosition((prev) => prev + 1);

          analyzePosition(
            tempGame.fen(),
            color,
            maxDepth,
            currentDepth + 1,
            moveId,
            moves
          ).then((result) => {
            moves[moveId].next =
              result[
                Object.keys(result).find((m) => result[m].parent === moveId)
              ]?.id || null;

            processNextVariation(); // Move to the next variation after current finishes
          });
        } catch (error) {
          processNextVariation();
        }
      };

      processNextVariation(); // Start processing the first variation
    });
  };

  const getMoveSequence = useCallback(() => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && repertoireMoves[currentMoveId]) {
      const san = repertoireMoves[currentMoveId].san;
      if (san && san.trim() !== "") {
        movePath.unshift(san); // Insert at the beginning to maintain move order
      }
      currentMoveId = repertoireMoves[currentMoveId].parent; // Move backwards to the parent
    }
    return movePath; // Return the sequence from root to selectedMoveId
  }, [selectedMoveId, repertoireMoves]);

  const getMoveSequenceInputs = (repertoireMoves, selectedMoveId) => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && repertoireMoves[currentMoveId]) {
      const san = repertoireMoves[currentMoveId].san;
      if (san && san.trim() !== "") {
        movePath.unshift(san); // Insert at the beginning to maintain move order
      }
      currentMoveId = repertoireMoves[currentMoveId].parent; // Move backwards to the parent
    }

    return movePath; // Return the sequence from root to selectedMoveId
  };

  const handleNext = useCallback(() => {
    if (!selectedMoveId) return; // No move selected

    const currentMove = repertoireMoves[selectedMoveId];
    if (!currentMove || !currentMove.next) return; // No next move available

    const nextMoveId = currentMove.next;
    const nextMove = repertoireMoves[nextMoveId];

    if (!nextMove) return; // Defensive check

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.move(nextMove.san); // Play the next move
      return newGame;
    });

    setSelectedMoveId(nextMoveId); // Update selected move
  }, [selectedMoveId, repertoireMoves, setGame, setSelectedMoveId]);

  const handleBack = useCallback(() => {
    if (
      Object.keys(repertoireMoves).length > 0 &&
      (!selectedMoveId || selectedMoveId === "root")
    )
      return;

    // If repertoireMoves is empty, just undo the last move in the current game
    if (Object.keys(repertoireMoves).length === 0) {
      setGame((prevGame) => {
        const newGame = new Chess();
        newGame.loadPgn(prevGame.pgn());
        newGame.undo();

        setProcessingPosition(newGame.history().length);
        findOpening(newGame.history());
        setOpeningName(findOpening(newGame.history()));
        return newGame;
      });
      return;
    }

    const currentMove = repertoireMoves[selectedMoveId];
    if (!currentMove || !currentMove.parent) return; // No parent = already at the start

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.undo(); // Undo the last move
      return newGame;
    });

    setSelectedMoveId(currentMove.parent); // Move back to the parent move
  }, [selectedMoveId, repertoireMoves, setGame, setSelectedMoveId]);

  const handleCancel = () => {
    canceledRef.current = true; // Stop ongoing processing
    setRepertoireMoves([]); // Clear any generated moves
    setLoading(false); // Stop loading state
  };

  useEffect(() => {
    const stockfishInstance = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    setStockfish(stockfishInstance); // Save Stockfish instance to state

    // Terminate the Stockfish instance on cleanup
    return () => {
      stockfishInstance.terminate();
    };
  }, []);

  useEffect(() => {
    try {
      if (!selectedMoveId || !repertoireMoves[selectedMoveId]) return;

      setGame(() => {
        const newGame = new Chess();
        const moveSequence = getMoveSequence();

        moveSequence.forEach((san) => {
          newGame.move(san); // Apply each move to reconstruct the game state
        });

        return newGame;
      });
    } catch (error) {}
  }, [selectedMoveId, repertoireMoves, getMoveSequence]);

  return (
    <Dialog
      sx={{
        backgroundImage: "none !important",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900], // Text color
          border: `0.5px solid #222222`, // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {useDialogFocused(open)}
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AutoAwesomeSharpIcon />
          <Typography variant="h6">Repertoire Wizard</Typography>
        </Stack>
      </DialogTitle>
      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row", // ✅ Stack vertically on mobile
            gap: 2,
            alignItems: "flex-start",
            width: "100%",
            margin: "0 auto",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "400px", // ✅ Full width on mobile
              flexShrink: 0,
            }}
          >
            {/* Chessboard */}
            <Chessboard
              position={game.fen()}
              onPieceDrop={handleMove}
              arePiecesDraggable={Object.keys(repertoireMoves).length === 0}
              areArrowsAllowed={false}
              boardOrientation={repertoireColor}
              customLightSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .lightSquare,
                backgroundImage:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .lightSquarePattern,
              }}
              customDarkSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .darkSquare,
                backgroundImage:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .darkSquarePattern,
              }}
              customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
            />

            {/* Chessboard Buttons */}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              textAlign="left"
              sx={{ pt: 2, pb: 2, width: "100%" }}
            >
              <Tooltip title="Reset the game">
                <span>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedMoveId("root");
                      setGame(new Chess());
                      if (!repertoireMoves || repertoireMoves.length === 0) {
                        setOpeningName("");
                      }
                    }}
                    style={{ marginRight: 10 }}
                    disabled={
                      selectedMoveId === "root" &&
                      (Object.keys(repertoireMoves).length > 0 ||
                        game.history().length === 0)
                    }
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <ReplayIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to previous move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    disabled={
                      selectedMoveId === "root" &&
                      (Object.keys(repertoireMoves).length > 0 ||
                        game.history().length === 0)
                    }
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to next move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={!repertoireMoves[selectedMoveId]?.next}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Toggle board orientation">
                <span>
                  <Button
                    variant="contained"
                    onClick={() =>
                      setRepertoireColor((prev) =>
                        prev === "white" ? "black" : "white"
                      )
                    }
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <SwapVertRoundedIcon />
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              minWidth: 0,
              maxHeight: isMobile ? "100%" : "400px", // ✅ Cap height on mobile
              overflowY: isMobile ? "auto" : "visible", // ✅ Allow scrolling on mobile
              width: "100%",
            }}
          >
            {Object.keys(repertoireMoves).length === 0 ? ( // ✅ Show options only if no moves are generated
              <>
                <Box sx={{ textAlign: "left", width: "100%" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline",
                      whiteSpace: "normal", // ✅ Allows natural wrapping
                      lineHeight: "2rem", // ✅ Ensures uniform line height
                    }}
                  >
                    I want to create a repertoire for{" "}
                    <Box
                      component="span"
                      sx={{
                        display: "inline-flex",
                        borderBottom: `1px dashed ${colors.black[300]}`, // ✅ Permanent dashed border
                        alignItems: "center",
                        minHeight: "2rem", // ✅ Matches line height
                      }}
                    >
                      <Select
                        value={repertoireColor}
                        onChange={(e) => setRepertoireColor(e.target.value)}
                        variant="standard"
                        disableUnderline
                        sx={{
                          minWidth: "70px",
                          height: "2rem", // ✅ Match line height
                          "&:before, &:after": { display: "none" },
                        }}
                      >
                        <MenuItem value="white">White</MenuItem>
                        <MenuItem value="black">Black</MenuItem>
                      </Select>
                    </Box>{" "}
                    and{" "}
                    <Box
                      component="span"
                      sx={{
                        display: "inline-flex",
                        borderBottom: `1px dashed ${colors.black[300]}`,
                        alignItems: "center",
                        minHeight: "2rem",
                      }}
                    >
                      <TextField
                        variant="standard"
                        type="number"
                        value={depth}
                        onChange={(e) =>
                          setDepth(
                            Math.max(1, Math.min(20, Number(e.target.value)))
                          )
                        }
                        inputProps={{ min: 1, max: 20 }}
                        sx={{
                          width: "40px",
                          textAlign: "center",
                          height: "2rem",
                          "& .MuiInputBase-root": { height: "2rem" }, // ✅ Consistent height
                          "& .MuiInputBase-root::before, & .MuiInputBase-root::after":
                            {
                              display: "none",
                            },
                        }}
                      />
                    </Box>{" "}
                    half-moves from the position on the board
                    {openingName &&
                      openingName.name &&
                      ` (${openingName.name})`}
                    , with{" "}
                    <Box
                      component="span"
                      sx={{
                        display: "inline-flex",
                        borderBottom: `1px dashed ${colors.black[300]}`,
                        alignItems: "center",
                        minHeight: "2rem",
                      }}
                    >
                      <TextField
                        variant="standard"
                        type="number"
                        value={pv}
                        onChange={(e) =>
                          setPV(
                            Math.max(1, Math.min(5, Number(e.target.value)))
                          )
                        }
                        inputProps={{ min: 1, max: 5 }}
                        sx={{
                          width: "40px",
                          textAlign: "center",
                          height: "2rem",
                          "& .MuiInputBase-root": { height: "2rem" },
                          "& .MuiInputBase-root::before, & .MuiInputBase-root::after":
                            {
                              display: "none",
                            },
                        }}
                      />
                    </Box>{" "}
                    branches (possible moves for{" "}
                    {repertoireColor === "black" ? "White" : "Black"}), and
                    using Stockfish depth{" "}
                    <Box
                      component="span"
                      sx={{
                        display: "inline-flex",
                        borderBottom: `1px dashed ${colors.black[300]}`,
                        alignItems: "center",
                        minHeight: "2rem",
                      }}
                    >
                      <TextField
                        variant="standard"
                        type="number"
                        value={stockfishDepth}
                        onChange={(e) =>
                          setStockfishDepth(
                            Math.max(1, Math.min(20, Number(e.target.value)))
                          )
                        }
                        inputProps={{ min: 1, max: 20 }}
                        sx={{
                          width: "40px",
                          textAlign: "center",
                          height: "2rem",
                          "& .MuiInputBase-root": { height: "2rem" },
                          "& .MuiInputBase-root::before, & .MuiInputBase-root::after":
                            {
                              display: "none",
                            },
                        }}
                      />
                    </Box>
                    .
                  </Typography>
                </Box>

                <Box sx={{ width: "100%", textAlign: "left", pt: 2 }}>
                  {/* Moves & Percentage Text */}

                  {loading && estimatedMoves > 0 && (
                    <>
                      <Typography>
                        Number of Moves: {processingPosition}
                      </Typography>
                      <Typography>
                        Estimated Moves: {estimatedMoves} |{" "}
                        {`${Math.min(
                          (processingPosition / estimatedMoves) * 100,
                          100
                        ).toFixed(1)}%`}
                      </Typography>

                      {/* Linear Progress Bar */}
                      <Box sx={{ width: "100%", mt: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={Math.min(
                            (processingPosition / estimatedMoves) * 100,
                            100
                          )} // Cap at 100%
                          sx={{
                            height: 20, // Thicker progress bar
                            borderRadius: 0, // Rounded edges
                            backgroundColor: "#e0e0e0", // Light grey background
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: colors.green[100], // Info blue color
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Box>

                {!loading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Aligns icon and text
                      backgroundColor: "#E3F2FD", // Light blue background for info
                      border: "1px solid #42A5F5", // Info blue border
                      borderRadius: "4px", // Rounded corners
                      padding: "12px", // Padding for spacing
                      gap: "10px", // Space between icon and text
                      width: "100%", // Ensure it takes full width
                      maxWidth: "100%", // Prevent unexpected shrink
                      overflow: "visible", // Ensures content is not clipped
                      mt: 1,
                    }}
                  >
                    <InfoOutlinedIcon sx={{ color: "#1E88E5", fontSize: 24 }} />{" "}
                    {/* Info icon */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#000000",
                        whiteSpace: "normal", // Allows text to wrap
                        wordWrap: "break-word", // Ensures long words break properly
                        flex: 1, // Allows text to take up remaining space
                      }}
                    >
                      Based on <strong>{depth}</strong> half-moves from this
                      position, we estimate generating{" "}
                      <strong>{estimatedMoves}</strong> moves.
                    </Typography>
                  </Box>
                )}
                {stockfishDepth > 12 && !loading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Aligns icon and text
                      backgroundColor: "#FFFAE6", // Light yellow background
                      border: "1px solid #FFC107", // Warning yellow border
                      borderRadius: "4px", // Rounded corners
                      padding: "12px", // Padding for spacing
                      gap: "10px", // Space between icon and text
                      width: "100%", // Ensure it takes full width
                      maxWidth: "100%", // Prevent unexpected shrink
                      overflow: "visible", // Ensures content is not clipped
                      mt: 1,
                    }}
                  >
                    <WarningAmberIcon sx={{ color: "#FFA000", fontSize: 24 }} />{" "}
                    {/* Warning icon */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#000000",
                        whiteSpace: "normal", // Allows text to wrap
                        wordWrap: "break-word", // Ensures long words break properly
                        flex: 1, // Allows text to take up remaining space
                      }}
                    >
                      Higher Stockfish depth settings may increase processing
                      time.
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Typography variant="body1" sx={{ pb: 1 }}>
                  {openingName?.name}
                </Typography>
                <Typography variant="body1" sx={{ pb: 1 }}>
                  Number of Moves: {processingPosition}
                </Typography>
                <MovesCard
                  moves={repertoireMoves}
                  selectedMoveId={selectedMoveId}
                  setSelectedMoveId={setSelectedMoveId}
                />
              </>
            )}
          </Box>
        </Box>
      </DialogContent>

      <Divider />
      <DialogActions sx={{ padding: 2, justifyContent: "flex-end" }}>
        {Object.keys(repertoireMoves).length > 0 && (
          <Button
            variant="contained"
            onClick={() => {
              resetBoard(); // Reset the chessboard
              setSelectedMoveId("root"); // Set selected move to root
              setRepertoireMoves([]); // Clear repertoire moves
              setOpeningName("");
              setProcessingPosition(0);
            }}
            startIcon={<RestartAltRoundedIcon />}
          >
            Restart
          </Button>
        )}

        {loading ||
          (Object.keys(repertoireMoves).length === 0 && (
            <Button
              variant="contained"
              disabled={loading || Object.keys(repertoireMoves).length > 0}
              onClick={generateRepertoire}
              sx={{
                color: "white",
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
              startIcon={<HandymanIcon />}
            >
              Build Repertoire
            </Button>
          ))}

        {loading && (
          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{
              color: "white",
              backgroundColor: colors.red[500],
              "&:hover": {
                backgroundColor: colors.red[500],
              },
            }}
          >
            Cancel
          </Button>
        )}
        {Object.keys(repertoireMoves).length > 0 && (
          <Button
            onClick={handleAddRepertoire}
            variant="contained"
            color="primary"
            sx={{
              color: "white",
              backgroundColor: colors.green[100],
              "&:hover": {
                backgroundColor: colors.green[200],
              },
            }}
            startIcon={<SaveRoundedIcon />}
          >
            Save Repertoire
          </Button>
        )}
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RepertoireWizardDialog;
